import React from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Container,
  LinearProgress,
  Box,
  Typography,
  Button
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';

import {
  stopScanProducts,
  startPauseScanProducts,
  getControls,
  fetchProducts
} from '../../../reducers/Barcodes';

const ProgressContainer = ({
  progress,
  totalCount,
  stop,
  play,
  playTrigger,
  startFetching
}) => (
  <>
    <div className="divider my-4" />

    <Card>
      <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-0">
        <Container className="py-4">
          <div className="px-0 pb-4">
            <div>
              <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                Fetching Products Info
              </h6>
              <p className="text-black-50 mb-0">
                Please wait until we found your products.
              </p>
            </div>
          </div>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                value={(progress * 100) / totalCount}
              />
            </Box>
            <Box minWidth={50}>
              <Typography variant="body2" color="textSecondary">
                {`${progress} / ${totalCount}`}
              </Typography>
            </Box>
            <Box minWidth={50}>
              <div className="d-flex align-items-center justify-content-center flex-wrap">
                <Button
                  onClick={() =>
                    play.isPaused ? startFetching() : playTrigger()
                  }
                  className="btn-success btn-icon btn-pill d-20 p-0 m-2">
                  <span className="btn-wrapper--icon">
                    {play.isPaused ? (
                      <PlayArrowIcon className="font-size-xs" />
                    ) : (
                      <PauseIcon className="font-size-xs" />
                    )}
                  </span>
                </Button>
                <Button
                  onClick={() => stop()}
                  className="btn-danger btn-icon btn-pill d-20 p-0 m-2">
                  <span className="btn-wrapper--icon">
                    <StopIcon className="font-size-xs" />
                  </span>
                </Button>
              </div>
            </Box>
          </Box>
        </Container>
      </div>
    </Card>
  </>
);
const mapStateToProps = (state) => ({
  play: getControls(state)
});
const mapDispatchToProps = (dispatch) => ({
  startFetching: () => dispatch(fetchProducts()),
  stop: () => dispatch(stopScanProducts()),
  playTrigger: () => dispatch(startPauseScanProducts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressContainer);
