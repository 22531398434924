import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import InvalidSubscription from './components/InvalidSubscription';
import { Card } from '@material-ui/core';
import { getIsAuthenticated } from './reducers/UserAccount';
import {
  getSubscription,
  getIsLoadingSubscription
} from './reducers/UserSubscriptions';
import { MoonLoader } from 'react-spinners';
const ProtectedRoute = ({
  isAuthenticated,
  subscription,
  isLoadingSubscription,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        props.location.pathname === '/app/payment' ||
        props.location.pathname === '/app/settings' ||
        // props.location.pathname === '/app/home' ||
        props.location.pathname === '/app/plans' ||
        props.location.pathname === '/app/notifications' ||
        props.location.pathname === '/app/ticket' ? (
          <Component {...props} />
        ) : !isLoadingSubscription ? (
          subscription != null &&
          (subscription.status === 'active' ||
            subscription.status === 'trialing') ? (
            <Component {...props} />
          ) : subscription != null && subscription.status === 'past_due' ? (
            <InvalidSubscription status={subscription.status} />
          ) : (
            <InvalidSubscription />
          )
        ) : (
          <Card className="rounded-sm card-box shadow-none p-3 m-3">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: '100%', height: '100%' }}>
              <MoonLoader color={'var(--primary)'} loading={true} />
            </div>
            <p className="mb-0 pt-3 text-black-50 text-center">
              Please wait we are loading user details
            </p>
          </Card>
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
  subscription: getSubscription(state),
  isLoadingSubscription: getIsLoadingSubscription(state)
});

export default connect(mapStateToProps)(ProtectedRoute);
