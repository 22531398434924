import React from 'react';
import Container from '@material-ui/core/Container';

const TabItemWrapper = ({ title, subtitle, children }) => (
  <Container className="py-3">
    <div className="card-header d-flex align-items-center bg-transparent card-header-alt px-0 py-0">
      <div>
        <h6 className="font-weight-bold font-size-xl mb-1 text-black">
          {title}
        </h6>
        <p className="text-black-50 mb-0">{subtitle}</p>
      </div>
    </div>

    <div className="py-3">{children}</div>
  </Container>
);

export default TabItemWrapper;
