import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fromUnixTime } from 'date-fns';

import {
  Button,
  Card,
  Snackbar,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CardContent,
  DialogTitle
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { axiosIns } from '../../../config/configAxios';

import { getIsUploadingUser, getUser, fetchUser } from 'reducers/UserAccount';
import { getPlans, getIsLoadingPlans, loadPlans } from 'reducers/Plans';
import {
  getSubscription,
  getIsLoadingSubscription,
  getIsUploadingSubscription,
  loadSubscription,
  deleteSubscription,
  upgradeSubscription
} from 'reducers/UserSubscriptions';

import CardInput from './CardInput';
import InvoiceHistory from '../InvoiceHistory';
import UsageBox from '../UsageBox';
import PaymentMethods from '../PaymentMethods';
// import RetryFailedInvoiceModal from './RetryFailedInvoiceModal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faArrowsRotate,
  faExchange
} from '@fortawesome/free-solid-svg-icons';
import Subscriptions from '../Subscriptions';
import UpcomingBill from '../UpcomingBill';
import CreditBalance from './CreditBalance';
import OwedBalance from './OwedBalance';

const Payment = ({
  user,
  startLoading,
  loadUser,
  loadUserSubscription,
  isLoadingSubscription,
  subscription
}) => {
  const [error, setError] = useState(false);
  const [retryProp] = useState(false);
  const [errorMsg] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  useEffect(() => {
    startLoading();
    loadUserSubscription();
  }, [loadUserSubscription, startLoading]);

  useEffect(() => {
    setIsLoading(true);
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    axiosIns.get('/users/payments/card-source').then((response) => {
      setIsLoading(false);
      setInvoices(response.data);
    });
  }, [user]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSuccess(false);
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const AddOrUpdatePaymentMethod = ({ handleClickOpen2, invoices }) => (
    <>
      <div>
        <Button
          disabled={isLoading}
          className={
            invoices?.length > 0 ? 'btn-outline-primary' : 'btn-primary'
          }
          onClick={handleClickOpen2}>
          {invoices?.length > 0 ? (
            <>
              Update Payment Method
              <FontAwesomeIcon icon={faArrowsRotate} className="ml-2" />
            </>
          ) : (
            <>
              Add Payment Method
              <FontAwesomeIcon icon={faAdd} className="ml-2" />
            </>
          )}
        </Button>
      </div>

      <Dialog
        classes={{ paper: 'modal-content' }}
        fullWidth
        maxWidth="md"
        open={open2}
        onClose={handleClose2}
        aria-labelledby="form-dialog-title2">
        <DialogTitle>
          {invoices?.length > 0
            ? 'Update Payment Method'
            : 'Add Payment Method'}
        </DialogTitle>
        <DialogContent>
          <div className="mb-3">
            <CardInput
              loadUser={loadUser}
              handleClose2={handleClose2}
              user={user}
              retryProp={retryProp}
              retryFunctionHandle={null}
              successMsgHandle={setSuccessMsg}
              successHandle={setIsSuccess}
              invoices={invoices}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12}>
          <Card className="card-box overflow-visible mb-3">
            <div className="card-header">
              <div className="card-header--title font-size-md font-weight-bold py-2">
                <Typography
                  variant="subtitle1"
                  className="text-primary pl-2 font-size-md text-uppercase"
                  style={{ fontWeight: 'bold' }}>
                  Payment
                </Typography>
              </div>
            </div>

            {!isLoadingSubscription &&
              subscription !== null &&
              subscription?.status === 'trialing' &&
              subscription?.paymentStatus === 'paid' && (
                <Alert severity="warning">
                  {`You are using trial version. 
            ${
              !user?.hasPaymentMethod
                ? 'Please update your payment method to start automatic renewal of subscription when trial ends '
                : 'Trial will end on'
            }
              ${fromUnixTime(subscription?.endtime).toDateString()}
           `}
                </Alert>
              )}
            {!isLoadingSubscription &&
              subscription !== null &&
              subscription?.status === 'trialing' &&
              subscription?.paymentStatus === 'paid' &&
              subscription?.isTrailEnding && (
                <Alert severity="warning">
                  Your trial is ending on{' '}
                  {fromUnixTime(subscription.endtime).toDateString()}
                </Alert>
              )}

            {!isLoadingSubscription && <OwedBalance />}
            <CardContent className=" d-flex flex-column flex-lg-row justify-content-between align-items-center">
              <PaymentMethods
                user={user}
                invoices={invoices}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setInvoices={setInvoices}
              />
              <AddOrUpdatePaymentMethod
                handleClickOpen2={handleClickOpen2}
                invoices={invoices}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} md={12}>
          <Card className="card-box overflow-visible mb-3">
            <div className="card-header">
              <div className="card-header--title font-size-md font-weight-bold py-2">
                <Typography
                  variant="subtitle1"
                  className="text-primary pl-2 font-size-md text-uppercase"
                  style={{ fontWeight: 'bold' }}>
                  Subscriptions
                </Typography>
              </div>
            </div>
            <CardContent>
              <Subscriptions />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} md={12}>
          <Card className="card-box overflow-visible mb-3">
            <div className="card-header">
              <div className="card-header--title font-size-md font-weight-bold py-2 d-flex justify-content-between align-items-center">
                <Typography
                  variant="subtitle1"
                  className="text-primary pl-2 font-size-md text-uppercase"
                  style={{ fontWeight: 'bold' }}>
                  Plan
                </Typography>
                <Grid item lg={2} md={2}>
                  <Link to={'/app/plans'}>
                    <Button className="btn-outline-primary">
                      Change Plan
                      <FontAwesomeIcon icon={faExchange} className="ml-2" />
                    </Button>
                  </Link>
                </Grid>
              </div>
            </div>
            <CardContent className="p-0">
              <UsageBox subscription={subscription} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} md={12}>
          <Card className="card-box overflow-visible mb-3">
            <div className="card-header">
              <div className="card-header--title font-size-md font-weight-bold py-2">
                <Typography
                  variant="subtitle1"
                  className="text-primary pl-2 font-size-md text-uppercase"
                  style={{ fontWeight: 'bold' }}>
                  Upcoming Bills
                </Typography>
              </div>
            </div>
            <CardContent className="p-4">
              <UpcomingBill />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} md={12}>
          <Card className="card-box overflow-visible mb-3">
            <div className="card-header">
              <div className="card-header--title font-size-md font-weight-bold py-2">
                <Typography
                  variant="subtitle1"
                  className="text-primary pl-2 font-size-md text-uppercase"
                  style={{ fontWeight: 'bold' }}>
                  Invoice History
                </Typography>
              </div>
            </div>
            <CardContent className="p-4">
              <InvoiceHistory />
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={4} md={4}>
          <CreditBalance />
        </Grid>
      </Grid>

      <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert severity="success">No Payment Method Found</Alert>
      </Snackbar>
      <Snackbar open={isSuccess} autoHideDuration={1500} onClose={handleClose}>
        <Alert severity="success">{successMsg}</Alert>
      </Snackbar>
      <Dialog
        open={error}
        onClose={() => setError(false)}
        classes={{ paper: 'shadow-lg rounded' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <ErrorOutlineIcon />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Error!</h4>
          <p className="mb-0 font-size-lg text-muted">{errorMsg}</p>
          <div className="pt-4">
            <Button
              onClick={() => {
                setError(false);
                loadUserSubscription();
              }}
              className="btn-danger btn-pill mx-1">
              <span className="btn-wrapper--label">Close</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  isUploading: getIsUploadingUser(state),
  plans: getPlans(state),
  isLoading: getIsLoadingPlans(state),
  isLoadingSubscription: getIsLoadingSubscription(state),
  isUploadingSubscription: getIsUploadingSubscription(state),
  subscription: getSubscription(state)
});
const mapDispatchToProps = (dispatch) => ({
  loadUser: (data) => dispatch(fetchUser(data)),

  startLoading: () => dispatch(loadPlans()),
  loadUserSubscription: () => dispatch(loadSubscription()),
  deleteSubscription: (subscriptionId, modalHandle) =>
    dispatch(deleteSubscription(subscriptionId, modalHandle)),
  createUpgrade: (invoiceId) => dispatch(upgradeSubscription(invoiceId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
