import React from 'react';

import { Grid, Container, Card, List, ListItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrow from 'assets/images/stock-photos/arrow.png';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

const PROBLEMS = [
  ' Listing products is time-consuming for my team',
  'Manual product listing costs time and money',
  'We rely on marketplaces for sales',
  'Inventory changes daily',
  'We sell liquidation and surplus products',
  'Third-party fees eat into profits',
  'Need to build a strong brand to differentiate from competitors',
  'Lack technical knowledge to run an online store efficiently'
];

export default function LivePreviewExample() {
  return (
    <>
      <Container>
        <Grid container alignItems="center" justify="center">
          <Grid item md={6}>
            <Grid item md={12}>
              <div className="d-flex align-items-center justify-content-end  mb-3 mb-md-0">
                <h3 className="display-3 font-weight-bold text-center  pl-lg-5 pl-md-5 pr-md-0 pb-lg-3 pb-md-3">
                  If any of this sounds familiar, you need Cartlauncher
                </h3>
                <img
                  src={arrow}
                  width={60}
                  alt="arrow"
                  className="d-none d-md-block"
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            className="d-flex align-items-center">
            <Card className="bg-secondary m-lg-0 object-skew hover-scale-lg shadow-xl w-100 card-box">
              <List component="div" className="list-group-flush">
                {PROBLEMS.map((problem, index) => (
                  
                <ListItem key={`problem-${index}`} className="d-flex rounded-top align-items-center py-1">
                  <div className="d-flex align-items-center">
                    <div className="mr-1 d-50 text-success">
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </div>
                    <div>
                      <div className="font-weight-bold ">
                      {problem}
                      </div>
                    </div>
                  </div>
                </ListItem>
                ))}
              
                {/* <ListItem className="d-flex align-items-center py-1">
                  <div className="d-flex align-items-center">
                    <div className="mr-1 d-50 text-success">
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        pending hours Listing products manually one by one ?
                      </div>
                    </div>
                  </div>
                </ListItem> */}
              </List>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* <div className="d-flex align-items-center justify-content-center text-center mx-lg-5">
        <div className="mx-s-5 mx-xs-5 px-s-5 p-xs-0">
          <h1 class="text-center display-3 mb-2 font-weight-bold  ">
            <span className="text-primary">Seriously !</span> is this the best
            way to run your liquidation business ?
          </h1>
        </div>
      </div>
      <Container>
        <Grid container md={12}>
          <Grid item lg={4}>
            <div className="p-4 d-flex flex-column align-items-center">
              <div className="d-flex align-items-center justify-content-between py-2">
                <div className="d-50 border-0 my-2 card-icon-wrapper bg-danger rounded-circle text-white btn-icon text-center ">
                  <FontAwesomeIcon icon={faTimes} className="font-size-xxl" />
                </div>
              </div>
              <div className=" font-weight-bold pt-2  text-left display-4 ">
                Spending hours listing products manually one by one ?
              </div>
              <Typography variant="body1" className="mt-2">
                Time = money the less time you spend on managing and adding
                products to you store the more money you save!{' '}
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4}>
            <div className="p-4 d-flex flex-column align-items-center">
              <div className="d-flex align-items-center justify-content-between py-2">
                <div className="d-50 border-0 my-2 card-icon-wrapper bg-danger rounded-circle text-white btn-icon text-center">
                  <FontAwesomeIcon icon={faTimes} className="font-size-xxl" />
                </div>
              </div>
              <div className=" font-weight-bold pt-2  text-left display-4 ">
                Not investing in building your own brand ?
              </div>
              <Typography variant="body1" className="mt-2">
                {' '}
                Shoppers focus their attention on products, not on the sellers.
                For that, it is impossible to develop your unique brand and
                build a loyal customer base.{' '}
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4}>
            <div className="p-4 d-flex flex-column align-items-center">
              <div className="d-flex align-items-center justify-content-between py-2">
                <div className="d-50 border-0 my-2 card-icon-wrapper bg-danger rounded-circle text-white btn-icon text-center">
                  <FontAwesomeIcon icon={faTimes} className="font-size-xxl" />
                </div>
              </div>
              <div className=" font-weight-bold pt-2 text-left display-4 ">
                Using generic CMS like shopify or ecwid?
              </div>
              <Typography variant="body1" className="mt-2">
                Limited performance and require more tehcnical skills to run in
                addition the extra and hidden costs associated with apps and
                custom store features..
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Grid item lg={4}>
            <div className="p-4 d-flex flex-column align-items-center">
              <div className="d-flex align-items-center justify-content-center py-2 w-100">
                <div className="d-50 border-0 my-2 card-icon-wrapper bg-danger rounded-circle text-white btn-icon text-center">
                  <FontAwesomeIcon icon={faTimes} className="font-size-xxl" />
                </div>
              </div>
              <div className=" font-weight-bold pt-2 text-left display-4 ">
                Relying solely on marketplace to sell ?
              </div>
              <Typography variant="body1" className="mt-2">
                {' '}
                Putting all your eggs in one basket is risky, if it fails, you
                have no alternatives left!
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4}>
            <div className="p-4 d-flex flex-column align-items-center ">
              <div className="d-flex align-items-center justify-content-between py-2">
                <div className="d-50 border-0 my-2 card-icon-wrapper bg-danger rounded-circle text-white btn-icon text-center">
                  <FontAwesomeIcon icon={faTimes} className="font-size-xxl" />
                </div>
              </div>
              <div className=" font-weight-bold pt-2  text-left display-4">
                Investing thousand of dollars hiring an agency to build your
                store?
              </div>
              <Typography variant="body1" className="mt-2">
                Premium admin template powered by the most popular UI components
                framework available for React: Material-UI.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4}>
            <div className="p-4 d-flex flex-column align-items-center">
              <div className="d-flex align-items-center justify-content-between py-2">
                <div className="d-50 border-0 my-2 card-icon-wrapper bg-danger rounded-circle text-white btn-icon text-center">
                  <FontAwesomeIcon icon={faTimes} className="font-size-xxl" />
                </div>
              </div>
              <div className=" font-weight-bold pt-2 text-left display-4 ">
                Spending hours Listing products manually one by one ?
              </div>
              <Typography variant="body1" className="mt-2">
                Premium admin template powered by the most popular UI components
                framework available for React: Material-UI.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container> */}
    </>
  );
}
