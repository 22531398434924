import { axiosIns, axiosIns2 } from '../config/configAxios';
import {
  getStoreListStarted,
  getStoreListSuccess,
  getStoreListFailed
} from './Store';

/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const GET_FORMS = 'GET_FORNS';
export const getForms = () => ({
  type: GET_FORMS
});
export const ADD_FORMS = 'ADD_FORNS';
export const AddForms = (order) => ({
  type: ADD_FORMS,
  payload: { order }
});

export const UPDATE_FORM = 'UPDATE_FORM';
export const updateOrder = (order) => ({
  type: UPDATE_FORM,
  payload: { order }
});

export const UPDATE_FORMS = 'UPDATE_FORMS';
export const updateOrders = (data) => ({
  type: UPDATE_FORMS,
  payload: { data }
});

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const deleteForm = (id) => ({
  type: DELETE_REQUEST,
  payload: { id }
});

export const LOADING_IN_PROGRESS = 'FORMS_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'FORMS_LOADING_SUCCESS';
export const loadingSuccess = (orders) => ({
  type: LOADING_SUCCESS,
  payload: { orders }
});

export const LOADING_FAILURE = 'FORMS_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});
export const SHOW_ERROR = 'SHOW_ERROR';
export const showError = (error) => ({
  type: SHOW_ERROR,
  payload: { error }
});
export const NOTIFY_USER = 'NOTIFY_USER';
export const notifyUser = (message) => ({
  type: NOTIFY_USER,
  payload: { message }
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = { isLoading: false, data: [], error: null };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_FORMS: {
      const { order } = payload;
      const data = state.data.concat(order);
      return { ...state, data };
    }
    case UPDATE_FORM: {
      const { order } = payload;
      const data = state.data.map((o) => {
        if (o._id === order._id) return order;
        return o;
      });
      return { ...state, data, error: '' };
    }
    case NOTIFY_USER: {
      const { order } = payload;
      const data = state.data.map((o) => {
        if (o._id === order._id) return order;
        return o;
      });
      return { ...state, data };
    }
    case UPDATE_FORMS: {
      const { ids, attributes } = payload.data;

      let orders = [...state.data];

      ids.forEach((id) => {
        if ('isActive' in attributes) {
          orders = orders.filter((p) => p._id !== id);
        } else {
          const i = orders.findIndex((p) => p._id === id);
          Object.assign(orders[i], attributes);
        }
      });

      return { ...state, data: orders };
    }
    case DELETE_REQUEST: {
      const { id } = payload;
      return {
        ...state,
        data: state.data.filter((p) => p._id !== id)
      };
    }
    case LOADING_SUCCESS: {
      const { orders } = payload;
      return { ...state, isLoading: false, data: orders };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    case SHOW_ERROR: {
      console.log(payload.error);
      return { ...state, error: payload.error, isLoading: false };
    }
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const addForm = (data, props) => async (dispatch, getState) => {
  const {
    setSubmitting,
    // setStatus,
    resetForm,
    // toggleModal,
    seterror,
    setErrors
  } = props;
  setSubmitting(true);

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.post('/forms', data);
    if (res.status === 201) {
      dispatch(AddForms(res.data));
      // setStatus({ success: true });
      resetForm({});
      // toggleModal();
    }
  } catch (err) {
    console.log(err);
    // console.log()
    setErrors(err);
    if (err.response.status === 400) {
      seterror({ status: true, msg: err.response.data.errors[0].msg });
    }
  } finally {
    setSubmitting(false);
  }
};

export const initLoadingOrders = () => async (dispatch, getState) => {
  dispatch(getStoreListStarted());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/stores');
    const storeList = response.data;

    if (storeList.length > 0) dispatch(loadOrders(storeList[0]._id));
    dispatch(getStoreListSuccess(storeList));
  } catch (err) {
    dispatch(getStoreListFailed(err));
  }
};

export const loadOrders = (id, formType = 'product') => async (
  dispatch,
  getState
) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    console.log(formType);
    let query =
      formType === 'product'
        ? '?isProductRequest=true'
        : formType === 'contact'
        ? '?isContact=true'
        : formType === 'newsletter'
        ? '?isNewsLetter=true'
        : '';
    const response = await axiosIns.get(`/forms${query}`);
    const orders = response.data;

    dispatch(loadingSuccess(orders));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

export const updateOrderById = (id, data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.put(`/forms/${id}`, data);
    const newOrder = response.data;

    dispatch(updateOrder(newOrder));
  } catch (err) {
    console.log(err);
  }
};

export const updateMultipleOrders = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch('/orders', data);

    response.data &&
      response.data.nModified > 0 &&
      dispatch(updateOrders(data));
  } catch (err) {
    console.log(err);
  }
};

export const deleteComment = (id, commentId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.delete(
      `/orders/order_comment/${id}/${commentId}`
    );
    const newOrder = response.data;

    dispatch(updateOrder(newOrder));
  } catch (err) {
    console.log(err);
  }
};
export const emailUserNotify = (id, body, props) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.put(`/forms/notify/${id}`, {
      ...body
    });
    const newOrder = response.data;
    dispatch(updateOrder(newOrder));
    props.toggleModal();
  } catch (err) {
    dispatch(showError(err.response.data.message));
  }
};

export const deleteRequest = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    console.log(id);
    await axiosIns.delete(`/forms/${id}`);

    dispatch(deleteForm(id));
    // props.toggleModal();
  } catch (err) {
    console.log(err);
  }
};
/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getOrders = (state) => state.Forms.data;
export const getIsLoadingForms = (state) => state.Forms.isLoading;
export const getErrors = (state) => state.Forms.error;
