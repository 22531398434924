import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  CircularProgress,
  Divider,
  ListItemText
} from '@material-ui/core';

import { updateMultipleOrders, initLoadingOrders } from 'reducers/Orders';
import { getUser } from 'reducers/UserAccount';
import { axiosIns } from 'config/configAxios';

const Dividers = ({ children, ...props }) => (
  <Grid disabled container alignItems="center" spacing={3} {...props}>
    <Grid item xs>
      <Divider disabled />
    </Grid>
    <Grid item disabled>
      {children}
    </Grid>
    <Grid item xs>
      <Divider disabled />
    </Grid>
  </Grid>
);

const useStyles = makeStyles((theme) => ({
  stickyHeader: {
    [theme.breakpoints.down('sm')]: {
      background: '#f8f9ff',
      position: 'sticky !important',
      top: '58px',
      zIndex: '1151',
      borderRadius: '20px'
    }
  }
}));

const BulkActions = (props) => {
  const classes = useStyles();
  const { selected, setSelected, ordersCount, updateOrders, user } = props;

  const actions = [
    {
      id: 'status',
      name: 'Update order status',
      type: 'dropdown',
      values: [
        'New',
        'Pending',
        'Ready',
        'Shipped',
        'Delivered',
        'Completed',
        'Cancelled'
      ]
    },
    {
      id: 'invoice',
      name: 'Generate Batch Invoice',
      type: 'noinput',
      values: []
    },
    // Bulk post to shipstation
    {
      id: 'shipStation',
      name: 'Push to Shipstation',
      type: 'noinput',
      values: []
    }
  ];
  const nonAppActions = [
    {
      id: 'status',
      name: 'Update order status',
      type: 'dropdown',
      values: [
        'New',
        'Pending',
        'Ready',
        'Shipped',
        'Delivered',
        'Completed',
        'Cancelled'
      ]
    },
    {
      id: 'invoice',
      name: 'Generate Batch Invoice',
      type: 'noinput',
      values: []
    }
  ];
  const appActions = [
    // Bulk post to shipstation
    {
      id: 'shipStation',
      name: 'Push to Shipstation',
      type: 'noinput',
      values: []
    }
  ];
  const [action, setAction] = useState('');
  const handleAction = (event) => {
    const value = event.target.value;
    const action = actions.find((a) => a.id === value);
    setAction(action);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [actionValue, setActionValue] = useState('');
  const handleActionValue = (event) => setActionValue(event.target.value);
  const getInvoice = () => {
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    setIsLoading(true);
    axiosIns
      .post('/orders/batch_invoice/', { ids: selected })
      .then(async (res) => {
        setIsLoading(false);
        setAction('');
        setActionValue('');
        setSelected([]);

        const linkSource = res.data;
        const downloadLink = document.createElement('a');
        linkSource.forEach((e) => {
          const fileName = 'invoice_' + e.serialNo + '.pdf';
          downloadLink.href = e.invoice;
          downloadLink.download = fileName;
          downloadLink.click();
        });
      });
  };
  const pushShipStation = () => {
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    setIsLoading(true);
    axiosIns
      .post('/orders/bulkshipstation/', { ids: selected })
      .then(async (res) => {
        props.loadOrders(res.data);
        setAction('');
        setActionValue('');
        setSelected([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onActionSubmit = () => {
    let attributes = {};
    if (action.id === 'isActive') attributes[action.id] = false;
    else attributes[action.id] = actionValue;
    if (action.id === 'shipStation') {
      pushShipStation();
    }
    if (action.id === 'invoice') getInvoice();
    else {
      updateOrders({ ids: selected, attributes });

      setAction('');
      setActionValue('');
      setSelected([]);
    }
  };
  const checkAppStatus = (actionId) => {
    const apps = props.user?.installed_apps;
    let selectedApp = null;
    switch (actionId) {
      case 'shipStation':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === 'shipstation_app'
        );
        break;

      default:
        return;
    }
    return selectedApp;
  };
  return (
    <div
      className={`p-4 bg-secondary ${
        classes.stickyHeader ? classes.stickyHeader : ''
      }`}>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <FormControl variant="outlined" size="small" className="w-100">
            <InputLabel id="action-type-select-label">Actions</InputLabel>
            <Select
              fullWidth
              labelId="action-type-select-label"
              label="Actions"
              value={action.id ?? ''}
              onChange={handleAction}>
              <Divider />
              {nonAppActions.map((a) => (
                <MenuItem className="mx-2" value={a.id} key={a.id}>
                  {a.name}
                </MenuItem>
              ))}
              <Dividers disabled>APPS</Dividers>
              {appActions.map(
                (a) =>
                  checkAppStatus(a.id) && (
                    <MenuItem
                      // sx={{
                      //   '& .MuiListItemIcon-root': {
                      //     display: 'none !important'
                      //   }
                      // }}
                      className="mx-2"
                      value={a.id}
                      key={a.id}>
                      {/* <ListItemIcon
                        sx={{
                          '& .MuiListItemIcon-root': {
                            display: 'none !important'
                          }
                        }}>
                        <img
                          width={'40px'}
                          src={`${process.env.REACT_APP_ASSETS_BASE_URL}${a.icon}`}
                          alt={a.name}
                        />
                      </ListItemIcon> */}
                      <ListItemText>{a.name}</ListItemText>
                    </MenuItem>
                  )
                //  : (
                //   <MenuItem
                //     disabled={true}
                //     className="mx-2"
                //     value={a.id}
                //     key={a.id}>
                //     {a.name}
                //   </MenuItem>
                // )
              )}
            </Select>
          </FormControl>
        </Grid>
        {action !== '' && action.type !== 'noinput' && (
          <Grid item md={3}>
            {action.type === 'dropdown' && (
              <FormControl variant="outlined" size="small" className="w-100">
                <InputLabel id="action-value-select-label">
                  Action Value
                </InputLabel>
                <Select
                  fullWidth
                  labelId="action-value-select-label"
                  label="Action Value"
                  value={actionValue}
                  onChange={handleActionValue}>
                  {action.values.map((a) => (
                    <MenuItem className="mx-2" value={a} key={a}>
                      {a}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        )}
        {action !== '' && (
          <Grid item md={1}>
            <Button
              className="btn-primary font-weight-bold"
              onClick={onActionSubmit}>
              Go
            </Button>{' '}
          </Grid>
        )}
        {isLoading && (
          <Grid item md={1}>
            <CircularProgress />
          </Grid>
        )}
        <Grid item md={4}>
          <div className="py-2 text-black-50">
            {selected.length} of {ordersCount} selected
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
  updateOrders: (data) => dispatch(updateMultipleOrders(data)),
  loadOrders: (orders) => dispatch(initLoadingOrders())
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkActions);
