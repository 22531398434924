import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { fromUnixTime } from 'date-fns';

import { axiosIns } from '../../../config/configAxios';

import { Table, Button, Typography } from '@material-ui/core';

import { getUser } from '../../../reducers/UserAccount';
import Pagination from '@material-ui/lab/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const ResultsContainer = ({ user }) => {
  // const [expand, setExpand] = useState(false);
  const [invoices, setInvoices] = useState(null);
  // const [numofItems, setNumofItems] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterdInvoices, setFilteredInvoices] = useState([]);
  // const toggleExpand = () => setExpand(!expand);

  const handlePageChange = (event, val) => {
    setPageNumber(val);
    // setSelected([]);
  };
  useEffect(() => {
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    axiosIns.get('/subscriptions/invoices').then((res) => {
      setInvoices(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoices) {
      const groupedInvoices = {};
      // Group invoices by the same month and year
      invoices.forEach((invoice) => {
        const monthYear = `${
          fromUnixTime(invoice.status_transitions.finalized_at)
            .toLocaleDateString()
            .split('/')[0]
        }-${
          fromUnixTime(invoice.status_transitions.finalized_at)
            .toLocaleDateString()
            .split('/')[2]
        }`;
        if (groupedInvoices.hasOwnProperty(monthYear)) {
          groupedInvoices[monthYear].push(invoice);
        } else {
          groupedInvoices[monthYear] = [invoice];
        }
      });
      // Convert object to array of objects with 'monthYear' and 'invoices' properties
      const groupedInvoicesArray = Object.entries(groupedInvoices).map(
        ([monthYear, invoices]) => ({
          monthYear,
          invoices
        })
      );
      setFilteredInvoices(groupedInvoicesArray);
    }
  }, [invoices]);

  return (
    <div className="table-responsive-md">
      <Table className="table table-alternate-spaced mb-0">
        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
          <tr>
            <th className="text-center px-4">DATE ISSUED</th>
            <th className="text-center px-4">AMOUNT</th>
            <th className="text-center px-4">STATUS</th>
            <th className="text-center px-4">INVOICE</th>
          </tr>
        </thead>
        <tbody>
          {filterdInvoices
            ?.slice((pageNumber - 1) * 5, pageNumber * 5)
            ?.map(({ invoices }) => (
              <>
                {invoices?.map(
                  (inv, i) =>
                    inv.status !== 'draft' && (
                      <tr className="rounded  border-dark border-1" key={i}>
                        <td className="text-center px-4">
                          <span>
                            {' '}
                            {inv.status_transitions.finalized_at != null
                              ? fromUnixTime(
                                  inv.status_transitions.finalized_at
                                ).toLocaleDateString()
                              : 'Not Finalized Yet'}
                          </span>
                        </td>
                        <td className="text-center px-4">
                          <span> $ {Number(inv?.amount_due) / 100}</span>
                        </td>

                        <td className="text-center px-4">
                          {inv.status === 'paid' ? (
                            <p className="badge badge-success badge-pill">
                              Paid
                            </p>
                          ) : (
                            <p className="badge badge-danger badge-pill">
                              {inv.status}
                            </p>
                          )}
                        </td>
                        <td className="text-center px-4">
                          {inv.invoice_pdf ? (
                            <Button
                              href={inv.invoice_pdf}
                              rel="noopener noreferrer"
                              target="_blank"
                              className="btn-primary p-2">
                              Download
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="ml-2"
                              />
                            </Button>
                          ) : (
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 'bold' }}>
                              Not Available
                            </Typography>
                          )}
                        </td>
                      </tr>
                    )
                )}
                <tr className="divider"></tr>
              </>
            ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center w-100">
        <Pagination
          className="pagination-primary"
          count={Math.ceil(invoices?.length / 5)}
          page={pageNumber}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: getUser(state)
});
export default connect(mapStateToProps, null)(ResultsContainer);
