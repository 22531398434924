import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from '@material-ui/icons/Search';
import LibraryBooksTwoToneIcon from '@material-ui/icons/LibraryBooksTwoTone';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import ListIcon from '@material-ui/icons/List';
import { Badge, Collapse } from '@material-ui/core';
import { getStoreList } from 'reducers/Store';
import StoreSync from './StoreSync';
import { axiosIns } from 'config/configAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faDollar } from '@fortawesome/free-solid-svg-icons';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;
  const toggleSidebarMobile = () => setSidebarToggleMobile(false);
  const [widgetsOpen, setWidgetsOpen] = useState(false);
  const [length, setLength] = useState(0);
  const [storeWidgetsOpen, setStoreWidgetsOpen] = useState(false);
  const [appWidgetsOpen, setAppWidgetsOpen] = useState(false);
  const [settingsWidgetsOpen, setSettingsWidgetsOpen] = useState(false);

  useEffect(() => {
    const getTotalOrders = async () => {
      axiosIns.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${props.token}`;
      const response = await axiosIns.get(`/forms`);
      setLength(response.data.length);
    };
    getTotalOrders();
    // eslint-disable-next-line
  }, [props.storeList]);

  useEffect(() => {
    props.getStoreList(true);
    // eslint-disable-next-line
  }, [props.getStoreList]);

  const toggleWidgets = (event) => {
    setWidgetsOpen(!widgetsOpen);
    event.preventDefault();
  };
  const toggleStoreWidgets = (event) => {
    setStoreWidgetsOpen(!storeWidgetsOpen);
    event.preventDefault();
  };
  const toggleAppWidgets = (event) => {
    setAppWidgetsOpen(!appWidgetsOpen);
    event.preventDefault();
  };
  const toggleSettingsWidgets = (event) => {
    setSettingsWidgetsOpen(!settingsWidgetsOpen);
    event.preventDefault();
  };

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Navigation</span>
          </div>
          <ul>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/home'}>
                <span className="sidebar-icon">
                  <DashboardIcon />
                </span>
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/lookup'}>
                <span className="sidebar-icon">
                  <SearchIcon />
                </span>
                Barcodes Lookup
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/products'}>
                <span className="sidebar-icon">
                  <BusinessCenterIcon />
                </span>
                Products
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/categories'}>
                <span className="sidebar-icon">
                  <ListIcon />
                </span>
                Categories
              </NavLink>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleStoreWidgets}
                className={clsx({ active: storeWidgetsOpen })}>
                <span className="sidebar-icon">
                  <StorefrontIcon />
                </span>
                <span className="sidebar-item-label">Store</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={storeWidgetsOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={`${process.env.REACT_APP_D_PREFIX}/stores/pages`}>
                      Pages
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={`${process.env.REACT_APP_D_PREFIX}/stores/blogposts`}>
                      Blog post
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={{
                        pathname: process.env.REACT_APP_D_PREFIX + '/design'
                      }}>
                      Design
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <a
                href={`${process.env.REACT_APP_D_PREFIX}/apps`}
                onClick={toggleAppWidgets}
                className={clsx({ active: appWidgetsOpen })}>
                <span className="sidebar-icon">
                  <LibraryBooksTwoToneIcon />
                </span>
                <span className="sidebar-item-label">Apps</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={appWidgetsOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={`${process.env.REACT_APP_D_PREFIX}/apps`}>
                      App Store
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={process.env.REACT_APP_D_PREFIX + '/apps/installed'}>
                      My Apps
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/stores/users'}>
                <span className="sidebar-icon">
                  <PeopleAltIcon />
                </span>
                Customers
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={process.env.REACT_APP_D_PREFIX + '/forms'}>
                <span className="sidebar-icon">
                  <DescriptionIcon />
                </span>
                <Badge
                  className="mx-2"
                  badgeContent={Number(length)}
                  color="error">
                  Forms
                </Badge>
              </NavLink>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleWidgets}
                className={clsx({ active: widgetsOpen })}>
                <span className="sidebar-icon">
                  <ShoppingCartIcon />
                </span>
                <span className="sidebar-item-label">Orders</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={widgetsOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={process.env.REACT_APP_D_PREFIX + '/orders'}>
                      {/* <span className="sidebar-icon">
                        <ShoppingCartIcon />
                      </span> */}
                      Orders
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={process.env.REACT_APP_D_PREFIX + '/abandonedorders'}>
                      Abandoned
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to={`${process.env.REACT_APP_D_PREFIX}/stores/${props.storeList[0]?._id}/discounts`}>
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faDollar} />
                </span>
                Discounts
              </NavLink>
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleSettingsWidgets}
                className={clsx({ active: settingsWidgetsOpen })}>
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faCogs} />
                </span>
                <span className="sidebar-item-label">Settings</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={settingsWidgetsOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={process.env.REACT_APP_D_PREFIX + '/edit'}>
                      Store
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={`${process.env.REACT_APP_D_PREFIX}/stores/${props.storeList[0]?._id}/shipping`}>
                      Shipping and Delivery
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={
                        process.env.REACT_APP_D_PREFIX +
                        '/settings/payment-processors'
                      }>
                      Payment
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      activeClassName="active"
                      className="nav-link-simple"
                      to={process.env.REACT_APP_D_PREFIX + '/export'}>
                      Export/Import
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={`${process.env.REACT_APP_D_PREFIX}/stores/${props.storeList[0]?._id}/settings/locations`}>
                      Locations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={
                        process.env.REACT_APP_D_PREFIX +
                        '/settings/notifications'
                      }>
                      Notifications
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to={process.env.REACT_APP_D_PREFIX + '/payment'}>
                      Billing and Plan
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
          </ul>

          <StoreSync />
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  storeId: state.Store.storeId,
  storeList: state.Store.data,
  token: state.UserAccount.data.token
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  getStoreList: (isActive) => dispatch(getStoreList(isActive))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
