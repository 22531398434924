import React from 'react';

import { PageTitle } from '../../layout-components';

import { Profile } from '../../components';
export default function ProfilePage() {
  return (
    <>
      <PageTitle
        titleHeading="Your Profile"
        titleDescription="Manage your profile settings from this page."></PageTitle>

      <Profile />
    </>
  );
}
