import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Snackbar,
  CircularProgress,
  makeStyles,
  DialogTitle,
  RadioGroup,
  Radio,
  FormControlLabel,
  DialogActions,
  Typography
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Alert from '@material-ui/lab/Alert';
import { useStripe } from '@stripe/react-stripe-js';
import { axiosIns } from 'config/configAxios';
import { fromUnixTime } from 'date-fns';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DotLoader, PulseLoader } from 'react-spinners';
import { getIsLoadingPlans, getPlans, loadPlans } from 'reducers/Plans';
import { fetchUser, getIsUploadingUser, getUser } from 'reducers/UserAccount';
import {
  deleteSubscription,
  getIsLoadingSubscription,
  getIsUploadingSubscription,
  getSubscription,
  loadSubscription,
  upgradeSubscription
} from 'reducers/UserSubscriptions';
import CardInput from '../Payment/CardInput';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(() => ({
  circularProgress: {
    color: '#1bc943',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const UpgradePlan = ({
  open,
  handleClose,
  subscription,
  createUpgrade,
  loadUserSubscription,
  startLoading,
  selectedPlanId,
  createSubscription,
  isUploading,
  setIsUploading
}) => {
  const [billingCycles, setBillingCycles] = useState([]);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [planId, setPlanId] = useState(subscription?.priceId || '');
  useEffect(() => {
    if (!selectedPlanId) return;
    setBillingCycles([
      {
        id: 1,
        label: 'Annual',
        value: 'annual',
        price: selectedPlanId.stripeYearlyPrice.price,
        priceId: selectedPlanId.stripeYearlyPrice.priceId
      },
      {
        id: 2,
        label: 'Monthly',
        value: 'monthly',
        price: selectedPlanId.stripeMonthlyPrice.price,
        priceId: selectedPlanId.stripeMonthlyPrice.priceId
      }
    ]);
  }, [selectedPlanId]);
  useEffect(() => {
    startLoading();
    loadUserSubscription();
  }, [loadUserSubscription, startLoading]);
  const classes = useStyles();
  const handleSubscription = () => {
    setIsUpgrading(true);
    if (subscription?.subscriptionId === null || subscription === null) {
      createSubscription(planId, selectedPlanId._id);
      handleClose();
    } else {
      createUpgrade(planId)
        .then((re) => {
          if (re.status === 200) {
            loadUserSubscription();
            setIsUpgrading(false);
            setSuccessMsg('you have successfully subscribed to the plan!');
            setIsSuccess(true);
          }
        })
        .catch(({ response }) => {
          console.log(response, 'invalid request consoled');
          setIsUpgrading(false);
          setError(true);
          setErrorMsg(
            response.data.raw.message ||
              'Something went wrong please try again later'
          );
        });
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>Plan Biling Cycle</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{errorMsg}</Alert>}
        {isSuccess && <Alert severity="success">{successMsg}</Alert>}
        {!isSuccess && !error && (
          <>
            <Typography variant="body1">
              Choose the billing cycle for your subscription
            </Typography>
            <RadioGroup
              value={planId}
              onChange={(e) => setPlanId(e.target.value)}>
              {billingCycles.map(({ id, label, price, priceId }) => (
                <React.Fragment key={id}>
                  <FormControlLabel
                    value={priceId}
                    checked={planId === priceId}
                    control={<Radio />}
                    label={label}
                  />
                  <Typography
                    variant="body2"
                    className="pl-4 font-weight-bold text-primay">
                    ${price} every {label === 'Annual' ? 'year' : 'month'}
                    {label === 'Annual' && (
                      <span className="badge badge-info ml-2">
                        {' '}
                        save{' '}
                        {(
                          ((selectedPlanId.stripeMonthlyPrice.price * 12 -
                            selectedPlanId.stripeYearlyPrice.price) *
                            100) /
                          (selectedPlanId.stripeMonthlyPrice.price * 12)
                        ).toFixed(1)}
                        %
                      </span>
                    )}
                  </Typography>
                </React.Fragment>
              ))}
            </RadioGroup>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isSuccess || error ? (
          <Button
            onClick={() => {
              setError(false);
              setIsSuccess(false);
              handleClose();
            }}
            className="btn-outline-dark">
            Close
          </Button>
        ) : (
          <>
            <Button
              disabled={isUpgrading || isUploading}
              className="btn-primary"
              onClick={handleSubscription}>
              {isUpgrading ||
                (isUploading && (
                  <CircularProgress
                    size={24}
                    thickness={5}
                    className={classes.circularProgress}
                  />
                ))}
              Proceed
            </Button>
            <Button
              disabled={isUpgrading || isUploading}
              className="btn-outline-dark"
              onClick={() => {
                setError(false);
                setIsSuccess(false);
                handleClose();
              }}>
              Cancel
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const Payment = ({
  user,
  plans,
  isLoading,
  startLoading,
  loadUser,
  loadUserSubscription,
  isLoadingSubscription,
  isUploadingSubscription,
  subscription,
  createUpgrade,
  deleteSubscription
}) => {
  const [selectedPlanId, setselectedPlanId] = useState(null);
  const [selectedPriceId, setselectedPriceId] = useState(null);
  const [price, setPrice] = useState(null);
  const [modal1, setModal1] = useState(false);
  const openSubscriptionBox = async (priceId = null, planId = null, price) => {
    if (modal1) {
      setselectedPlanId(null);
      setselectedPriceId(null);
    } else {
      setselectedPriceId(priceId);
      setselectedPlanId(planId);
      setPrice(price);
    }
    setModal1(!modal1);
  };

  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(false);
  const [retryProp, setRetryProp] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  // const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  // const [isPricePreviewLoad, setIsPricePreviewLoad] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteDialog = () => setDeleteModal(!deleteModal);
  const [openUpgradePlan, setOpenUpgradePlan] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const stripe = useStripe();

  useEffect(() => {
    startLoading();
    loadUserSubscription();
  }, [loadUserSubscription, startLoading]);

  const handleCloseUpgradePlan = () => setOpenUpgradePlan(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSuccess(false);
    setOpen(false);
  };
  const getFeatureUsage = (featureIdentifier, plan) => {
    return plan.features.filter(
      (feat) => feat.featureId.identifier === featureIdentifier
    );
  };
  const isUpgradeAvailable = (subscription, plantsFeatures) => {
    const productLimit = getFeatureUsage('LPL', plantsFeatures)[0];
    const scanLimit = getFeatureUsage('BUM', plantsFeatures)[0];
    const webSyncLimit = getFeatureUsage('SW', plantsFeatures)[0];
    if (
      productLimit === undefined ||
      scanLimit === undefined ||
      webSyncLimit === undefined
    ) {
      return true;
    }
    if (
      subscription.totalProducts <= productLimit.usageLimit &&
      subscription.consumedScans <= scanLimit.usageLimit &&
      subscription.webSyncs <= webSyncLimit.usageLimit
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  // function retrieveUpcomingInvoice(
  //   customerId,
  //   subscriptionId,
  //   newPriceId,
  //   features
  // ) {
  //   toggle4();

  //   setIsPricePreviewLoad(true);
  //   axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

  //   return axiosIns
  //     .post('/subscriptions/retrieve-upcoming-invoice', {
  //       customerId: customerId,
  //       subscriptionId: subscriptionId,
  //       newPriceId: newPriceId
  //     })
  //     .then((response) => {
  //       return response;
  //     })
  //     .then((invoice) => {
  //       setUpcomingInvoice(invoice);
  //       setUpgradeFeatures(features);
  //       setIsPricePreviewLoad(false);

  //       return invoice;
  //     });
  // }
  function createSubscription(priceId, planId) {
    setIsUploading(true);
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

    return (
      axiosIns
        .post('/subscriptions', {
          priceId: priceId,
          planId: planId
        })
        .then((response) => {
          setIsUploading(false);
          return response;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            priceId: priceId,
            subscription: result.data,
            paymentMethodId: user.stripeCustomerDetails.paymentId
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch(({ response }) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          // showCardError(error);

          setIsUploading(false);
          if (response && response.data) {
            if (response.data.raw?.code === 'resource_missing') {
              setOpen2(true);
            } else {
              setError(true);
              setModal1(!modal1);

              setErrorMsg(response.data.message);
            }
          } else {
            setError(true);
            setModal1(!modal1);
            setErrorMsg(response.data.message);
          }
        })
    );
  }
  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry
  }) {
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.data.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      // setModal1(!modal1);
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).

            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId
              };
            }
          }
        })
        .catch((error) => {
          // displayError(error);
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod(data) {
    if (data !== undefined) {
      const subscription = data.subscription;

      const paymentMethodId = data.paymentMethodId;
      const priceId = data.priceId;

      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem(
          'latestInvoicePaymentIntentStatus',
          subscription.latest_invoice.payment_intent.status
        );
        setRetryProp(true);
        setOpen2(true);
        throw new Error("'Your card was declined.");
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    } else {
      throw Error('insufficient Funds');
    }
  }
  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
    return (
      axiosIns
        .post('/subscriptions/retry-invoice', {
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId
        })
        .then((response) => {
          return response;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.

            setErrorMsg('Your card was declined.');
            setError(true);
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            // priceId: priceId,
            isRetry: true
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          // displayError(error);
          console.log('error');
          setErrorMsg('error');
          setError(true);
        })
    );
  }
  function onSubscriptionComplete(result) {
    loadUserSubscription();
    // setModal1(!modal1);
    // Payment was successful.
    if (
      result.subscription != null &&
      result.subscription.status === 'active'
    ) {
      setSuccessMsg('Your Subscription Request Submitted!');
      setIsSuccess(true);
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
    }
    localStorage.removeItem('latestInvoiceId');
    localStorage.removeItem('latestInvoicePaymentIntentStatus');
    handleClose2();
  }
  const checkPriceId = (monthlyId, yearlyId) => {
    if (subscription?.priceId === monthlyId) {
      return 'Upgrade To Annual';
    } else if (subscription?.priceId === yearlyId) {
      return 'Downgrade';
    } else {
      return 'Subscribe';
    }
  };

  return (
    <>
      <UpgradePlan
        open={openUpgradePlan}
        handleClose={handleCloseUpgradePlan}
        subscription={subscription}
        selectedPlanId={selectedPlanId}
        createUpgrade={createUpgrade}
        loadUserSubscription={loadUserSubscription}
        startLoading={startLoading}
        createSubscription={createSubscription}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
      />
      {!isLoadingSubscription && subscription !== null && (
        <Card>
          {subscription?.status === 'incomplete' &&
            subscription?.paymentStatus === 'open' && (
              <Alert severity="warning">
                Your Transaction was not successful!
              </Alert>
            )}
          {subscription.status === 'active' &&
            subscription?.paymentStatus === 'open' && (
              <Alert severity="warning">
                Your account is activated while payment recovery is in process
              </Alert>
            )}

          {(subscription?.status === 'past_due' ||
            subscription?.status === 'incomplete') &&
            subscription?.paymentStatus === 'open' && (
              <Alert severity="warning">
                Your payment needs authentication check your email or click
                {subscription.hosted_invoice_url ? (
                  <a
                    className="button-primary secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={subscription.hosted_invoice_url}>
                    {' '}
                    here
                  </a>
                ) : (
                  ''
                )}
              </Alert>
            )}
          {subscription?.status === 'trialing' &&
            subscription?.paymentStatus === 'paid' && (
              <Alert severity="warning">
                {`You are using trail version. 
                ${
                  !user?.hasPaymentMethod
                    ? 'Please update your payment method to start automatic renewal of subscription when trail ends '
                    : 'Trial will end on'
                }
                  ${fromUnixTime(subscription.endtime).toDateString()}
               `}
              </Alert>
            )}
          {subscription?.status === 'trialing' &&
            subscription?.paymentStatus === 'paid' &&
            subscription?.isTrailEnding && (
              <Alert severity="warning">
                Your trail is ending in 3 days on{' '}
                {fromUnixTime(subscription.endtime).toDateString()}
              </Alert>
            )}
        </Card>
      )}
      <div
        style={{ display: 'flex' }}
        className="w-100 flex align-items-center justify-content-between p-0"></div>

      <Container className="py-3 py-xl-5">
        <div className="text-center mb-5">
          <h1 className="display-2 text-black mb-3 font-weight-bold">
            Plans & pricing
          </h1>
          <p className="font-size-xl mb-5 text-black-50">
            Upgrade or downgrade plan to suit your business needs
          </p>
          <div
            style={{ display: 'inline' }}
            className="flex-end  text-right mr-3">
            {subscription !== null && subscription.status !== 'canceled' ? (
              !subscription?.cancel_at_period_end ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={toggleDeleteDialog}
                  startIcon={<ClearIcon />}>
                  Cancel Subscription
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={toggleDeleteDialog}
                  startIcon={<ClearIcon />}>
                  Resume
                </Button>
              )
            ) : (
              ''
            )}
          </div>
        </div>
        <Grid container spacing={2}>
          {!isLoading && !isLoadingSubscription ? (
            plans.map((row, index) => (
              <React.Fragment key={index}>
                {row.isCustom ? (
                  subscription && row._id === subscription.planId._id ? (
                    <Grid item xl={3} md={4}>
                      <Card>
                        {(row.stripeMonthlyPrice?.priceId ===
                          subscription?.priceId ||
                          row.stripeYearlyPrice?.priceId ===
                            subscription?.priceId) &&
                          subscription.status === 'active' && (
                            <span className="ribbon-angle ribbon-angle--top-right ribbon-success">
                              <small>ACTIVE</small>
                            </span>
                          )}
                        <CardContent className="px-4 pb-4 pt-3 text-center">
                          <h3
                            style={{
                              color: row.color ? '#' + row.color : '#22194D'
                            }}
                            className="font-size-xxl text-uppercase my-4 font-weight-bold ">
                            {row?.name}
                          </h3>
                          <div
                            style={{ color: row.color && '#' + row.color }}
                            className="bg-neutral-info p-3 rounded mb-4">
                            <span className="display-2 font-weight-bold">
                              <small>$</small>
                              {row?.stripeMonthlyPrice.price}
                            </span>
                            <div
                              style={{ color: row.color && '#' + row.color }}>
                              Per month
                            </div>
                          </div>
                          <ul className="list-unstyled text-lg-left mb-4 font-size-xs">
                            {row.features?.map((feat, key) => (
                              <li key={key} className="px-4 py-2">
                                <CheckIcon
                                  style={{
                                    fontSize: '80%',
                                    fill: row.color ? `#${row.color}` : '22194D'
                                  }}
                                  className="text-success mr-2"
                                />
                                {feat.featureId.name}
                              </li>
                            ))}
                          </ul>
                          <Button
                            key={index}
                            disabled={isUpgradeAvailable(subscription, row)}
                            size="large"
                            className="btn-pill w-100 btn-outline-info"
                            variant="text"
                            onClick={() => {
                              setOpenUpgradePlan(true);
                              setselectedPlanId(row);
                            }}>
                            {checkPriceId(
                              row.stripeMonthlyPrice?.priceId,
                              row.stripeYearlyPrice?.priceId
                            )}
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  ) : (
                    ''
                  )
                ) : (
                  <Grid item xl={3} md={6}>
                    <Card>
                      {(row.stripeMonthlyPrice?.priceId ===
                        subscription?.priceId ||
                        row.stripeYearlyPrice?.priceId ===
                          subscription?.priceId) &&
                        subscription.status === 'active' && (
                          <span className="ribbon-angle ribbon-angle--top-right ribbon-success">
                            <small>ACTIVE</small>
                          </span>
                        )}
                      <CardContent className="px-4 pb-4 pt-3 text-center">
                        <h3
                          style={{
                            color: row.color ? '#' + row.color : '#22194D'
                          }}
                          className="font-size-xxl text-uppercase my-4 font-weight-bold ">
                          {row.name}
                        </h3>
                        <div
                          style={{ color: row.color && '#' + row.color }}
                          className="bg-neutral-info p-3 rounded mb-4">
                          <span className="display-2 font-weight-bold">
                            <small>$</small>
                            {row.stripeMonthlyPrice.price}
                          </span>
                          <div style={{ color: row.color && '#' + row.color }}>
                            Per month
                          </div>
                        </div>
                        <ul className="list-unstyled text-lg-left mb-4 font-size-xs">
                          {row.features.map((feat, key) => (
                            <li key={key} className="px-4 py-2">
                              {/* <FontAwesomeIcon
                      icon={['far', 'check-circle']}
                      className="text-success mr-2"
                    /> */}
                              <CheckIcon
                                style={{
                                  fontSize: '80%',
                                  fill: row.color ? `#${row.color}` : '22194D'
                                }}
                                className="text-success mr-2"
                              />
                              {/* {feat.featureId.isNumber
                              ? feat.usageLimit + ' x'
                              : ''}{' '} */}
                              {feat.featureId.name}
                            </li>
                          ))}
                        </ul>
                        <Button
                          key={index}
                          size="large"
                          className="btn-pill w-100 btn-outline-info"
                          variant="text"
                          onClick={() => {
                            setOpenUpgradePlan(true);
                            setselectedPlanId(row);
                          }}>
                          {checkPriceId(
                            row.stripeMonthlyPrice?.priceId,
                            row.stripeYearlyPrice?.priceId
                          )}
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </React.Fragment>
            ))
          ) : (
            <>
              <Grid item xl={12}>
                <Card>
                  <BlockUi
                    className="p-5 block-loading-overlay-dark"
                    tag="div"
                    blocking={isLoading || isLoadingSubscription}
                    loader={
                      <DotLoader
                        color={'var(--white)'}
                        loading={isLoading || isLoadingSubscription}
                      />
                    }>
                    <div className="font-weight-bold font-size-lg mb-2">
                      {' '}
                      Loading Plans
                    </div>
                    <p className="text-black-50 mb-0">This may take a while.</p>
                  </BlockUi>
                </Card>
              </Grid>
            </>
          )}
          {!isLoading && !isLoadingSubscription && (
            <Grid item xl={3} md={6}>
              <Card>
                <CardContent className="px-4 pb-4 pt-3 text-center">
                  <h3 className="font-size-xxl text-uppercase my-4 font-weight-bold text-warning">
                    Large
                  </h3>
                  <div className="bg-neutral-warning p-3 rounded mb-4">
                    <span className="display-2 font-weight-bold">
                      <small>$</small>
                      99
                    </span>
                    <div className="text-black-50">Per month</div>
                  </div>
                  <ul className="list-unstyled text-lg-left mb-4 font-size-sm">
                    <li className="px-4 py-2">
                      <CheckIcon
                        style={{ fontSize: '80%' }}
                        className="text-success mr-2"
                      />
                      Scans as you want
                    </li>{' '}
                    <li className="px-4 py-2">
                      <CheckIcon
                        style={{ fontSize: '80%' }}
                        className="text-success mr-2"
                      />
                      Custom Domain
                    </li>{' '}
                    <li className="px-4 py-2">
                      <CheckIcon
                        style={{ fontSize: '80%' }}
                        className="text-success mr-2"
                      />
                      Premium Support
                    </li>{' '}
                    <li className="px-4 py-2">
                      <CheckIcon
                        style={{ fontSize: '80%' }}
                        className="text-success mr-2"
                      />
                      SEO Optimization
                    </li>
                    <li className="px-4 py-2">
                      <CheckIcon
                        style={{ fontSize: '80%' }}
                        className="text-success mr-2"
                      />
                      Custom Contract & Invoicing
                    </li>
                    <li className="px-4 py-2">
                      <CheckIcon
                        style={{ fontSize: '80%' }}
                        className="text-success mr-2"
                      />
                      High Performance Builds
                    </li>
                    <li className="px-4 py-2">
                      <CheckIcon
                        style={{ fontSize: '80%' }}
                        className="text-success mr-2"
                      />
                      Custom Theme
                    </li>
                    <li className="px-4 py-2 text-black-50">
                      <CheckIcon
                        style={{ fontSize: '80%' }}
                        className="text-success mr-2"
                      />
                      Marketing and Design
                    </li>
                  </ul>

                  <Button
                    href="/help/contact"
                    size="large"
                    className="btn-outline-warning btn-pill w-100"
                    variant="text">
                    Contact Us
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}{' '}
        </Grid>
      </Container>

      <Dialog
        classes={{ paper: 'modal-content' }}
        fullWidth
        maxWidth="md"
        open={open2}
        onClose={handleClose2}
        aria-labelledby="form-dialog-title2">
        <DialogContent className="p-0">
          <div>
            <div className="bg-secondary border-0">
              <div className="card-body px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-3">
                  Update Payment Method
                </div>
                <p className="text-danger">
                  * You do not have any payment method attached
                </p>
                <div className="mb-3">
                  <CardInput
                    loadUser={loadUser}
                    handleClose2={handleClose2}
                    user={user}
                    retryProp={retryProp}
                    retryFunctionHandle={retryInvoiceWithNewPaymentMethod}
                    successMsgHandle={setSuccessMsg}
                    successHandle={setIsSuccess}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center font-size-md"></div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert severity="success">No Payment Method Found</Alert>
      </Snackbar>
      <Snackbar open={isSuccess} autoHideDuration={1500} onClose={handleClose}>
        <Alert severity="success">{successMsg}</Alert>
      </Snackbar>
      <Dialog
        open={error}
        onClose={() => setError(false)}
        classes={{ paper: 'shadow-lg rounded' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <ErrorOutlineIcon />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Error!</h4>
          <p className="mb-0 font-size-lg text-muted">{errorMsg}</p>
          <div className="pt-4">
            <Button
              onClick={() => {
                setError(false);
                loadUserSubscription();
              }}
              className="btn-danger btn-pill mx-1">
              <span className="btn-wrapper--label">Close</span>
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={deleteModal}
        onClose={toggleDeleteDialog}
        classes={{ paper: 'shadow-lg rounded' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-warning text-white m-0 d-130"></div>
          </div>
          {subscription != null && subscription.cancel_at_period_end ? (
            <>
              <div className="font-weight-bold font-size-lg mt-4">
                Resume Subscription?
              </div>
              <p className="mb-0 mt-2 text-muted">
                Would you like to resume your subscription?
              </p>
            </>
          ) : (
            <>
              <div className="font-weight-bold font-size-lg mt-4">
                Cancel Subscription?
              </div>
              <p className="mb-0 mt-2 text-muted">
                Cancellation will result in decline of our services to your
                business
              </p>
            </>
          )}

          <div className="pt-4">
            <Button
              onClick={toggleDeleteDialog}
              className="btn-neutral-secondary btn-pill mx-1">
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            {isUploadingSubscription ? (
              <PulseLoader color={'var(--primary)'} loading={true} />
            ) : (
              <Button
                onClick={() =>
                  deleteSubscription(
                    subscription.subscriptionId,
                    toggleDeleteDialog
                  )
                }
                variant="text"
                className="btn-outline-warning btn-pill mx-1">
                <span className="btn-wrapper--label">Yes, do it</span>
              </Button>
            )}
          </div>
        </div>
      </Dialog>
      <Dialog
        open={modal1}
        onClose={openSubscriptionBox}
        classes={{ paper: 'modal-content rounded-lg' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
              <CheckIcon />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Do you want to continue?</h4>
          <p className="mb-0 font-size-lg">
            You will be charged <b>${price}</b> once you click create.
          </p>
          <div className="pt-4">
            <Button
              onClick={openSubscriptionBox}
              className="btn-neutral-dark btn-pill mx-1">
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            {isUploading ? (
              <CircularProgress size={24} thickness={5} />
            ) : (
              <Button
                onClick={() =>
                  createSubscription(selectedPriceId, selectedPlanId)
                }
                className="btn-success btn-pill mx-1">
                <span className="btn-wrapper--label">Create</span>
              </Button>
            )}
          </div>
        </div>
      </Dialog>

      <div className="text-info text-center font-size-sm">
        To request a custom plan, Please{' '}
        <Link to={'/help/contact'}>
          <b>submit a request here</b>
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  isUploading: getIsUploadingUser(state),
  plans: getPlans(state),
  isLoading: getIsLoadingPlans(state),
  isLoadingSubscription: getIsLoadingSubscription(state),
  isUploadingSubscription: getIsUploadingSubscription(state),
  subscription: getSubscription(state)
});
const mapDispatchToProps = (dispatch) => ({
  loadUser: (data) => dispatch(fetchUser(data)),

  startLoading: () => dispatch(loadPlans()),
  loadUserSubscription: () => dispatch(loadSubscription()),
  deleteSubscription: (subscriptionId, modalHandle) =>
    dispatch(deleteSubscription(subscriptionId, modalHandle)),
  createUpgrade: (invoiceId) => dispatch(upgradeSubscription(invoiceId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
