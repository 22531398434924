import React from 'react';
import { Grid, Card, Container, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import stock1 from '../../assets/images/placeholders/no-repo-preview-image.jpg';
import ccb from '../../assets/images/ccb_img1.jpg';
import {
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
// import Slider from 'react-slick';

// function SliderArrowNext(props) {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick}>
//       <FontAwesomeIcon icon={faChevronRight} />
//     </div>
//   );
// }

// function SliderArrowPrev(props) {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick}>
//       <FontAwesomeIcon icon={faChevronLeft} />
//     </div>
//   );
// }

const CaseStudies = () => {
  // const carouselSettings = {
  //   dots: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   nextArrow: <SliderArrowNext />,
  //   prevArrow: <SliderArrowPrev />,
  //   responsive: [
  //     {
  //       breakpoint: 1450,
  //       settings: { slidesToShow: 3, slidesToScroll: 1 }
  //     },
  //     {
  //       breakpoint: 1100,
  //       settings: { slidesToShow: 2, slidesToScroll: 1 }
  //     },
  //     {
  //       breakpoint: 900,
  //       settings: { slidesToShow: 1, slidesToScroll: 1 }
  //     }
  //   ]
  // };

  return (
    <>
      <div
        className="hero-wrapper bg-composed-wrapper pt-5 mb-spacing-6-x2"
        style={{ backgroundColor: '#0c145b' }}>
        <div className="flex-grow-1 w-100 d-flex flex-column align-items-center">
          <h2 className="text-center text-white display-3 mb-2 px-4 font-weight-bold">
            Customer success stories
          </h2>
          <p className="text-center text-white opacity-7 mb-4 font-size-lg line-height-2">
            Read how costumers use Cartlauncher to grow their business and grow
            their store
          </p>
          <Container>
            <Card className="card-box p-4 mb-spacing-6-x2">
              <Grid container spacing={0}>
                <Grid item lg={7} className="d-flex align-items-center">
                  <div className="p-4 text-center text-lg-left p-lg-5">
                    <p>
                      <span className="text-left display-5 mb-4 font-weight-bold">
                        Latest case study
                      </span>
                    </p>
                    <h4 className="display-4 font-weight-bold mb-3">
                    Circle City Bargains
                    </h4>
                    <p className="opacity-7 mb-4 font-size-lg line-height-2">
                      Starting as a small-scale liquidation business with a
                      meager $500 investment, Tony and Brad quickly grew into
                      one of the largest companies in the industry. In just six
                      months...
                    </p>
                    <Link to={'/customer/ccb'}>
                      <Button className="btn-primary text-uppercase font-weight-bold btn-pill px-4 font-size-sm">
                        <span className="btn-wrapper--label">
                          Read case study
                        </span>
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </span>
                      </Button>
                    </Link>
                  </div>
                </Grid>
                <Grid item lg={5} className="d-flex align-items-center">
                  <img alt="ccb store" className="w-100 p-4 p-lg-0" src={ccb} />
                </Grid>
              </Grid>
            </Card>
          </Container>
        </div>
      </div>
      {/* <Container className="mb-4">
        <h3 className="text-center display-3 mb-4 px-4 font-weight-bold">
          Browse all case studies
        </h3>
        <Slider
          className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside"
          {...carouselSettings}>
          <div>
            <a
              href="/customer/ccb"
              onClick={(e) => e.preventDefault()}
              className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
              <img src={ccb} className="card-img-top" alt="..." style={{maxWidth: "100%",maxHeight: "14.875rem"}} />
              <div className="p-3  rounded-bottom p-xl-4">
                <h5 className="card-title font-weight-bold font-size-xxl">
                  Circle City Bargains
                </h5>
                <p className="card-text">
                  Family run and 100% Aussie owned business bedhead Hats
                  promotes sun safety and confort for babies and children.
                  Looking...
                </p>
                <Link to={'/customer/ccb'}>
                  <Button color="primary" variant="contained">
                    Read more
                  </Button>
                </Link>
              </div>
            </a>
          </div>
       

          <div>
            <a
              href="/casestdydetails"
              onClick={(e) => e.preventDefault()}
              className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
              <img src={stock1} className="card-img-top" alt="..." />
              <div className="p-3  rounded-bottom p-xl-4">
                <h5 className="card-title font-weight-bold font-size-xxl">
                  Jimenez Mr Discount{' '}
                </h5>
                <p className="card-text">
                  Established in the 1970s and passed down through the family .
                  Sequel School and Office Supplies has grown to become...
                </p>
                <Link to={'/customer/ccb'}>
                  <Button color="primary" variant="contained">
                    Read more
                  </Button>
                </Link>
              </div>
            </a>
          </div>

          <div>
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
              <img src={stock1} className="card-img-top" alt="..." />
              <div className="p-3  rounded-bottom p-xl-4">
                <h5 className="card-title font-weight-bold font-size-xxl">
                  Unique party shop
                </h5>
                <p className="card-text">
                  Despite growing to be Brisbane's largest party supplier,
                  Unique Party Shop faced challenges that were limiting their...
                </p>
                <Link to={'/customer/ccb'}>
                  <Button color="primary" variant="contained">
                    Read more
                  </Button>
                </Link>
              </div>
            </a>
          </div>
          <div>
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="card bg-white m-3 shadow-sm-dark card-box-hover-rise">
              <img src={stock1} className="card-img-top" alt="..." />
              <div className="p-3  rounded-bottom p-xl-4">
                <h5 className="card-title font-weight-bold font-size-xxl">
                  Circle City Bargains
                </h5>
                <p className="card-text">
                  Family run and 100% Aussie owned business bedhead Hats
                  promotes sun safety and confort for babies and children.
                  Looking...
                </p>
                <Link to={'/customer/ccb'}>
                  <Button color="primary" variant="contained">
                    Read more
                  </Button>
                </Link>
              </div>
            </a>
          </div>
        </Slider>
      </Container> */}
    </>
  );
};

export default CaseStudies;
