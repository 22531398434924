// import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // Button,
  List,
  ListItem,
  // Typography,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  // CircularProgress,
  // makeStyles
} from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
// useState
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadPlans } from 'reducers/Plans';
import {
  getAppSubscription,
  loadAppSubscription,
  upgradeAppSubscription
} from 'reducers/AppSubscription';

// const useStyles = makeStyles(() => ({
//   circularProgress: {
//     color: '#1bc943',
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     marginTop: -12,
//     marginLeft: -12
//   }
// }));
// const UpgradeToAnnual = ({
//   open,
//   handleClose,
//   subscription,
//   createUpgrade,
//   loadUserSubscription,
//   startLoading
// }) => {
//   const [isUpgrading, setIsUpgrading] = useState(false);
//   const [error, setError] = useState(null);
//   const [errorMsg, setErrorMsg] = useState(null);
//   const [isSuccess, setIsSuccess] = useState(false);
//   const [successMsg, setSuccessMsg] = useState(null);
//   const classes = useStyles();

//   useEffect(() => {
//     startLoading();
//     loadUserSubscription();
//   }, [loadUserSubscription, startLoading]);
//   const handleUpgradeToAnnual = () => {
//     setIsUpgrading(true);
//     createUpgrade(subscription.planId.stripeYearlyPrice.priceId)
//       .then((re) => {
//         if (re.status === 200) {
//           loadUserSubscription();
//           setIsUpgrading(false);
//           setSuccessMsg('Upgrade Request Successful!');
//           setIsSuccess(true);
//         }
//       })
//       .catch(({ response }) => {
//         console.log(response, 'invalid request consoled');
//         setIsUpgrading(false);
//         setError(true);
//         setErrorMsg(
//           response.data.raw.message ||
//             'Something went wrong please try again later'
//         );
//       });
//   };

//   return (
//     <>
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Upgrade to annual subscription</DialogTitle>
//         <DialogContent>
//           {error && <Alert severity="error">{errorMsg}</Alert>}
//           {isSuccess && <Alert severity="success">{successMsg}</Alert>}
//           {!isSuccess && !error && (
//             <>
//               By upgrading to annual subscription for the plan{' '}
//               <span className="text-uppercase font-weight-bold font-size-sm">
//                 {subscription?.planId?.name}
//               </span>
//               , you will be charged{' '}
//               <span className="font-size-md font-weight-bold text-primary">
//                 ${subscription.planId?.stripeYearlyPrice?.price} /year
//               </span>
//               , and you will save{' '}
//               <span className="font-size-md font-weight-bold text-primary">
//                 $
//                 {subscription.planId?.stripeMonthlyPrice?.price * 12 -
//                   subscription.planId?.stripeYearlyPrice?.price}
//               </span>{' '}
//               .
//             </>
//           )}
//         </DialogContent>
//         <DialogActions>
//           {isSuccess || error ? (
//             <Button
//               onClick={() => {
//                 setError(false);
//                 setIsSuccess(false);
//                 handleClose();
//               }}
//               className="btn-outline-dark">
//               Close
//             </Button>
//           ) : (
//             <>
//               <Button
//                 disabled={isUpgrading}
//                 className="btn-primary"
//                 onClick={handleUpgradeToAnnual}>
//                 {isUpgrading && (
//                   <CircularProgress
//                     size={24}
//                     thickness={5}
//                     className={classes.circularProgress}
//                   />
//                 )}
//                 {isUpgrading ? 'Upgrading...' : 'Upgrade'}
//               </Button>
//               <Button
//                 disabled={isUpgrading}
//                 className="btn-outline-dark"
//                 onClick={() => {
//                   setError(false);
//                   setIsSuccess(false);
//                   handleClose();
//                 }}>
//                 Cancel
//               </Button>
//             </>
//           )}
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

const Subscriptions = ({
  appSubscription,
  // createAppUpgrade,
  loadAppSubscription,
  // startLoading
}) => {
  useEffect(() => {
    loadAppSubscription();
  },[loadAppSubscription]);
  // const [open, setOpen] = useState(false);

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const isOnMonthlyBillingCycle =
  //   subscription?.priceId === subscription.planId?.stripeMonthlyPrice?.priceId;

  return (
    <>
      <List className="mb-4 list-group-bordered">
        {appSubscription?.installed_apps?.length > 0 ? (
          appSubscription?.installed_apps?.map((app) => (
            <>
              {app?.app_id && (
                <ListItem className="d-block d-lg-flex justify-content-between align-items-center py-3">
                  <div className="d-flex align-items-center mr-4">
                    <div className="d-50 d-flex shadow-none p-0 align-items-center justify-content-center rounded-pill border-0 mr-3">
                      <img
                        alt={'app'}
                        width={50}
                        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${app?.app_id?.app_id?.icon}`}
                      />
                    </div>
                    <div>
                      <div className="d-block">
                        <div className="font-weight-bold">
                          {app?.app_id?.app_id?.name}
                        </div>
                        <div className="opacity-7">By Cartlauncher</div>
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-md-flex mt-3 mt-lg-0 align-items-center">
                    ${app.app_id?.app_id?.pricing} per month
                  </div>
                </ListItem>
              )}
            </>
          ))
        ) : (
          <>You do not have any app subscriptions</>
        )}
      </List>
      {/* {isOnMonthlyBillingCycle && subscription?.status !== 'canceled' && (
        <Alert severity="info" icon={false}>
          <div className="d-flex justify-content-start align-items-center">
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Get benefit from annual subscription
            </Typography>
            <div className="badge badge-pill badge-info ml-2">
              Save{' '}
              {(
                ((subscription.planId?.stripeMonthlyPrice?.price * 12 -
                  subscription.planId?.stripeYearlyPrice?.price) *
                  100) /
                (subscription.planId?.stripeMonthlyPrice?.price * 12)
              ).toFixed(1)}
              %
            </div>

          </div>
          <Typography variant="body1">
            Don't miss out on this opportunity to save money and simplify your
            life. Upgrade to our annual subscription today and start enjoying
            all the benefits of our premium service. Act now and take advantage
            of this limited-time offer!
          </Typography>
          <Button onClick={handleOpen} className="btn-small btn-primary mt-4">
            Upgrade to annual subscription
            <FontAwesomeIcon icon={faArrowAltCircleRight} className="ml-2" />
          </Button>
        </Alert>
      )}

      <UpgradeToAnnual
        open={open}
        handleClose={handleClose}
        subscription={subscription}
        createUpgrade={createAppUpgrade}
        loadUserSubscription={loadAppSubscription}
        startLoading={startLoading}
      /> */}
    </>
  );
};
const mapStateToProps = (state) => ({
  appSubscription: getAppSubscription(state)
});
const mapDispatchToProps = (dispatch) => ({
  createAppUpgrade: (invoiceId) => dispatch(upgradeAppSubscription(invoiceId)),
  loadAppSubscription: () => dispatch(loadAppSubscription()),
  startLoading: () => dispatch(loadPlans())
});
export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
