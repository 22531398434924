import {
  Card,
  CardContent,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { axiosIns } from 'config/configAxios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getIsLoadingPlans } from 'reducers/Plans';
import { fetchUser, getIsUploadingUser, getUser } from 'reducers/UserAccount';
import {
  getIsLoadingSubscription,
  getIsUploadingSubscription,
  getSubscription
} from 'reducers/UserSubscriptions';

function CreditBalance({ user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    console.log('users effect');
    setIsLoading(true);
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    axiosIns.get('/users/platforms/stripe/user').then((response) => {
      setIsLoading(false);
      setData(response.data);
    });
  }, [user]);
  return (
    <Card className="card-box overflow-visible mb-3">
      <div className="card-header">
        <div className="card-header--title font-size-md font-weight-bold py-2">
          <Typography
            variant="subtitle1"
            className="text-primary pl-2 font-size-md text-uppercase"
            style={{ fontWeight: 'bold' }}>
            Credits
          </Typography>
        </div>
      </div>
      <CardContent>
        {!isLoading ? (
          <Typography variant="body2" className="font-weight-bold">
            {!data
              ? 'No credits available'
              : '$ ' + Number(data.balance / 100).toFixed(2)}
          </Typography>
        ) : (
          <CircularProgress size={24} thickness={5} />
        )}
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  isUploading: getIsUploadingUser(state),
  isLoading: getIsLoadingPlans(state),
  isLoadingSubscription: getIsLoadingSubscription(state),
  isUploadingSubscription: getIsUploadingSubscription(state),
  subscription: getSubscription(state)
});
const mapDispatchToProps = (dispatch) => ({
  loadUser: (data) => dispatch(fetchUser(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditBalance);
