import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import BcOptions from './BcOptions';

const Barcodes = ({ selected, products }) => {
  const [open, setOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  useEffect(() => {
    const row = [];
    products.forEach((product) => {
      if (selected.some((id) => id === product._id)) {
        row.push(product);
      }
    });
    setSelectedProducts(row);
  }, [products, selected]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button onClick={handleClickOpen} className="m-2 btn-first">
        Get Started !
      </Button>

      <BcOptions open={open} products={selectedProducts} setOpen={setOpen} />
    </>
  );
};

export default Barcodes;