import { axiosIns, axiosIns2 } from '../config/configAxios';
import { transformErrors } from 'utils';
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_APP_CATEGORY = 'ADD_APP_CATEGORY';
export const addAppCategorySuccess = (category) => ({
  type: ADD_APP_CATEGORY,
  payload: { category }
});

export const DELETE_APP_CATEGORY = 'DELETE_APP_CATEGORY';
export const deleteAppCategorySuccess = (id) => ({
  type: DELETE_APP_CATEGORY,
  payload: { id }
});

export const UPDATE_APP_CATEGORY = 'UPDATE_APP_CATEGORY';
export const updateAppCategorySuccess = (category) => ({
  type: UPDATE_APP_CATEGORY,
  payload: { category }
});

export const ADD_APP_SUBCATEGORY = 'ADD_APP_SUBCATEGORY';
export const addAppSubCategorySuccess = (subcategory) => ({
  type: ADD_APP_SUBCATEGORY,
  payload: { subcategory }
});


export const LOADING_APP_IN_PROGRESS = 'CATEGORIES_APP_LOADING_IN_PROGRESS';
export const loadingAppInProgress = () => ({
  type: LOADING_APP_IN_PROGRESS
});

export const LOADING_APP_SUCCESS = 'CATEGORIES_APP_LOADING_SUCCESS';
export const loadingAppSuccess = (categories) => ({
  type: LOADING_APP_SUCCESS,
  payload: { categories }
});

export const LOADING_APP_FAILURE = 'CATEGORIES_APP_LOADING_FAILURE';
export const loadingAppFailure = () => ({
  type: LOADING_APP_FAILURE
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = { isLoading: false, data: [] };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_APP_CATEGORY: {
      const { category } = payload;

      return { ...state, data: state.data.concat(category) };
    }

    case DELETE_APP_CATEGORY: {
      const { id } = payload;
      return { ...state, data: state.data.filter((c) => c._id !== id) };
    }

    case UPDATE_APP_CATEGORY: {
      const { category } = payload;
      const newData = state.data.map((c) => {
        if (c._id === category._id) return { ...c, ...category };
        return c;
      });
      return { ...state, data: newData };
    }

    case LOADING_APP_SUCCESS: {
      const { categories } = payload;
      return {
        ...state,
        isLoading: false,
        data: categories
      };
    }
    case LOADING_APP_IN_PROGRESS:
      return { ...state, isLoading: true };
    case LOADING_APP_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadAppCategories = () => async (dispatch, getState) => {
  dispatch(loadingAppInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/apps/categories');
    const categories = response.data;
    console.log(categories);
    dispatch(loadingAppSuccess(categories));
  } catch (err) {
    dispatch(loadingAppFailure());
  }
};

export const addCategory = (data, props) => async (dispatch, getState) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);

  

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.post('/apps/categories', data);
    if (res.status === 201) {
      dispatch(addAppCategorySuccess(res.data));
      setStatus({ success: true });
      resetForm({});
      toggleModal();
    }
  } catch (err) {
    console.log(err.response.data.errors);
    // console.log()
    setErrors(transformErrors(err.response.data.errors));
    setSubmitting(false);
    // if (err.response.status === 400) {
    //   seterror({ status: true, msg: err.response.data.errors[0].msg });
    // }
  } finally {
    setSubmitting(false);
  }
};

export const deleteCategory = (categoryId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns.delete(`/apps/categories/${categoryId}`);

    if (res.status === 204) dispatch(deleteAppCategorySuccess(categoryId));
  } catch (err) {
    console.log(err);
  }
};

export const updateCategory = (cId, data, props) => async (
  dispatch,
  getState
) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);

  

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.put(`/apps/categories/${cId}`, data);
    if (res.status === 200) {
      dispatch(updateAppCategorySuccess(res.data));
      setStatus({ success: true });
      resetForm({});
      toggleModal();
    }
  } catch (err) {
    console.log(err);
    setErrors(transformErrors(err.response.data.errors));

    setSubmitting(false);
  } finally {
    setSubmitting(false);
  }
};

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getAllAppCategories = (state) => state.AppCategory.data;
export const getIsLoadingAppCategories = (state) => state.AppCategory.isLoading;
