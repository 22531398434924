import React from 'react';
import { connect } from 'react-redux';
import { fromUnixTime } from 'date-fns';
import { Button, Card,  Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// import ReplayIcon from '@material-ui/icons/Replay';
import {
  getSubscription,
  getIsLoadingSubscription,
  getIsUploadingSubscription,
  loadSubscription,
  deleteSubscription
} from 'reducers/UserSubscriptions';

import Alert from '@material-ui/lab/Alert';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { getIsUploadingUser, getUser, fetchUser } from 'reducers/UserAccount';
import { getPlans, getIsLoadingPlans, loadPlans } from 'reducers/Plans';

import CountUp from 'react-countup';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResultsContainer = ({
  subscription,
  isLoadingSubscription,
}) => {
  const getFeatureUsage = (featureIdentifier) => {
    return subscription.planId?.features?.filter(
      (feat) => feat.featureId.identifier === featureIdentifier
    );
  };
  return (
    <>
      <div className="p-4 mb-spacing-6-x2">
        {!isLoadingSubscription &&
          subscription !== null &&
          subscription?.status === 'incomplete' &&
          subscription?.paymentStatus === 'open' && (
            <Alert severity="warning">
              Your Transaction was not successful!
            </Alert>
          )}
        {!isLoadingSubscription &&
          subscription !== null &&
          subscription?.status === 'active' &&
          subscription?.paymentStatus === 'open' && (
            <Alert severity="warning">
              Your account is activated while payment recovery is in process
            </Alert>
          )}

        {!isLoadingSubscription &&
          subscription !== null &&
          (subscription?.status === 'past_due' ||
            subscription?.status === 'incomplete') &&
          subscription?.paymentStatus === 'open' && (
            <Alert severity="warning">
              Your payment needs authentication check your email or click
              {subscription?.hosted_invoice_url ? (
                 <Button
                 className="btn-link-primary btn btn-link"
                 target="_blank"
                 rel="noopener noreferrer"
                 href={subscription?.hosted_invoice_url || '#'}>
                 {' '}
                 here
                 <FontAwesomeIcon icon={faLink} className="ml-2" />
               </Button>
              ) : (
                ''
              )}
            </Alert>
          )}
        {!isLoadingSubscription &&
          subscription !== null &&
          subscription.cancel_at_period_end && (
            <Alert severity="warning">
              Your Subscription will be cancelled at the end of this billing
              period on {fromUnixTime(subscription.endtime).toDateString()}
            </Alert>
          )}
        {!isLoadingSubscription &&
          subscription !== null &&
          subscription?.status === 'trialing' &&
          subscription?.paymentStatus === 'paid' &&
          subscription?.isTrailEnding && (
            <Alert severity="warning">
              Your trail is ending in 3 days on{' '}
              {fromUnixTime(subscription?.endtime).toDateString()}
            </Alert>
          )}
        {!isLoadingSubscription &&
        subscription != null && subscription?.status!=='canceled'&&
        subscription?.planId != null ? (
          <>
            <Grid
              container
              direction="row"
              justify="left"
              alignItems="left"
              spacing={3}
              className="d-flex align-items-center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="pt-3 mb-1 font-size-md">
                  <b>Active Plan: </b>
                  <span className="badge badge-success badge-pill">
                    {subscription?.planId?.name}
                  </span>
                </div>
                <div>
                  <span className="text-primary font-size-sm">
                    Next Payment:{' '}
                  </span>
                  <span className="font-size-xs">
                    {fromUnixTime(subscription.endtime).toDateString()}
                  </span>
                </div>
            
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="pt-3 mb-1 font-size-md">
                  <b>Plan Usage: </b>
                </div>
              </Grid>
              <Grid item xs={12} sm={8} md={4} lg={4} xl={4}>
                <Card className="card-box p-3">
                  <div className="d-flex align-items-center">
                    <CircularProgressbarWithChildren
                      value={subscription.totalProducts}
                      maxValue={getFeatureUsage('LPL')[0]?.usageLimit}
                      strokeWidth={6}
                      className="circular-progress-success">
                      <div className="d-40 rounded-circle text-size-sm bg-neutral-success btn-icon">
                        <CountUp
                          start={0}
                          end={subscription?.totalProducts}
                          duration={6}
                          delay={2}
                          separator=""
                        />
                      </div>
                    </CircularProgressbarWithChildren>
                    <div className="pl-3 ">
                      <div className="text-primary font-size-sm font-weight-bold">
                        Product Limits
                      </div>
                      <div className="display-4 font-size-sm  font-weight-bold pt-2 text-black">
                        {subscription?.totalProducts}/
                        {getFeatureUsage('LPL')[0]?.usageLimit}
                      </div>
                    </div>
                  </div>
                </Card>
              </Grid>

              <Grid item xs={12} sm={8} md={4} lg={4} xl={4}>
                <Card style={{ marginLeft: '20px' }} className="card-box p-3">
                  <div className="d-flex align-items-center">
                    <CircularProgressbarWithChildren
                      value={subscription?.consumedScans}
                      maxValue={getFeatureUsage('BUM')[0]?.usageLimit}
                      strokeWidth={6}
                      className="circular-progress-success">
                      <div className="d-40 rounded-circle bg-neutral-success btn-icon">
                        {/* {subscription?.consumedScans} */}
                        <CountUp
                          start={0}
                          end={subscription?.consumedScans}
                          duration={6}
                          delay={2}
                          separator=""
                        />
                      </div>
                    </CircularProgressbarWithChildren>
                    <div className="pl-3">
                      <div className="text-primary font-size-sm font-weight-bold">
                        Barcode Scans
                      </div>
                      <div className="display-4 font-size-sm font-weight-bold pt-2 text-black">
                        {subscription.consumedScans}
                        {'/'}
                        {getFeatureUsage('BUM')[0]?.usageLimit}
                      </div>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={8} md={4} lg={4} xl={4}>
                <Card style={{ marginLeft: '20px' }} className="card-box p-3">
                  <div className="d-flex align-items-center">
                    <CircularProgressbarWithChildren
                      value={subscription.webSyncs}
                      maxValue={getFeatureUsage('SW')[0]?.usageLimit}
                      strokeWidth={6}
                      className="circular-progress-success">
                      <div className="d-40 rounded-circle bg-neutral-success btn-icon">
                        {/* {subscription?.webSyncs} */}
                        <CountUp
                          start={0}
                          end={subscription?.webSyncs}
                          duration={6}
                          delay={2}
                          separator=""
                        />
                      </div>
                    </CircularProgressbarWithChildren>
                    <div className="pl-3">
                      <div className="text-primary font-size-sm font-weight-bold">
                        Site Builds
                      </div>
                      <div className="display-4 font-size-sm font-weight-bold pt-2 text-black">
                        {subscription?.webSyncs}
                        {'/'}
                        {getFeatureUsage('SW')[0]?.usageLimit}
                      </div>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : subscription === null || subscription?.status==='canceled' ? (
          'You do not have active subscription'
        ) : (
          <>
            <Skeleton
              animation="wave"
              height={20}
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={50} width="100%" />
          </>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: getUser(state),
  isUploading: getIsUploadingUser(state),
  plans: getPlans(state),
  isLoading: getIsLoadingPlans(state),
  isLoadingSubscription: getIsLoadingSubscription(state),
  isUploadingSubscription: getIsUploadingSubscription(state),
  subscription: getSubscription(state)
});
const mapDispatchToProps = (dispatch) => ({
  loadUser: (data) => dispatch(fetchUser(data)),
  startLoading: () => dispatch(loadPlans()),
  loadUserSubscription: () => dispatch(loadSubscription()),
  deleteSubscription: (subscriptionId, modalHandle) =>
    dispatch(deleteSubscription(subscriptionId, modalHandle))
});
export default connect(mapStateToProps, mapDispatchToProps)(ResultsContainer);
