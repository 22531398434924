import React from 'react';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { PageTitle } from '../../layout-components';
import { Orders1, StoresDropdown } from '../../components';

export default function Orders() {
  return (
    <>
      <PageTitle
        titleHeading="Orders"
        titleDescription="List order for your Store."
        titleIcon={<ShoppingCartIcon />}>
        <StoresDropdown />
      </PageTitle>

      <Orders1 />
    </>
  );
}
