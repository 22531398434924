import { axiosIns } from '../config/configAxios';

const STORE_USERS_FETCH_REQUEST = 'STORE_USERS_FETCH_REQUEST';
const STORE_USERS_FETCH_SUCCESS = 'STORE_USERS_FETCH_SUCCESS';
const STORE_USERS_FETCH_FAILURE = 'STORE_USERS_FETCH_FAILURE';

const getStoreUsers = () => ({
  type: STORE_USERS_FETCH_REQUEST
});
const getStoreUsersSuccess = (data) => ({
  type: STORE_USERS_FETCH_SUCCESS,
  payload: data
});
const setStoreUsersFailure = (error) => ({
  type: STORE_USERS_FETCH_FAILURE,
  payload: error
});

const initialState = {
  data: [],
  // Multiple possible status enum values
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_USERS_FETCH_REQUEST: {
      console.log('FEtch request');
      return {
        ...state,
        status: 'loading'
      };
    }
    case STORE_USERS_FETCH_SUCCESS:
      return {
        ...state,
        status: 'succeeded',
        data: action.payload
      };
    case STORE_USERS_FETCH_FAILURE:
      return {
        ...state,
        status: 'failed',
        error: action.payload
      };
    default:
      return state;
  }
}

export const fetchUsers = () => async (
  dispatch,
  getState
) => {
  dispatch(getStoreUsers());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get(`/users/marketplace`);
    const storeList = response.data;
    console.log("fetch response",response);
    dispatch(getStoreUsersSuccess(storeList));
  } catch (err) {
    dispatch(setStoreUsersFailure(err));
  }
};

export const getStatus = (state) => state.StoreUsers.status;
export const getErrors = (state) => state.StoreUsers.errors;
export const getUsers = (state) => state.StoreUsers.data;
