import React from 'react';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import slugify from 'slugify';
import JSZip from 'jszip';
import FileSaver, { saveAs } from 'file-saver';
import {
  pdf,
  Page,
  Document,
  Text,
  StyleSheet,
  Image,
  Font
} from '@react-pdf/renderer';
import Regular from './poppins-regular.ttf';
import Bold from './Poppins-Bold.ttf';

import Logo from '../../../assets/images/logo192.png';
import Img from '../../../assets/images/stock-photos/stock-1.jpg';
import QRCode from 'qrcode';

Font.register({ family: 'Poppins', src: Bold });
Font.register({ family: 'Font1', src: Regular });

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid black'
  },
  title: {
    paddingLeft: '260px',
    fontSize: '15px'
  },

  product: {
    height: '250px',
    width: '250px',
    position: 'relative ',
    alignItems: 'center',
    padding: '10px'
  },
  div: {
    display: 'flex',
    flexDirection: 'row',

    flexWrap: 'wrap',
    fontFamily: 'Poppins'
  },
  price: {
    paddingLeft: '260px',
    color: 'lightgrey'
  },
  div2: {
    position: 'absolute',
    justifyContent: 'center',
    paddingTop: '50px',
    top: '90px',

    left: '260px',
    backgroundColor: '#fefefa',
    width: '320px',
    height: '80px'
  },
  price2: {
    fontFamily: 'Poppins',
    position: 'absolute',
    paddingTop: '4px',
    paddingLeft: '6px',
    display: 'flex',
    marginLeft: '30px',
    marginBottom: '5px',
    width: '180px',
    backgroundColor: '#C02425',
    color: 'white',
    fontSize: '15px',
    border: '1px solid red',
    borderRadius: '5px'
  },
  div3: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fefefa',
    top: '190px',

    left: '260px',
    width: '320px',
    height: '100px'
  },
  qrcodeDiv: {
    width: '50%'
  },
  logoDiv: {
    width: '50%'
  },
  qrcode: {
    height: '110px',
    width: '100px',

    float: 'right',
    paddingBottom: '10px'
  },
  logo: {
    height: '100px',
    width: '100%',

    float: 'right',
    paddingBottom: '10px',
    paddingRight: '10px'
  },

  image: {
    position: 'absolute',
    height: '290px',
    backgroundColor: '#fefefa'
  },
  orig: {
    color: 'red'
  },
  notes: {
    fontFamily: 'Poppins',
    fontSize: '10px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    backgroundColor: '#fefefa',
    top: '300px'
  }
});
const useStyles = makeStyles(() => ({
  circularProgress: {
    color: '#1bc943',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const MyDocument = (props) => {
  const classes = useStyles();
  const zip = new JSZip();
  props.products.forEach((product) => {
    let qrcode;

    let URL = `${props?.activeStore[0]?.site.url}/products/${slugify(
      product.title
    ).toLowerCase()}`;

    QRCode.toDataURL(URL, (err, url) => {
      qrcode = url;
    });
    zip.file(product.title + '.png', qrcode.substring(22), { base64: true });
  });

  const document = (
    <Document style={{ backgroundColor: '#EEEDEB' }}>
      {props.products.map((item, index) => {
        let qrcode;

        let URL = `${props?.activeStore[0]?.site.url}products/${slugify(
          item.title
        ).toLowerCase()}`;
        const price = Number(item.price);
        const oldPrice = Number(item.oldPrice);
        let discount = oldPrice > price ? Math.abs(oldPrice - price) : 0;
        let percentage =
          oldPrice > price
            ? ((100 * (Number(oldPrice) - Number(price))) / oldPrice).toFixed(0)
            : 0;
        if (item.oldPrice === 0) {
          percentage = 0;
          discount = 0;
        }
        props.url === 'product'
          ? QRCode.toDataURL(URL, (err, url) => {
              qrcode = url;
            })
          : props.url === 'store'
          ? QRCode.toDataURL(
              `${props?.activeStore[0]?.site.url}`,
              (err, url) => {
                qrcode = url;
              }
            )
          : props.url === 'homedepot' &&
            QRCode.toDataURL(
              item?.listingURL.includes('amazon')
                ? item?.listingURL
                : `https://homedepot.com${item?.listingURL}`,
              (err, url) => {
                qrcode = url;
              }
            );

        return props.stock ? (
          [...Array(item.quantity)].map((_, i) => (
            <Page
              size={props.Layout}
              wrap={false}
              key={i}
              style={{ backgroundColor: '#EEEDEB' }}
              orientation="potrait">
              <div style={styles.div}>
                <div style={{ backgroundColor: 'white', width: '580px' }}>
                  <Text style={styles.title}>
                    {props.showProductName === true ? item.title : ''}
                  </Text>
                  {props.showProductPrice === true ? (
                    <Text style={styles.price}>
                      <div style={{ color: '#C02425' }}>${item.price}</div>
                      <div style={{ textDecoration: 'line-through' }}>
                        ${item.oldPrice}
                      </div>
                    </Text>
                  ) : (
                    <Text style={styles.price}></Text>
                  )}
                </div>
                {props.image === true ? (
                  <div style={styles.image}>
                    <Image
                      src={{
                        uri: `${process.env.REACT_APP_ASSETS_BASE_URL}/${
                          item.images && item.images.length > 0
                            ? item.images[0]?.url
                            : item.thumbnail
                            ? item.thumbnail
                            : 'no_product.png'
                        }`,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' }
                      }}
                      // src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${item.thumbnail}`}
                      style={styles.product}
                    />
                  </div>
                ) : (
                  <div style={styles.image}>
                    <Text style={styles.product}></Text>
                  </div>
                )}
              </div>
              {props.showSavePrice === true ? (
                <div style={styles.div2}>
                  <Text style={styles.price2}>
                    <p style={{ fontFamily: 'Font1' }}>Save</p>

                    <div>
                      {percentage}% ${discount}
                    </div>
                  </Text>
                </div>
              ) : (
                <Text style={styles.div2}> </Text>
              )}

              <div style={styles.div3}>
                <Image src={qrcode} style={styles.qrcode} />
                <Image src={Logo} style={styles.logo} />
              </div>

              <Text
                style={{
                  position: 'relative',
                  top: '230px',
                  paddingLeft: '10px',
                  backgroundColor: '#fefefa',
                  fontFamily: 'Poppins',
                  fontSize: '10px'
                }}>
                Notes : {props.Notes}
              </Text>
            </Page>
          ))
        ) : (
          <Page
            size={props.Layout}
            wrap={false}
            key={index}
            style={{ backgroundColor: '#EEEDEB' }}
            orientation="potrait">
            <div style={styles.div}>
              <div style={{ backgroundColor: 'white', width: '580px' }}>
                <Text style={styles.title}>
                  {props.showProductName && item.title}
                </Text>
                {props.showProductPrice && (
                  <Text style={styles.price}>
                    <div style={{ color: '#C02425' }}>${item.price}</div>
                    <div style={{ textDecoration: 'line-through' }}>
                      ${item.oldPrice}
                    </div>
                  </Text>
                )}
              </div>
              {props.image && (
                <div style={styles.image}>
                  <Image src={Img} style={styles.product} />
                </div>
              )}
            </div>
            {props.showSavePrice && (
              <div style={styles.div2}>
                <Text style={styles.price2}>
                  <p style={{ fontFamily: 'Font1' }}>Save</p>

                  <div>
                    {percentage}% ${discount}
                  </div>
                </Text>
              </div>
            )}

            <div style={styles.div3}>
              <div style={styles.qrcodeDiv}>
                <Image src={qrcode} style={styles.qrcode} />
              </div>
              <div style={styles.logoDiv}>
                <Image src={Logo} style={styles.logo} />
              </div>
            </div>
            <Text
              style={{
                position: 'relative',
                top: '230px',
                paddingLeft: '10px',
                backgroundColor: '#fefefa',
                fontFamily: 'Poppins',
                fontSize: '10px'
              }}>
              {props.showNotes && `Notes : ${props.Notes}`}
            </Text>
          </Page>
        );
      })}
    </Document>
  );

  return (
    <>
      {props.openSpec && (
        <ButtonGroup
          variant="contained"
          color="primary"
          disabled={props.quantity > 150}
          aria-label="full-width contained primary button group">
          <Button
            disabled={props.isLoading}
            onClick={async () => {
              props.setIsLoading(true);
              const asPdf = pdf([]); // {} is important, throws without an argument
              asPdf.updateContainer(document);
              const blob = await asPdf.toBlob();
              saveAs(blob, 'document.pdf');
              props.ResetFilter();
            }}>
            Download PDF
            {props.isLoading && (
              <CircularProgress
                size={24}
                thickness={5}
                className={classes.circularProgress}
              />
            )}
          </Button>
        </ButtonGroup>
      )}
      {props.qrLayout && (
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="full-width contained primary button group">
          <Button
            onClick={async () => {
              props.setIsLoading(true);
              zip.generateAsync({ type: 'blob' }).then(function (content) {
                FileSaver.saveAs(content, 'qrcodes.zip');
              });
            }}>
            Download Zip
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};
export default MyDocument;
