import { axiosIns } from '../config/configAxios';
import {
  getStoreListStarted,
  getStoreListSuccess,
  getStoreListFailed
} from './Store';

/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const updateOrder = (order) => ({
  type: UPDATE_ORDER,
  payload: { order }
});

export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const updateOrders = (data) => ({
  type: UPDATE_ORDERS,
  payload: { data }
});

export const LOADING_IN_PROGRESS = 'ORDERS_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'ORDERS_LOADING_SUCCESS';
export const loadingSuccess = (orders) => ({
  type: LOADING_SUCCESS,
  payload: { orders }
});

export const LOADING_FAILURE = 'ORDERS_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = { isLoading: false, data: [] };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ORDER: {
      const { order } = payload;
      const data = state.data.map((o) => {
        if (o._id === order._id) return order;
        return o;
      });
      return { ...state, data };
    }
    case UPDATE_ORDERS: {
      const { ids, attributes } = payload.data;

      let orders = [...state.data];

      ids.forEach((id) => {
        if ('isActive' in attributes) {
          orders = orders.filter((p) => p._id !== id);
        } else {
          const i = orders.findIndex((p) => p._id === id);
          Object.assign(orders[i], attributes);
        }
      });

      return { ...state, data: orders };
    }
    case LOADING_SUCCESS: {
      const { orders } = payload;
      return { ...state, isLoading: false, data: orders };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */

export const initLoadingOrders = () => async (dispatch, getState) => {
  dispatch(getStoreListStarted());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/stores');
    const storeList = response.data;

    if (storeList.length > 0) dispatch(loadOrders(storeList[0]._id));
    dispatch(getStoreListSuccess(storeList));
  } catch (err) {
    dispatch(getStoreListFailed(err));
  }
};

export const loadOrders = (id) => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get(`/orders?storeId=${id}&isActive=true`);
    const orders = response.data;

    dispatch(loadingSuccess(orders));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

export const updateOrderById = (id, data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.put(`/orders/${id}`, data);
    const newOrder = response.data;

    dispatch(updateOrder(newOrder));
  } catch (err) {
    console.log(err);
  }
};

export const updateMultipleOrders = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch('/orders', data);

    response.data &&
      response.data.nModified > 0 &&
      dispatch(updateOrders(data));
  } catch (err) {
    console.log(err);
  }
};

export const deleteComment = (id, commentId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.delete(
      `/orders/order_comment/${id}/${commentId}`
    );
    const newOrder = response.data;

    dispatch(updateOrder(newOrder));
  } catch (err) {
    console.log(err);
  }
};
export const addComment = (id, body) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.post(`/orders/order_comment/${id}`, {
      body
    });
    const newOrder = response.data;

    dispatch(updateOrder(newOrder));
  } catch (err) {
    console.log(err);
  }
};
/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getOrders = (state) => state.Orders.data;
export const getIsLoadingOrders = (state) => state.Orders.isLoading;
