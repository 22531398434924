import { Box, Grid, TextField, Typography } from '@material-ui/core';

import React, { useEffect, useRef, useState } from 'react';
import PopUpWarningCode from './PopUpWarningCode';

const BarcodeForm = ({
  products,
  setProductToUpdate,
  showUpdateForm,
  productToUpdate,
  increase,
  decrease,
  productNotSaved,
  updateProductById,
  isEmpty
}) => {
  const [code, setCode] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [errorCode, setErrorCode] = useState(false);

  const [showWarning, setShowWarning] = useState(false);

  const codeCopy = useRef('');

  const changeCodeHandler = (e) => {
    setErrorCode(false);
    setCode(e.target.value);
  };

  const submitFormHandler = (e) => {
    e.preventDefault();

    //submit a digit < 12 ==> error
    if (code.length !== 12) {
      setErrorCode(true);
      setErrorMessage('Please enter a valid indentifier');
    }
  };

  useEffect(() => {
    if (code.length > 4) {
      const product = products.find(
        (product) =>
          String(product.cl_upc) === String(code) ||
          String(product.sku) === String(code) ||
          String(product.asin) === String(code) ||
          String(product.barcode) === String(code) ||
          String(product.aspects?.Model) === String(code)
      );

      if (product) {
        //copy the code before initiate '' to use it after if someone entered a new code
        //to pull another product without saving the updates o the previous one
        codeCopy.current = code;

        if (
          isEmpty(productToUpdate.price) ||
          !productNotSaved(productToUpdate)
        ) {
          //Increase ? ==> Increase Quantity by 1
          if (increase) {
            const qty = parseInt(product.quantity);

            setProductToUpdate({ ...product, quantity: qty + 1 });

            updateProductById({ _id: product._id, quantity: qty + 1 });
          }
          //Decrease ? ==> Decrease Quantity by 1
          else if (decrease) {
            const qty = parseInt(product.quantity);
            if (qty > 0) setProductToUpdate({ ...product, quantity: qty - 1 });

            updateProductById({ _id: product._id, quantity: qty - 1 });

            //if no increase or no decrease so there is no actions ==> initiate the producttoupdate with the pulled product
          } else {
            setProductToUpdate(product);
          }
        } else {
          //if the client have tried to pull a new product with barcode without saving the modifications
          //applied for the previous one we will show a warning message to save or undo the updates.
          setShowWarning(true);
        }
        showUpdateForm(true);
        setCode('');
      } else {
        setErrorCode(true);
        setErrorMessage('There is no product with this code');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  //save the products automatically if quantity changed 'auto actions' (increase||decrease)
  useEffect(() => {
    if (increase || decrease) {
      const qty = parseInt(productToUpdate.quantity);

      updateProductById({ _id: productToUpdate._id, quantity: qty });
    }
  }, [productToUpdate, updateProductById, increase, decrease]);

  const saveUpdates = () => {
    //save the products with the updates made in 'productToUpdate'

    //close warning
    setShowWarning(false);

    //Pull the product with the new barcode

    updateProductById({
      _id: productToUpdate._id,
      quantity: productToUpdate.quantity,
      price: productToUpdate.price
    });
  };

  const undoUpdates = () => {
    const product = products.find(
      (product) => product.cl_upc === codeCopy.current
    );

    //inititiate the form with the new product
    setProductToUpdate(product);
    //close warning
    setShowWarning(false);
  };

  const helperText = !errorCode
    ? 'Scan or Enter the code for the product you want to update'
    : errorMessage;

  return (
    <>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={8} lg={12}>
          <Box component="form" onSubmit={submitFormHandler}>
            <TextField
              error={errorCode}
              autoFocus
              label="Bar code"
              fullWidth
              autoComplete="off"
              onChange={changeCodeHandler}
              // type="number"
              size="small"
              placeholder="Enter the bar code"
              InputProps={{
                inputProps: { style: { textAlign: 'center', fontSize: 12 } }
              }}
              value={code}
            />
            <Typography
              fontSize={9}
              color={errorCode ? 'red' : 'gray'}
              sx={{ px: 1 }}>
              {helperText}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <PopUpWarningCode
        showWarning={showWarning}
        saveUpdate={saveUpdates}
        undoUpdate={undoUpdates}
      />
    </>
  );
};

export default BarcodeForm;
