import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Pagination from '@material-ui/lab/Pagination';

import {
  CircularProgress,
  FormControl,
  Grid,
  Select,
  Typography
} from '@material-ui/core';
import {
  Table,
  Checkbox,
  Card,
  CardContent,
  Button,
  Menu,
  TextField,
  ListItem,
  List,
  InputAdornment
} from '@material-ui/core';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import ArrowUpwardTwoToneIcon from '@material-ui/icons/ArrowUpwardTwoTone';
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import ArrowDownwardTwoToneIcon from '@material-ui/icons/ArrowDownwardTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@material-ui/icons/RadioButtonUncheckedTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import {
  getErrors,
  getStatus,
  getUsers,
  fetchUsers
} from '../../reducers/StoreUsers';
import ExportUsers from './ExportUsers';
import { MenuItem } from 'react-contextmenu';
const Users = (props) => {
  const { statuses, users, startLoading, loadUsers } = props;
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [pageNumber, setPageNumber] = useState(1);
  const [numofItems, setNumofItems] = useState(20);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);
  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(users);
      filterProducts(KeywordState);
    }
    // eslint-disable-next-line
  }, [users]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRefresh = () => {
    // setStatus(0);
    setKeywordState('');
    startLoading();
    // setFormats(false);
    setFilteredUsers(users);
  };
  const handleReset = () => {
    setFilteredUsers(users);
    setStatus(0);
    setKeywordState('');
    // setFormats(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const handlePageChange = (event, val) => {
    setPageNumber(val);
    // setSelected([]);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [searchOpen, setSearchOpen] = useState(false);

  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);

  const [status, setStatus] = useState(0);

  const statusvalues = [
    'All statuses',
    'Registered',
    'UnRegistered',
    'Subscribers',
    'Abandoned'
  ];

  const handleStatus = (event) => {
    setStatus(Number(event.target.value));
  };
  const [KeywordState, setKeywordState] = useState('');

  const filterProducts = (query) => {
    setPageNumber(1);
    handleClose();
    let allUsers = users;

    let keyword = query.toLowerCase();
    console.log(statusvalues[status], KeywordState);
    if (status === 0) {
      setFilteredUsers(users);
      return;
    } else if (status !== 0) {
      allUsers = users.filter((p) => {
        if (statusvalues[status] === 'Registered') {
          return p.registeredUser?.length > 0;
        } else if (statusvalues[status] === 'UnRegistered')
          return p.registeredUser?.length === 0;
        else if (statusvalues[status] === 'Subscribers')
          return p?.forms === true;
        else if (statusvalues[status] === 'Abandoned')
          return p?.abandonedCart === true;
        return false;
      });
      setFilteredUsers(allUsers);
      return;
    }
    if (keyword !== '') {
      allUsers = allUsers.filter((p) => {
        return p._id.toLowerCase()?.search(keyword) !== -1;
      });
      setFilteredUsers(allUsers);
      return;
    }

    setFilteredUsers(users);
    return;
  };

  const [selected, setSelected] = React.useState([]);

  const onSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers
        .slice((pageNumber - 1) * numofItems, pageNumber * numofItems)
        .map((p) => p._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const filterByEbayListing = (event) => {
  //   if (!formats) {
  //     const allUsers = users.filter((p) => {
  //       return p.ebayListing.listingId !== null;
  //     });

  //     setFilteredUsers(allUsers);
  //   } else {
  //     setFilteredUsers(users);
  //   }
  //   setFormats(!formats);
  // };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  //   useEffect(() => {
  //     startLoading();
  //     startLoadingCategories();
  //   }, [startLoading, startLoadingCategories]);

  return (
    <>
      <div>
        <ExportUsers selected={selected} users={users} />
      </div>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header">
          <div className="divider" />
          <div className="card-header--title">
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              Users list
            </h4>
          </div>

          <div className="card-header--actions">
            <div>
              <div className="d-flex justify-content-between px-4 py-3">
                <div
                  className={clsx(
                    'search-wrapper search-wrapper--alternate search-wrapper--grow',
                    { 'is-active': searchOpen }
                  )}>
                  <TextField
                    variant="outlined"
                    size="small"
                    id="input-with-icon-textfield22-2"
                    placeholder="Search User..."
                    onFocus={openSearch}
                    onBlur={closeSearch}
                    value={KeywordState}
                    onChange={async (e) => {
                      let value = e.target.value;

                      setKeywordState(value);
                      filterProducts(value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchTwoToneIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    {' '}
                    <Button
                      onClick={handleRefresh}
                      className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                      <RefreshTwoToneIcon className="w-50" />
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={handleClick}
                      className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                      <FilterListTwoToneIcon className="w-50" />
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={Boolean(anchorEl)}
                      classes={{ list: 'p-0' }}
                      onClose={handleClose}>
                      <div className="dropdown-menu-xxl overflow-hidden p-0">
                        <div className="p-3">
                          <Grid container spacing={6}>
                            <Grid item md={12}>
                              <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                                Status
                              </small>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small">
                                <Select
                                  fullWidth
                                  value={status}
                                  onChange={handleStatus}
                                  labelWidth={0}>
                                  <MenuItem value={0}>All statuses</MenuItem>
                                  <MenuItem value={1}>Registered</MenuItem>
                                  <MenuItem value={2}>UnRegistered</MenuItem>
                                  <MenuItem value={3}>
                                    Email Subscribers
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    Abandoned checkouts in last 30 days
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="divider" />
                        <div className="p-3 text-center bg-secondary">
                          <Button
                            onClick={(e) => filterProducts(KeywordState)}
                            className="btn-primary"
                            size="small">
                            Filter results
                          </Button>
                        </div>
                        <div className="divider" />
                        <div className="p-3">
                          <Grid container spacing={6}>
                            <Grid item md={12}>
                              <List className="nav-neutral-success flex-column p-0">
                                <ListItem
                                  button
                                  className="d-flex rounded-sm justify-content-center"
                                  href="#/"
                                  onClick={handleReset}>
                                  <div className="mr-2">
                                    <SaveTwoToneIcon />
                                  </div>
                                  <span>Reset Filter</span>
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="p-3">
                          <Grid container spacing={6}>
                            <Grid item md={12}>
                              <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                                Other Filters
                              </small>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Menu>
                  </div>
                  <div>
                    <Button
                      onClick={handleClick2}
                      className="btn-outline-primary d-flex align-items-center justify-content-center d-40 p-0 rounded-pill">
                      <SettingsTwoToneIcon className="w-50" />
                    </Button>
                    <Menu
                      anchorEl={anchorEl2}
                      keepMounted
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={Boolean(anchorEl2)}
                      classes={{ list: 'p-0' }}
                      onClose={handleClose2}>
                      <div className="dropdown-menu-lg overflow-hidden p-0">
                        <div className="font-weight-bold px-4 pt-3">
                          Results
                        </div>
                        <List className="nav-neutral-first nav-pills-rounded flex-column p-2">
                          <ListItem
                            button
                            href="#/"
                            onClick={(e) => {
                              setPageNumber(1);
                              setNumofItems(5);
                              handleClose2();
                            }}>
                            <div className="nav-link-icon mr-2">
                              <RadioButtonUncheckedTwoToneIcon />
                            </div>
                            <span className="font-size-md">
                              <b>5 </b> results per page
                            </span>
                          </ListItem>
                          <ListItem
                            button
                            href="#/"
                            onClick={(e) => {
                              setPageNumber(1);
                              setNumofItems(20);
                              handleClose2();
                            }}>
                            <div className="nav-link-icon mr-2">
                              <RadioButtonUncheckedTwoToneIcon />
                            </div>
                            <span className="font-size-md">
                              <b>20</b> results per page
                            </span>
                          </ListItem>
                          <ListItem
                            button
                            href="#/"
                            onClick={(e) => {
                              setPageNumber(1);
                              setNumofItems(30);
                              handleClose2();
                            }}>
                            <div className="nav-link-icon mr-2">
                              <RadioButtonUncheckedTwoToneIcon />
                            </div>
                            <span className="font-size-md">
                              <b>30</b> results per page
                            </span>
                          </ListItem>
                          <ListItem
                            button
                            href="#/"
                            onClick={(e) => {
                              setPageNumber(1);
                              setNumofItems(props.users.length);
                              handleClose2();
                            }}>
                            <div className="nav-link-icon mr-2">
                              <RadioButtonUncheckedTwoToneIcon />
                            </div>
                            <span className="font-size-md">List all</span>
                          </ListItem>
                        </List>
                        <div className="divider" />
                        <div className="font-weight-bold px-4 pt-4">Order</div>
                        <List className="nav-neutral-first nav-pills-rounded flex-column p-2">
                          <ListItem
                            button
                            href="#/"
                            onClick={(e) => {
                              handleClose2();
                              setFilteredUsers(
                                filteredUsers.sort((a, b) => {
                                  var x = a._id.toLowerCase();
                                  var y = b._id.toLowerCase();
                                  if (x < y) {
                                    return -1;
                                  }
                                  if (x > y) {
                                    return 1;
                                  }
                                  return 0;
                                })
                              );
                            }}>
                            <div className="mr-2">
                              <ArrowUpwardTwoToneIcon />
                            </div>
                            <span className="font-size-md">
                              Ascending (A-Z)
                            </span>
                          </ListItem>
                          <ListItem
                            button
                            href="#/"
                            onClick={(e) => {
                              handleClose2();
                              setFilteredUsers(
                                filteredUsers.sort((a, b) => {
                                  var x = a._id.toLowerCase();
                                  var y = b._id.toLowerCase();
                                  if (x < y) {
                                    return 1;
                                  }
                                  if (x > y) {
                                    return -1;
                                  }
                                  return 0;
                                })
                              );
                            }}>
                            <div className="mr-2">
                              <ArrowDownwardTwoToneIcon />
                            </div>
                            <span className="font-size-md">
                              Descending (A-Z)
                            </span>
                          </ListItem>
                          <ListItem
                            button
                            href="#/"
                            onClick={() => {
                              handleClose2();
                              setFilteredUsers(
                                filteredUsers.sort((a, b) => {
                                  var x = +a.totalAmount;
                                  var y = +b.totalAmount;
                                  if (x > y) {
                                    return 1;
                                  }
                                  if (x < y) {
                                    return -1;
                                  }
                                  return 0;
                                })
                              );
                            }}>
                            <div className="mr-2">
                              <ArrowUpwardTwoToneIcon />
                            </div>
                            <span className="font-size-md">
                              Ascending (Price)
                            </span>
                          </ListItem>
                          <ListItem
                            button
                            href="#/"
                            onClick={() => {
                              handleClose2();
                              setFilteredUsers(
                                filteredUsers.sort((a, b) => {
                                  var x = +a.totalAmount;
                                  var y = +b.totalAmount;
                                  if (x < y) {
                                    return 1;
                                  }
                                  if (x > y) {
                                    return -1;
                                  }
                                  return 0;
                                })
                              );
                            }}>
                            <div className="mr-2">
                              <ArrowDownwardTwoToneIcon />
                            </div>
                            <span className="font-size-md">
                              Descending (Price)
                            </span>
                          </ListItem>
                        </List>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {statuses === 'loading' ? (
          <tr>
            <td>
              <CircularProgress
                size={24}
                thickness={5}
                //   className={classes.circularProgress}
              />
            </td>
          </tr>
        ) : (
          <CardContent className="px-0 pt-2 pb-3">
            <Table className="table table-borderless table-hover table-alternate text-nowrap mb-0 font-size-sm">
              <thead>
                <tr>
                  <th scope="col" className="pb-4 text-center">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < users.length
                      }
                      checked={
                        users.length > 0 &&
                        selected.length ===
                          filteredUsers.slice(
                            (pageNumber - 1) * numofItems,
                            pageNumber * numofItems
                          ).length
                      }
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      onChange={onSelectAll}
                    />
                  </th>
                  {selected.length > 0 ? (
                    <th className="font-size-sm font-weight-normal pb-4 text-capitalize text-dark">
                      {selected.length} of{' '}
                      {
                        filteredUsers.slice(
                          (pageNumber - 1) * numofItems,
                          pageNumber * numofItems
                        ).length
                      }{' '}
                      selected
                    </th>
                  ) : (
                    <>
                      <th className="font-size-sm">User</th>
                      <th className="font-size-sm">Email</th>
                      <th className="text-center font-size-sm">Status</th>

                      <th className="text-center font-size-sm">
                        Email Subscription
                      </th>
                      <th className="text-center font-size-sm">Orders</th>
                      <th className="text-center font-size-sm">Amount Spent</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredUsers
                  .slice((pageNumber - 1) * numofItems, pageNumber * numofItems)
                  .map((user) => (
                    <tr>
                      <td className="text-center">
                        <Checkbox
                          color="primary"
                          checked={isSelected(user._id)}
                          onChange={(e) => onSelect(user._id)}
                          id="checkboxProjects15"
                          className="align-self-center "
                        />
                      </td>
                      <td>
                        <div className="d-flex">
                          <div>
                            <a
                              href="#/"
                              onClick={(e) => e.preventDefault()}
                              className="font-weight-bold text-black font-size-sm"
                              title="...">
                              {user.customer.firstName} {user.customer.lastName}
                            </a>
                            <span className="text-black-50 d-block font-size-sm">
                              {user.customer.state}, {user.customer.city},{' '}
                              {user.customer.zip}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-left">
                        <span className="text-black font-size-sm">
                          {user._id}
                        </span>
                      </td>
                      <td className="text-center">
                        <span className=" font-weight-bold">
                          {user.registeredUser?.length > 0
                            ? 'Registered'
                            : 'Not registered'}
                        </span>
                      </td>
                      <td className="text-center">
                        {user.forms ? (
                          <span className=" font-weight-bold font-size-sm badge badge-success text-small">
                            Subscribed{' '}
                          </span>
                        ) : (
                          <span className=" font-weight-bold font-size-sm badge badge-warning text-small">
                            Not Subscribed
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="badge font-size-sm">
                          {' '}
                          {user.totalOrder}
                        </div>
                        {user?.lastOrderDate && (
                          <div className="font-size-sm text-black-50 d-block">
                            Last order: <br />
                            {new Date(user.lastOrderDate).toDateString()}
                          </div>
                        )}
                      </td>
                      <td className="text-right">
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="font-weight-bold font-size-sm pr-2">
                            $ {user.totalAmount?.toFixed(2)}
                          </div>
                          {/* <FontAwesomeIcon
                            icon={['fas', 'arrow-down']}
                            className="font-size-sm opacity-5"
                          /> */}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="divider mb-3" />
          </CardContent>
        )}
        <div className="d-flex align-items-center justify-content-center flex-wrap mb-4">
          <Typography>Page: </Typography>
          <Pagination
            className="pagination-success"
            count={Math.ceil(filteredUsers.length / numofItems)}
            page={pageNumber}
            onChange={handlePageChange}
          />
        </div>
      </Card>
    </>
  );
};
const mapStateToProps = (state) => ({
  statuses: getStatus(state),
  errors: getErrors(state),
  users: getUsers(state)
});

const mapDispatchToProps = (dispatch) => ({
  loadUsers: () => dispatch(fetchUsers())
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
