import React from 'react';
import {
  Button,
  ButtonGroup,
  Grid,
  Switch,
  Typography,
  FormControlLabel
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const AutoActions = ({
  increase,
  setIncrease,
  decrease,
  setDecrease,
  checked,
  setChecked,
  productNotSaved,
  showWarning
}) => {
  const clickIncreaseHandler = () => {
    setIncrease(!increase);
    setDecrease(false);

  };
  const clickDecreaseHandler = () => {
    setDecrease(!decrease);
    setIncrease(false);
  };

  const handleCheck = (e) => {
    if (productNotSaved()) {
      //If we want to make auto actions swhile the product is not
      //saved we will show the user a pop informs him to save the updates
      showWarning();
    } else {
      setChecked(e.target.checked);
      setIncrease(false);
      setDecrease(false);
    }
  };

  const AutoActions = checked ? (
    <Grid item container justifyContent="center">
      <Grid item xs={7} className="d-flex justify-content-center">
        <ButtonGroup>
          <Button
            color="error"
            size="small"
            variant={decrease ? 'contained' : 'outlined'}
            onClick={clickDecreaseHandler}>
            Decrease (-1)
          </Button>
          <Button
            color="success"
            size="small"
            variant={increase ? 'contained' : 'outlined'}
            onClick={clickIncreaseHandler}>
            Increase (+1)
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  ) : null;
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      className=" mb-3">
      <Grid item xs={3}>
        <Typography fontSize={12}>Auto action:</Typography>
      </Grid>

      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onClick={(e) => handleCheck(e)}
              inputProps={{ 'aria-label': 'controlled' }}
              className="switch-small"
            />
          }
        />
      </Grid>

      <Grid item container justifyContent="center" className="px-1 mb-2">
        <Alert severity="info">
          <Typography fontSize={10}>
            By activating the auto action mode you can chose between adding or
            reducing the product's quantity automatically by scanning or
            entering the barcode in the barcode section
          </Typography>
        </Alert>
      </Grid>
      {AutoActions}
    </Grid>
  );
};

export default AutoActions;
