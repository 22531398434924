import { axiosIns } from '../config/configAxios';
const LOADING_APP_SUBSCRIPTION_INPROGRESS = 'LOADING_APP_SUBSCRIPTION_INPROGRESS';
const LOADING_APP_SUBSCRIPTION_SUCCESS = 'LOADING_APP_SUBSCRIPTION_SUCCESS';
const UPDATE_APP_SUBSCRIPTION_INPROGRESS = 'UPDATE_APP_SUBSCRIPTION_INPROGRESS';
const UPDATE_APP_SUBSCRIPTION_SUCCESS = 'UPDATE_APP_SUBSCRIPTION_SUCCESS';
const DELETE_APP_SUBSCRIPTION = 'DELETE_APP_SUBSCRIPTION';

export const loadingAppSubscription = () => ({
  type: LOADING_APP_SUBSCRIPTION_INPROGRESS
});
export const loadingAppSubscriptionSuccess = (data) => ({
  type: LOADING_APP_SUBSCRIPTION_SUCCESS,
  payload: { data }
});
export const uploadingAppSubscriptionInProgress = () => ({
  type: UPDATE_APP_SUBSCRIPTION_INPROGRESS
});
export const deleteAppSubscriptionState = () => ({
  type: DELETE_APP_SUBSCRIPTION
});
export const updateAppSubscriptionSuccess = (data) => ({
  type: UPDATE_APP_SUBSCRIPTION_SUCCESS,
  payload: { data }
});
const initialState = {
  data: {},
  isLoading: false,
  isUploading: false,
  error: {}
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_APP_SUBSCRIPTION_INPROGRESS: {
      return { ...state, isLoading: true };
    }
    case LOADING_APP_SUBSCRIPTION_SUCCESS: {
      const { data } = payload;
      return { ...state, data: data, isLoading: false, isUploading: false };
    }
    case UPDATE_APP_SUBSCRIPTION_INPROGRESS: {
      return { ...state, isUploading: true };
    }
    case UPDATE_APP_SUBSCRIPTION_SUCCESS: {
      const { data } = payload;
      // console.log("upgrade")
      return { ...state, data: data, isUploading: false };
    }
    case DELETE_APP_SUBSCRIPTION: {
      return {
        ...state,
        isUploading: false,
        data: {
          ...state.data,
          cancel_at_period_end: !state.data.cancel_at_period_end
        }
      };
    }
    default:
      return state;
  }
}
/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadAppSubscription = () => async (dispatch, getState) => {
  dispatch(loadingAppSubscription());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.get('/subscriptions/app');
    const subscription = response.data;
    dispatch(loadingAppSubscriptionSuccess(subscription));
  } catch (e) {
    console.log(e);
  }
};
export const deleteAppSubscription = (
  subscriptionId,
  toggleDeleteDialog
) => async (dispatch, getState) => {
  dispatch(uploadingAppSubscriptionInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.delete('/subscriptions' + subscriptionId);
    if (response.status === 200) {
      toggleDeleteDialog();
      dispatch(deleteAppSubscriptionState());
    }
  } catch (e) {
    console.log(e);
  }
};
export const upgradeAppSubscription = (invoiceId) => async (
  dispatch,
  getState
) => {
  dispatch(uploadingAppSubscriptionInProgress());
  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return axiosIns
    .post('/subscriptions/update-subscription', {
      newPriceId: invoiceId
    })

    .then((response) => {
      dispatch(updateAppSubscriptionSuccess(response.data));
      return response;
    });
};
export const getAppSubscription = (state) => state.AppSubscription.data;
export const getIsLoadingAppSubscription = (state) =>
  state.AppSubscription.isLoading;
export const getIsUploadingAppSubscription = (state) =>
  state.AppSubscription.isUploading;
