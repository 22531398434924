import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import { axiosIns } from 'config/configAxios';
import RequestTable from './RequestTable';
import { getUser } from 'reducers/UserAccount';
import { CircularProgress } from '@material-ui/core';
const RequestPage = ({ orders, user }) => {
  const [crequest, setCRequest] = useState(orders);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await axiosIns.get('/truckloads/requests');
        setCRequest(response.data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  // const handleRemove = (req, name) => {
  //   setCRequest(crequest.filter((request) => request.name !== name));
  // };
  const handleStatus = async (id, value) => {
    console.log(id, value);
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    try {
      await axiosIns.put('/truckloads/requests/' + id, value);
      const updatedRequest = crequest.map((request) => {
        if (request._id === id) {
          return { ...request, ...value };
        }
        return request;
      });
      console.log(updatedRequest);
      setCRequest(updatedRequest);
    } catch (e) {
      console.log(e);
    }
  };
  const handleRemove = async (id, value) => {
    console.log(id, value);
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    try {
      await axiosIns.delete('/truckloads/requests/' + id);
      const updatedRequest = crequest.filter((request) => request._id !== id);
      console.log(updatedRequest);
      setCRequest(updatedRequest);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress
          size={24}
          thickness={5}
          // className={classes.circularProgress}
        />
      ) : (
        <RequestTable
          crequest={crequest}
          handleStatus={handleStatus}
          handleDelete={handleRemove}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: getUser(state)
});

export default connect(mapStateToProps, null)(RequestPage);
