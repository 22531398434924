import { axiosIns } from '../config/configAxios';
const LOADING_SUBSCRIPTION_INPROGRESS = 'LOADING_SUBSCRIPTION_INPROGRESS';
const LOADING_SUBSCRIPTION_SUCCESS = 'LOADING_SUBSCRIPTION_SUCCESS';
const UPDATE_SUBSCRIPTION_INPROGRESS = 'UPDATE_SUBSCRIPTION_INPROGRESS';
const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';

export const loadingSubscription = () => ({
  type: LOADING_SUBSCRIPTION_INPROGRESS
});
export const loadingSubscriptionSuccess = (data) => ({
  type: LOADING_SUBSCRIPTION_SUCCESS,
  payload: { data }
});
export const uploadingSubscriptionInProgress = () => ({
  type: UPDATE_SUBSCRIPTION_INPROGRESS
});
export const deleteSubscriptionState = () => ({
  type: DELETE_SUBSCRIPTION
});
export const updateSubscriptionSuccess = (data) => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload: { data }
});
const initialState = {
  data: {},
  isLoading: false,
  isUploading: false,
  error: {}
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_SUBSCRIPTION_INPROGRESS: {
      return { ...state, isLoading: true };
    }
    case LOADING_SUBSCRIPTION_SUCCESS: {
      const { data } = payload;
      return { ...state, data: data, isLoading: false, isUploading: false };
    }
    case UPDATE_SUBSCRIPTION_INPROGRESS: {
      return { ...state, isUploading: true };
    }
    case UPDATE_SUBSCRIPTION_SUCCESS: {
      const { data } = payload;
      // console.log("upgrade")
      return { ...state, data: data, isUploading: false };
    }
    case DELETE_SUBSCRIPTION: {
      return {
        ...state,
        isUploading: false,
        data: {
          ...state.data,
          cancel_at_period_end: !state.data.cancel_at_period_end
        }
      };
    }
    default:
      return state;
  }
}
/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadSubscription = () => async (dispatch, getState) => {
  dispatch(loadingSubscription());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.get('/subscriptions');
    const subscription = response.data;
    dispatch(loadingSubscriptionSuccess(subscription));
  } catch (e) {
    console.log(e);
  }
};
export const deleteSubscription = (
  subscriptionId,
  toggleDeleteDialog
) => async (dispatch, getState) => {
  dispatch(uploadingSubscriptionInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.delete('/subscriptions/' + subscriptionId);
    if (response.status === 200) {
      toggleDeleteDialog();
      dispatch(deleteSubscriptionState());
    }
  } catch (e) {
    console.log(e);
  }
};
export const upgradeSubscription = (invoiceId) => async (
  dispatch,
  getState
) => {
  dispatch(uploadingSubscriptionInProgress());
  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return axiosIns
    .post('/subscriptions/update-subscription', {
      newPriceId: invoiceId
    })

    .then((response) => {
      dispatch(updateSubscriptionSuccess(response.data));
      return response;
    });
};
export const getSubscription = (state) => state.UserSubscriptions.data;
export const getIsLoadingSubscription = (state) =>
  state.UserSubscriptions.isLoading;
export const getIsUploadingSubscription = (state) =>
  state.UserSubscriptions.isUploading;
