import { axiosIns, axiosIns2 } from '../config/configAxios';
import { resizeImg } from 'utils';
const transformErrors = (res) => {
  let errors = {};
  try {
    for (let i = 0; i < res.length; i++) {
      const el = res[i];
      errors[el.param] = el.msg;
    }
  } catch (err) {
    console.log(err);
  }
  return errors;
};
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_TRUCKLOAD = 'ADD_TRUCKLOAD';
export const addTruckload = (truckload) => ({
  type: ADD_TRUCKLOAD,
  payload: { truckload }
});

export const UPDATE_TRUCKLOAD = 'UPDATE_TRUCKLOAD';
export const updateTruckload = (truckload) => ({
  type: UPDATE_TRUCKLOAD,
  payload: { truckload }
});

export const DELETE_TRUCKLOAD = 'DELETE_TRUCKLOAD';
export const deleteTruckload = (id) => ({
  type: DELETE_TRUCKLOAD,
  payload: { id }
});

export const UPDATE_TRUCKLOADS = 'UPDATE_TRUCKLOADS';
export const updateTruckloads = (data) => ({
  type: UPDATE_TRUCKLOADS,
  payload: { data }
});

const BULKWRITE_TRUCKLOADS = 'BULKWRITE_TRUCKLOADS';
const bulkWriteTruckloadsAction = (data) => ({
  type: BULKWRITE_TRUCKLOADS,
  payload: { data }
});

export const LOADING_IN_PROGRESS = 'TRUCKLOADS_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'TRUCKLOADS_LOADING_SUCCESS';
export const loadingSuccess = (truckloads) => ({
  type: LOADING_SUCCESS,
  payload: { truckloads }
});

export const LOADING_FAILURE = 'TRUCKLOADS_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});

export const UPLOADING_IN_PROGRESS = 'TRUCKLOADS_UPLOADING_IN_PROGRESS';
export const uploadingInProgress = () => ({
  type: UPLOADING_IN_PROGRESS
});

export const UPLOADING_SUCCESS = 'TRUCKLOADS_UPLOADING_SUCCESS';
export const uploadingSuccess = () => ({
  type: UPLOADING_SUCCESS
});

export const UPLOADING_FAILURE = 'TRUCKLOADS_UPLOADING_FAILURE';
export const uploadingFailure = (data) => ({
  type: UPLOADING_FAILURE,
  payload: { data }
});

const TRUCKLOADS_CHANGES_DETECTED = 'TRUCKLOADS_CHANGES_DETECTED';
export const changesDetected = () => ({ type: TRUCKLOADS_CHANGES_DETECTED });

const TRUCKLOADS_CHANGES_PUBLISHED = 'TRUCKLOADS_CHANGES_PUBLISHED';
export const changesPublished = () => ({ type: TRUCKLOADS_CHANGES_PUBLISHED });

export const SHOW_UPGRADE_MODAL = 'SHOW_UPGRADE_MODAL';
export const upgradeModal = (error) => ({
  type: SHOW_UPGRADE_MODAL,
  payload: { error }
});
export const HIDE_UPGRADE_MODAL = 'HIDE_UPGRADE_MODAL';
export const hideUpgradeModal = () => ({
  type: HIDE_UPGRADE_MODAL
});
/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = {
  isLoading: false,
  isUploading: false,
  data: [],
  isChanged: false,
  subscriptionStatus: {},
  errors: {}
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_TRUCKLOAD: {
      const { truckload } = payload;
      return {
        ...state,
        data: state.data.concat(truckload),
        isUploading: false,
        isChanged: true,
        errors: {}
      };
    }
    case UPDATE_TRUCKLOAD: {
      const { truckload } = payload;
      const data = state.data.map((p) => {
        if (p._id === truckload._id) return truckload;
        return p;
      });
      data.isChanged = true;
      return {
        ...state,
        data,
        isUploading: false,
        isChanged: true,
        errors: {}
      };
    }
    case DELETE_TRUCKLOAD: {
      const { id } = payload;
      return {
        ...state,
        data: state.data.filter((p) => p._id !== id),
        isChanged: true
      };
    }
    case UPDATE_TRUCKLOADS: {
      const { ids, attributes } = payload.data;

      let truckloads = [...state.data];

      ids.forEach((id) => {
        if ('isActive' in attributes) {
          truckloads = truckloads.filter((p) => p._id !== id);
        } else if ('percentageDiscount' in attributes) {
          const i = truckloads.findIndex((p) => p._id === id);
          const price = truckloads[i].price;
          const obj = {
            price: Number(
              price - price * (attributes.percentageDiscount / 100)
            ).toFixed(2),
            oldPrice: price
          };
          Object.assign(truckloads[i], obj);
        } else {
          const i = truckloads.findIndex((p) => p._id === id);
          Object.assign(truckloads[i], attributes);
        }
      });
      truckloads = truckloads.map((p) => {
        return { ...p, isChanged: true };
      });
      return { ...state, data: truckloads, isChanged: true };
    }
    case BULKWRITE_TRUCKLOADS: {
      const { data } = payload;
      let truckloads = [...state.data];
      data.forEach(({ _id, ...truckload }) => {
        const i = truckloads.findIndex((p) => p._id === _id);
        if (i !== -1) Object.assign(truckloads[i], truckload);
      });

      return { ...state, data: truckloads, isChanged: true };
    }
    case LOADING_SUCCESS: {
      const { truckloads } = payload;
      return { ...state, isLoading: false, data: truckloads };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true, errors: {} };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    case UPLOADING_SUCCESS: {
      return { ...state, isUploading: false, errors: {} };
    }
    case UPLOADING_IN_PROGRESS:
      return { ...state, isUploading: true };
    case UPLOADING_FAILURE: {
      const { data } = payload;
      let errors = {};
      if (data) {
        errors = transformErrors(data);
      }
      return Object.assign({}, state, { isUploading: false, errors: errors });
    }
    case TRUCKLOADS_CHANGES_DETECTED:
      return { ...state, isChanged: true };
    case TRUCKLOADS_CHANGES_PUBLISHED:
      return { ...state, isChanged: false };
    case SHOW_UPGRADE_MODAL: {
      const { error } = payload;
      return { ...state, subscriptionStatus: error };
    }
    case HIDE_UPGRADE_MODAL: {
      return { ...state, subscriptionStatus: {} };
    }
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadTruckloads = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/truckloads?isActive=true');
    const truckloads = response.data;

    dispatch(loadingSuccess(truckloads));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

export const addNewTruckload = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());

  data.images = await Promise.all(
    data.images.map(async (img) => await resizeImg(img))
  );

  const formData = new FormData();
  const ARRAY_KEYS = ['images'];
  for (const [key, value] of Object.entries(data)) {
    if ((key === 'category' || key === 'subCategory') && value === '') continue;
    else if (ARRAY_KEYS.some((elem) => elem === key))
      for (const image of value) formData.append(key, image);
    else if (typeof value === 'object' && key !== 'csv')
      formData.append(key, JSON.stringify(value));
    else formData.append(key, value);
  }
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns2.post('/truckloads', formData);

    dispatch(addTruckload(res.data));
  } catch (err) {
    dispatch(uploadingFailure());
    console.log(err.response);
    if (err.response.status === 500) {
      console.log(err);
      if (err.response.data) {
        if (
          err.response.data.error.name === 'ERR_EXCEED' ||
          err.response.data.error.name === 'ERR_UPGRADE'
        ) {
          const error = {
            errorCode: err.response.data.error.name,
            errorMsg: err.response.data.message
          };
          dispatch(upgradeModal(error));
        }
      }
    } else {
      dispatch(uploadingFailure(err.response.data.errors));
      throw err;
    }
  }
};

export const updateTruckloadById = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());

  if (data?.images) {
    data.images = await Promise.all(
      data.images.map(async (img) => {
        console.log(typeof img, img);
        if (typeof img === 'object' && !img.url) {
          const resizedImg = await resizeImg(img);
          return resizedImg;
        }
        return img;
      })
    );
  }

  const formData = new FormData();
  const ARRAY_KEYS = ['images', 'removeImages'];
  for (const [key, value] of Object.entries(data)) {
    if ((key === 'category' || key === 'subCategory') && value === '') continue;
    else if (ARRAY_KEYS.some((elem) => elem === key))
      for (const image of value) formData.append(key, image);
    else if (typeof value === 'object') {
      formData.append(key, JSON.stringify(value));
    } else formData.append(key, value);
  }
  formData.set('isChanged', true);
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns2.put(`/truckloads/${data._id}`, formData);
    const newTruckload = response.data;

    dispatch(updateTruckload(newTruckload));
  } catch (err) {
    dispatch(uploadingFailure(err.response.data.errors));
    throw err;
  }
};

export const modifyTruckloadbyId = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch(`/truckloads/${data._id}`, data);
    const newTruckload = response.data;

    dispatch(updateTruckload(newTruckload));
  } catch (err) {
    dispatch(uploadingFailure(err.response.data.errors));
    throw err;
  }
};

export const updateTruckloadFromPOS = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns2.put(
      `/truckloads/updatepos/${data._id}`,
      data
    );
    const newTruckload = response.data;

    dispatch(updateTruckload(newTruckload));
  } catch (err) {
    dispatch(uploadingFailure(err.response.data.errors));
    throw err;
  }
};

export const deleteTruckloadById = (id, setIsLoading) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const formData = new FormData();
    formData.append('isActive', false);
    await axiosIns2.delete(`/truckloads/${id}`, formData);

    dispatch(deleteTruckload(id));
    setIsLoading(false);
  } catch (err) {
    console.log(err);
    setIsLoading(false);
  }
};

export const updateMultipleTruckloads = ({
  data,
  setResponseError,
  setresponseSuccess
}) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch('/truckloads', data);
    if (response.data && response.data.nModified > 0) {
      dispatch(updateTruckloads(data));
      setresponseSuccess('Updated Successfully');
    }
  } catch (err) {
    console.log(err);
    setResponseError('Bulk Operation Failed!');
  }
};

export const bulkWriteTruckloads = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch('/truckloads/bulkWrite', data);

    response.data &&
      response.data.nModified === data.length &&
      dispatch(bulkWriteTruckloadsAction(data));
  } catch (err) {
    console.log(err);
    if (err.response.status === 500) {
      console.log(err);
      if (err.response.data) {
        if (err.response.data.error.name === 'ERR_UPGRADE') {
          const error = {
            errorCode: err.response.data.error.name,
            errorMsg: err.response.data.message
          };
          dispatch(upgradeModal(error));
        }
      }
    }
  }
};

export const deleteDemoTruckloads = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    const truckloads = state.Truckloads.data;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.delete('/truckloads/deletedemo');

    response.data &&
      response.status === 200 &&
      dispatch(loadingSuccess(truckloads.filter((p) => !p.isDemo)));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getTruckloads = (state) => state.Truckload.data;
export const getUnCategorizedTruckloads = (state) =>
  state.Truckload.data.filter((p) => p.category === null);
export const getIsLoadingTruckloads = (state) => state.Truckload.isLoading;
export const getIsUploadingTruckload = (state) => state.Truckload.isUploading;
export const getIsChangedTruckloads = (state) => state.Truckload.isChanged;
export const getSubscriptionStatus = (state) =>
  state.Barcodes.subscriptionStatus;
export const getFormError = (state) => state.Truckload.errors;
