import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
  Typography,
  List,
  ListItem,
  Checkbox,
  Switch,
  FormControlLabel,
  FormGroup,
  Card,
  
} from '@material-ui/core';
import QuantityAlert from './QuantityAlert';
import BcPdf from './BcPdf';


//components responsible of showing options before generating the barecodes
const BcOptions = ({ open, setOpen, products }) => {
  const [showProductName, setShowProductName] = useState(true);
  const [showProductPrice, setShowProductPrice] = useState(true);
  const [showProductModel, setShowProductModel] = useState(true);
  const [openAlert, setOpenAlert] = useState(true);
  const [printStockQty, setPrintStockQty] = useState(false);
  const [openBcPdf, setOpenBcPdf] = useState(false);
  const [barecodeSize, setBareCodeSize] = useState({
    id: null,
    labelName: '',
    labelSize: {
      textSize: null,
      dimensions: []
    }
  });
  const [isGenerating, setIsGenerating] = useState(false);

  //isOver variable to check f totalQty is over a limit
  const [isOver, setIsOver] = useState(false);

  //open pdf
  const handleClickOpen = () => {
    setOpenBcPdf(true);
  };

  //close options
  const handleClose = () => {
    setOpen(false);
  };

  //check global Qty

  const checkQty = () => {
    let globalQty = products
      .map((item) => item.quantity)
      .reduce((partialSum, a) => partialSum + a, 0);
    if (globalQty > 200) {
      setIsOver(true);
      setOpenAlert(true);
    } else setOpenBcPdf(true);
  };
  const LABELS = [
    {
      id: 0,
      labelName: 'DK-1221 (0.9" X 0.9" or 23 mm x 23 mm)',
      labelSize: {
        textSize: 6,
        dimensions: ['0.9', '0.9']
      }
    },
    {
      id: 1,
      labelName: 'DK-1204 (0.66" x 2.1" or 17 mm x 54 mm)',
      labelSize: {
        textSize: 7.5,
        dimensions: ['2.1', '0.66']
      }
    },
    {
      id: 2,
      labelName: 'DK-1209 (1.1" x 2.4" or 29 mm x 62 mm)',
      labelSize: {
        textSize: 10,
        dimensions: ['2.4', '1.1']
      }
    },
    {
      id: 3,
      labelName: 'DK-1201 (1.1" x 3.5" or 29 mm x 90 mm)',
      labelSize: {
        textSize: 10,
        dimensions: ['3.5', '1.1']
      }
    },
    {
      id: 4,
      labelName: 'DK-1208 (1.4" x 3.5" or 38 mm x 90 mm)',
      labelSize: {
        textSize: 13,
        dimensions: ['3.5', '1.4']
      }
    },
    {
      id: 5,
      labelName: 'DK-1202 (2.4" x 3.9" or 62 mm x 100 mm)',
      labelSize: {
        textSize: 14,
        dimensions: ['3.9', '2.4']
      }
    },
    {
      id: 6,
      labelName: 'DYMO 1738595 (3/4" x 2-1/2" - 19mm x 64mm)',
      labelSize: {
        textSize: 8,
        dimensions: ['2.5', '0.75']
      }
    },
    {
      id: 7,
      labelName: 'DYMO 30251 (1-1/8" x 3-1/2" - 28mm x 89mm)',
      labelSize: {
        textSize: 10,
        dimensions: ['3.5', '1.125']
      }
    },
    {
      id: 8,
      labelName: 'A30334 (1-1/4" x 2-1/4" - 32mm x 57mm)',
      labelSize: {
        textSize: 10,
        dimensions: ['2.24409', '1.25984']
      }
    }
  ];

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Barcode options</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can select label dimensions and details to show on it
          </DialogContentText>
          <Grid container direction="column">
            <Grid item>
              <List>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  Details to show
                </Typography>
                <ListItem alignItems="center">
                  <Checkbox
                    defaultValue={showProductName}
                    defaultChecked={showProductName}
                    onChange={() => setShowProductName(!showProductName)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography variant="subtitle2">Show product name</Typography>
                  <Checkbox
                    defaultValue={showProductPrice}
                    defaultChecked={showProductPrice}
                    onChange={() => setShowProductPrice(!showProductPrice)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography variant="subtitle2">
                    Show product price
                  </Typography>
                  <Checkbox
                    defaultValue={showProductModel}
                    defaultChecked={showProductModel}
                    onChange={() => setShowProductModel(!showProductModel)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography variant="subtitle2">
                    Show product model
                  </Typography>
                </ListItem>
                <ListItem>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={printStockQty}
                          onChange={() => setPrintStockQty(!printStockQty)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          className="switch-small"
                        />
                      }
                    />
                  </FormGroup>
                  <Typography variant="subtitle2">
                    Print labels based on stock quantity
                  </Typography>
                </ListItem>

                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  Dimensions
                </Typography>
                <Grid item>
                  <Card className="card-box bg-neutral-success p-1 p-xl-4">
                    <div className="bg-composed-wrapper--content d-block text-xl-left d-xl-flex justify-content-between align-items-center">
                      <Typography variant="subtitle2">
                        The barcode generator integrates with the Brother
                        QL-710W/QL-720NW printer. Choose from label sizes:
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                {LABELS.map((label, index) => {
                  return (
                    <ListItem alignItems="center" key={label.id}>
                      <Checkbox
                        checked={label.id === barecodeSize.id}
                        onClick={() => {
                          setBareCodeSize(label);
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <Typography variant="subtitle2">
                        {label.labelName}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className="m-2 btn-success"
            disabled={isGenerating}
            onClick={() => {
              if (printStockQty) {
                checkQty();
              } else {
                setIsGenerating(true);
                handleClickOpen()};
            }}>
            {isGenerating ? 'Generating...' : 'Generate'}
            
          </Button>
          <Button
            onClick={() => {
              handleClose();
            }}
            className="m-2 btn-dark">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {isOver && (
        <QuantityAlert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          setIsOver={setIsOver}
        />
      )}
      <BcPdf
        infos={products}
        handleCloseOptions={handleClose}
        showProductName={showProductName}
        setShowProductName={setShowProductName}
        showProductPrice={showProductPrice}
        setShowProductPrice={setShowProductPrice}
        showProductModel={showProductModel}
        setShowProductModel={setShowProductModel}
        barecodeSize={barecodeSize}
        setBareCodeSize={setBareCodeSize}
        open={openBcPdf}
        setOpen={setOpenBcPdf}
        printStockQty={printStockQty}
        setIsOver={setIsOver}
        setIsGenerating={setIsGenerating}
      />
    </div>
  );
};

export default BcOptions;