import React, { useState } from 'react';

import {
  Button,
  Dialog,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel
} from '@material-ui/core';

import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';

const ExportUsers = ({ selected, users }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [exportType, setExportType] = useState('all');
  const handleExportType = (e) => setExportType(e.target.value);

  const downloadCsv = () => {
    const data = transformToCsv();
    const csv = data.map((r) => r.join(',')).join('\n');

    const filename = 'users_export.csv';
    const contentType = 'text/csv;charset=utf-8';
    const content = encodeURIComponent(csv);
    const a = document.createElement('a');

    a.download = filename;
    a.href = `data:${contentType},${content}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    toggleModal();
  };

  const transformToCsv = () => {
    const rows = [['Email', 'First Name', 'Last Name']];

    if (exportType === 'all') {
      users.forEach((user) => {
        const row = userToArray(user);
        rows.push(row);
      });
    } else {
      users.forEach((user) => {
        if (selected.some((id) => id === user._id)) {
          const row = userToArray(user);
          rows.push(row);
        }
      });
    }
    return rows;
  };

  const userToArray = (user) => {
    return [user._id, user.customer.firstName, user.customer.lastName];
  };

  return (
    <>
      <Button
        size="small"
        className="btn-neutral-secondary"
        onClick={toggleModal}>
        <span className="btn-wrapper--icon">
          <PublishIcon />
        </span>
        <span className="btn-wrapper--label">Export</span>
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="xs"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>
        </div>

        <div className="p-3">
          <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
            Export Users
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="p-3 text-black-70">
          <div className="mb-2">
            This CSV file can be used to update all user info.
          </div>
          <FormLabel style={{ marginBottom: '8px' }}>Export</FormLabel>
          <RadioGroup
            aria-label="export"
            name="export"
            value={exportType}
            onChange={handleExportType}>
            <FormControlLabel
              value="all"
              control={<Radio style={{ padding: '2px 8px' }} />}
              label="All Users"
            />
            <FormControlLabel
              value="selected"
              control={<Radio style={{ padding: '2px 8px' }} />}
              disabled={selected.length === 0}
              label={`Selected: ${selected.length} Users`}
            />
          </RadioGroup>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="p-3 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral font-size-sm mr-3">
            Cancel
          </Button>
          <Button
            className="btn-primary font-weight-bold"
            onClick={downloadCsv}>
            Export Users
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default ExportUsers;
