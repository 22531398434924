import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

const QuantityAlert = ({ openAlert, setOpenAlert, setIsOver, handleClose }) => {
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  return (
    <div>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs">
        <DialogContent>
          <DialogContentText>
            You have exceeded the limit of 200 label, reduce the quantity and
            try again
          </DialogContentText>
        </DialogContent>

        <Button
          className="btn-danger m-4"
          onClick={() => {
            setIsOver(false);
            handleClose();
            handleCloseAlert();
          }}>
          Close
        </Button>
      </Dialog>
    </div>
  );
};

export default QuantityAlert;