import React, { useEffect } from 'react';
import {LinearProgress } from '@material-ui/core';

import { getStoreDeploymentStatus } from 'reducers/Store';
import { connect } from 'react-redux';

const DeployProgress = ({ store, getStoreDeploymentStatus, totalProducts }) => {
  const [progress, setProgress] = React.useState(0);
  const [steps, setSteps] = React.useState(10);
  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      console.log('beforeunload');
      window.localStorage.removeItem('showStoreDeploymentProgress');
    });

    return () => {
      // hit endpoint to end show
      window.removeEventListener('beforeunload', () => {
        console.log('leave');
        window.localStorage.removeItem('showStoreDeploymentProgress');
      });
    };
  }, []);

  useEffect(() => {
    let estimatedSteps = Math.floor(totalProducts / 50);
    if (totalProducts < 50) estimatedSteps = 3;
    let progressPerStep = 100 / estimatedSteps;
    setSteps(progressPerStep >= 20 ? 12 : progressPerStep);
    console.log(steps, 'Steps of minutes', 1);
    const intervalId = setInterval(() => {
      setProgress((prev) => {
        getStoreDeploymentStatus(store._id);
        if (prev >= 80) {
          if (store.site.status === 'ready') {
            clearInterval(intervalId);
          }
          return 99;
        } else {
          return prev + steps;
        }
      });
    }, 60000 * 1);
    return () => clearInterval(intervalId);
  }, [totalProducts, getStoreDeploymentStatus, store, steps]);
  const showProgress = () => {
    if (progress <= 25) {
      return 'Getting Deployment Bots Ready....';
    } else if (progress <= 50) {
      return 'Creating Pages....';
    } else if (progress <= 90) {
      return 'Deploying Products....';
    } else if (progress < 100) {
      return 'Almost Done....';
    } else {
      return 'Deployment Completed';
    }
  };
  return (
    JSON.parse(window.localStorage.getItem('showStoreDeploymentProgress')) ===
      true && (
        <>
        <div className="d-flex align-items-center  justify-content-between">
          <div>
            <span className="text-white d-block" style={{fontSize: "0.8rem"}}>{showProgress()}</span>
          </div>
        </div>
        <div>
          <LinearProgress
            variant="determinate"
            className="progress-sm progress-bar-rounded progress-animated-alt progress-bar-first"
            value={progress}
          />
          <div className="align-box-row progress-bar--label  text-muted">
            <div className="ml-auto font-sm ">
              {Number(progress).toFixed(0)}% out of 100%
            </div>
          </div>
        </div>
        </>
    )
  );
};
const mapStateToProps = (state) => ({
  storeList: state.Store.data
});
const mapDispatchToProps = (dispatch) => ({
  getStoreDeploymentStatus: (isActive) =>
    dispatch(getStoreDeploymentStatus(isActive))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeployProgress);
