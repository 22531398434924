import React, { useState } from 'react';

import {
  Button,
  Dialog,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';

import { axiosIns } from 'config/configAxios';

import ebayLogo from 'assets/images/ebay-logo.png';

const useStyles = makeStyles(() => ({
  circularProgress: {
    color: '#1bc943',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const ManageListing = ({ user, productId, ebayListing }) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    setIsSuccess(false);
    setErrors([]);
  };

  const { token, platforms } = user;
  axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const isEbayConnected = platforms?.ebay.isConnected || false;
  const [isListed, setIsListed] = useState(!!ebayListing?.listingId);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isEnding, setIsEnding] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const publish = () => {
    setIsUpdating(true);
    axiosIns
      .post('/ebay/publishItem', { productId })
      .then((res) => {
        if (res.status === 204) {
          setIsSuccess(true);
          setIsListed(true);
        }
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors.map((err) => err.message));
        } else {
          setErrors([
            'Something went wrong. Please refresh the page and try again.'
          ]);
        }
      })
      .finally(() => setIsUpdating(false));
  };

  const update = () => {
    setIsUpdating(true);
    axiosIns
      .post('/ebay/updateItem', { productId })
      .then((res) => {
        if (res.status === 204) {
          setIsSuccess(true);
        }
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors.map((err) => err.message));
        } else {
          setErrors([
            'Something went wrong. Please refresh the page and try again.'
          ]);
        }
      })
      .finally(() => setIsUpdating(false));
  };

  const unpublish = () => {
    setIsSuccess(false);
    setIsEnding(true);
    axiosIns
      .post('/ebay/unpublishItem', { productId })
      .then((res) => {
        if (res.status === 204) setIsListed(false);
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors.map((err) => err.message));
        } else {
          setErrors([
            'Something went wrong. Please refresh the page and try again.'
          ]);
        }
      })
      .finally(() => setIsEnding(false));
  };

  return (
    <>
      <Button
        onClick={toggleModal}
        className="btn-primary mx-1 rounded-sm shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
        <PublishIcon className="font-size-xl font-weight-bold" />
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="sm"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-xl font-weight-bold" />
          </Button>
        </div>

        <div className="p-3">
          <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
            Manage sales channel listing
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="py-2">
          <List>
            <ListItem>
              <ListItemAvatar>
                <img src={ebayLogo} alt="eBay logo" width={40} />
              </ListItemAvatar>
              <ListItemText
                primary="eBay"
                secondary={
                  <>
                    {!isEbayConnected && (
                      <span className="text-danger">
                        Account is not connected.
                      </span>
                    )}
                    {isListed
                      ? 'This item is currently active on eBay.'
                      : 'This item is not listed yet on eBay.'}
                  </>
                }
              />
              <ListItemSecondaryAction>
                {isListed ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div style={{ position: 'relative' }} className="ml-2">
                      <Button
                        size="small"
                        className="btn-neutral-primary"
                        onClick={update}
                        disabled={isUpdating}>
                        Update
                      </Button>
                      {isUpdating && (
                        <CircularProgress
                          size={24}
                          thickness={5}
                          className={classes.circularProgress}
                        />
                      )}
                    </div>

                    <div style={{ position: 'relative' }} className="ml-2">
                      <Button
                        size="small"
                        className="btn-neutral-danger"
                        onClick={unpublish}
                        disabled={isEnding || isUpdating}>
                        End
                      </Button>
                      {isEnding && (
                        <CircularProgress
                          size={24}
                          thickness={5}
                          className={classes.circularProgress}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ position: 'relative' }}>
                    <Button
                      edge="end"
                      size="small"
                      aria-label="publish"
                      className="btn-neutral-primary"
                      disabled={!isEbayConnected || isUpdating}
                      onClick={publish}>
                      Publish
                    </Button>

                    {isUpdating && (
                      <CircularProgress
                        size={24}
                        thickness={5}
                        className={classes.circularProgress}
                      />
                    )}
                  </div>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        {''}
        {!isListed}
        {!isListed && ebayListing?.errors?.isError ? (
          <Alert severity="error">
            <AlertTitle>
              <span className="text-primary">
                Errors from previous listing attempt
              </span>
            </AlertTitle>
            <ul>
              {' '}
              {ebayListing?.errors?.messages.map((m) => (
                <li>{m}</li>
              ))}
            </ul>
          </Alert>
        ) : (
          ''
        )}
        {errors.length > 0 && (
          <div className="p-3">
            <Alert severity="error">
              <AlertTitle>Error/s occured while publishing</AlertTitle>
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </Alert>
          </div>
        )}
        {isSuccess && (
          <div className="p-3">
            <Alert severity="success">
              Success! You product is now active on eBay. You can review it on
              your eBay seller dashboard.
            </Alert>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ManageListing;
