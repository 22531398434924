import React from 'react';

import { Register1 } from '../../components';

export default function PageRegister() {
  return (
    <>
      <Register1 />
    </>
  );
}
