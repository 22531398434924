import React from 'react';

import StorefrontIcon from '@material-ui/icons/Storefront';
import { PageTitle } from '../../layout-components';
import StoreUsers from '../../components/StoreUsers';
import { connect } from 'react-redux';
const StoreUsersPage = ({ storesCount }) => {
  return (
    <>
      <PageTitle
        titleHeading="Customers"
        titleDescription="Manage Customers on your store."
        titleIcon={<StorefrontIcon />}
      />
      <StoreUsers />
    </>
  );
};
const mapStateToProps = (state) => ({
  storesCount: state.Store.data.length
});
export default connect(mapStateToProps)(StoreUsersPage);
