import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card } from '@material-ui/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function LivePreviewExample() {
  return (
    <>
      <div className=" d-flex flex-column justify-content-center align-items-center mb-3 px-0">
        <div className="MuiContainer-root MuiContainer-maxWidthLg">
          <h2 class=" text-center display-3 mb-5 font-weight-bold">
            Discover the key features
          </h2>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justify="center">
            <Grid container lg={12} md={12} s={6} spacing={2} justify="center">
              <Grid item lg={3} md={5} s={1}>
                <Card className="card-box p-2 d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper text-white btn-icon text-center"style={{backgroundColor: '#0c145b'}}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                  <div className="font-size-xl font-weight-bold pt-2  text-center">
                    Security SSL and CDN
                  </div>
                </Card>
              </Grid>
              <Grid item lg={3} md={5} s={1}>
                <Card className="card-box p-2 d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper text-white btn-icon text-center"style={{backgroundColor: '#0c145b'}}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                  <div className="font-size-xl font-weight-bold pt-2  text-center">
                    24/7 Live support
                  </div>
                </Card>
              </Grid>
              <Grid item lg={3} md={5} s={1}>
                <Card className="card-box p-2 d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper text-white btn-icon text-center"style={{backgroundColor: '#0c145b'}}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                  <div className="font-size-xl font-weight-bold pt-2  text-center">
                    Liquidation approved
                  </div>
                </Card>
              </Grid>
              <Grid item lg={3} md={5} s={1}>
                <Card className="card-box p-2 d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper text-white btn-icon text-center"style={{backgroundColor: '#0c145b'}}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                  <div className="font-size-xl font-weight-bold pt-2 text-center">
                    Responsive Themes
                  </div>
                </Card>
              </Grid>
              <Grid item lg={3} md={5}>
                <Card className="card-box p-2 d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper text-white btn-icon text-center"style={{backgroundColor: '#0c145b'}}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                  <div className="font-size-xl font-weight-bold pt-2  text-center">
                    Version Control and Backups
                  </div>
                </Card>
              </Grid>
              <Grid item lg={3} md={5}>
                <Card className="card-box p-2 d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper text-white btn-icon text-center"style={{backgroundColor: '#0c145b'}}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                  <div className="font-size-xl font-weight-bold pt-2  text-center">
                    Built-in SEO Tools
                  </div>
                </Card>
              </Grid>

              <Grid item lg={3} md={5}>
                <Card className="card-box p-2 d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper text-white btn-icon text-center"style={{backgroundColor: '#0c145b'}}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                  <div className="font-size-xl font-weight-bold pt-2  text-center">
                    Blazing fast PWA
                  </div>
                </Card>
              </Grid>
              <Grid item lg={3} md={5}>
                <Card className="card-box p-2 d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div className="d-50 rounded-circle border-0 my-2 card-icon-wrapper text-white btn-icon text-center"style={{backgroundColor: '#0c145b'}}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                  <div className="font-size-xl font-weight-bold pt-2  text-center">
                    Robust Integrations
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
