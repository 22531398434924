import React from 'react';

import ListIcon from '@material-ui/icons/List';

import { PageTitle } from '../../layout-components';
import { Categories1 } from '../../components';

export default function Categories() {
  return (
    <>
      <PageTitle
        titleHeading="Categories"
        titleDescription="Manage categories and sub-categries for your store products."
        titleIcon={<ListIcon />}
      />

      <Categories1 />
    </>
  );
}
