import React, { useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Table, Card, CardContent } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
export default function LivePreviewExample({ isLoading, stats }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [numofItems] = useState(5);
  const handlePageChange = (event, val) => {
    setPageNumber(val);
    // setSelected([]);
  };

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>Latest Orders</small>
            <b>Most Recent Orders</b>
          </div>
          <div className="card-header--actions"></div>
        </div>
        <CardContent className="p-0">
          <div className="table-responsive-md">
            <Table className="table text-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th className="text-center" style={{ width: '20%' }}>
                    Order Status
                  </th>
                  <th className="text-center" style={{ width: '20%' }}>
                    Payment Status
                  </th>
                  <th>Total</th>
                  {/* <th className="text-center">Total</th> */}
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                stats != null &&
                stats.orders != null &&
                stats.orders.length > 0 ? (
                  stats.orders
                    .slice(
                      (pageNumber - 1) * numofItems,
                      pageNumber * numofItems
                    )
                    .map((order, index) => (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <b>#{order.serialNo}</b>
                            <span className="text-black-50 d-block">
                              {order.customer.firstName +
                                ' ' +
                                order.customer.lastName}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="align-box-row">
                            {new Date(order.createdAt).toDateString()}
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="badge badge-warning h-auto py-0 px-3">
                            {order.status}
                          </div>
                        </td>

                        <td className="text-center">
                          <div className="badge badge-success h-auto py-0 px-3">
                            {order.payment.status}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper mr-2"></div>
                            <div>
                              <span className="text-black-50 d-block">
                                $
                                {Number(
                                  order.subtotal +
                                    order.tax +
                                    order.shipping -
                                    order.discount.amount -
                                    Number(
                                      order.payment.status !== 'refunded'
                                        ? 0
                                        : order.payment.metadata.amountRefunded
                                    )
                                ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                ) : isLoading ? (
                  <tr>
                    <td>
                      <CircularProgress
                        size={24}
                        thickness={5}
                        //   className={classes.circularProgress}
                      />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>No Orders For This Week</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {stats != null && (
            <Pagination
              className="pagination-first"
              count={Math.ceil(stats.orders.length / numofItems)}
              page={pageNumber}
              onChange={handlePageChange}
              size="small"
            />
          )}
          <div className="divider" />
          <div className="divider" />
        </CardContent>
      </Card>
    </>
  );
}
