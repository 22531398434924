import React, { useEffect, useState } from 'react';
import { Grid, Card } from '@material-ui/core';
import { axiosIns } from 'config/configAxios';
import { connect } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { getUser } from '../../reducers/UserAccount';
const Notifications = ({ user }) => {
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);

    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

    axiosIns.get('/notification').then((res) => {
      setNotification(res.data);

      setIsLoading(false);
    });
  }, [user]);
  return (
    <Grid container spacing={6}>
      <Grid item lg={12}>
        <Card className="card-box p-4 mb-5">
          <div className="timeline-item">
            <div className="timeline-item--content text-center">
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                Updates and Notifications
              </h4>
              <p>
                Includes new features, system health, and other important info
              </p>

              <div className="d-flex align-items-center flex-grow-1 justify-content-between">
                <span className="font-size-md text-black-50"></span>
                <span>Date</span>
              </div>
            </div>
          </div>

          <div className="timeline-list pt-0 mb-4">
            {!isLoading && notification != null ? (
              notification.map((note, index) => (
                <div className="timeline-item" key={index}>
                  <div
                    className={clsx({
                      'timeline-item--content': true
                    })}>
                    <div
                      className={clsx({
                        'timeline-item--icon': true,
                        'timeline-icon-alternate bg-success':
                          note.type === 'newfeature' || note.type === 'update',
                        'timeline-icon-alternate bg-warning':
                          note.type === 'bugfound',

                        'timeline-icon-alternate bg-primary':
                          note.type === 'bugfix'
                      })}
                    />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      {note.title}{' '}
                      <span
                        className={clsx({
                          'badge ml-2': true,
                          'badge-neutral-success text-success':
                            note.type === 'newfeature' ||
                            note.type === 'update',
                          'badge-neutral-danger text-danger':
                            note.type === 'bugfound',

                          'badge-neutral-primary text-primary':
                            note.type === 'bugfix'
                        })}>
                        {note.type}
                      </span>
                    </h4>
                    <div className="d-flex align-items-center flex-grow-1 justify-content-between">
                      <p>
                        {note.text}{' '}
                        {note.link && (
                          <span className=" badge badge-neutral-danger text-danger ml-2">
                            <a href={note.link}>Link</a>
                          </span>
                        )}
                      </p>

                      <span>{new Date(note.createdAt).toDateString()}</span>
                    </div>
                  </div>
                </div>
              ))
            ) : isLoading ? (
              <>
                <>
                  <Skeleton
                    animation="wave"
                    height={'80%'}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="pulse" height={50} width="100%" />
                </>
              </>
            ) : (
              'You are all caught up no new updates'
            )}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state)
});
export default connect(mapStateToProps, null)(Notifications);
