import React, { useState } from 'react';
import { Card } from '@material-ui/core';

import {
  Grid,
  Button,
  Dialog,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider
} from '@material-ui/core';

import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
import ClearIcon from '@material-ui/icons/Clear';
import Skeleton from '@material-ui/lab/Skeleton';

const LivePreviewExample = ({ isLoading, stats }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  return (
    <>
      {!isLoading && stats != null ? (
        <div className="mb-spacing-6-x2 ">
          <div className="mb-spacing-6 text-center">
            <div className="my-3">
              <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                Overall Statistics
              </h6>
              <Button
                onClick={toggleModal}
                className="btn-primary  d-inline-flex align-items-center justify-content-center">
                Show Report{' '}
                {/* <PublishIcon className="font-size-xl font-weight-bold" /> */}
              </Button>

              <Dialog
                scroll="body"
                open={modal}
                maxWidth="sm"
                onClose={toggleModal}
                classes={{ paper: 'w-100 border-0 bg-white' }}>
                <div className="card-tr-actions z-over">
                  <Button
                    onClick={toggleModal}
                    className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                    <ClearIcon className="font-size-xl font-weight-bold" />
                  </Button>
                </div>

                <div className="p-3">
                  <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
                    Sales
                  </h4>
                </div>

                <div className="divider bg-dark opacity-3" />

                <div className="py-2">
                  <List>
                    <ListItem>
                      <ListItemText primary="Gross Sales" />
                      <ListItemSecondaryAction>
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            style={{ position: 'relative' }}
                            className="ml-2">
                            ${Number(stats.grossSales).toFixed(2) ?? 0}
                          </div>
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>{' '}
                    <ListItem>
                      <ListItemText primary="Discounts" />
                      <ListItemSecondaryAction>
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            style={{ position: 'relative' }}
                            className="ml-2">
                            ${Number(stats.discount).toFixed(2) ?? 0}
                          </div>
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>{' '}
                    <ListItem>
                      <ListItemText primary="Returns" />
                      <ListItemSecondaryAction>
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            style={{ position: 'relative' }}
                            className="ml-2">
                            ${Number(stats.refund).toFixed(2) ?? 0}
                          </div>
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>{' '}
                    <Divider />
                    <ListItem>
                      <ListItemText primary="Net Sales" />
                      <ListItemSecondaryAction>
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            style={{ position: 'relative' }}
                            className="ml-2">
                            ${Number(stats.netSales).toFixed(2) ?? 0}
                          </div>
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>{' '}
                    <ListItem>
                      <ListItemText primary="Shipping" />
                      <ListItemSecondaryAction>
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            style={{ position: 'relative' }}
                            className="ml-2">
                            ${Number(stats.shipping).toFixed(2) ?? 0}
                          </div>
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>{' '}
                    <ListItem>
                      <ListItemText primary="Taxes" />
                      <ListItemSecondaryAction>
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            style={{ position: 'relative' }}
                            className="ml-2">
                            ${Number(stats.taxes).toFixed(2) ?? 0}
                          </div>
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>{' '}
                    <Divider />
                    <ListItem>
                      <ListItemText primary="Total Sales" />
                      <ListItemSecondaryAction>
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            style={{ position: 'relative' }}
                            className="ml-2">
                            $
                            {Number(
                              stats.netSales + stats.shipping + stats.taxes
                            ).toFixed(2)}
                          </div>
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </div>
              </Dialog>
            </div>
            <Grid container spacing={2}>
              <Grid item xl={4} md={6}>
                <Card className="bg-vicious-stance p-4">
                  <div className="d-flex align-items-center">
                    <div className="d-50 rounded-circle btn-icon bg-first text-white text-center font-size-md mr-1">
                      <BusinessCenterTwoToneIcon />{' '}
                      {/* <FontAwesomeIcon icon={['far', 'keyboard']} /> */}
                    </div>
                    <div className="text-white font-weight-bold font-size-md">
                      Total Products
                    </div>
                  </div>
                  <div className="display-3 text-center line-height-sm text-white text-center d-flex align-items-center pt-3 justify-content-center">
                    <small>
                      {/* <FontAwesomeIcon
                    icon={['fas', 'arrow-down']}
                    className="text-danger mr-2"
                  /> */}
                    </small>
                    <div>{stats.totalProducts ?? 0}</div>
                  </div>
                </Card>
              </Grid>
              <Grid item xl={4} md={6}>
                <Card className="bg-midnight-bloom p-4">
                  <div className="d-flex align-items-center">
                    <div className="d-50 rounded-circle btn-icon bg-success text-white text-center font-size-md mr-1">
                      {/* <FontAwesomeIcon icon={['far', 'file-excel']} /> */}
                      <BusinessCenterTwoToneIcon />
                    </div>
                    <div className="text-white font-weight-bold font-size-md">
                      Total Sales
                    </div>
                  </div>
                  <div className="display-3 text-center line-height-sm text-white text-center d-flex align-items-center pt-3 justify-content-center">
                    <small>
                      {/* <FontAwesomeIcon
                    icon={['far', 'dot-circle']}
                    className="text-warning mr-2"
                  /> */}
                    </small>
                    <div>
                      $
                      {Number(
                        stats.netSales +
                          (stats.shipping || 0) +
                          (stats.taxes || 0)
                      ).toFixed(2)}
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xl={4} md={6}>
                <Card className="bg-royal p-4">
                  <div className="d-flex align-items-center">
                    <div className="d-50 rounded-circle btn-icon bg-danger text-white text-center font-size-md mr-1">
                      <LocalLibraryTwoToneIcon />{' '}
                      {/* <FontAwesomeIcon icon={['far', 'user']} /> */}
                    </div>
                    <div className="text-white font-weight-bold font-size-md">
                      Total Orders
                    </div>
                  </div>
                  <div className="display-3 text-center line-height-sm text-white text-center d-flex align-items-center pt-3 justify-content-center">
                    <small>
                      {/* <FontAwesomeIcon
                    icon={['fas', 'arrow-up']}
                    className="text-success mr-2"
                  /> */}
                    </small>
                    <div>{stats.total ?? 0}</div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <>
          <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={50} width="100%" />
        </>
      )}
    </>
  );
};

export default LivePreviewExample;
