import React from 'react';

import { Container, Button } from '@material-ui/core';

import particles3 from '../../assets/images/hero-bg/particles-3.svg';
import illustration from '../../assets/images/hero-bg/banner-illustration.svg';

export default function LivePreviewExample() {
  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper"style={{backgroundColor: '#0c145b'}}>
        <div className="hero-wrapper--content flex-column">
          {/* <div className="shape-container-bottom-1 w-100">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="var(--white)"
                fillOpacity="1"
                d="M0,64L1440,128L1440,0L0,0Z"></path>
            </svg>
          </div> */}
          <div
            className="bg-composed-wrapper--image bg-composed-filter-rm opacity-6"
            style={{ backgroundImage: 'url(' + particles3 + '),url(' + illustration + ')',backgroundPosition: 'center,bottom right',backgroundSize:'100%,300px' }}
          />
          <div className="bg-composed-wrapper--content pb-5">
            <Container className="py-5 text-center">
              <div className="py-5 py-xl-0 mb-2">
                <div>
                  <h4 className="font-weight-bold text-white display-3">
                  Many liquidation businesses have trusted us to launch their eCom stores. <span style={{color: "#ffa403"}}> When will you? </span>
                  </h4>
                  
                </div>
                <Button className="btn bg-brand-pinterest px-5 text-white font-size-lg shadow-sm-dark py-3 hover-scale-sm btn-pill mx-2 mt-3">
                Give it a whirl
                </Button>
                
              </div>
            </Container>
          </div>
         
        </div>
      </div>
    </>
  );
}
