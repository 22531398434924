import React from 'react';

import { Container } from '@material-ui/core';

import logo1 from '../../assets/images/stock-logos/stripe.svg';
import paypal from '../../assets/images/stock-logos/paypal.svg';
import logo3 from '../../assets/images/stock-logos/ebay.svg';
import logo4 from '../../assets/images/stock-logos/shipstationlogo.svg';
import logo5 from '../../assets/images/stock-logos/klaviyo.svg';
import logo6 from '../../assets/images/stock-logos/clover.svg';
import logo7 from 'assets/images/stock-logos/netlify.svg';
import logo8 from 'assets/images/stock-logos/algolia.svg';
import logo9 from 'assets/images/stock-logos/gatsby.svg';

export default function LivePreviewExample() {
  return (
    <>
      <div className=" rounded bg-white mb-spacing-6-x2">
        <h1 className="text-center display-3 mb-2 font-weight-bold">
          Our partners
        </h1>
        <div className="py-1">
          <Container>
            <div className="d-flex align-items-center justify-content-center flex-wrap">
              <div className="p-1 m-1 p-md-3 m-md-3 d-flex align-self-stretch">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="bg-filter-grayscale opacity-8 hover-scale-lg">
                  <img
                    src={logo1}
                    style={{ height: 30 }}
                    className="m-auto img-fluid"
                    alt="..."
                  />
                </a>
              </div>
              <div className="p-1 m-1 p-md-3 m-md-3 d-flex align-self-stretch">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="bg-filter-grayscale opacity-8 hover-scale-lg">
                  <img
                    src={paypal}
                    style={{ height: 30 }}
                    className="m-auto img-fluid"
                    alt="..."
                  />
                </a>
              </div>
              <div className="p-1 m-1 p-md-3 m-md-3 d-flex align-self-stretch">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="bg-filter-grayscale opacity-8 hover-scale-lg">
                  <img
                    src={logo3}
                    style={{ height: 30 }}
                    className="m-auto img-fluid"
                    alt="..."
                  />
                </a>
              </div>
              <div className="p-1 m-1 p-md-3 m-md-3 d-flex align-self-stretch">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="bg-filter-grayscale opacity-8 hover-scale-lg">
                  <img
                    src={logo4}
                    style={{ height: 30 }}
                    className="m-auto img-fluid"
                    alt="..."
                  />
                </a>
              </div>
              <div className="p-1 m-1 p-md-3 m-md-3 d-flex align-self-stretch">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="bg-filter-grayscale opacity-8 hover-scale-lg">
                  <img
                    src={logo5}
                    style={{ height: 30 }}
                    className="m-auto img-fluid"
                    alt="..."
                  />
                </a>
              </div>
              <div className="p-1 m-1 p-md-3 m-md-3 d-flex align-self-stretch">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="bg-filter-grayscale opacity-8 hover-scale-lg">
                  <img
                    src={logo6}
                    style={{ height: 30 }}
                    className="m-auto img-fluid"
                    alt="..."
                  />
                </a>
              </div>
              <div className="p-1 m-1 p-md-3 m-md-3 d-flex align-self-stretch">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="bg-filter-grayscale opacity-8 hover-scale-lg">
                  <img
                    src={logo7}
                    style={{ height: 30 }}
                    className="m-auto img-fluid"
                    alt="..."
                  />
                </a>
              </div>
              <div className="p-1 m-1 p-md-3 m-md-3 d-flex align-self-stretch">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="bg-filter-grayscale opacity-8 hover-scale-lg">
                  <img
                    src={logo8}
                    style={{ height: 30 }}
                    className="m-auto img-fluid"
                    alt="..."
                  />
                </a>
              </div>
              <div className="p-1 m-1 p-md-3 m-md-3 d-flex align-self-stretch">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="bg-filter-grayscale opacity-8 hover-scale-lg">
                  <img
                    src={logo9}
                    style={{ height: 30 }}
                    className="m-auto img-fluid"
                    alt="..."
                  />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
