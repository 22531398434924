import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress
} from '@material-ui/core';

import { initLoadingOrders, loadOrders } from 'reducers/Forms';

const StoresDropdown = ({
  stores,
  isLoading,
  startLoading,
  loadById,
  setStores
}) => {
  const [store, setStore] = useState('');
  const handleStore = (event) => {
    const value = event.target.value;
    setStore(value);
    setStores(value);
    loadById(value);
  };

  useEffect(() => {
    startLoading();
  }, [startLoading]);

  return (
    <>
      {!isLoading && <CircularProgress size={24} thickness={5} />}
      {stores.length > 0 && (
        <FormControl variant="outlined" size="small" className="w-100">
          <InputLabel id="select-label">Select Store</InputLabel>
          <Select
            fullWidth
            labelId="select-label"
            label="Select Store"
            value={store === '' ? stores[0]._id : store}
            onChange={handleStore}>
            {stores.map((s) => (
              <MenuItem className="mx-2" value={s._id} key={s._id}>
                {s.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  stores: state.Store.data,
  isLoading: state.Store.success
});

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(initLoadingOrders()),
  loadById: (id) => dispatch(loadOrders(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(StoresDropdown);
