import React, { useState } from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import { Dialog, Button, Container, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from 'react-router-dom';

const RequestDetailsDialog = ({ request }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  return (
    <>
      <div className="d-block d-md-flex mt-3 mt-lg-0 align-items-center">
        <Button
          className="btn-light shadow-none font-weight-bold font-size-lg"
          onClick={toggleModal}>
          <LaunchIcon />
        </Button>
      </div>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="sm"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>
        </div>

        <div className="p-4">
          <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
            Request Details{' '}
            {/* <Link
              to={
                process.env.REACT_APP_D_PREFIX +
                '/truckloads/details/' +
                request.truckload?._id
              }
              color='primary'
              > */}
              <Button component={Link} to={
                process.env.REACT_APP_D_PREFIX +
                '/truckloads/details/' +
                request.truckload?._id
              } variant='primary' size='small' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 mr-1 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                <LaunchIcon />
              </Button>
            {/* </Link> */}
          </h4>
        </div>
        <Container className="pb-4">
          <Grid container spacing={2}>
            <Grid item md={12}>
              <div className="py-2 text-uppercase font-weight-bold text-primary font-size-sm">
                Details
              </div>
              <div className="py-2 font-size-sm p-2 bg-secondary rounded-sm">
                <div className="d-flex ">
                  <span className="font-weight-bold">Name :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {request.name}
                  </span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold">E-mail :</span>
                  <span className="text-black-50  ml-3">
                    {request?.email ?? 'N/A'}
                  </span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold">Phone :</span>
                  <span className="text-black-50  ml-3">
                    {request?.phone ?? 'N/A'}
                  </span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold"> Message :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {request?.notes ?? 'N/A'}
                  </span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold"> Keywords :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {request?.truckload?.name ?? 'N/A'}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="divider bg-dark opacity-3" />
      </Dialog>
    </>
  );
};

export default RequestDetailsDialog;
