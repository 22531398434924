import React from 'react';

import Reviews from '../../../layout-components/Overview/Overview3';
import Header from '../../../layout-components/Overview/Overview4';
import HappyCostumers from '../../../layout-components/Overview/Overview2';
import ProblemsSection from '../../../layout-components/ProblemsSection';
import Integraions from '../../../layout-components/Integrations';

import Features from '../../../layout-components/Features';
import Benefits from '../../../layout-components/Benefits';
import Video from '../../../layout-components/Video';
import Footer from '../../../layout-components/Overview/Overview6';
import CallToAction from '../../../layout-components/CallToAction';
import CaseStudies from 'components/CaseStudies';
export default function LivePreviewExample() {
  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper bg-white">
        <div className="header-top-section pb-2">
          <div style={{ marginBottom: '5rem' }}>
            <Header />
          </div>
          <div
            style={{ marginBottom: '5rem'}}>
            <HappyCostumers />
          </div>
          <div style={{ marginBottom: '5rem' }}>
            <ProblemsSection />
          </div>
          <div style={{ marginBottom: '5rem' }}>
            <Video />
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ marginBottom: '5rem' }}>
            <Features />
          </div>
          <div style={{ marginBottom: '5rem' }}>
            <Integraions />
          </div>
          <div style={{ marginBottom: '5rem' }}>
            <Reviews />
          </div>
          <div
            style={{ marginBottom: '5rem', background: 'rgb(238, 238, 238)' }}>
            <Benefits />
          </div>
          
          <div style={{ marginBottom: '5rem' }}>
            <CaseStudies />
          </div>
          <CallToAction />
          <Footer />
        </div>
      </div>
    </>
  );
}
