import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, CircularProgress } from '@material-ui/core';
import { cleanCategories, loadCategories } from 'reducers/AllCategories';

const CleanCategories = ({ clean, loadCategories }) => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);
  const toggleModal = () => setModal(!modal);
  const [isLoading, setisLoading] = useState(false);

  return (
    <>
      <Button
        className="btn-danger"
        size="small"
        color="danger"
        variant="contained"
        onClick={toggleModal}>
        Clean
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="sm"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="p-4">
          <h4 className="font-size-xl font-weight-bold mb-0">
            Do you want to run Cleanup?
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />
        <p className="text-danger">{error}</p>
        <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral font-size-sm mr-2">
            Cancel
          </Button>
          <div style={{ position: 'relative' }}>
            <Button
              className="btn-success font-weight-bold"
              type="submit"
              onClick={() => {
                setError(null);
                setisLoading(true);
                clean().then((res) => {
                  if (res.status === 200) {
                    toggleModal();
                    loadCategories();
                  } else {
                    console.log(res.response.data.error);
                    setError(res.response.data.error);
                  }
                  setisLoading(false);
                });
              }}
              disabled={isLoading}>
              {isLoading ? 'Deleting...' : 'Delete Now'}
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                thickness={5}
                style={{
                  color: '#1bc943',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12
                }}
              />
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  clean: () => dispatch(cleanCategories()),
  loadCategories: () => dispatch(loadCategories())
});

export default connect(mapStateToProps, mapDispatchToProps)(CleanCategories);
