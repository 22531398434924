import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import MediaHandler from 'components/common/MediaHandler';
import { useEffect } from 'react';
// import ImageGallery from './ImageGallery';
function CkEditor({ form, value, name }) {
  // eslint-disable-next-line no-unused-vars
  const [text, setValue] = useState(value);
  useEffect(()=>{
setValue(value)
  },[value])
  return (
    <div>
      {/* Todo: Future update of having image galleries like other ecomm providers have */}
      {/* <ImageGallery /> */}
      <MediaHandler/>
      <CKEditor
        editor={ClassicEditor}
        allowedContent={true}
        // fullPage= {true}
        // extraAllowedContent: '*{*}',
        config={{
          allowedContent: true,
          fontFamily: {
            options: [
              'default',
              'Ubuntu, Arial, sans-serif',
              'Ubuntu Mono, Courier New, Courier, monospace'
            ]
          }
        }}
        data={text ?? 'Add Content Here'}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log('Editor is ready to use!', editor);
          // console.log(Array.from(editor.ui.componentFactory.names()));
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
            console.log({ event, editor, data });
          form(name, data);
        }}
      />
    </div>
  );
}

export default CkEditor;
