import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { Button, Dialog, Typography } from "@material-ui/core";

const PopUpCancel = ({ showWarning, cancelUpdateHandler, closeWarning }) => {
  return (
    <Dialog open={showWarning}>
      <div className="text-center p-5">
        <div className="text-white rounded-circle  bg-warning w-25 m-auto p-4">
          <WarningIcon fontSize="large" className="w-100" />
        </div>

        <div className="mt-4">
          <Typography fontSize={15} fontWeight="bold">
            You didn't save your product !
          </Typography>
        </div>
        <p className="mb-0 mt-2 text-muted">
          You'll lose all the updated data once you confirm this
        </p>
        <div className="pt-4">
          <Button
variant="contained" className="btn-pill m-2 btn-warning"
            onClick={cancelUpdateHandler}
          >Yes, do it
          </Button>
          <Button
            onClick={closeWarning}
            variant="contained" className="btn-pill m-2 btn-danger"
          >Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default PopUpCancel;
