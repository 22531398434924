import { axiosIns, axiosIns2 } from '../config/configAxios';
import { transformErrors } from 'utils';

/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
const GET_STORE_LIST_STARTED = 'GET_STORE_LIST_STARTED';
const GET_STORE_LIST_SUCCESS = 'GET_STORE_LIST_SUCCESS';
const GET_STORE_LIST_FAILED = 'GET_STORE_LIST_FAILED';

export const getStoreListStarted = () => ({
  type: GET_STORE_LIST_STARTED
});

export const getStoreListFailed = (error) => ({
  type: GET_STORE_LIST_FAILED,
  payload: error
});

export const getStoreListSuccess = (storeList) => ({
  type: GET_STORE_LIST_SUCCESS,
  payload: storeList
});

const DELETE_STORE_STARTED = 'DELETE_STORE_STARTED';
const DELETE_STORE_SUCCESS = 'DELETE_STORE_SUCCESS';
const DELETE_STORE_FAILED = 'DELETE_STORE_FAILED';

const removeStoreStarted = () => ({
  type: DELETE_STORE_STARTED
});

const removeStoreFailed = (error) => ({
  type: DELETE_STORE_FAILED,
  payload: error
});

const removeStoreSuccess = (storeId) => ({
  type: DELETE_STORE_SUCCESS,
  payload: storeId
});

const ADD_STORE_STARTED = 'ADD_STORE_STARTED';
const ADD_STORE_SUCCESS = 'ADD_STORE_SUCCESS';
const ADD_STORE_FAILED = 'ADD_STORE_FAILED';

const addStoreStarted = () => ({
  type: ADD_STORE_STARTED
});

const addStoreFailed = (error) => ({
  type: ADD_STORE_FAILED,
  payload: error
});

const addStoreSuccess = (newStore) => ({
  type: ADD_STORE_SUCCESS,
  payload: newStore
});

const UPDATE_STORE_STARTED = 'UPDATE_STORE_STARTED';
const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
const UPDATE_STORE_FAILED = 'UPDATE_STORE_FAILED';

const updateStoreStarted = () => ({
  type: UPDATE_STORE_STARTED
});

const updateStoreFailed = (error) => ({
  type: UPDATE_STORE_FAILED,
  payload: error
});

const updateStoreSuccess = (store) => ({
  type: UPDATE_STORE_SUCCESS,
  payload: { store }
});

const PUBLISH_STORE_STARTED = 'PUBLISH_STORE_STARTED';
const PUBLISH_STORE_SUCCESS = 'PUBLISH_STORE_SUCCESS';
const PUBLISH_STORE_FAILED = 'PUBLISH_STORE_FAILED';

const publishStoreStarted = () => ({
  type: PUBLISH_STORE_STARTED
});

const publishStoreFailed = (error) => ({
  type: PUBLISH_STORE_FAILED,
  payload: error
});

const publishStoreSuccess = (newStore) => ({
  type: PUBLISH_STORE_SUCCESS,
  payload: newStore
});

const HEALTHCHECK_STORE_STARTED = 'HEALTHCHECK_STORE_STARTED';
const HEALTHCHECK_STORE_SUCCESS = 'HEALTHCHECK_STORE_SUCCESS';
const HEALTHCHECK_STORE_FAILED = 'HEALTHCHECK_STORE_FAILED';

const healthcheckStoreStarted = () => ({
  type: HEALTHCHECK_STORE_STARTED
});

const healthcheckStoreFailed = (error) => ({
  type: HEALTHCHECK_STORE_FAILED,
  payload: error
});

const healthcheckStoreSuccess = (id, status) => ({
  type: HEALTHCHECK_STORE_SUCCESS,
  payload: { id, status }
});

const REBUILD_STORE_STARTED = 'REBUILD_STORE_STARTED';
const REBUILD_STORE_SUCCESS = 'REBUILD_STORE_SUCCESS';
const REBUILD_STORE_FAILED = 'REBUILD_STORE_FAILED';

const rebuildStoreStarted = () => ({
  type: REBUILD_STORE_STARTED
});

const rebuildStoreFailed = (error) => ({
  type: REBUILD_STORE_FAILED,
  payload: error
});

const rebuildStoreSuccess = (id, status) => ({
  type: REBUILD_STORE_SUCCESS,
  payload: { id, status }
});

export const SHOW_UPGRADE_MODAL = 'SHOW_UPGRADE_MODAL';
export const upgradeModal = (error) => ({
  type: SHOW_UPGRADE_MODAL,
  payload: { error }
});
export const HIDE_UPGRADE_MODAL = 'HIDE_UPGRADE_MODAL';
export const hideUpgradeModal = () => ({
  type: HIDE_UPGRADE_MODAL
});
export const ADD_LOGO = 'ADD_LOGO';
export const addLogo = (payload) => ({
  type: ADD_LOGO,
  payload
});

export const UPDATE_STORE_DESIGN = 'UPDATE_STORE_DESIGN';
export const updateStoreDesignv2 = (payload) => ({
  type: UPDATE_STORE_DESIGN,
  payload
});
/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = {
  success: false,
  data: [],
  errors: null,
  deleteSuccess: false,
  publishSuccess: false,
  healthcheckSuccess: false,
  rebuildSuccess: false,
  storeId: '',
  addStatus: 'init', //init, inprogress, failed, success
  updateStatus: 'init', //init, inprogress, failed, success,
  subscriptionStatus: {},
  logo: ''
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_STORE_LIST_STARTED: {
      return { ...state, ...initialState };
    }
    case GET_STORE_LIST_FAILED: {
      return { ...state, errors: payload };
    }
    case GET_STORE_LIST_SUCCESS:
      return { ...state, success: true, data: payload };
    case DELETE_STORE_STARTED: {
      return { ...state, errors: null, deleteSuccess: false };
    }
    case DELETE_STORE_FAILED: {
      return { ...state, errors: payload };
    }
    case DELETE_STORE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        data: state.data.filter((store) => store['_id'] !== payload)
      };

    // Store add
    case ADD_STORE_STARTED:
      return {
        ...state,
        success: false,
        errors: null,
        addStatus: 'inprogress'
      };
    case ADD_STORE_FAILED:
      return { ...state, errors: payload, addStatus: 'failed' };
    case ADD_STORE_SUCCESS:
      return {
        ...state,
        success: true,
        addStatus: 'success',
        data: state.data.concat(payload)
      };

    // Store Update
    case UPDATE_STORE_STARTED:
      return { ...state, updateStatus: 'inprogress' };
    case UPDATE_STORE_FAILED:
      return { ...state, updateStatus: 'failed', errors: payload };
    case UPDATE_STORE_SUCCESS: {
      const { store } = payload;
      const data = state.data.map((s) => {
        if (s._id === store._id) return store;
        return s;
      });
      return { ...state, data, updateStatus: 'success' };
    }

    case PUBLISH_STORE_STARTED: {
      return {
        ...state,
        success: false,
        errors: null,
        publishSuccess: false,
        storeId: ''
      };
    }
    case PUBLISH_STORE_FAILED: {
      return { ...state, errors: payload };
    }
    case PUBLISH_STORE_SUCCESS:
      return {
        ...state,
        success: true,
        storeId: payload._id,
        publishSuccess: true,
        data: state.data.map((v) => {
          if (v._id === payload._id) return payload;
          return v;
        })
      };
    case HEALTHCHECK_STORE_STARTED: {
      return {
        ...state,
        success: false,
        errors: null,
        healthcheckSuccess: false,
        storeId: ''
      };
    }
    case HEALTHCHECK_STORE_FAILED: {
      return { ...state, errors: payload };
    }
    case HEALTHCHECK_STORE_SUCCESS:
      return {
        ...state,
        success: true,
        storeId: payload.id,
        healthcheckSuccess: true,
        data: state.data.map((v) => {
          if (v._id === payload.id) v.site.status = payload.status;
          return v;
        })
      };
    case REBUILD_STORE_STARTED: {
      return {
        ...state,
        success: false,
        errors: null,
        rebuildSuccess: false,
        storeId: ''
      };
    }
    case REBUILD_STORE_FAILED: {
      return { ...state, errors: payload };
    }
    case REBUILD_STORE_SUCCESS:
      return {
        ...state,
        success: true,
        storeId: payload._id,
        rebuildSuccess: true,
        data: state.data.map((v) => {
          if (v._id === payload.id) v.site.status = payload.status;
          return v;
        })
      };
    case SHOW_UPGRADE_MODAL: {
      const { error } = payload;
      return { ...state, subscriptionStatus: error };
    }
    case HIDE_UPGRADE_MODAL: {
      return { ...state, subscriptionStatus: {} };
    }
    case ADD_LOGO: {
      const { logo } = payload;
      return { ...state, logo: logo };
    }
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */

export const getStoreList = (isActive = false) => async (
  dispatch,
  getState
) => {
  dispatch(getStoreListStarted());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get(`/stores?isActive=${isActive}`);
    const storeList = response.data;

    dispatch(getStoreListSuccess(storeList));
  } catch (err) {
    dispatch(getStoreListFailed(err));
  }
};

export const removeStore = (id) => async (dispatch, getState) => {
  dispatch(removeStoreStarted());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    await axiosIns.delete(`/stores/${id}`);
    dispatch(removeStoreSuccess(id));
  } catch (err) {
    dispatch(removeStoreFailed(err));
  }
};

export const addStore = (data, formikProps) => async (dispatch, getState) => {
  const { setSubmitting, setErrors, setStatus, resetForm } = formikProps;

  dispatch(addStoreStarted());
  try {
    const formData = new FormData();
    const ARRAY_KEYS = ['sliderImages', 'removeImages', 'shipTo'];
    for (const [key, value] of Object.entries(data)) {
      if (ARRAY_KEYS.some((elem) => elem === key)) {
        if (key === 'shipTo' && value.length < 1) formData.append(key, []);
        value.forEach((val, i) => formData.append(key, val));
      } else if (
        typeof value === 'object' &&
        key !== 'logo' &&
        key !== 'secondary_logo'
      ) {
        for (let subKey in value)
          formData.append(`${key}.${subKey}`, value[subKey]);
      } else formData.append(key, value);
    }

    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns2.post(`/stores`, formData);

    if (res.status === 201) {
      const { data } = res;
      dispatch(addStoreSuccess(data));
      setSubmitting(false);
      setStatus({ success: true });
      resetForm({});
    }
  } catch (err) {
    setSubmitting(false);
    if (err.response.status === 400) {
      const errors = transformErrors(err.response.data.errors);
      setErrors(errors);
      dispatch(addStoreFailed(errors));
    } else {
      dispatch(addStoreFailed(err));
    }
  }
};

export const updateStore = (id, data, formikProps) => async (
  dispatch,
  getState
) => {
  const { setSubmitting = null, setErrors = null, setStatus = null } =
    formikProps || {};

  dispatch(updateStoreStarted());
  try {
    const formData = new FormData();
    const ARRAY_KEYS = ['sliderImages', 'removeImages', 'shipTo'];
    for (const [key, value] of Object.entries(data)) {
      if (ARRAY_KEYS.some((elem) => elem === key)) {
        if (key === 'shipTo' && value.length < 1) formData.append(key, []);
        value.forEach((val) => formData.append(key, val));
      } else if (
        typeof value === 'object' &&
        key !== 'logo' &&
        key !== 'secondary_logo'
      ) {
        for (let subKey in value)
          formData.append(`${key}.${subKey}`, value[subKey]);
      } else formData.append(key, value);
    }

    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns2.put(`/stores/${id}`, formData);

    if (res.status === 200) {
      const { data } = res;
      dispatch(updateStoreSuccess(data));
      if (formikProps) {
        setSubmitting(false);
        setStatus({ success: true });
      }
      // resetForm({});
    }
  } catch (err) {
    if (formikProps) setSubmitting(false);
    if (err.response.status === 400) {
      const errors = transformErrors(err.response.data.errors);
      if (formikProps) setErrors(errors);
      dispatch(updateStoreFailed(errors));
    } else {
      dispatch(updateStoreFailed(err));
    }
  }
};


export const updateStoreMedia = (id, data) => async (dispatch, getState) => {
  // const { setSubmitting = null, setErrors = null, setStatus = null } =;

  dispatch(updateStoreStarted());
  try {
    console.log(id, data, 'data');
    const formData = new FormData();
    // toFormData(data, formData);
    data.images.forEach((object, index) => {
      formData.append(`images[${index}][url]`, object.url);
      formData.append(`images[${index}][linkTo]`, object.linkTo);
      formData.append(`images[${index}][name]`, object.url?.name);
    });

    // const formData = new FormData();
    // const ARRAY_KEYS = ['sliderImages', 'removeImages', 'shipTo'];
    // for (const [key, value] of Object.entries(data)) {
    //   if (ARRAY_KEYS.some((elem) => elem === key)) {
    //     if (key === 'shipTo' && value.length < 1) formData.append(key, []);
    //     value.forEach((val) => formData.append(key, val));
    //   } else if (
    //     typeof value === 'object' &&
    //     key !== 'logo' &&
    //     key !== 'secondary_logo'
    //   ) {
    //     for (let subKey in value)
    //       formData.append(`${key}.${subKey}`, value[subKey]);
    //   } else formData.append(key, value);
    // }

    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns2.put(
      `/stores/${id}/design/media?component=HomeSlider`,
      formData
    );

    if (res.status === 200) {
      const { data } = res;
      
      dispatch(updateStoreSuccess(data));
      return data;
      // if (formikProps) {
      //   // setSubmitting(false);
      //   // setStatus({ success: true });
      // }
      // resetForm({});
    }
  } catch (err) {
    // if (formikProps) setSubmitting(false);
    if (err.response.status === 400) {
      const errors = transformErrors(err.response.data.errors);
      // if (formikProps) setErrors(errors);
      dispatch(updateStoreFailed(errors));
    } else {
      dispatch(updateStoreFailed(err));
    }
  }
};

export const updateStoreShipping = (
  method = 'put',
  id,
  data,
  formikProps
) => async (dispatch, getState) => {
  const { setSubmitting, setErrors, setStatus } = formikProps;

  dispatch(updateStoreStarted());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    console.log('formdata', data, method);
    const res = await axiosIns2({
      url: `/stores/${id}/shipping`,
      data,
      method: method
    });

    if (res.status === 200) {
      const { data } = res;
      dispatch(updateStoreSuccess(data));
      setSubmitting(false);
      setStatus('success');
      return res;
      // resetForm({});
    }
  } catch (err) {
    setSubmitting(false);
    if (err.response.status === 400) {
      const errors = transformErrors(err.response.data.errors);
      setErrors(errors);
      dispatch(updateStoreFailed(errors));
    } else {
      dispatch(updateStoreFailed(err));
    }
    throw err;
  }
};
export const updateStoreDesign = (id, data) => async (dispatch, getState) => {
  dispatch(updateStoreStarted());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns2.put(`/stores/${id}/design`, data);

    if (res.status === 200) {
      const { data } = res;
      dispatch(updateStoreSuccess(data));

      // resetForm({});
    }
  } catch (err) {
    if (err.response.status === 400) {
      const errors = transformErrors(err.response.data.errors);

      dispatch(updateStoreFailed(errors));
    } else {
      dispatch(updateStoreFailed(err));
    }
  }
};
export const saveStoreDesignV2 = (id, data) => async (dispatch, getState) => {
  console.log('data', JSON.stringify(data), 'thunk');
  dispatch(updateStoreStarted());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    if (data !== '') {
      const res = await axiosIns.put(`/stores/${id}/design`, data);
      if (res.status === 200) {
        const { data } = res;
        dispatch(updateStoreDesignv2(data));

        // resetForm({});
      }
    }
  } catch (err) {
    if (err.response.status === 400) {
      const errors = transformErrors(err.response.data.errors);

      dispatch(updateStoreFailed(errors));
    } else {
      dispatch(updateStoreFailed(err));
    }
  }
};
export const publishStore = (id) => async (dispatch, getState) => {
  dispatch(publishStoreStarted());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.post(`/stores/${id}/deploy`);
    const newStore = response.data;

    dispatch(publishStoreSuccess(newStore));
  } catch (err) {
    if (err.response.status === 500) {
      console.log(err);
      if (err.response.data) {
        if (
          err.response.data.error.name === 'ERR_UPGRADE' ||
          err.response.data.error.name === 'ERR_EXCEED'
        ) {
          const error = {
            errorCode: err.response.data.error.name,
            errorMsg: err.response.data.message
          };
          dispatch(upgradeModal(error));
          dispatch(publishStoreFailed(err));
        }
      }
    } else dispatch(publishStoreFailed(err));
  }
};

export const healthcheckStore = (id) => async (dispatch, getState) => {
  dispatch(healthcheckStoreStarted());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get(`/stores/${id}/healthcheck`);
    const status = response.data.status;

    dispatch(healthcheckStoreSuccess(id, status));
  } catch (err) {
    dispatch(healthcheckStoreFailed(err));
  }
};
export const getStoreDeploymentStatus = (id) => async (dispatch, getState) => {
  dispatch(healthcheckStoreStarted());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get(
      `/stores/${id}/getStoreDeploymentStatus`
    );
    const status = response.data.status;
    if (status !== '') dispatch(healthcheckStoreSuccess(id, status));
  } catch (err) {
    dispatch(healthcheckStoreFailed(err));
  }
};

export const disableDemoMode = (id) => async (dispatch, getState) => {
  dispatch(updateStoreStarted());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.put(`/stores/${id}/disabledemo`);
    const status = response.data.status;
    if (status !== '')
      dispatch(updateStoreSuccess({ _id: id, isDemoMode: false }));
    // dispatch(deleteDemoCategories)
  } catch (err) {
    const errors = transformErrors(err.response.data.errors);

    dispatch(updateStoreFailed(errors));
  }
};

export const rebuildStore = (id) => async (dispatch, getState) => {
  dispatch(rebuildStoreStarted());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.post(`/stores/${id}/build`);
    const data = response.data;
    dispatch(rebuildStoreSuccess(id, data.status));
  } catch (err) {
    if (err.response.status === 500) {
      console.log(err);
      if (err.response.data) {
        if (
          err.response.data.error.name === 'ERR_UPGRADE' ||
          err.response.data.error.name === 'ERR_EXCEED'
        ) {
          const error = {
            errorCode: err.response.data.error.name,
            errorMsg: err.response.data.message
          };
          dispatch(upgradeModal(error));
          // dispatch(publishStoreFailed(err));
          dispatch(rebuildStoreFailed(err));
        }
      }
    } else dispatch(publishStoreFailed(err));
  }
};

export const isSuccess = (state) => state.Store.success;
export const store = (state) => state.Store.data;
export const activeStore = (state) =>
  state.Store.data?.filter((store) => store.isActive);
export const getSubscriptionStatus = (state) => state.Store.subscriptionStatus;
export const getStoreLocation = (state) =>
  state.Store.data
    .filter((store) => store.isActive)
    .map((store) => {
      return store.storeLocation.map((location) => {
        return { _id: location._id, name: location.name };
      });
    })
    .flat();

export const getStoreLogo = (state) => state.Store.logo;
