import React, { useEffect, useState, useCallback } from 'react';

import { connect } from 'react-redux';

import { axiosIns } from 'config/configAxios';
import { getUser, updateUser } from '../../../reducers/UserAccount';
import { getStoreList } from '../../../reducers/Store';
import DashboardIndicator from '../BlocksGrids2';
import OrderTable from '../OrdersTable';
import Guide from '../../GetStartGuide';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Grid, Card, Button } from '@material-ui/core';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

const LivePreviewExample = ({
  getStoreList,
  stores,
  isLoadingStores,
  user,
  updateUser
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const [store, setStore] = useState('');
  const handleStore = (event) => {
    const value = event.target.value;
    setStore(value);
  };
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    overAllStats();
  };
  useEffect(() => {
    getStoreList();
  }, [getStoreList]);
  useEffect(() => {
    if (stores.length > 0) {
      setStore(stores[0]._id);
      setSelectedDate(new Date(stores[0].create_date));
    }
  }, [stores]);
  const overAllStats = useCallback(() => {
    setIsLoading(true);

    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

    axiosIns
      .post(`/users/dashboard/all/${store}`, {
        start_date: selectedDate,
        end_date: selectedEndDate
      })
      .then((resp) => {

        setIsLoading(false);
        setStats(resp.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user, store, selectedDate, selectedEndDate]);
  useEffect(() => {
    overAllStats();
  }, [overAllStats]);

  return (
    <>
      <Grid container spacing={1} className="d-flex">
        <Grid item md={2} lg={2} xl={2}>
          {!isLoadingStores && <CircularProgress size={24} thickness={5} />}
          {stores.length > 0 && (
            <FormControl variant="outlined" size="small" className="w-100">
              <InputLabel id="select-label">Select Store</InputLabel>
              <Select
                fullWidth
                labelId="select-label"
                label="Select Store"
                value={store === '' ? stores[0]._id : store}
                onChange={handleStore}>
                {stores.map((s) => (
                  <MenuItem className="mx-2" value={s._id} key={s._id}>
                    {s.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid className="d-flex" item md={10} lg={10} xl={10}>
          <Grid
            container
            className="d-flex"
            alignItems="end"
            justify="end"
            style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid item md={3} lg={3} xl={3}>
              <div className="d-flex align-items-center justify-content-end flex-wrap">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="From"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid item md={3} lg={3} xl={3}>
              <div className="d-flex align-items-right justify-content-center flex-wrap">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="To"
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="d-flex">
        {!isLoading && stats != null && user.guideStatus !== true ? (
          !user.stripeCustomerDetails.paymentId ||
          !user.platforms.paypal.isConnected ||
          !stats.featuredCategory > 0 ||
          !stats.featuredProducts > 0 ||
          !stats.totalProducts > 0 ||
          !user.platforms.ebay.isConnected ||
          !user.platforms.stripe.chargesEnabled ||
          !(
            user.platforms.ebay.listingConfig?.listingPolicies
              ?.returnPolicyId !== null &&
            user.platforms.ebay.listingConfig?.listingPolicies?.listingPolicies
              ?.paymentPolicyId !== null &&
            user.platforms.ebay.listingConfig?.listingPolicies
              ?.fulfillmentPolicyId !== null
          ) ? (
            <>
              <Grid item md={6} lg={6} xl={6}>
                <DashboardIndicator isLoading={isLoading} stats={stats} />
              </Grid>
              <Grid item md={6} lg={6} xl={6}>
                <Guide isLoading={isLoading} stats={stats} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={8} lg={8} xl={8}>
                <DashboardIndicator isLoading={isLoading} stats={stats} />
              </Grid>
              <Grid item md={4} lg={4} xl={4}>
                <Card className="rounded shadow-xxl">
                  <div className="p-3 p-lg-4">
                    <div className="bg-grow-early text-white font-size-xl d-60 btn-icon card-icon-wrapper rounded-circle">
                      <CheckCircleOutlineIcon
                        style={{ fontSize: '24px' }}
                        fontSize="large"
                      />{' '}
                      {/* <FontAwesomeIcon icon={['far', 'address-card']} /> */}
                    </div>
                    <h5 className="font-weight-bold display-5 mt-4 mb-3">
                      Congrats! you have completed the startup guide.
                    </h5>

                    <div className="text-center">
                      <Button
                        onClick={(e) => updateUser({ guideStatus: true })}
                        fullWidth
                        className="btn-success mt-1 text-uppercase font-size-sm rounded d-inline-block"
                        target="_blank"
                        title="Learn more">
                        <span>Click here to close!</span>
                      </Button>
                    </div>
                  </div>
                </Card>
              </Grid>
            </>
          )
        ) : (
          <Grid item md={12} lg={12} xl={12}>
            <DashboardIndicator isLoading={isLoading} stats={stats} />
          </Grid>
        )}
      </Grid>
      <OrderTable isLoading={isLoading} stats={stats} />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  stores: state.Store.data,
  isLoadingStores: state.Store.success
});
const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  // startLoading: () => dispatch(initLoadingOrders()),
  getStoreList: (isActive) => dispatch(getStoreList(isActive))
});
export default connect(mapStateToProps, mapDispatchToProps)(LivePreviewExample);
