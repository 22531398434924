import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  Table,
  Grid,
  InputAdornment,
  Card,
  Menu,
  MenuItem,
  Button,
  List,
  ListItem,
  TextField,
  FormControl,
  Select,
  Checkbox,
  CircularProgress,
  makeStyles
} from '@material-ui/core';

import ArrowUpwardTwoToneIcon from '@material-ui/icons/ArrowUpwardTwoTone';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import ArrowDownwardTwoToneIcon from '@material-ui/icons/ArrowDownwardTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@material-ui/icons/RadioButtonUncheckedTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import { getOrders, getIsLoadingOrders } from 'reducers/Orders';
import { loadCategories, getAllCategories } from '../../reducers/AllCategories';
import BulkActions from './BulkActions';
import TableRow from './TableRow';
import { Pagination } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
  stickyHeader: {
    [theme.breakpoints.down('sm')]: {
      background: '#f8f9ff',
      position: 'sticky !important',
      top: '0px',
      zIndex: '1151',
      borderRadius: '20px'
    }
  }
}));
const Orders1 = (props) => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [numofItems, setNumofItems] = useState(5);
  const handlePageChange = (event, val) => {
    setPageNumber(val);
    // setSelected([]);
  };
  const { orders, isLoading, loadCategories } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [updatedOrders, setUpdatedOrders] = useState(orders);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [searchOpen, setSearchOpen] = useState(false);
  const [KeywordState, setKeywordState] = useState('');

  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);

  const [status, setStatus] = useState(-1);

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const updateOrders = (orders) => {
    const o = orders.filter((o) => {
      if (
        o.payment.status === 'refunded' ||
        o.payment.status === 'succeeded' ||
        o.payment.status === 'processing'
      )
        return o._id;
      return false;
    });
    return o;
  };

  useEffect(() => {
    loadCategories();
    if (!isLoading) {
      const o = updateOrders(orders);
      setUpdatedOrders(o);
    }
  }, [orders, isLoading, loadCategories]);
  const [selected, setSelected] = useState([]);

  const onSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = updatedOrders
        .slice((pageNumber - 1) * numofItems, pageNumber * numofItems)
        .map((o) => o._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const filterOrders = (query) => {
    setPageNumber(1);
    handleClose();
    let allOrders = orders;

    const value = query?.toLowerCase().trim();

    if (value !== '') {
      console.log('SKU');
      allOrders = orders.filter((p) => {
        return (
          p?.checkoutID + '' === value ||
          p?.serialNo + '' === value ||
          p?.customer?.email.includes(value) ||
          (p.comments?.length > 0 &&
            p.comments[0].message.split(':')[1] === value)
        );
      });
      setUpdatedOrders(updateOrders(allOrders));

      return;
    } else if (status !== -1) {
      allOrders = orders.filter((p) => p.status === status);
      setUpdatedOrders(updateOrders(allOrders));
      return;
    } else {
      setUpdatedOrders(updateOrders(orders));
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
      <Card className="card-box shadow-none">
        <div
          className={`d-flex justify-content-between px-4 py-3 ${
            classes.stickyHeader ? classes.stickyHeader : ''
          }`}>
          <div
            className={clsx(
              'search-wrapper search-wrapper--alternate search-wrapper--grow',
              { 'is-active': searchOpen }
            )}>
            <TextField
              variant="outlined"
              size="small"
              id="input-with-icon-textfield22-2"
              placeholder="Search orders..."
              onFocus={openSearch}
              onBlur={closeSearch}
              value={KeywordState}
              onChange={async (e) => {
                let value = e.target.value;

                setKeywordState(value);
                filterOrders(value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <div>
              <Button
                onClick={handleClick}
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                <FilterListTwoToneIcon className="w-50" />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                classes={{ list: 'p-0' }}
                onClose={handleClose}>
                <div className="dropdown-menu-xxl overflow-hidden p-0">
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                          Status
                        </small>
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                            fullWidth
                            value={status}
                            onChange={handleStatus}
                            labelWidth={0}>
                            <MenuItem value={-1}>All statuses</MenuItem>
                            <MenuItem value={'New'}>New</MenuItem>
                            <MenuItem value={'Completed'}>Completed</MenuItem>
                            <MenuItem value={'Shipped'}> Shipped</MenuItem>
                            <MenuItem value={'Delivered'}> Delivered</MenuItem>
                            <MenuItem value={'Pending'}>Pending</MenuItem>

                            <MenuItem value={'Ready'}>
                              Ready for Pickup
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>{' '}
                    </Grid>
                  </div>
                  <div className="divider" />
                  <div className="p-3 text-center bg-secondary">
                    <Button className="btn-primary" size="small">
                      Filter results
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={6}>
                        <List className="nav-neutral-danger flex-column p-0">
                          <ListItem
                            button
                            className="d-flex rounded-sm justify-content-center"
                            href="#/"
                            onClick={(e) => handleClose()}>
                            <div className="mr-2">
                              <DeleteTwoToneIcon />
                            </div>
                            <span>Cancel</span>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item md={6}>
                        <List className="nav-neutral-success flex-column p-0">
                          <ListItem
                            button
                            className="d-flex rounded-sm justify-content-center"
                            href="#/"
                            onClick={() => filterOrders(KeywordState)}>
                            <div className="mr-2">
                              <SaveTwoToneIcon />
                            </div>
                            <span>Save filter</span>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Menu>
            </div>
            <div>
              <Button
                onClick={handleClick2}
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 p-0 rounded-pill">
                <SettingsTwoToneIcon className="w-50" />
              </Button>
              <Menu
                anchorEl={anchorEl2}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorEl2)}
                classes={{ list: 'p-0' }}
                onClose={handleClose2}>
                <div className="dropdown-menu-lg overflow-hidden p-0">
                  <div className="font-weight-bold px-4 pt-3">Results</div>
                  <List className="nav-neutral-first nav-pills-rounded flex-column p-2">
                    <ListItem
                      button
                      href="#/"
                      onClick={(e) => {
                        setPageNumber(1);
                        setNumofItems(10);
                      }}>
                      <div className="nav-link-icon mr-2">
                        <RadioButtonUncheckedTwoToneIcon />
                      </div>
                      <span className="font-size-md">
                        <b>10</b> results per page
                      </span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={(e) => {
                        setPageNumber(1);
                        setNumofItems(20);
                      }}>
                      <div className="nav-link-icon mr-2">
                        <RadioButtonUncheckedTwoToneIcon />
                      </div>
                      <span className="font-size-md">
                        <b>20</b> results per page
                      </span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={(e) => {
                        setPageNumber(1);
                        setNumofItems(30);
                      }}>
                      <div className="nav-link-icon mr-2">
                        <RadioButtonUncheckedTwoToneIcon />
                      </div>
                      <span className="font-size-md">
                        <b>30</b> results per page
                      </span>
                    </ListItem>
                  </List>
                  <div className="divider" />
                  <div className="font-weight-bold px-4 pt-4">Order</div>
                  <List className="nav-neutral-first nav-pills-rounded flex-column p-2">
                    <ListItem
                      button
                      href="#/"
                      onClick={() => {
                        handleClose2();
                        setUpdatedOrders(
                          updatedOrders.sort((a, b) => {
                            var x = a.createdAt;
                            var y = b.createdAt;
                            if (x > y) {
                              return 1;
                            }
                            if (x < y) {
                              return -1;
                            }
                            return 0;
                          })
                        );
                      }}>
                      <div className="mr-2">
                        <ArrowUpwardTwoToneIcon />
                      </div>
                      <span className="font-size-md">Ascending</span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={() => {
                        handleClose2();
                        setUpdatedOrders(
                          updatedOrders.sort((a, b) => {
                            var x = a.createdAt;
                            var y = b.createdAt;
                            if (x > y) {
                              return 1;
                            }
                            if (x < y) {
                              return -1;
                            }
                            return 0;
                          })
                        );
                      }}>
                      <div className="mr-2">
                        <ArrowDownwardTwoToneIcon />
                      </div>
                      <span className="font-size-md">Descending</span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={() => {
                        handleClose2();
                        setUpdatedOrders(
                          updatedOrders.sort((a, b) => {
                            var x = a?.payment?.date;
                            var y = b?.payment?.date;
                            if (x > y) {
                              return -1;
                            }
                            if (x < y) {
                              return 1;
                            }
                            return 0;
                          })
                        );
                      }}>
                      <div className="mr-2">
                        <ArrowDownwardTwoToneIcon />
                      </div>
                      <span className="font-size-md">Recently Paid</span>
                    </ListItem>
                  </List>
                </div>
              </Menu>
            </div>
          </div>
        </div>

        <div className="divider" />

        {selected.length > 0 && (
          <>
            <BulkActions
              selected={selected}
              setSelected={setSelected}
              ordersCount={updatedOrders.length}
              loadOrders={getOrders}
            />
            <div className="divider" />
          </>
        )}

        <div
          className="table-responsive-md d-flex flex-column text-center m-auto
    
">
          {isLoading && <CircularProgress size={24} thickness={5} />}
          {!isLoading && updatedOrders && updatedOrders.length === 0 && (
            <div className="text-center font-weight-bold my-3">
              No order exists yet!
            </div>
          )}

          {updatedOrders.length > 0 && (
            <Table stickyHeader className="table table-alternate-spaced mb-0">
              <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                <tr>
                  <th scope="col">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < orders.length
                      }
                      checked={
                        updatedOrders.length > 0 &&
                        selected.length ===
                          updatedOrders.slice(
                            (pageNumber - 1) * numofItems,
                            pageNumber * numofItems
                          ).length
                      }
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      onChange={onSelectAll}
                    />
                  </th>
                  <th scope="col">Order #</th>
                  <th scope="col">Date</th>
                  <th scope="col">Client</th>
                  <th scope="col">Status</th>
                  <th scope="col">Total</th>
                  <th scope="col" className="text-right">
                    Actions
                  </th>
                </tr>
              </thead>
              {/* <Container> */}
              <tbody>
                {updatedOrders
                  .slice((pageNumber - 1) * numofItems, pageNumber * numofItems)
                  .map(
                    (o) =>
                      (o.payment.status === 'succeeded' ||
                        o.payment.status === 'refunded' ||
                        o.payment.status === 'processing') && (
                        <TableRow
                          isAbandoned={false}
                          key={o._id}
                          order={o}
                          isChecked={isSelected(o._id)}
                          onCheck={onSelect}
                        />
                      )
                  )}
              </tbody>
              {/* </Container> */}
            </Table>
          )}
          {updatedOrders != null && (
            <Pagination
              className="pagination-success m-auto my-2"
              count={Math.ceil(updatedOrders.length / numofItems)}
              page={pageNumber}
              onChange={handlePageChange}
            />
          )}
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  orders: getOrders(state),
  isLoading: getIsLoadingOrders(state),
  categories: getAllCategories(state)
});

const mapDispatchToProps = (dispatch) => ({
  loadCategories: () => dispatch(loadCategories())
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders1);
