import { axiosIns, axiosIns2 } from '../config/configAxios';
const transformErrors = (res) => {
  let errors = {};
  try {
    for (let i = 0; i < res.length; i++) {
      const el = res[i];
      errors[el.param] = el.msg;
    }
  } catch (err) {
    console.log(err);
  }
  return errors;
};
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_MERCHANT_PROFILE = 'ADD_MERCHANT_PROFILE';
export const addMerchant = (merchant) => ({
  type: ADD_MERCHANT_PROFILE,
  payload: { merchant }
});

export const UPDATE_MERCHANT_PROFILE = 'UPDATE_MERCHANT_PROFILE';
export const updateMerchant = (merchant) => ({
  type: UPDATE_MERCHANT_PROFILE,
  payload: { merchant }
});

export const DELETE_MERCHANT_PROFILE = 'DELETE_MERCHANT_PROFILE';
export const deleteMerchant = (id) => ({
  type: DELETE_MERCHANT_PROFILE,
  payload: { id }
});

export const UPDATE_MERCHANT_PROFILES = 'UPDATE_MERCHANT_PROFILES';
export const updateMerchants = (data) => ({
  type: UPDATE_MERCHANT_PROFILES,
  payload: { data }
});

const BULKWRITE_MERCHANT_PROFILES = 'BULKWRITE_MERCHANT_PROFILES';
const bulkWriteMerchantsAction = (data) => ({
  type: BULKWRITE_MERCHANT_PROFILES,
  payload: { data }
});

export const LOADING_IN_PROGRESS = 'MERCHANT_PROFILES_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'MERCHANT_PROFILES_LOADING_SUCCESS';
export const loadingSuccess = (merchants) => ({
  type: LOADING_SUCCESS,
  payload: { merchants }
});

export const LOADING_FAILURE = 'MERCHANT_PROFILES_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});

export const UPLOADING_IN_PROGRESS = 'MERCHANT_PROFILES_UPLOADING_IN_PROGRESS';
export const uploadingInProgress = () => ({
  type: UPLOADING_IN_PROGRESS
});

export const UPLOADING_SUCCESS = 'MERCHANT_PROFILES_UPLOADING_SUCCESS';
export const uploadingSuccess = () => ({
  type: UPLOADING_SUCCESS
});

export const UPLOADING_FAILURE = 'MERCHANT_PROFILES_UPLOADING_FAILURE';
export const uploadingFailure = (data) => ({
  type: UPLOADING_FAILURE,
  payload: { data }
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = {
  isLoading: false,
  isUploading: false,
  data: {},
  errors: {}
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_MERCHANT_PROFILE: {
      const { merchant } = payload;
      return {
        ...state,
        data: merchant,
        isUploading: false,
        isChanged: true,
        errors: {}
      };
    }
    case UPDATE_MERCHANT_PROFILE: {
      const { merchant } = payload;

      return {
        ...state,
        data: merchant,
        isUploading: false,
        isChanged: true,
        errors: {}
      };
    }
    case DELETE_MERCHANT_PROFILE: {
      return {
        ...state,
        data: {}
      };
    }
    case UPDATE_MERCHANT_PROFILES: {
      const { ids, attributes } = payload.data;

      let merchants = [...state.data];

      ids.forEach((id) => {
        if ('isActive' in attributes) {
          merchants = merchants.filter((p) => p._id !== id);
        } else if ('percentageDiscount' in attributes) {
          const i = merchants.findIndex((p) => p._id === id);
          const price = merchants[i].price;
          const obj = {
            price: Number(
              price - price * (attributes.percentageDiscount / 100)
            ).toFixed(2),
            oldPrice: price
          };
          Object.assign(merchants[i], obj);
        } else {
          const i = merchants.findIndex((p) => p._id === id);
          Object.assign(merchants[i], attributes);
        }
      });
      merchants = merchants.map((p) => {
        return { ...p, isChanged: true };
      });
      return { ...state, data: merchants, isChanged: true };
    }
    case BULKWRITE_MERCHANT_PROFILES: {
      const { data } = payload;
      let merchants = [...state.data];
      data.forEach(({ _id, ...merchant }) => {
        const i = merchants.findIndex((p) => p._id === _id);
        if (i !== -1) Object.assign(merchants[i], merchant);
      });

      return { ...state, data: merchants, isChanged: true };
    }
    case LOADING_SUCCESS: {
      const { merchants } = payload;
      return { ...state, isLoading: false, data: merchants };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true, errors: {} };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    case UPLOADING_SUCCESS: {
      return { ...state, isUploading: false, errors: {} };
    }
    case UPLOADING_IN_PROGRESS:
      return { ...state, isUploading: true };
    case UPLOADING_FAILURE: {
      const { data } = payload;
      let errors = {};
      if (data) {
        errors = transformErrors(data);
      }
      return Object.assign({}, state, { isUploading: false, errors: errors });
    }

    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadMerchants = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/truckloads/profile');
    const merchants = response.data;

    dispatch(loadingSuccess(merchants));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

export const addNewMerchant = (data, showModal) => async (
  dispatch,
  getState
) => {
  dispatch(uploadingInProgress());

  console.log('save all');

  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (
      key === 'seoMetaData' ||
      key === 'program' ||
      key === 'vendor' ||
      key === 'loadType'
    ) {
      formData.append(`${key}`, JSON.stringify(value));
    } else {
      formData.append(`${key}`, value);
    }
  });
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns2.post('/truckloads/profile', formData);
    showModal(false);
    dispatch(addMerchant(res.data));
  } catch (err) {
    dispatch(uploadingFailure());
    console.log(err.response);
    if (err.response.status === 500) {
      console.log(err);
    } else {
      dispatch(uploadingFailure(err.response.data.errors));
      throw err;
    }
  }
};

export const updateMerchantById = (data, showModal) => async (
  dispatch,
  getState
) => {
  dispatch(uploadingInProgress());

  console.log('save all');

  const formData = new FormData();
  // const ARRAY_KEYS = ['images', 'deleteImages'];

  Object.entries(data).forEach(([key, value]) => {
    console.log(key, value);
    if (
      key === 'seoMetaData' ||
      key === 'program' ||
      key === 'vendor' ||
      key === 'loadType'
    ) {
      formData.append(`${key}`, JSON.stringify(value));
    } else {
      formData.append(`${key}`, value);
    }
  });
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns2.put(
      `/truckloads/profile/${data._id}`,
      formData
    );
    const newMerchant = response.data;
    showModal(false);
    dispatch(updateMerchant(newMerchant));
  } catch (err) {
    dispatch(uploadingFailure(err.response.data.errors));
    throw err;
  }
};

export const updateMerchantFromPOS = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns2.put(
      `/merchants/updatepos/${data._id}`,
      data
    );
    const newMerchant = response.data;

    dispatch(updateMerchant(newMerchant));
  } catch (err) {
    dispatch(uploadingFailure(err.response.data.errors));
    throw err;
  }
};

export const deleteMerchantById = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const formData = new FormData();
    await axiosIns2.delete(`/truckloads/profile/${id}`, formData);

    dispatch(deleteMerchant(id));
  } catch (err) {
    console.log(err);
  }
};

export const updateMultipleMerchants = ({
  data,
  setResponseError,
  setresponseSuccess
}) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch('/merchants', data);
    if (response.data && response.data.nModified > 0) {
      dispatch(updateMerchants(data));
      setresponseSuccess('Updated Successfully');
    }
  } catch (err) {
    console.log(err);
    setResponseError('Bulk Operation Failed!');
  }
};

export const bulkWriteMerchants = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch('/merchants/bulkWrite', data);

    response.data &&
      response.data.nModified === data.length &&
      dispatch(bulkWriteMerchantsAction(data));
  } catch (err) {
    console.log(err);
    if (err.response.status === 500) {
      console.log(err);
    }
  }
};

export const deleteDemoMerchants = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    const merchants = state.Merchants.data;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.delete('/merchants/deletedemo');

    response.data &&
      response.status === 200 &&
      dispatch(loadingSuccess(merchants.filter((p) => !p.isDemo)));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getMerchants = (state) => state.MerchantProfile.data;
export const getUnCategorizedMerchants = (state) =>
  state.MerchantProfile.data.filter((p) => p.category === null);
export const getIsLoadingMerchants = (state) => state.MerchantProfile.isLoading;
export const getIsUploadingMerchant = (state) =>
  state.MerchantProfile.isUploading;
export const getIsChangedMerchants = (state) => state.MerchantProfile.isChanged;
export const getFormError = (state) => state.MerchantProfile.errors;
