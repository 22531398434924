import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import { Grid, CardContent, Card } from '@material-ui/core';

export default function LivePreviewExample() {
  return (
    <>
      <div className="MuiContainer-root MuiContainer-maxWidthLg">
        <div className="text-center p-0 p-lg-5 mx-4 rounded mb-2 py-3">
          <h2 class="display-3  mb-5 font-weight-bold">
            Grow your business fast
          </h2>
          <Grid
            container
            lg={12}
            md={12}
            sm={6}
            spacing={2}
            justify="center"
            style={{ margin: '0' }}>
            <Grid
              item
              lg={4}
              md={5}
              sm={12}
              className="d-flex align-items-center">
              <Card
                className="card bg-white card-box card-box card-box-hover-rise-alt"
                style={{ height: 'max-content' }}>
                <CardContent>
                  <FontAwesomeIcon
                    icon={faGem}
                    className="text-danger mb-3"
                    size="2xl"
                  />

                  <div className="text-left mb-3">
                    <p className="font-size-xxl font-weight-bold px-xl-0 text-center text-lg-left m-0 mb-2">
                      Smart owners invest in tools that help them grow
                    </p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={5} sm={12}>
              <Card
                className="card bg-white card-box card-box card-box-hover-rise-alt"
                style={{ height: 'max-content' }}>
                <CardContent>
                  <FontAwesomeIcon
                    icon={faGem}
                    className="text-danger mb-3"
                    size="2xl"
                  />
                  <div className="text-left mb-3">
                    <p className="font-size-xxl font-weight-bold px-xl-0 text-center text-lg-left m-0 mb-2">
                      Let your customers order online and pickup instore or
                      Delivered
                    </p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={5} sm={12}>
              <Card
                className="card bg-white card-box card-box card-box-hover-rise-alt"
                style={{ height: 'max-content' }}>
                <CardContent>
                  <FontAwesomeIcon
                    icon={faGem}
                    className="text-danger mb-3"
                    size="2xl"
                  />
                  <div className="text-left mb-3">
                    <p className="font-size-xxl font-weight-bold px-xl-0 text-center text-lg-left m-0 mb-2">
                      Save $$$ by spending less time listing and more time with
                      familly
                    </p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={5} sm={12}>
              <Card
                className="card bg-white card-box card-box card-box-hover-rise-alt"
                style={{ height: 'max-content' }}>
                <CardContent>
                  <FontAwesomeIcon
                    icon={faGem}
                    className="text-danger mb-3"
                    size="2xl"
                  />
                  <div className="text-left mb-3">
                    <p className="font-size-xxl font-weight-bold px-xl-0 text-center text-lg-left m-0 mb-2">
                      Build your own brand and never put all you eggs in one
                      basket!
                    </p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={5} sm={12}>
              <Card
                className="card bg-white card-box card-box card-box-hover-rise-alt"
                style={{ height: 'max-content' }}>
                <CardContent>
                  <FontAwesomeIcon
                    icon={faGem}
                    className="text-danger mb-3"
                    size="2xl"
                  />
                  <div className="text-left mb-3">
                    <p className="font-size-xxl font-weight-bold px-xl-0 text-center text-lg-left m-0 mb-2">
                      Stop paying high comission and listing fees
                    </p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={5} sm={12}>
              <Card
                className="card bg-white card-box card-box card-box-hover-rise-alt"
                style={{ height: 'max-content' }}>
                <CardContent>
                  <FontAwesomeIcon
                    icon={faGem}
                    className="text-danger mb-3"
                    size="2xl"
                  />
                  <div className="text-left mb-3">
                    <p className="font-size-xxl font-weight-bold px-xl-0 text-center text-lg-left m-0 mb-2">
                      Take control
                    </p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
