import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import BulkActions from './BulkActions';
import ExportProducts from './ExportProducts';
import ImportProducts from './ImportProducts';
import PosUpdater from './PosUpdater';
import SubscriptionsModal from '../../layout-components/SubscriptionsModal';

import {
  loadProducts,
  getProducts,
  getIsLoadingProducts,
  bulkWriteProducts,
} from '../../reducers/Products';
import { loadCategories, getAllCategories } from '../../reducers/AllCategories';
import { getSubscription } from '../../reducers/UserSubscriptions';
import { getStoreList, getStoreLocation } from '../../reducers/Store';
import { getAllApps, loadApps } from '../../reducers/Apps';
import {
  Table,
  Grid,
  InputAdornment,
  Card,
  Menu,
  MenuItem,
  Button,
  List,
  ListItem,
  TextField,
  FormControl,
  Select,
  Checkbox,
  CircularProgress,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import Pagination from '@material-ui/lab/Pagination';
import ArrowUpwardTwoToneIcon from '@material-ui/icons/ArrowUpwardTwoTone';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import ArrowDownwardTwoToneIcon from '@material-ui/icons/ArrowDownwardTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@material-ui/icons/RadioButtonUncheckedTwoTone';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import { Alert } from '@material-ui/lab';
import TableRow from './TableRow';
import { useCallback } from 'react';
// import DeleteDemoProducts from './DeleteDemoProducts';
const Products1 = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    products,
    isLoading,
    startLoading,
    startLoadingCategories,
    categories,
    bulkWriteProducts,
    subscription,
    loadStores,
    loadApps,
    allApps,
    stores
  } = props;
  const [filteredProducts, setfilteredProducts] = useState(products);
  // const [statusProducts, setStatusProducts] = useState(products);
  // const [textFilterProducts, setTextFilterProducts] = useState(products);
  const [pageNumber, setPageNumber] = useState(1);
  const [numofItems, setNumofItems] = useState(20);
  // const [keyword,setKeyword]=useState("");
  const [responseError, setresponseError] = useState('');
  const [responseSuccess, setresponseSuccess] = useState('');
  const [formats, setFormats] = useState(false);
  const [KeywordState, setKeywordState] = useState('');

  const [status, setStatus] = useState(0);
  const [location, setStoreLocation] = useState(-1);
  const [category, setCategory] = useState(-1);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [lockExport, setLockExport] = useState(false);

  const statusvalues = ['All statuses', 'Ready', 'Published', 'Unpublished'];
  const categoryValues = categories;

  const filterProducts = useCallback(
    (query) => {
      setPageNumber(1);
      handleClose();
      let allProducts = products;

      const value = query.trim();
      const asinRegex = /^(B0|BT)[0-9A-Z]{8}$/;
      const upcRegex = /^\d{12}|\d{13}$/;
      const clSku = /^(12345)\d{7}$/;
      const sku = /^[0-9]+/;
      if (clSku.test(value)) {
        console.log('CLSKU', value);
        allProducts = allProducts.filter((p) => {
          return p.cl_upc === Number(value);
        });
        setfilteredProducts(allProducts);
        return;
      } else if (sku.test(value)) {
        console.log('SKU');
        if (upcRegex.test(value)) {
          allProducts = allProducts.filter((p) => {
            return p.barcode === value;
          });
          setfilteredProducts(allProducts);
          return;
        } else {
          allProducts = allProducts.filter((p) => {
            return p.sku === value;
          });
          setfilteredProducts(allProducts);
          return;
        }
      } else if (asinRegex.test(value)) {
        console.log('ASIN');
        allProducts = allProducts.filter((p) => {
          return p.asin === value;
        });
        setfilteredProducts(allProducts);
        return;
      }
      let keyword = query.toLowerCase();
      console.log('Keyword');
      if (keyword === '') {
        if (status === 0 && category === -1) {
          setfilteredProducts(allProducts);
          return;
        } else if (status !== 0 && category === -1) {
          allProducts = allProducts.filter((p) => {
            return p.status === statusvalues[status];
          });
          setfilteredProducts(allProducts);
          return;
        } else if (status === 0 && category !== -1) {
          allProducts = allProducts.filter((p) => {
            return (category === -2 ? null : category) === (p.category ?? null);
          });
          setfilteredProducts(allProducts);
          return;
        }
        allProducts = allProducts.filter((p) => {
          return p.status === statusvalues[status] && category === p.category;
        });
        setfilteredProducts(allProducts);
        return;
      } else if (status === 0) {
        if (category !== -1) {
          allProducts = allProducts.filter((p) => {
            return (
              (category === -2 ? null : category) === (p.category ?? null) &&
              (p.title.toLowerCase().includes(keyword) ||
                p.brand?.toLowerCase().includes(keyword) ||
                p.aspects?.Model?.toLowerCase()?.includes(keyword))
            );
          });
          setfilteredProducts(allProducts);
          return;
        }
        allProducts = allProducts.filter(
          (p) =>
            p.title.toLowerCase().includes(keyword) ||
            p.brand?.toLowerCase().includes(keyword) ||
            p.aspects?.Model?.toLowerCase()?.includes(keyword)
        );
        setfilteredProducts(allProducts);
        return;
      } else if (category === -1) {
        if (status !== 0) {
          allProducts = allProducts.filter((p) => {
            return (
              p.status === statusvalues[status] &&
              (p.title.toLowerCase().includes(keyword) ||
                p.brand?.toLowerCase().includes(keyword) ||
                p.aspects?.Model?.toLowerCase()?.includes(keyword))
            );
          });
          setfilteredProducts(allProducts);

          return;
        }
        allProducts = allProducts.filter(
          (p) =>
            p.title.toLowerCase().includes(keyword) ||
            p.brand?.toLowerCase().includes(keyword) ||
            p.aspects?.Model?.toLowerCase()?.includes(keyword)
        );
        setfilteredProducts(allProducts);
        return;
      } else {
        allProducts = products.filter(
          (p) =>
            p.status === statusvalues[status] &&
            (p.title.toLowerCase().includes(keyword) ||
              p.brand?.toLowerCase().includes(keyword) ||
              p.aspects?.Model?.toLowerCase()?.includes(keyword)) &&
            (category === -2 ? null : category) === (p.category ?? null)
        );
        setfilteredProducts(allProducts);

        return;
      }
    },
    [products, status, category, statusvalues]
  );

  useEffect(() => {
    if (products.length > 0) {
      setfilteredProducts(products);
      filterProducts(KeywordState);
    }
    // eslint-disable-next-line
  }, [products]);
  useEffect(() => {
    startLoadingCategories();
    loadStores(true);
    if (subscription.planId.features) {
      if (
        !subscription.planId.features.some(
          (feat) => feat.featureId.identifier === 'I/EP'
        )
      )
        setLockExport(true);
    }
  }, [loadStores, startLoadingCategories, subscription]);

  // useEffect(() => {

  //   if (products.length > 0) {
  //     setfilteredProducts(products);
  //   }
  // }, [products, startLoadingCategories, subscription, loadStores]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRefresh = () => {
    setStatus(0);
    setCategory(-1);
    setKeywordState('');
    startLoading();
    startLoadingCategories();
    setFormats(false);
    setfilteredProducts(products);
  };
  const handleReset = () => {
    setfilteredProducts(products);
    setStatus(0);
    setCategory(-1);
    setKeywordState('');
    setFormats(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const handlePageChange = (event, val) => {
    setPageNumber(val);
    setSelected([]);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [searchOpen, setSearchOpen] = useState(false);

  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);

  const handleStatus = (event) => {
    setStatus(Number(event.target.value));
  };
  const handleMainCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleStoreLocation = (event) => {
    if (event.target.value === -1) {
      setfilteredProducts(products);
    } else {
      const allProducts = products.filter(
        (p) => p.storeLocation === event.target.value
      );
      setfilteredProducts(allProducts);
    }
    setStoreLocation(event.target.value);
  };

  const [selected, setSelected] = React.useState([]);

  const onSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredProducts
        .slice((pageNumber - 1) * numofItems, pageNumber * numofItems)
        .map((p) => p._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const filterByEbayListing = (event) => {
    if (!formats) {
      const allProducts = products.filter((p) => {
        return p.ebayListing.listingId !== null;
      });

      setfilteredProducts(allProducts);
    } else {
      setfilteredProducts(products);
    }
    setFormats(!formats);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    startLoading();
    startLoadingCategories();
    loadApps();
  }, [startLoading, startLoadingCategories, loadApps]);
  const hideUpgradeModal = () => {
    setShowSubscriptionModal(false);
  };
  const showModal = () => {
    setShowSubscriptionModal(lockExport);
  };
  
  return (
    <>
      <div className="mb-2">
        <SubscriptionsModal
          status={showSubscriptionModal}
          closeHandler={hideUpgradeModal}
          upgradeStatus={{ errorMsg: 'This Feature is not part of your plan' }}
        />
        {lockExport ? (
          <>
            <Button
              size="small"
              className="btn-neutral-secondary"
              onClick={showModal}>
              <span className="btn-wrapper--icon">
                <NoEncryptionIcon />
              </span>
              <span className="btn-wrapper--label">Export</span>
            </Button>
            <Button
              size="small"
              className="btn-neutral-secondary"
              onClick={showModal}>
              <span className="btn-wrapper--icon">
                <NoEncryptionIcon />
              </span>
              <span className="btn-wrapper--label">Import</span>
            </Button>
          </>
        ) : (
          <>
            <ExportProducts selected={selected} products={products} />
            <ImportProducts bulkWriteProducts={bulkWriteProducts} />
          </>
        )}
      </div>
      <div>
        {responseError && (
          <>
            <Alert
              severity="error"
              onClose={() => {
                setresponseError('');
              }}>
              {responseError}
            </Alert>
          </>
        )}
        {responseSuccess && (
          <>
            <Alert
              severity="success"
              onClose={() => {
                setresponseSuccess('');
              }}>
              {responseSuccess}
            </Alert>
          </>
        )}
      </div>
      <Card className="card-box shadow-none">
        <div className="d-flex justify-content-between px-4 py-3">
          <div
            className={clsx(
              'search-wrapper search-wrapper--alternate search-wrapper--grow',
              { 'is-active': searchOpen }
            )}>
            <TextField
              variant="outlined"
              size="small"
              id="input-with-icon-textfield22-2"
              placeholder="Search Products..."
              onFocus={openSearch}
              onBlur={closeSearch}
              value={KeywordState}
              onChange={async (e) => {
                let value = e.target.value;

                setKeywordState(value);
                filterProducts(value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            {/* <DeleteDemoProducts isLoading={isLoading}/> */}
            {!isLoading && (
              <div>
                {' '}
                <PosUpdater className="d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill" />
              </div>
            )}
            <div>
              {' '}
              <Button
                onClick={handleRefresh}
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                <RefreshTwoToneIcon className="w-50" />
              </Button>
            </div>
            <div>
              <Button
                onClick={handleClick}
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                <FilterListTwoToneIcon className="w-50" />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                classes={{ list: 'p-0' }}
                onClose={handleClose}>
                <div className="dropdown-menu-xxl overflow-hidden p-0">
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                          Status
                        </small>
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                            fullWidth
                            value={status}
                            onChange={handleStatus}
                            labelWidth={0}>
                            <MenuItem value={0}>All statuses</MenuItem>
                            {/* <MenuItem value={1}>Added</MenuItem> */}
                            <MenuItem value={1}>Ready</MenuItem>
                            <MenuItem value={2}>Published</MenuItem>
                            <MenuItem value={3}>Unpublished</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                          Category
                        </small>
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                            fullWidth
                            value={category}
                            onChange={handleMainCategory}
                            labelWidth={0}>
                            <MenuItem key={0} value={-1}>
                              All Categories
                            </MenuItem>
                            {categoryValues.map((c, index) => (
                              <MenuItem key={index} value={c._id}>
                                {c.name}
                              </MenuItem>
                            ))}
                            <MenuItem key={-2} value={-2}>
                              UnCategorized
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="divider" />
                  <div className="p-3 text-center bg-secondary">
                    <Button
                      onClick={(e) => filterProducts(KeywordState)}
                      className="btn-primary"
                      size="small">
                      Filter results
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <List className="nav-neutral-success flex-column p-0">
                          <ListItem
                            button
                            className="d-flex rounded-sm justify-content-center"
                            href="#/"
                            onClick={handleReset}>
                            <div className="mr-2">
                              <SaveTwoToneIcon />
                            </div>
                            <span>Reset Filter</span>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                          Ebay Filters
                        </small>
                        {/* <small style={{fontSize:'7px'}} className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                  Store Location
                </small> */}
                        <div className="d-flex align-items-center">
                          <FormControl variant="outlined" size="small">
                            <ToggleButton
                              size="small"
                              onClick={filterByEbayListing}
                              selected={formats}
                              value={formats}
                              aria-label="ebay">
                              Filter by Ebay Listing
                            </ToggleButton>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                          Other Filters
                        </small>
                        {/* <small style={{fontSize:'7px'}} className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                  Store Location
                </small> */}
                        <div className="d-flex align-items-center">
                          {stores && stores.length > 0 ? (
                            <FormControl
                              variant="outlined"
                              className="pr-4"
                              size="small">
                              <Select
                                value={location}
                                onChange={handleStoreLocation}
                                labelWidth={0}>
                                <MenuItem key={0} value={-1}>
                                  All stores
                                </MenuItem>
                                {stores.map((c, index) => (
                                  <MenuItem key={index} value={c._id}>
                                    {c.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <p>No Stores Found</p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Menu>
            </div>
            <div>
              <Button
                onClick={handleClick2}
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 p-0 rounded-pill">
                <SettingsTwoToneIcon className="w-50" />
              </Button>
              <Menu
                anchorEl={anchorEl2}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorEl2)}
                classes={{ list: 'p-0' }}
                onClose={handleClose2}>
                <div className="dropdown-menu-lg overflow-hidden p-0">
                  <div className="font-weight-bold px-4 pt-3">Results</div>
                  <List className="nav-neutral-first nav-pills-rounded flex-column p-2">
                    <ListItem
                      button
                      href="#/"
                      onClick={(e) => {
                        setPageNumber(1);
                        setNumofItems(5);
                        handleClose2();
                      }}>
                      <div className="nav-link-icon mr-2">
                        <RadioButtonUncheckedTwoToneIcon />
                      </div>
                      <span className="font-size-md">
                        <b>5 </b> results per page
                      </span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={(e) => {
                        setPageNumber(1);
                        setNumofItems(20);
                        handleClose2();
                      }}>
                      <div className="nav-link-icon mr-2">
                        <RadioButtonUncheckedTwoToneIcon />
                      </div>
                      <span className="font-size-md">
                        <b>20</b> results per page
                      </span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={(e) => {
                        setPageNumber(1);
                        setNumofItems(30);
                        handleClose2();
                      }}>
                      <div className="nav-link-icon mr-2">
                        <RadioButtonUncheckedTwoToneIcon />
                      </div>
                      <span className="font-size-md">
                        <b>30</b> results per page
                      </span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={(e) => {
                        setPageNumber(1);
                        setNumofItems(props.products.length);
                        handleClose2();
                      }}>
                      <div className="nav-link-icon mr-2">
                        <RadioButtonUncheckedTwoToneIcon />
                      </div>
                      <span className="font-size-md">List all</span>
                    </ListItem>
                  </List>
                  <div className="divider" />
                  <div className="font-weight-bold px-4 pt-4">Order</div>
                  <List className="nav-neutral-first nav-pills-rounded flex-column p-2">
                    <ListItem
                      button
                      href="#/"
                      onClick={(e) => {
                        handleClose2();
                        setfilteredProducts(
                          filteredProducts.sort((a, b) => {
                            var x = a.title.toLowerCase();
                            var y = b.title.toLowerCase();
                            if (x < y) {
                              return -1;
                            }
                            if (x > y) {
                              return 1;
                            }
                            return 0;
                          })
                        );
                      }}>
                      <div className="mr-2">
                        <ArrowUpwardTwoToneIcon />
                      </div>
                      <span className="font-size-md">Ascending (A-Z)</span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={(e) => {
                        handleClose2();
                        setfilteredProducts(
                          filteredProducts.sort((a, b) => {
                            var x = a.title.toLowerCase();
                            var y = b.title.toLowerCase();
                            if (x < y) {
                              return 1;
                            }
                            if (x > y) {
                              return -1;
                            }
                            return 0;
                          })
                        );
                      }}>
                      <div className="mr-2">
                        <ArrowDownwardTwoToneIcon />
                      </div>
                      <span className="font-size-md">Descending (A-Z)</span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={() => {
                        handleClose2();
                        setfilteredProducts(
                          filteredProducts.sort((a, b) => {
                            var x = +a.price;
                            var y = +b.price;
                            if (x > y) {
                              return 1;
                            }
                            if (x < y) {
                              return -1;
                            }
                            return 0;
                          })
                        );
                      }}>
                      <div className="mr-2">
                        <ArrowUpwardTwoToneIcon />
                      </div>
                      <span className="font-size-md">Ascending (Price)</span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={() => {
                        handleClose2();
                        setfilteredProducts(
                          filteredProducts.sort((a, b) => {
                            var x = +a.price;
                            var y = +b.price;
                            if (x < y) {
                              return 1;
                            }
                            if (x > y) {
                              return -1;
                            }
                            return 0;
                          })
                        );
                      }}>
                      <div className="mr-2">
                        <ArrowDownwardTwoToneIcon />
                      </div>
                      <span className="font-size-md">Descending (Price)</span>
                    </ListItem>{' '}
                    <ListItem
                      button
                      href="#/"
                      onClick={() => {
                        handleClose2();
                        setfilteredProducts(
                          filteredProducts.sort((a, b) => {
                            var x = +a.quantity;
                            var y = +b.quantity;
                            if (x > y) {
                              return 1;
                            }
                            if (x < y) {
                              return -1;
                            }
                            return 0;
                          })
                        );
                      }}>
                      <div className="mr-2">
                        <ArrowUpwardTwoToneIcon />
                      </div>
                      <span className="font-size-md">Ascending (Quantity)</span>
                    </ListItem>
                    <ListItem
                      button
                      href="#/"
                      onClick={() => {
                        handleClose2();
                        setfilteredProducts(
                          filteredProducts.sort((a, b) => {
                            var x = +a.quantity;
                            var y = +b.quantity;
                            if (x < y) {
                              return 1;
                            }
                            if (x > y) {
                              return -1;
                            }
                            return 0;
                          })
                        );
                      }}>
                      <div className="mr-2">
                        <ArrowDownwardTwoToneIcon />
                      </div>
                      <span className="font-size-md">
                        Descending (Quantity)
                      </span>
                    </ListItem>
                  </List>
                </div>
              </Menu>
            </div>
          </div>
        </div>

        <div className="divider" />

        {selected.length > 0 && (
          <>
            <BulkActions
              setresponseError={setresponseError}
              setresponseSuccess={setresponseSuccess}
              selected={selected}
              setSelected={setSelected}
              allApps={allApps}
              products={filteredProducts.slice(
                (pageNumber - 1) * numofItems,
                pageNumber * numofItems
              )}
            />
            <div className="divider" />
          </>
        )}

        <div className={matches ? 'p-4' : 'p-2'}>
          <div className="table-responsive-md">
            {isLoading && <CircularProgress size={24} thickness={5} />}
            {products.length === 0 ? (
              <p>Not product exists yet!</p>
            ) : (
              <Table className="table table-alternate-spaced mb-0">
                <thead>
                  <tr>
                    <th scope="col" className="pb-4 text-center">
                      <Checkbox
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < products.length
                        }
                        checked={
                          products.length > 0 &&
                          selected.length ===
                            filteredProducts.slice(
                              (pageNumber - 1) * numofItems,
                              pageNumber * numofItems
                            ).length
                        }
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        onChange={onSelectAll}
                      />
                    </th>
                    {selected.length > 0 ? (
                      <th className="font-size-md font-weight-normal pb-4 text-capitalize text-dark">
                        {selected.length} of{' '}
                        {
                          filteredProducts.slice(
                            (pageNumber - 1) * numofItems,
                            pageNumber * numofItems
                          ).length
                        }{' '}
                        selected
                      </th>
                    ) : (
                      <>
                        <th
                          className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                          scope="col">
                          Product
                        </th>
                        <th
                          className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                          scope="col">
                          Price
                        </th>
                        <th
                          className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                          scope="col">
                          Rating
                        </th>
                        <th
                          className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                          scope="col">
                          Status
                        </th>
                        <th
                          className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                          scope="col">
                          Listing
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts
                    .slice(
                      (pageNumber - 1) * numofItems,
                      pageNumber * numofItems
                    )
                    .map((p) => (
                      <TableRow
                        key={p._id}
                        product={p}
                        productCategory={categories?.find(
                          (cat) => p.category === cat._id
                        )}
                        isChecked={isSelected(p._id)}
                        onCheck={onSelect}
                      />
                    ))}
                </tbody>
              </Table>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center flex-wrap">
            <Typography>Page: </Typography>
            <Pagination
              className="pagination-success"
              count={Math.ceil(filteredProducts.length / numofItems)}
              page={pageNumber}
              onChange={handlePageChange}
            />
          </div>
          <div>
            <span className="text-capitalize font-weight-bold">Ready:</span>{' '}
            Ready to publish on the store. <br />
            <span className="text-capitalize font-weight-bold">
              Published:
            </span>{' '}
            Product is currently listed on the store. <br />
            <span className="text-capitalize font-weight-bold">
              Unpublished:
            </span>{' '}
            Product is removed from the store temporarily (You can still use it
            to list on ebay). <br />
          </div>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  categories: getAllCategories(state),
  products: getProducts(state),
  isLoading: getIsLoadingProducts(state),
  subscription: getSubscription(state),
  stores: getStoreLocation(state),
  allApps: getAllApps(state)
});

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(loadProducts()),
  startLoadingCategories: () => dispatch(loadCategories()),
  bulkWriteProducts: (data) => dispatch(bulkWriteProducts(data)),
  loadStores: (value) => dispatch(getStoreList(value)),
  loadApps: () => dispatch(loadApps())
});

export default connect(mapStateToProps, mapDispatchToProps)(Products1);
