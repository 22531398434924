import React, { useState } from 'react';

import {
  Button,
  Dialog,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel
} from '@material-ui/core';

import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';

const ExportProducts = ({ selected, products }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [exportType, setExportType] = useState('all');
  const handleExportType = (e) => setExportType(e.target.value);

  const downloadCsv = () => {
    const data = transformToCsv();
    const csv = data.map((r) => r.join(',')).join('\n');

    const filename = 'products_export.csv';
    const contentType = 'text/csv;charset=utf-8';
    const content = encodeURIComponent(csv);
    const a = document.createElement('a');

    a.download = filename;
    a.href = `data:${contentType},${content}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    toggleModal();
  };

  const transformToCsv = () => {
    const rows = [
      [
        "Product ID (don't edit)",
        'Title',
        'Barcode',
        'ASIN',
        'SKU',
        'CL_SKU',
        'Manufacturer',
        'Brand',
        'Price',
        'Compare at price',
        'Condition',
        'Quantity',
        'customField',
        'isShippable',
        'isDeliverable',
        'status'
      ]
    ];

    if (exportType === 'all') {
      products.forEach((product) => {
        const row = productToArray(product);
        rows.push(row);
      });
    } else {
      products.forEach((product) => {
        if (selected.some((id) => id === product._id)) {
          const row = productToArray(product);
          rows.push(row);
        }
      });
    }
    return rows;
  };

  const productToArray = (product) => {
    return [
      product._id,
      product.title.replace(/,/g, ''),
      product.barcode || '',
      product.asin || '',
      product.sku || '',
      product.cl_sku || '',
      product.manufacturer ? product.manufacturer.replace(/,/g, '') : '',
      product.brand || '',
      product.price,
      product.oldPrice,
      product.condition,
      product.quantity,
      product.customField || '',
      product.isShippable || false,
      product.isDeliverable || false,
      product.status?.trim() || 'Unpublished'
    ];
  };

  return (
    <>
      <Button
        size="small"
        className="btn-neutral-secondary"
        onClick={toggleModal}>
        <span className="btn-wrapper--icon">
          <PublishIcon />
        </span>
        <span className="btn-wrapper--label">Export</span>
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="xs"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>
        </div>

        <div className="p-3">
          <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
            Export products
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="p-3 text-black-70">
          <div className="mb-2">
            This CSV file can be used to update all product info.
          </div>
          <FormLabel style={{ marginBottom: '8px' }}>Export</FormLabel>
          <RadioGroup
            aria-label="export"
            name="export"
            value={exportType}
            onChange={handleExportType}>
            <FormControlLabel
              value="all"
              control={<Radio style={{ padding: '2px 8px' }} />}
              label="All products"
            />
            <FormControlLabel
              value="selected"
              control={<Radio style={{ padding: '2px 8px' }} />}
              disabled={selected.length === 0}
              label={`Selected: ${selected.length} products`}
            />
          </RadioGroup>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="p-3 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral font-size-sm mr-3">
            Cancel
          </Button>
          <Button
            className="btn-primary font-weight-bold"
            onClick={downloadCsv}>
            Export products
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default ExportProducts;
