import { Container } from '@material-ui/core';
import React from 'react';

import logo1 from '../../../assets/images/stock-logos/ccb.png';
import logo2 from '../../../assets/images/stock-logos/jiminez.png';
import logo3 from '../../../assets/images/stock-logos/ohio.png';

export default function LivePreviewExample() {
  return (
    <Container>
      {/* <div className="bg-royal mb-spacing-6-x2"> */}
      <p className="text-center text-uppercase font-weight-bold text-primary">
        Trusted by top liquidation businesses{' '}
      </p>
      <h2 className="text-center display-3 font-weight-bold">
      The #1 Liquidation eCommerce platform choice for growing businesses
      </h2>
      <div className="py-3">
        <div className="d-flex align-items-center justify-content-center flex-wrap">
          <div className="mx-1 d-flex align-self-stretch">
            <div className="hover-scale-rounded object-skew shadow-sm bg-white opacity-9 rounded-sm p-2 p-md-4">
              <img
                src={logo1}
                style={{ height: 40 }}
                className="m-auto img-fluid text-white"
                alt="..."
              />
            </div>
          </div>
          <div className="mx-1 d-flex align-self-stretch">
            <div className="hover-scale-rounded object-skew shadow-sm bg-white opacity-9 rounded-sm p-2 p-md-4">
              <img
                src={logo2}
                style={{ height: 40 }}
                className="m-auto img-fluid text-white"
                alt="..."
              />
            </div>
          </div>
          <div className="mx-1  d-flex align-self-stretch">
            <div className="hover-scale-rounded object-skew shadow-sm bg-white opacity-9 rounded-sm p-2 p-md-4">
              <img
                src={logo3}
                style={{ height: 40 }}
                className="m-auto img-fluid text-white"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Container>
  );
}
