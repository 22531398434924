import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Button, Box } from '@material-ui/core';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';

import { addBarcode } from '../../../reducers/Barcodes';

import TabItemWrapper from '../TabItemWrapper';

const BarcodeScanner = ({ addBarcode }) => {
  const [isPlaying, setPlaying] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [stream, setStream] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const videoRef = useRef();
  const videoParentRef = useRef();

  const isMobile = () => {
    return !!navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod/i);
  };

  const isCameraSupported = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  };

  const isBarcodeApiSupported = () => {
    return !!window.BarcodeDetector;
  };

  const startStream = () => {
    const video = videoRef.current;
    video.width = videoParentRef.current.offsetWidth;
    video.height = videoParentRef.current.offsetWidth;

    const constraints = {
      audio: false,
      video: {
        width: video.width,
        height: video.height,
        facingMode: isMobile() ? 'environment' : 'user'
      }
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          video.play();
          video.hidden = false;
          setStream(stream);
          setPlaying(true);

          const temp = setInterval(() => {
            const canvasEl = document.createElement('canvas');
            canvasEl.width = video.width;
            canvasEl.height = video.height;

            const canvas = canvasEl.getContext('2d');
            canvas.drawImage(video, 0, 0, video.width, video.height);
            const image = canvas.getImageData(0, 0, video.width, video.height);

            detectAndAdd(image);
          }, 500);

          setIntervalId(temp);
        };
      })
      .catch((err) => {
        setVideoError(true);
        console.error(err);
      });
  };

  const stopStream = () => {
    const video = videoRef.current;
    video.hidden = true;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setPlaying(false);
      clearInterval(intervalId);
    }
  };

  const detectAndAdd = (image) => {
    try {
      const codeDetector = new window.BarcodeDetector({
        formats: ['ean_13', 'ean_8', 'upc_a', 'upc_e']
      });

      codeDetector.detect(image).then((codes) => {
        
        if (codes.length > 0) {
          for (const code of codes) {
            addBarcode({
              value: code.rawValue,
              format: 'upc',
              source: 'amazon',
              quantity: 1
            });
          }
        }
      });
    } catch (e) {
      setVideoError(true);
      console.error('Barcode detection failed:', e);
    }
  };

  return (
    <TabItemWrapper
      title="Scan Barcodes"
      subtitle="Automatically scan barcodes of different formats.">
      <div ref={videoParentRef}>
        <video hidden ref={videoRef} />
      </div>

      {isCameraSupported && isBarcodeApiSupported && !videoError ? (
        <div className="text-center mt-2">
          {isPlaying ? (
            <Button
              variant="contained"
              color="danger"
              className="mx-2"
              onClick={stopStream}>
              <span className="btn-wrapper--icon">
                <StopIcon />
              </span>
              <span className="btn-wrapper--label font-weight-bold">Stop</span>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className="mx-2"
              onClick={startStream}>
              <span className="btn-wrapper--icon">
                <PlayArrowIcon />
              </span>
              <span className="btn-wrapper--label font-weight-bold">Start</span>
            </Button>
          )}
        </div>
      ) : (
        <Box className="text-center">
          <div className="mb-2">
            <ErrorTwoToneIcon style={{ fontSize: 48 }} />
          </div>
          <div>
            Your device does not support camera access or something went wrong.
            But you can still enter the barcodes manually.
          </div>
        </Box>
      )}
    </TabItemWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addBarcode: (barcode) => dispatch(addBarcode(barcode, true))
});

export default connect(null, mapDispatchToProps)(BarcodeScanner);
