import { axiosIns, axiosIns2 } from '../config/configAxios';
import { resizeImg } from 'utils';
const transformErrors = (res) => {
  let errors = {};
  try {
    for (let i = 0; i < res.length; i++) {
      const el = res[i];
      errors[el.param] = el.msg;
    }
  } catch (err) {
    console.log(err);
  }
  return errors;
};
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const addProduct = (product) => ({
  type: ADD_PRODUCT,
  payload: { product }
});

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const updateProduct = (product) => ({
  type: UPDATE_PRODUCT,
  payload: { product }
});

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const deleteProduct = (id) => ({
  type: DELETE_PRODUCT,
  payload: { id }
});

export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const updateProducts = (data) => ({
  type: UPDATE_PRODUCTS,
  payload: { data }
});

const BULKWRITE_PRODUCTS = 'BULKWRITE_PRODUCTS';
const bulkWriteProductsAction = (data) => ({
  type: BULKWRITE_PRODUCTS,
  payload: { data }
});

export const LOADING_IN_PROGRESS = 'PRODUCTS_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'PRODUCTS_LOADING_SUCCESS';
export const loadingSuccess = (products) => ({
  type: LOADING_SUCCESS,
  payload: { products }
});

export const LOADING_FAILURE = 'PRODUCTS_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});

export const UPLOADING_IN_PROGRESS = 'PRODUCTS_UPLOADING_IN_PROGRESS';
export const uploadingInProgress = () => ({
  type: UPLOADING_IN_PROGRESS
});

export const UPLOADING_SUCCESS = 'PRODUCTS_UPLOADING_SUCCESS';
export const uploadingSuccess = () => ({
  type: UPLOADING_SUCCESS
});

export const UPLOADING_FAILURE = 'PRODUCTS_UPLOADING_FAILURE';
export const uploadingFailure = (data) => ({
  type: UPLOADING_FAILURE,
  payload: { data }
});

const PRODUCTS_CHANGES_DETECTED = 'PRODUCTS_CHANGES_DETECTED';
export const changesDetected = () => ({ type: PRODUCTS_CHANGES_DETECTED });

const PRODUCTS_CHANGES_PUBLISHED = 'PRODUCTS_CHANGES_PUBLISHED';
export const changesPublished = () => ({ type: PRODUCTS_CHANGES_PUBLISHED });

export const SHOW_UPGRADE_MODAL = 'SHOW_UPGRADE_MODAL';
export const upgradeModal = (error) => ({
  type: SHOW_UPGRADE_MODAL,
  payload: { error }
});
export const HIDE_UPGRADE_MODAL = 'HIDE_UPGRADE_MODAL';
export const hideUpgradeModal = () => ({
  type: HIDE_UPGRADE_MODAL
});
export const SELECTS_PRODUCTS = 'SELECTS_PRODUCTS';
export const selectedProducts = (payload) => ({
  type: SELECTS_PRODUCTS,
  payload
});

export const UPDATE_MULTIPLE_PRODUCTS = 'UPDATE_MULTIPLE_PRODUCTS';
export const updatingMultipleProducts = (payload) => ({
  type: UPDATE_MULTIPLE_PRODUCTS,
  payload
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = {
  isLoading: false,
  isUploading: false,
  data: [],
  isChanged: false,
  subscriptionStatus: {},
  errors: {},
  selectedProducts: []
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_PRODUCT: {
      const { product } = payload;
      return {
        ...state,
        data: state.data.concat(product),
        isUploading: false,
        isChanged: true,
        errors: {}
      };
    }
    case UPDATE_PRODUCT: {
      const { product } = payload;
      const data = state.data.map((p) => {
        if (p._id === product._id) return product;
        return p;
      });
      data.isChanged = true;
      return {
        ...state,
        data,
        isUploading: false,
        isChanged: true,
        errors: {}
      };
    }
    case DELETE_PRODUCT: {
      const { id } = payload;
      return {
        ...state,
        data: state.data.filter((p) => p._id !== id),
        isChanged: true
      };
    }
    case UPDATE_PRODUCTS: {
      const { ids, attributes } = payload.data;

      let products = [...state.data];

      ids.forEach((id) => {
        if ('isActive' in attributes) {
          products = products.filter((p) => p._id !== id);
        } else if ('percentageDiscount' in attributes) {
          const i = products.findIndex((p) => p._id === id);
          const price = products[i].price;
          const obj = {
            price: Number(
              price - price * (attributes.percentageDiscount / 100)
            ).toFixed(2),
            oldPrice: price
          };
          Object.assign(products[i], obj);
        } else {
          const i = products.findIndex((p) => p._id === id);
          Object.assign(products[i], attributes);
        }
      });
      products = products.map((p) => {
        return { ...p, isChanged: true };
      });
      return { ...state, data: products, isChanged: true };
    }
    case BULKWRITE_PRODUCTS: {
      const { data } = payload;
      let products = [...state.data];
      data.forEach(({ _id, ...product }) => {
        const i = products.findIndex((p) => p._id === _id);
        if (i !== -1) Object.assign(products[i], product);
      });

      return { ...state, data: products, isChanged: true };
    }
    case LOADING_SUCCESS: {
      const { products } = payload;
      return { ...state, isLoading: false, data: products };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true, errors: {} };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    case UPLOADING_SUCCESS: {
      return { ...state, isUploading: false, errors: {} };
    }
    case UPLOADING_IN_PROGRESS:
      return { ...state, isUploading: true };
    case UPLOADING_FAILURE: {
      const { data } = payload;
      let errors = {};
      if (data) {
        errors = transformErrors(data);
      }
      return Object.assign({}, state, { isUploading: false, errors: errors });
    }
    case PRODUCTS_CHANGES_DETECTED:
      return { ...state, isChanged: true };
    case PRODUCTS_CHANGES_PUBLISHED:
      return { ...state, isChanged: false };
    case SHOW_UPGRADE_MODAL: {
      const { error } = payload;
      return { ...state, subscriptionStatus: error };
    }
    case HIDE_UPGRADE_MODAL: {
      return { ...state, subscriptionStatus: {} };
    }
    case SELECTS_PRODUCTS: {
      const { selected } = payload;
      const filterProducts = state.data.filter((prod) =>
        selected.includes(prod._id)
      );
      console.log('Filtered', filterProducts);
      return { ...state, selectedProducts: filterProducts };
    }
    case UPDATE_MULTIPLE_PRODUCTS: {
      const { updatedProducts } = payload;
      console.log(' UPDATE_MULTIPLE_PRODUCTS');
      console.log(' payload : ', payload);
      console.log(' updatedProducts : ', updatedProducts);
      let newProducts = [...state.data];
      updatedProducts.forEach((product) => {
        const index = newProducts.findIndex(
          (newProduct) => newProduct._id === product._id
        );
        if (index !== -1) {
          newProducts[index] = product;
        }
      });
      console.log(' newProducts : ', newProducts);

      return { ...state, data: [...newProducts] };
    }
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadProducts = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/products?isActive=true');
    const products = response.data;

    dispatch(loadingSuccess(products));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

export const addNewProduct = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());

  data.images = await Promise.all(
    data.images.map(async (img) => await resizeImg(img))
  );

  const formData = new FormData();
  const ARRAY_KEYS = ['images'];
  for (const [key, value] of Object.entries(data)) {
    if ((key === 'category' || key === 'subCategory') && value === '') continue;
    else if (ARRAY_KEYS.some((elem) => elem === key))
      for (const image of value) formData.append(key, image);
    else if (typeof value === 'object')
      formData.append(key, JSON.stringify(value));
    else formData.append(key, value);
  }
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns2.post('/products', formData);

    dispatch(addProduct(res.data));
  } catch (err) {
    dispatch(uploadingFailure());
    console.log(err.response.status);
    if (err.response.status === 500) {
      console.log(err);
      if (err.response.data) {
        if (
          err.response.data.error.name === 'ERR_EXCEED' ||
          err.response.data.error.name === 'ERR_UPGRADE'
        ) {
          const error = {
            errorCode: err.response.data.error.name,
            errorMsg: err.response.data.message
          };
          dispatch(upgradeModal(error));
        }
      }
    } else {
      dispatch(uploadingFailure(err.response.data.errors));
      throw err;
    }
  }
};

export const updateProductById = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());

  data.images = await Promise.all(
    data.images.map(async (img) => {
      if (typeof img === 'object') {
        const resizedImg = await resizeImg(img);
        return resizedImg;
      }
      return img;
    })
  );

  const formData = new FormData();
  const ARRAY_KEYS = ['images', 'deleteImages'];
  for (const [key, value] of Object.entries(data)) {
    if ((key === 'category' || key === 'subCategory') && value === '') continue;
    else if (ARRAY_KEYS.some((elem) => elem === key))
      for (const image of value) formData.append(key, image);
    else if (typeof value === 'object') {
      formData.append(key, JSON.stringify(value));
    } else formData.append(key, value);
  }
  formData.set('isChanged', true);
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns2.put(`/products/${data._id}`, formData);
    const newProduct = response.data;

    dispatch(updateProduct(newProduct));
  } catch (err) {
    dispatch(uploadingFailure(err.response.data.errors));
    throw err;
  }
};

export const updateProductFromPOS = (data) => async (dispatch, getState) => {
  dispatch(uploadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns2.put(
      `/products/updatepos/${data._id}`,
      data
    );
    const newProduct = response.data;

    dispatch(updateProduct(newProduct));
  } catch (err) {
    dispatch(uploadingFailure(err.response.data.errors));
    throw err;
  }
};

export const deleteProductById = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const formData = new FormData();
    formData.append('isActive', false);
    await axiosIns2.delete(`/products/${id}`, formData);

    dispatch(deleteProduct(id));
  } catch (err) {
    console.log(err);
  }
};

export const updateMultipleProducts = ({
  data,
  setResponseError,
  setresponseSuccess
}) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch('/products', data);
    if (response.data && response.data.nModified > 0) {
      dispatch(updateProducts(data));
      setresponseSuccess('Updated Successfully');
    }
  } catch (err) {
    console.log(err);
    setResponseError('Bulk Operation Failed!');
  }
};

export const bulkAddtoClover = ({
  data,
  setResponseError,
  setresponseSuccess
}) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.post('/clover/bulkpublishitem', data);

    if (response.data.result && response.data.result.nModified > 0) {
      dispatch(bulkWriteProductsAction(response.data.products));
      setresponseSuccess('Successfully added to clover');
    }
  } catch (err) {
    console.log(err);

    setResponseError(err.response.data.errors[0].message);
  }
};
export const bulkRemoveFromClover = ({
  data,
  setResponseError,
  setresponseSuccess
}) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.post('/clover/bulkremoveitem', data);
    if (response.data && response.data.nModified > 0) {
      dispatch(
        updateProducts({ ids: data.ids, attributes: { cloverId: null } })
      );
      setresponseSuccess('Successfully removed from clover');
    }
  } catch (err) {
    console.log(err);
    setResponseError(err.response.data.errors[0].message);
  }
};

export const bulkWriteProducts = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch('/products/bulkWrite', data);

    response.data &&
      response.data.nModified === data.length &&
      dispatch(bulkWriteProductsAction(data));
  } catch (err) {
    console.log(err);
    if (err.response.status === 500) {
      console.log(err);
      if (err.response.data) {
        if (err.response.data.error.name === 'ERR_UPGRADE') {
          const error = {
            errorCode: err.response.data.error.name,
            errorMsg: err.response.data.message
          };
          dispatch(upgradeModal(error));
        }
      }
    }
  }
};

export const bulkWriteProductsV2 = (
  data,
  setresponseSuccess,
  setresponseError
) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.patch('/products/bulkWrite', data);

    response.data &&
      response.data.nModified === data.length &&
      dispatch(bulkWriteProductsAction(data));

    setresponseSuccess(
      `Updated Successfully ${response.data.nModified} of ${data.length} products `
    );
  } catch (err) {
    console.log(err);
    if (err.response.status === 500) {
      console.log(err);
      if (err.response.data) {
        if (err.response.data.error.name === 'ERR_UPGRADE') {
          const error = {
            errorCode: err.response.data.error.name,
            errorMsg: err.response.data.message
          };
          setresponseError(err.response.data.message);
          dispatch(upgradeModal(error));
        }
      }
    } else {
      setresponseError(err.response.data.message);
    }
  }
};

export const deleteDemoProducts = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    const products = state.Products.data;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.delete('/products/deletedemo');

    response.data &&
      response.status === 200 &&
      dispatch(loadingSuccess(products.filter((p) => !p.isDemo)));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getProducts = (state) => state.Products.data;
export const getZeroProducts = (state) =>
  state.Products.data.some((p) => +p.price === 0);
export const getUnCategorizedProducts = (state) =>
  state.Products.data.filter((p) => p.category === null);
export const getIsLoadingProducts = (state) => state.Products.isLoading;
export const getIsUploadingProduct = (state) => state.Products.isUploading;
export const getIsChangedProducts = (state) => state.Products.isChanged;
export const getSubscriptionStatus = (state) =>
  state.Barcodes.subscriptionStatus;
export const getFormError = (state) => state.Products.errors;
export const getSelectedProducts = (state) => state.Products.selectedProducts;
