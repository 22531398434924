import { axiosIns, axiosIns2 } from '../config/configAxios';
import { transformErrors } from 'utils';
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const addCategorySuccess = (category) => ({
  type: ADD_CATEGORY,
  payload: { category }
});

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const deleteCategorySuccess = (id) => ({
  type: DELETE_CATEGORY,
  payload: { id }
});

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const updateCategorySuccess = (category) => ({
  type: UPDATE_CATEGORY,
  payload: { category }
});

export const ADD_SUBCATEGORY = 'ADD_SUBCATEGORY';
export const addSubCategorySuccess = (subcategory) => ({
  type: ADD_SUBCATEGORY,
  payload: { subcategory }
});

export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';
export const deleteSubcategorySucccess = (categoryId, subCategoryId) => ({
  type: DELETE_SUBCATEGORY,
  payload: { categoryId, subCategoryId }
});

export const UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY';
export const updateSubcategorySuccess = (subcategory) => ({
  type: UPDATE_SUBCATEGORY,
  payload: { subcategory }
});

export const LOADING_IN_PROGRESS = 'CATEGORIES_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'CATEGORIES_LOADING_SUCCESS';
export const loadingSuccess = (categories) => ({
  type: LOADING_SUCCESS,
  payload: { categories }
});

export const LOADING_FAILURE = 'CATEGORIES_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = { isLoading: false, data: [], noCategoryCount: 0 };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_CATEGORY: {
      const { category } = payload;
      category.productsCount = 0;
      category.subCategories = [];
      return { ...state, data: state.data.concat(category) };
    }

    case ADD_SUBCATEGORY: {
      const { subcategory } = payload;
      subcategory.productsCount = 0;
      const newData = state.data.map((c) => {
        if (c._id === subcategory.category)
          c.subCategories = c.subCategories.concat(subcategory);
        return c;
      });

      return { ...state, data: newData };
    }

    case DELETE_CATEGORY: {
      const { id } = payload;
      return { ...state, data: state.data.filter((c) => c._id !== id) };
    }

    case DELETE_SUBCATEGORY: {
      const { categoryId, subCategoryId } = payload;

      const newData = state.data.map((c) => {
        if (c._id === categoryId)
          c.subCategories = c.subCategories.filter(
            (sc) => sc._id !== subCategoryId
          );
        return c;
      });

      return { ...state, data: newData };
    }

    case UPDATE_CATEGORY: {
      const { category } = payload;
      const newData = state.data.map((c) => {
        if (c._id === category._id) return { ...c, ...category };
        return c;
      });
      return { ...state, data: newData };
    }

    case UPDATE_SUBCATEGORY: {
      const { subcategory } = payload;

      const newData = state.data.map((c) => {
        if (c._id === subcategory.category)
          c.subCategories = c.subCategories.map((sc) => {
            if (sc._id === subcategory._id) sc.name = subcategory.name;
            return sc;
          });
        return c;
      });

      return { ...state, data: newData };
    }

    case LOADING_SUCCESS: {
      const { categories } = payload;
      return {
        ...state,
        isLoading: false,
        data: categories.categories,
        noCategoryCount: categories.noCategoryCount
      };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadCategories = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/allCategories');
    const categories = response.data;

    dispatch(loadingSuccess(categories));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

export const addCategory = (data, props) => async (dispatch, getState) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);

  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === 'seoMetaData') {
      formData.append(`${key}`, JSON.stringify(value));
    } else {
      formData.append(`${key}`, value);
    }
  });

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.post('/categories', formData);
    if (res.status === 201) {
      dispatch(addCategorySuccess(res.data));
      setStatus({ success: true });
      resetForm({});
      toggleModal();
    }
  } catch (err) {
    console.log(err.response.data.errors);
    // console.log()
    setErrors(transformErrors(err.response.data.errors));
    setSubmitting(false);
    // if (err.response.status === 400) {
    //   seterror({ status: true, msg: err.response.data.errors[0].msg });
    // }
  } finally {
    setSubmitting(false);
  }
};

export const addSubCategory = (categoryId, values, props) => async (
  dispatch,
  getState
) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const data = { categoryId, ...values };
    const res = await axiosIns.post('/subCategories', data);
    if (res.status === 201) dispatch(addSubCategorySuccess(res.data));
    toggleModal();
    setStatus({ success: true });
    resetForm({});
    setSubmitting(false);
  } catch (err) {
    setErrors(transformErrors(err.response.data.errors));
    setSubmitting(false);
    console.log(err);
  }
};

export const deleteCategory = (categoryId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns.delete(`/categories/${categoryId}`);

    if (res.status === 204) dispatch(deleteCategorySuccess(categoryId));
  } catch (err) {
    console.log(err);
  }
};

export const deleteSubCategory = (cId, scId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const data = { categoryId: cId };
    const res = await axiosIns.delete(`/subCategories/${scId}`, data);

    if (res.status === 204) dispatch(deleteSubcategorySucccess(cId, scId));
  } catch (err) {
    console.log(err);
  }
};

export const updateCategory = (cId, data, props) => async (
  dispatch,
  getState
) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);

  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === 'seoMetaData') {
      formData.append(`${key}`, JSON.stringify(value));
    } else {
      formData.append(`${key}`, value);
    }
  });

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.put(`/categories/${cId}`, formData);
    if (res.status === 200) {
      dispatch(updateCategorySuccess(res.data));
      setStatus({ success: true });
      resetForm({});
      toggleModal();
    }
  } catch (err) {
    console.log(err);
    setErrors(transformErrors(err.response.data.errors));

    setSubmitting(false);
  } finally {
    setSubmitting(false);
  }
};

export const updateSubCategory = (scId, values, props) => async (
  dispatch,
  getState
) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns.put(`/subCategories/${scId}`, values);

    if (res.status === 200) dispatch(updateSubcategorySuccess(res.data));
    setStatus({ success: true });
    resetForm({});
    toggleModal();
    setSubmitting(false);
  } catch (err) {
    console.log(err);
    setErrors(transformErrors(err.response.data.errors));
    setSubmitting(false);
  }
};
export const deleteDemoCategories = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.delete('/categories/deletedemo');
    const response2 = await axiosIns.get('/allCategories');
    const categories = response2.data;
    response.status === 200 && dispatch(loadingSuccess(categories));
  } catch (err) {
    dispatch(loadingFailure());
  }
};

export const cleanCategories = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns.delete(`/categories/cleanAll`);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getAllCategories = (state) => state.AllCategories.data;
export const getIsLoadingCategories = (state) => state.AllCategories.isLoading;
export const getNoCategoryCount = (state) =>
  state.AllCategories.noCategoryCount;
