import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Snackbar,
  CircularProgress,
  List,
  ListItem,
  Typography,
  CardMedia,
  Tooltip
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ClearIcon from '@material-ui/icons/Clear';

import { useDropzone } from 'react-dropzone';

import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import CheckIcon from '@material-ui/icons/Check';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { getUser } from 'reducers/UserAccount';
import { axiosIns, axiosIns2 } from 'config/configAxios';
import clsx from 'clsx';
import { Image, Link,Check } from '@material-ui/icons';
// import Edit from '@material-ui/icons/Edit';

const ImageUploadCard = ({
  handleClose,
  user,
  elem,
  component,
  handleChange,
  value
}) => {
  const [files, setFiles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState('');
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: 'image/*',
    multiple: false,
    // maxSize:,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });
  const handleClick = (msg) => {
    setMsg(msg);
    setOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const thumbs = files.map((file) => (
    <Grid item md={3} className="p-2" key={file.name}>
      <div className="p-2 bg-white shadow-xxl border-dark card-box d-flex overflow-hidden rounded-sm">
        <img
          className="img-fluid img-fit-container rounded-sm"
          src={file.preview}
          alt="..."
        />
      </div>
    </Grid>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const handleUpload = async () => {
    // console.log(files);
    axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    const formData = new FormData();
    files.forEach((value) => {
      formData.append('images', value);
    });

    const res = await axiosIns2.post(`/media`, formData);

    if (res.status === 200) {
      handleClick('Image uploaded!');
      // resetForm({});
    }
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={msg}
          action={action}
        />
      </div>
      <DialogContent>
        <div className="p-4">
          <Grid container spacing={6}>
            <Grid item lg={12} justifyContent="center" alignItems="center">
              <div className="dropzone">
                <div
                  {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                  <input {...getInputProps()} />
                  <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                    {isDragAccept && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                          <CheckIcon className="d-50" />
                        </div>
                        <div className="font-size-xs text-success">
                          We're ready to start!
                        </div>
                      </div>
                    )}
                    {isDragReject && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                          <CloseTwoToneIcon className="d-50" />
                        </div>
                        <div className="font-size-xs text-danger">
                          These files are not images!
                        </div>
                      </div>
                    )}
                    {!isDragActive && (
                      <div>
                        <div className="d-90 btn-icon mb-3 hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first">
                          <CloudUploadTwoToneIcon className="d-40" />
                        </div>
                        <div className="font-size-sm">
                          Drag and drop images here
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="card-footer p-3 bg-secondary">
            <div>
              <div className="font-weight-bold mb-3 text-uppercase text-dark font-size-sm text-center">
                Uploaded Files
              </div>
              {thumbs.length <= 0 && (
                <div className="text-first text-center font-size-sm">
                  Uploaded images previews will appear here!
                </div>
              )}
              {thumbs.length > 0 && (
                <div>
                  <Alert severity="success" className="text-center mb-3">
                    You have uploaded <b>{thumbs.length}</b> files!
                  </Alert>
                  <Grid container spacing={0}>
                    {thumbs}
                  </Grid>
                </div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="btn-dark">
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          className="btn btn-primary"
          disabled={files.length === 0}>
          Upload
        </Button>
      </DialogActions>
    </>
  );
};
const EditImage = ({ open, setOpen, msg }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="success"
        onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={msg}
          action={action}
        />
      </div>
    </>
  );
};

const DeleteImage = ({ open, setOpen, isLoading, handleDelete }) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={() => {
        setOpen(false);
      }}>
      <DialogTitle>Delete Image</DialogTitle>
      <DialogContent className="my-2">
        Are you sure you want to delete this image?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            handleDelete();
          }}
          className="btn btn-danger">
          {isLoading ? <CircularProgress /> : 'Delete'}
        </Button>

        <Button className="btn btn-dark" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateImageAlt = ({
  open,
  setOpen,
  isLoading,
  setImage,
  image,
  handleUpdate
}) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={() => {
        setOpen(false);
      }}>
      <DialogTitle>Update Image Alt Text</DialogTitle>
      <DialogContent className="my-2">
        <form noValidate autoComplete="off">
          <Typography
            className="text-primary mb-1"
            variant="body1"
            style={{ fontWeight: 'bold' }}>
            Image alt text
          </Typography>
          <Grid container>
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="Alt text"
              value={image?.alt}
              onChange={(e) => {
                let im = image;
                im.alt = e.target.value;
                // console.log(im, e.target.value);
                setImage({ ...im });
              }}
              variant="outlined"
            />
          </Grid>

          <Typography variant="caption">
            Alt text makes images more accessible to everyone, including people
            with visual impairments
          </Typography>

          {isLoading && <CircularProgress />}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleUpdate();
            setOpen(false);
          }}
          disabled={isLoading}
          className="btn btn-primary">
          {isLoading ? <CircularProgress /> : 'Update'}
        </Button>

        <Button className="btn btn-dark" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ImageList = ({
  user,
  handleClose,
  activeTab,
  elem,
  component,
  handleChange,
  value
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [msg, setMsg] = useState('');
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openUpdateConfirm, setOpenUpdateConfirm] = useState(false);
  const [image, setImage] = useState(selectedImage);
  const [open, setOpen] = useState(false);
  const handleClick = (msg) => {
    setMsg(msg);
    setOpen(true);
  };
  useEffect(() => {
    setImage(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    setIsLoading(true);
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    axiosIns(`/media/`)
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.log(err);
        // setError(true);
      })
      .finally(() => setIsLoading(false));
    setIsLoading(false);

    // eslint-disable-next-line
  }, [activeTab]);

  const handleDelete = async () => {
    setIsLoading(true);
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    axiosIns
      .delete(`/media/${image._id}`)
      .then((res) => {
        let images_list = images.filter((img) => img._id !== image._id);
        setImages([...images_list]);
        setImage(null);

        setIsLoading(false);
        handleClick('Image Deleted!');
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        // setError(true);
      })
      .finally(() => setIsLoading(false));
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    axiosIns
      .put(`/media/${image._id}`, image)
      .then((res) => {
        setImage(res.data);
        let images_list = images.map((img) => {
          if (img._id === image._id) {
            return image;
          } else {
            return img;
          }
        });
        setImages([...images_list]);
        handleClick('Image Alt Updated');
      })
      .catch((err) => {
        console.log(err);
        // setError(true);
      })
      .finally(() => setIsLoading(false));
    setIsLoading(false);
  };
  const handleSelected = (tile) => {
    // console.log('handle Selected');
    setSelectedImage(tile);
  };
  return (
    <>
      {component}
      {elem}
      <UpdateImageAlt
        open={openUpdateConfirm}
        setOpen={setOpenUpdateConfirm}
        isLoading={isLoading}
        image={image}
        setImage={setImage}
        handleUpdate={handleUpdate}
        setIsLoading={setIsLoading}
      />
      <DeleteImage
        open={openDeleteConfirm}
        handleDelete={handleDelete}
        setOpen={setOpenDeleteConfirm}
        isLoading={isLoading}
      />
      <DialogContent className="py-2">
        <Grid container>
          <Grid item xs={12} sm={12} md={9}>
            {isLoading && <CircularProgress />}
            <Grid container className="px-3" spacing={2}>
              {images?.length > 0 &&
                images?.map((tile) => (
                  <Grid item key={tile._id} md={3}>
                    <Card className="bg-light">
                      <CardMedia
                        component={'img'}
                        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${tile.url}`}
                        alt={tile.alt}
                        style={{
                          height: '10rem',
                          objectFit: 'cover'
                        }}
                      />
                      <div className="my-2 d-flex justify-content-center align-items-center">
                        {/* <Button
                          onClick={() => {
                            handleSelected(tile);
                            setOpenUpdateConfirm(true);
                          }}
                          className="btn-info mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                          <Tooltip title="Edit image alt text" arrow={true}>
                            <Edit className="font-size-md font-weight-bold" />
                          </Tooltip>
                        </Button> */}

                        {elem && (
                          <Button
                            onClick={() => {
                              handleSelected(tile);
                              handleChange(
                                `${process.env.REACT_APP_ASSETS_BASE_URL}/${tile.url}`,
                                component,
                                elem
                              );
                              handleClick(
                                `Image Selected: ${process.env.REACT_APP_ASSETS_BASE_URL}/${tile.alt}`
                              );
                              handleClose();
                            }}
                            className="btn-success mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                            <Tooltip title="select image" arrow={true}>
                              <Check className="font-size-md font-weight-bold" />
                            </Tooltip>
                          </Button>
                        )}
                        <Button
                          onClick={() => {
                            handleSelected(tile);
                            navigator.clipboard.writeText(
                              `${process.env.REACT_APP_ASSETS_BASE_URL}/${tile.url}`
                            );
                            handleClick(
                              `Copied Image Url to clipboard: ${process.env.REACT_APP_ASSETS_BASE_URL}/${tile.alt}`
                            );
                          }}
                          className="btn-first mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                          <Tooltip
                            title="copy image url to clipboard"
                            arrow={true}>
                            <Link className="font-size-md font-weight-bold" />
                          </Tooltip>
                        </Button>
                        <Button
                          disabled={isLoading}
                          onClick={() => {
                            handleSelected(tile);
                            setOpenDeleteConfirm(true);
                          }}
                          className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                          <Tooltip title="Delete image" arrow={true}>
                            <ClearIcon className="font-size-md font-weight-bold" />
                          </Tooltip>
                        </Button>
                      </div>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <EditImage
              tile={selectedImage}
              images={images}
              setImages={setImages}
              user={user}
              handleDelete={handleDelete}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              open={open}
              setOpen={setOpen}
              handleClick={handleClick}
              msg={msg}
              image={image}
              handleUpdate={handleUpdate}
            />

            <Card className="bg-secondary text-center mt-2">
              <CardContent>
                <p style={{ textAlign: 'left' }}>
                  <strong>Instructions:</strong>{' '}
                </p>
                <ol style={{ textAlign: 'left' }}>
                  <li>Click on copy. Image will be copied to clipboard</li>
                  <li>
                    Place your cursor in editor where you want to insert image
                  </li>
                  <li>Press Ctrl + C. Image will be pasted in the editor</li>
                </ol>
                <p>
                  or watch detailed instructions{' '}
                  <strong>
                    <a
                      className=" px-0 btn btn-link btn-sm btn-link-primary"
                      href="https://www.loom.com/share/c800c51fa5b84273b39094d4a1333ad5">
                      here
                    </a>
                  </strong>
                </p>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="btn btn-dark">
          Close{' '}
        </Button>
      </DialogActions>
    </>
  );
};
const MediaUploadDialog = ({ user, elem, component, handleChange, value }) => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('0');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ textAlign: 'right' }} className="mb-3">
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <Image className="mr-2" />
        Media Gallery
      </Button>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Media Gallery</DialogTitle>
        <DialogContent>
          <List className="nav-tabs nav-tabs-primary d-flex">
            <ListItem
              button
              disableRipple
              selected={activeTab === '0'}
              onClick={() => {
                toggle('0');
              }}>
              Image List
            </ListItem>
            <ListItem
              button
              disableRipple
              selected={activeTab === '1'}
              onClick={() => {
                toggle('1');
              }}>
              Upload Media
            </ListItem>
          </List>

          <div
            className={clsx('tab-item-wrapper py-3', {
              active: activeTab === '0'
            })}
            index={0}>
            <ImageList
              elem={elem}
              component={component}
              handleChange={handleChange}
              value
              user={user}
              handleClose={handleClose}
              activeTab={activeTab}
            />
          </div>
          <div
            className={clsx('tab-item-wrapper', {
              active: activeTab === '1'
            })}
            index={1}>
            <ImageUploadCard handleClose={handleClose} user={user} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state)
});
export default connect(mapStateToProps)(MediaUploadDialog);
