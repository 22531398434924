import { Grid, Typography } from "@material-ui/core";
import React from "react";
import QrCodeScannerIcon from "@material-ui/icons/ScannerOutlined";

const Icons = () => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className="py-4"
    >
      <Grid item xs={2}>
        <QrCodeScannerIcon className="fs-2" />
      </Grid>
      <Grid item xs={4}>
        <Typography fontSize={13} fontWeight="bold">
          Scan or tap Barcode
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Icons;
