import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import hero1 from 'assets/images/hero-bg/hero-1.jpg';
import Footer from 'layout-components/Overview/Overview6';

import {
  Grid,
  Button,
  TextField,
  Snackbar,
  Typography,
  Card,
  Container
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  getIsAuthenticated,
  getVerificationStatus
} from '../../reducers/UserAccount';
import { axiosIns } from '../../config/configAxios';
import ReCAPTCHA from 'react-google-recaptcha';
import CommerceHeader from 'components/Homepage/Homepage1/CommerceHeader';
const ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
});

const Register = ({ isAuthenticated, isVerified }) => {
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [RecaptchError, setRecaptchError] = useState(false);

  const [user, setUser] = useState(null);
  const recaptchaRef = React.useRef();
  const transformErrors = (res) => {
    let errors = {};
    try {
      for (let i = 0; i < res.length; i++) {
        const el = res[i];
        errors[el.param] = el.msg;
        if (el.param === 'token') {
          setRecaptchError(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
    return errors;
  };
  // if(registerSuccess && isVerified=="pending" || isVerified=="inactive" ) return <Redirect to="/verifyaccount" />;

  if (isAuthenticated && isVerified === 'active')
    return <Redirect to={`${process.env.REACT_APP_D_PREFIX}`} />;

  if (registerSuccess) return <Redirect to={`/verifyaccount/${user._id}`} />;

  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper bg-white">
        <div className="header-top-section">
          <div className=" bg-night-sky sticky-top">
            <Container className="header-top-section">
              <CommerceHeader />
            </Container>
          </div>
        </div>
      </div>
      <div className="hero-wrapper bg-composed-wrapper bg-midnight-bloom">
        <div className="hero-wrapper--content">
          <div
            className="bg-composed-wrapper--image"
            style={{ backgroundImage: 'url(' + hero1 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-5" />
          <div className="bg-composed-wrapper--content">
            <Container className="z-over">
              <Container className="text-white py-5">
                <Grid container justify="center" className="pb-5">
                  <Grid
                    item
                    lg={6}
                    className="d-flex align-items-center justify-content-center pr-0 pr-xl-3">
                    <div className="text-center text-lg-left">
                      <h2 className="display-3 font-weight-bold">
                        Turn your business into a money making machine.
                      </h2>
                      <p className="font-size-xl py-3 text-white-50">
                        Get an online store that makes your business stand out
                        and keeps customers coming back.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    className="d-flex align-items-center justify-content-center">
                    <Card className="rounded-sm mt-5 mt-lg-0 p-3 bg-white-10">
                      <Card className="rounded-sm  card-box modal-content font-size-sm p-4">
                        <div className="py-4">
                          <h1 className="display-4 font-weight-bold text-center">
                            Sign up
                          </h1>
                          <Formik
                            initialValues={{
                              email: '',
                              password: '',
                              firstName: '',
                              lastName: ''
                            }}
                            validationSchema={ValidationSchema}
                            onSubmit={(
                              values,
                              { setSubmitting, setErrors, setStatus, resetForm }
                            ) => {
                              if (values.token) {
                                axiosIns
                                  .post('/signup', values)
                                  .then((res) => {
                                    if (res.status === 201) {
                                      resetForm({});
                                      setUser(res.data);
                                      setStatus({ success: true });
                                      recaptchaRef.current.reset();
                                    }
                                  })
                                  .then(() => setRegisterSuccess(true))
                                  .catch((err) => {
                                    if (err.response.status === 400) {
                                      recaptchaRef.current.reset();
                                      setErrors(
                                        transformErrors(
                                          err.response.data.errors
                                        )
                                      );
                                    } else if (err.status === 500)
                                      setOpenSnackbar(true);
                                    setStatus({ success: false });
                                    setSubmitting(false);
                                  });
                              } else {
                                recaptchaRef.current.reset();
                                setRecaptchError(true);
                                setStatus({ success: false });
                                setSubmitting(false);
                              }
                            }}>
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              isSubmitting
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                  <label className="font-weight-bold mb-2">
                                    Email address
                                  </label>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    placeholder="yourname@yourmail.com"
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={errors.email && touched.email}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label className="font-weight-bold mb-2">
                                    Password
                                  </label>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    placeholder="Enter your password"
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    helperText={
                                      touched.password && errors.password
                                    }
                                    error={errors.password && touched.password}
                                  />
                                </div>

                                <Grid container spacing={6}>
                                  <Grid item md={6}>
                                    <div>
                                      <label className="font-weight-bold mb-2">
                                        First name
                                      </label>
                                      <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        placeholder="Enter your first name"
                                        type="text"
                                        name="firstName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                        helperText={
                                          touched.firstName && errors.firstName
                                        }
                                        error={
                                          errors.firstName && touched.firstName
                                        }
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item md={6}>
                                    <div>
                                      <label className="font-weight-bold mb-2">
                                        Last name
                                      </label>
                                      <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        placeholder="Enter your last name"
                                        type="text"
                                        name="lastName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                        helperText={
                                          touched.lastName && errors.lastName
                                        }
                                        error={
                                          errors.lastName && touched.lastName
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={6}>
                                  <Grid item md={6}>
                                    <ReCAPTCHA
                                      ref={recaptchaRef}
                                      sitekey={
                                        process.env
                                          .REACT_APP_RECAPTCHA_CLIENT_KEY
                                      }
                                      onChange={(value) => {
                                        setFieldValue('token', value);
                                        setRecaptchError(false);
                                      }}
                                      onErrored={(value) =>
                                        recaptchaRef.current.reset()
                                      }
                                    />
                                    {RecaptchError && (
                                      <Typography
                                        color="error"
                                        variant="caption"
                                        component="p">
                                        Recaptcha Failed
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                                <div className="my-4">
                                  By clicking the{' '}
                                  <strong>Create account</strong> button below
                                  you agree to our{' '}
                                  <a href="/terms" target="__blank" className='underline' style={{fontStyle:'underline', textDecoration:"underline"}}>
                                    terms  of service
                                  </a>{' '}
                                 and  <a href="/terms" target="__blank" className='underline' style={{fontStyle:'underline', textDecoration:"underline"}}>
                                 privacy statement
                                  </a>.
                                </div>

                                <Button
                                  size="large"
                                  fullWidth
                                  disabled={isSubmitting}
                                  type="submit"
                                  className="text-uppercase font-weight-bold font-size-sm btn-primary">
                                  Create account
                                </Button>
                              </form>
                            )}
                          </Formik>

                          <div className="text-center mb-4">
                            <div className="text-center pt-4 text-black-50">
                              Already have an account?{' '}
                              <Link to="/login">Log in</Link>
                            </div>
                          </div>
                          <Snackbar
                            open={openSnackbar}
                            autoHideDuration={3000}
                            onClose={() => setOpenSnackbar(false)}>
                            <Alert
                              onClose={() => setOpenSnackbar(false)}
                              severity="error">
                              Oops! Something went wrong.
                            </Alert>
                          </Snackbar>
                        </div>
                      </Card>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </Container>
            <div
              className="shape-container-top-1 z-below 	d-none d-lg-block"
              style={{ marginTop: '-320px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  width="4391"
                  height="1176"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),

  isVerified: getVerificationStatus(state)
});

export default connect(mapStateToProps)(Register);
