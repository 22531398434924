/*
 *  ACTIONS TYPES
 * ------------------------------------
 */
//Headings
const CHANGE_HEADINGS = 'CHANGE_HEADINGS';

//Header

const CHANGE_HEADER = 'CHANGE_HEADER';

//popular categories

const CHANGE_POPULAR_CATEGORIES = 'CHANGE_POPULAR_CATEGORIES';

//Featured Products

const CHANGE_FEATURED_PRODUCTS = 'CHANGE_FEATURED_PRODUCTS';

//Icons

const CHANGE_ICONS = 'CHANGE_ICONS';

//Subscribe

const CHANGE_SUBSCRIBE = 'CHANGE_SUBSCRIBE';

//Testimonals

const CHANGE_TESTIMONALS = 'CHANGE_TESTIMONALS';

//footer

const CHANGE_FOOTER = 'CHANGE_FOOTER';

//userIsStyling

const STYLING_IS_CHANGED = 'STYLING_IS_CHANGED';

//showStarters

const CHANGE_SHOW_STARTERS = 'CHANGE_SHOW_STARTERS';

//choosed design

const CHANGE_CHOOSED_DESIGN = 'CHANGE_CHOOSED_DESIGN';

//selected section

const CHANGE_SELECTED_SECTION = 'CHANGE_SELECTED_SECTION';

//Load design
const LOAD_DESIGN = 'LOAD_DESIGN';

/*-----------------------Intitial State---------------------------------- */
const headings = {
  title__size: '',
  small__title__Size: ''
};

const header = {
  announcementBar__bgColor: '',
  announcementBar__textColor: '',
  announcementBar__fontSize: '',
  header__bgColor: '',
  header__iconColor: '',
  header__formBgColor: '',
  header__formBorderColor: '',
  menu__bgColor: '',
  menu__textColor: '',
  menu__fontSize: '',
  infos__bgColor: '',
  infos__textColor: '',
  infos__iconsColor: ''
};

const popularCategories = {
  bgColor: '',
  title__color: '',
  text__color: '',
  card__bgColor: '',
  small__title__color: ''
};

const featuredProduct = {
  bgColor: '',
  title__color: '',
  small__title__color: '',
  buttons__fontSize: '',
  buttons__textColor: '',
  buttons__hoverColor: '',
  subTitle__textColor: '',
  subTitle__fontSize: '',
  cards__bgColor: '',
  cards__textColor: '',
  cards__priceColor: '',
  cards__iconsColor: '',
  cards__fontSize: '',
  cards__priceSize: ''
};

const icons = {
  bgColor: '',
  icons__color: '',
  icons__fontSize: '',
  title__color: '',
  title__size: '',
  subtitle__color: '',
  subtitle__fontSize: ''
};

const subscribe = {
  bgColor: '',
  title__color: '',
  //  title__fontSize: "",
  text__color: '',
  text__fontSize: '',
  form__bgColor: '',
  form__textColor: '',
  form__Button__bgColor: '',
  form__Button__textColor: '',
  form__Button__hoverColor: ''
};

const testimonals = {
  bgColor: '',
  small__title__color: '',
  title__color: '',
  review__color: '',
  name__color: ''
};

const footer = {
  bgColor: '',
  separatorColor: '',
  title__color: '',
  title__fontSize: '',
  text__color: '',
  text__fontSize: '',
  icons__color: '',
  icons__fontSize: ''
};

const stylingIsChanged = false;

const showStarters = false;

const choosedDesign = '';

const selectedSection = '';

/*
-------------------------------------Actions-------------------------------------------------
*/
export const changeHeadings = (payload) => ({
  type: CHANGE_HEADINGS,
  payload
});

/*
-----------------------------------Header--------------------------------------------------
 */

export const changeHeader = (payload) => ({
  type: CHANGE_HEADER,
  payload
});

/*
--------------------------------Popular Categories----------------------------------------
*/

export const changePopularCategories = (payload) => ({
  type: CHANGE_POPULAR_CATEGORIES,
  payload
});

/*
-----------------------------------FEATURED PRODUCTS--------------------------------------- 
*/

export const changeFeaturedProducts = (payload) => ({
  type: CHANGE_FEATURED_PRODUCTS,
  payload
});

/*
------------------------------------------ICONS-----------------------------------------
*/

export const changeIcons = (payload) => ({
  type: CHANGE_ICONS,
  payload
});

/*
------------------------------------------SUBSCRIBE------------------------------------
 */

export const changeSubscribe = (payload) => ({
  type: CHANGE_SUBSCRIBE,
  payload
});

/*
-----------------------------------------TESTIMONALS-------------------------------
*/

export const changeTestimonals = (payload) => ({
  type: CHANGE_TESTIMONALS,
  payload
});

/*
------------------------------------------Footer-------------------------------------
 */

export const changeFooter = (payload) => ({
  type: CHANGE_FOOTER,
  payload
});

/**---------------------------------------------------designIsChanged----------------------------------------------------------
 * This will variable will be set to true once you started to customize your store
 * So we can check to see if the user start customizing the styling ==>why?
 * Because we will need to check it before showing the popup warning (that saying he will lose the styling)
 */
export const changeStylingIsChanged = (payload) => ({
  type: STYLING_IS_CHANGED,
  payload
});

/*-------------------------------------------------------Show Starters---------------------------------------------------------*/

export const changeShowStarters = (payload) => ({
  type: CHANGE_SHOW_STARTERS,
  payload
});

/**----------------------------------------------------------choosed Dessign-------------------------------------------------- */

export const changeChoosedDesign = (payload) => ({
  type: CHANGE_CHOOSED_DESIGN,
  payload
});
/*
/*----------------------------------------------------------Selected section--------------------------------------------*/
export const changeSelectedSection = (payload) => ({
  type: CHANGE_SELECTED_SECTION,
  payload
});
export const loadDesign = (payload) => ({
  type: LOAD_DESIGN,
  payload
});
/*--------------------------------------------------------------REDUCER--------------------------------------------------------------------
 */

// const persistedState = localStorage.getItem('landingPageStore')
//   ? JSON.parse(localStorage.getItem('landingPageStore'))
//   : {
//       //Here we will need to fetch the json file of the client who will contain his styling
//       header,
//       popularCategories,
//       featuredProduct,
//       icons,
//       subscribe,
//       testimonals,
//       footer,
//       headings,
//       stylingIsChanged,
//       showStarters,
//       choosedDesign,
//       selectedSection
//     };

export default function reducer(
  state = {
    header,
    popularCategories,
    featuredProduct,
    icons,
    subscribe,
    testimonals,
    footer,
    headings,
    stylingIsChanged,
    showStarters,
    choosedDesign,
    selectedSection
  },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_HEADER:
      const updateHeader = {
        ...state.header,
        [payload.item]: payload.value
      };
      return {
        ...state,
        header: updateHeader
      };

    case CHANGE_POPULAR_CATEGORIES:
      const updatePopular = {
        ...state.popularCategories,
        [payload.item]: payload.value
      };
      return {
        ...state,
        popularCategories: updatePopular
      };

    case CHANGE_FEATURED_PRODUCTS:
      const updateFeaturedProduct = {
        ...state.featuredProduct,
        [payload.item]: payload.value
      };
      return {
        ...state,
        featuredProduct: updateFeaturedProduct
      };

    case CHANGE_ICONS:
      const updateIcons = {
        ...state.icons,
        [payload.item]: payload.value
      };
      return {
        ...state,
        icons: updateIcons
      };

    case CHANGE_SUBSCRIBE:
      const updateSubscribe = {
        ...state.subscribe,
        [payload.item]: payload.value
      };
      return {
        ...state,
        subscribe: updateSubscribe
      };

    case CHANGE_TESTIMONALS:
      const updateTestimonals = {
        ...state.testimonals,
        [payload.item]: payload.value
      };
      return {
        ...state,
        testimonals: updateTestimonals
      };

    case CHANGE_FOOTER:
      const updateFooter = {
        ...state.footer,
        [payload.item]: payload.value
      };

      return {
        ...state,
        footer: updateFooter
      };

    case CHANGE_HEADINGS:
      const updateHeadings = {
        ...state.headings,
        [payload.item]: payload.value
      };

      return {
        ...state,
        headings: updateHeadings
      };

    case STYLING_IS_CHANGED:
      return {
        ...state,
        stylingIsChanged: payload
      };

    case CHANGE_SHOW_STARTERS:
      return {
        ...state,
        showStarters: payload
      };

    case CHANGE_CHOOSED_DESIGN:
      return {
        ...state,
        choosedDesign: payload
      };

    case CHANGE_SELECTED_SECTION:
      return {
        ...state,
        selectedSection: payload
      };
    case LOAD_DESIGN:
      let { themeStyleSheetJson, choosedDesign } = payload;
      console.log('Load Design', themeStyleSheetJson);
      if (themeStyleSheetJson) {
        themeStyleSheetJson = JSON.parse(themeStyleSheetJson);
        return {
          ...state,
          ...themeStyleSheetJson,
          choosedDesign,
          showStarters: choosedDesign ? true : false
        };
      } else {
        return {
          ...state,
          choosedDesign,
          showStarters: choosedDesign ? true : false
        };
      }

    default:
      return state;
  }
}

/*
----------------------------------Selectors-------------------------------
*/

export const headerStyling = (state) => state.landingPage.header;

export const popularCategoriesStyling = (state) =>
  state.landingPage.popularCategories;

export const featuredProductStyling = (state) =>
  state.landingPage.featuredProduct;

export const iconsStyling = (state) => state.landingPage.icons;

export const subscribeStyling = (state) => state.landingPage.subscribe;

export const testimonalsStyling = (state) => state.landingPage.testimonals;

export const footerStyling = (state) => state.landingPage.footer;

export const headingsStyling = (state) => state.landingPage.headings;

export const stylingChanged = (state) => state.landingPage.stylingIsChanged;

export const showStarterDesigns = (state) => state.landingPage.showStarters;

export const chosenDesign = (state) => state.landingPage.choosedDesign;

export const chosenSection = (state) => state.landingPage.selectedSection;
