import React from 'react';
import { connect } from 'react-redux';

import { Table, Button } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import { deleteSubCategory } from 'reducers/AllCategories';

import AddOrEditSubCategory from '../AddOrEditSubCategory';

const SubCategory = ({ item, deleteSC }) => {
  const { _id, name, productsCount, category } = item;
  return (
    <tr>
      <td>{name}</td>
      <td className="text-center">{productsCount}</td>
      <td> </td>
      <td className="text-center">
        <AddOrEditSubCategory isEdit={true} subcategory={item} cId={category} />

        <Button
          size="small"
          className="btn-danger hover-scale-sm d-30 p-0 mx-1 btn-icon"
          onClick={() => deleteSC(category, _id)}>
          <DeleteIcon className="font-size-lg" />
        </Button>
      </td>
    </tr>
  );
};

const SubCategories = (props) => {
  const { subCategories, deleteSC } = props;
  return (
    <div className="table-responsive-md">
      <Table className="table table-borderless mb-0">
        <thead>
          <tr>
            <th colSpan={4}>sub categories</th>
          </tr>
        </thead>
        <tbody>
          {subCategories.map((item) => (
            <SubCategory key={item._id} item={item} deleteSC={deleteSC} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteSC: (cId, scId) => dispatch(deleteSubCategory(cId, scId))
});

export default connect(null, mapDispatchToProps)(SubCategories);
