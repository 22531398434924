import React, { useState } from 'react';
// stripe
import { CardElement } from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';
import { DialogActions, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { axiosIns } from '../../../config/configAxios';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { PulseLoader } from 'react-spinners';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#159bef'
      },
      border: '1px solid #e1e1e1',
      borderRadius: '5px',
      padding: '18.5px 14px'
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

export default function CardInput({
  user,
  handleClose2,
  loadUser,
  retryProp,
  retryFunctionHandle,
  successHandle,
  successMsgHandle,
  invoices
}) {
  // const classes = useStyles();
  // State
  const [email, setEmail] = useState(invoices[0]?.billing_details?.email || '');
  const [address, setAddress] = useState(
    invoices[0]?.billing_details?.address.line1 || ''
  );
  const [city, setCity] = useState(
    invoices[0]?.billing_details?.address.city || ''
  );
  const [state, setState] = useState(
    invoices[0]?.billing_details?.address.state || ''
  );

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmitPay = async (event) => {
    setLoading(true);
    setErrors(null);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    );

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: email,
        address: {
          line1: address,
          city: city,
          state: state
        }
      }
    });
    if (error) {
      setLoading(false);
      setErrors(error.message);
      console.log('[createPaymentMethod error]', error);
    } else {
      const paymentMethodId = paymentMethod.id;
      if (
        latestInvoicePaymentIntentStatus === 'requires_payment_method' &&
        retryProp === true
      ) {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem('latestInvoiceId');
        retryFunctionHandle({
          paymentMethodId,
          invoiceId
        });
      } else {
        setErrors(null);
        axiosIns.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${user.token}`;
        try {
          if (invoices.length === 0) {
            await axiosIns.put('/users/addPayment', {
              paymentMethodId: paymentMethodId,
              city: city,
              address: address,
              state: state
            });
            setLoading(false);
            loadUser(user.token);

            handleClose2();
            successMsgHandle('Card Added Successfully!');
            successHandle(true);
          } else {
            await axiosIns
              .put('/users/addPayment', {
                paymentMethodId: paymentMethodId,
                city: city,
                address: address,
                state: state
              })
              .then((response) => {
                setLoading(false);
                handleClose2();
                loadUser(user.token);
              })
              .catch((error) => {
                handleClose2();
                setLoading(false);
                console.log(error);
              });
          }
        } catch (e) {
          console.log(e);
        }

        // Create the subscription
        // createSubscription({ customerId, paymentMethodId, priceId });
      }
    }
  };

  // function createSubscription({ customerId, paymentMethodId, priceId }) {
  //   return (
  //     fetch('/create-subscription', {
  //       method: 'post',
  //       headers: {
  //         'Content-type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         customerId: customerId,
  //         paymentMethodId: paymentMethodId,
  //         priceId: priceId,
  //       }),
  //     })
  //       .then((response) => {
  //         return response.json();
  //       })
  //       // If the card is declined, display an error to the user.
  //       .then((result) => {
  //         if (result.error) {
  //           // The card had an error when trying to attach it to a customer.
  //           throw result;
  //         }
  //         return result;
  //       })
  //       // Normalize the result to contain the object returned by Stripe.
  //       // Add the additional details we need.
  //       .then((result) => {
  //         return {
  //           paymentMethodId: paymentMethodId,
  //           priceId: priceId,
  //           subscription: result,
  //         };
  //       })
  //       // Some payment methods require a customer to be on session
  //       // to complete the payment process. Check the status of the
  //       // payment intent to handle these actions.
  //       .then(handlePaymentThatRequiresCustomerAction)
  //       // If attaching this card to a Customer object succeeds,
  //       // but attempts to charge the customer fail, you
  //       // get a requires_payment_method error.
  //       .then(handleRequiresPaymentMethod)
  //       // No more actions required. Provision your service for the user.
  //       .then(onSubscriptionComplete)
  //       .catch((error) => {
  //         // An error has happened. Display the failure to the user here.
  //         // We utilize the HTML element we created.
  //         showCardError(error);
  //       })
  //   );
  // }
  // function retryInvoiceWithNewPaymentMethod({
  //   customerId,
  //   paymentMethodId,
  //   invoiceId,
  //   priceId
  // }) {
  //   return (
  //     fetch('/retry-invoice', {
  //       method: 'post',
  //       headers: {
  //         'Content-type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         customerId: customerId,
  //         paymentMethodId: paymentMethodId,
  //         invoiceId: invoiceId,
  //       }),
  //     })
  //       .then((response) => {
  //         return response.json();
  //       })
  //       // If the card is declined, display an error to the user.
  //       .then((result) => {
  //         if (result.error) {
  //           // The card had an error when trying to attach it to a customer.
  //           throw result;
  //         }
  //         return result;
  //       })
  //       // Normalize the result to contain the object returned by Stripe.
  //       // Add the additional details we need.
  //       .then((result) => {
  //         return {
  //           // Use the Stripe 'object' property on the
  //           // returned result to understand what object is returned.
  //           invoice: result,
  //           paymentMethodId: paymentMethodId,
  //           priceId: priceId,
  //           isRetry: true,
  //         };
  //       })
  //       // Some payment methods require a customer to be on session
  //       // to complete the payment process. Check the status of the
  //       // payment intent to handle these actions.
  //       .then(handlePaymentThatRequiresCustomerAction)
  //       // No more actions required. Provision your service for the user.
  //       .then(onSubscriptionComplete)
  //       .catch((error) => {
  //         // An error has happened. Display the failure to the user here.
  //         // We utilize the HTML element we created.
  //         displayError(error);
  //       })
  //   );
  // }
  return (
    <>
      {errors !== null && <Alert severity="error">{errors}</Alert>}
      <TextField
        id="outlined-email-input"
        size="small"
        helperText={`Email you'll recive updates and receipts on`}
        margin="normal"
        variant="outlined"
        type="email"
        label="Email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
      />
      <TextField
        label="Billing Address"
        margin="normal"
        variant="outlined"
        type="text"
        required
        // value={invoices[0].}
        size="small"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        fullWidth
      />
      <div className="d-flex mb-3">
        <TextField
          label="City"
          margin="normal"
          variant="outlined"
          type="text"
          required
          // value={invoices[0].}
          value={city}
          onChange={(e) => setCity(e.target.value)}
          size="small"
        />
        <TextField
          className="ml-4"
          label="State"
          margin="normal"
          variant="outlined"
          type="text"
          required
          // value={invoices[0].}
          value={state}
          onChange={(e) => setState(e.target.value)}
          size="small"
        />
      </div>
      {/* <Elements  stripe={stripePromise}> */}

      <div className="mb-5">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      {/* </Elements> */}
      <DialogActions>
        <Button className="btn-primary" onClick={handleSubmitPay}>
          {loading ? (
            <PulseLoader color={'var(--primary)'} loading={true} />
          ) : invoices?.length > 0 ? (
            'Update Card'
          ) : (
            'Add Card'
          )}
        </Button>
        <Button onClick={handleClose2} className="btn-outline-dark">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}
