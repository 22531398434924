import React, { useState, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import {
  Button,
  Dialog,
  List,
  ListItem,
  Table,
  CircularProgress
} from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';
import ClearIcon from '@material-ui/icons/Clear';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import CheckIcon from '@material-ui/icons/Check';

const ImportProducts = ({ bulkWriteProducts }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState('init'); // init, uploaded, updating

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(`Accepted files: ${acceptedFiles.length}`);

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          const data = reader.result;
          const rows = data.split('\n');
          let items = [];

          rows.slice(1).forEach((row) => {
            const temp = row.split(',');
            if (temp[0]) {
              items.push({
                _id: temp[0] || '',
                title: temp[1] || '',
                barcode: temp[2] || '',
                asin: temp[3] || '',
                sku: temp[4] || '',
                cl_sku: temp[5] || '',
                manufacturer: temp[6] || '',
                brand: temp[7] || '',
                price: temp[8] || '',
                oldPrice: temp[9] || '',
                condition: temp[10] || 'New',
                quantity: temp[11] || 1,
                customField: temp[12] || '',
                isShippable:
                  (temp[13].toLowerCase() !== 'false\r' ? true : false) ||
                  false,
                isDeliverable:
                  (temp[14].toLowerCase() !== 'false\r' ? true : false) ||
                  false,
                status:
                  temp[15]?.trim()?.toLowerCase() === 'ready'
                    ? 'Ready'
                    : temp[15]?.trim()?.toLowerCase() === 'published'
                    ? 'Published'
                    : 'Unpublished' || 'Unpublished'
              });
            }
          });

          setProducts(items);
        };
      });
    },
    [setProducts]
  );

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({ onDrop, accept: '.csv', multiple: false });

  const files = acceptedFiles.map((file) => (
    <ListItem
      className="font-size-sm px-3 pb-2 text-primary d-flex justify-content-between align-items-center"
      key={file.path}>
      <span>{file.path}</span>{' '}
      <span className="badge badge-pill bg-neutral-warning text-warning">
        {file.size} bytes
      </span>
    </ListItem>
  ));

  const onSave = () => {
    setStatus('updating');
    bulkWriteProducts(products);

    setTimeout(() => {
      toggleModal();
      setStatus('init');
      setProducts([]);
    }, 2000);
  };

  const onCancel = () => {
    toggleModal();
    setStatus('init');
    setProducts([]);
  };

  return (
    <>
      <Button
        size="small"
        className="btn-neutral-secondary"
        onClick={toggleModal}>
        <span className="btn-wrapper--icon">
          <GetAppIcon color="inherit" />
        </span>
        <span className="btn-wrapper--label">Import</span>
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={onCancel}
            className="btn-neutral-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>
        </div>

        <div className="p-3">
          <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
            Import products by CSV
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="p-3 text-black-70">
          {status === 'init' ? (
            <>
              <div className="mb-3">
                Only the CSV file export from carlauncher.com dashboard is
                accepted.
                <p className="text-danger">
                  Note: Please do not change cl_sku field when importing
                  products it'll cause duplicates for new products
                </p>
              </div>

              <div className="dropzone">
                <div
                  {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                  <input {...getInputProps()} />
                  <div className="dropzone-inner-wrapper">
                    {isDragAccept && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                          <CheckIcon className="d-50" />
                        </div>
                        <div className="font-size-sm text-success">
                          All files will be uploaded!
                        </div>
                      </div>
                    )}
                    {isDragReject && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                          <CloseTwoToneIcon className="d-50" />
                        </div>
                        <div className="font-size-sm text-danger">
                          Some files will be rejected!
                        </div>
                      </div>
                    )}
                    {!isDragActive && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                          <CloudUploadTwoToneIcon className="d-50" />
                        </div>
                        <div className="font-size-sm">
                          Drag and drop files here{' '}
                          <span className="font-size-xs text-dark">(csv)</span>
                        </div>
                      </div>
                    )}

                    <small className="py-2 text-black-50">or</small>

                    <div>
                      <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                        <span className="px-2">Browse Files</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="font-weight-bold my-4 text-uppercase text-dark font-size-sm text-center">
                  Uploaded Files
                </div>
                {files.length <= 0 && (
                  <div className="text-info text-center font-size-sm">
                    Uploaded files will appear here!
                  </div>
                )}
                {files.length > 0 && (
                  <List className="font-size-sm">{files}</List>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="mb-3">
                Here is the preview of products info. Take a look and make sure
                everything is in right place.
              </div>
              <div
                className="table-responsive-md"
                style={{ overflowX: 'scroll' }}>
                <Table className="table table-sm table-bordered text-nowrap mb-4">
                  <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                    <tr>
                      <th className="text-left px-2">Product ID</th>
                      <th className="text-left px-2">Title</th>
                      <th className="text-left px-2">Barcode</th>
                      <th className="text-left px-2">ASIN</th>
                      <th className="text-left px-2">SKU</th>
                      <th className="text-left px-2">CL SKU</th>
                      <th className="text-left px-2">Manufacturer</th>
                      <th className="text-left px-2">Brand</th>
                      <th className="text-left px-2">Price</th>
                      <th className="text-left px-2">Compare at price</th>
                      <th className="text-left px-2">Condition</th>
                      <th className="text-left px-2">Quantity</th>
                      <th className="text-left px-2">CustomField</th>
                      <th className="text-left px-2">isShippable</th>
                      <th className="text-left px-2">isDeliverable</th>
                      <th className="text-left px-2">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((p) => (
                      <tr key={p._id}>
                        <td className="text-left px-2">{p._id}</td>
                        <td className="text-left px-2">
                          {p.title.length > 30
                            ? `${p.title.slice(0, 30)}...`
                            : p.title}
                        </td>
                        <td className="text-left px-2">{p.barcode}</td>
                        <td className="text-left px-2">{p.asin}</td>
                        <td className="text-left px-2">{p.sku}</td>
                        <td className="text-left px-2">{p.cl_sku}</td>
                        <td className="text-left px-2">{p.manufacturer}</td>
                        <td className="text-left px-2">{p.brand}</td>
                        <td className="text-left px-2">{p.price}</td>
                        <td className="text-left px-2">{p.oldPrice}</td>
                        <td className="text-left px-2">{p.condition}</td>
                        <td className="text-left px-2">{p.quantity}</td>
                        <td className="text-left px-2">{p.customField}</td>
                        <td className="text-left px-2">
                          {p.isShippable ? 'true' : 'false'}
                        </td>
                        <td className="text-left px-2">
                          {p.isDeliverable ? 'true' : 'false'}
                        </td>{' '}
                        <td className="text-left px-2">{p.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="p-3 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button onClick={onCancel} className="btn-neutral font-size-sm mr-2">
            Cancel
          </Button>
          {status === 'init' ? (
            <Button
              className="btn-primary font-weight-bold"
              onClick={() => setStatus('uploaded')}>
              Upload and continue
            </Button>
          ) : (
            <>
              <Button
                className="btn-dark font-weight-bold mr-2"
                onClick={() => {
                  setStatus('init');
                  setProducts([]);
                }}>
                Re-upload
              </Button>
              <div style={{ position: 'relative' }}>
                <Button
                  className="btn-success font-weight-bold"
                  onClick={onSave}
                  disabled={status === 'updating'}>
                  Save now
                </Button>
                {status === 'updating' && (
                  <CircularProgress
                    size={24}
                    thickness={5}
                    style={{
                      color: '#1bc943',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default ImportProducts;
