import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { connect } from 'react-redux';
import { getUser } from 'reducers/UserAccount';
import { axiosIns } from 'config/configAxios';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Delete } from '@material-ui/icons';

const CustomDesign = ({ user }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isStandardActivated, setIsStandardActivated] = useState(false);
  const [isStandardSelected, setIsStandardSelected] = useState(false);
  const [themes, setThemes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const history = useHistory();

  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const fetchThemes = useCallback(async () => {
    setIsLoading(true);
    setIsStandardSelected(true);
    try {
      axiosIns.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${user.token}`;
      const result = await axiosIns.get('/design');
      // if (result.data?.length > 0) {
      //   setSelectedItem(0);
      // }
      const isStandard = result.data.every((theme) => !theme.isActive);
      if (isStandard) {
        setIsStandardActivated(true);
      }
      setThemes(result.data);
    } catch (err) {
      setError(err.message || 'Unexpected Error!');
    } finally {
      setIsLoading(false);
    }
  }, [user.token]);

  useEffect(() => {
    fetchThemes();
  }, [fetchThemes]);
  const menuClicked = (index) => {
    setIsStandardSelected(false);
    setSelectedItem(index);
  };
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  const handleDelete = (id) => {
    if (themes.length > 0) {
      setThemes(themes.filter((theme, index) => index !== selectedItem));
    }
  };
  const handleAdd = () => {
    let newThemes = themes;
    let item = {
      id: `${Number(themes.length) + 1}`,
      name: 'theme' + (Number(themes.length) + 1),
      style: '',
      isActive: false,
      isStandard: false
    };
    newThemes.push(item);
    console.log(newThemes);
    setThemes([...newThemes]);
    setSelectedItem(themes.length - 1);
    setIsStandardSelected(false);
  };
  const activateDesign = async (id) => {
    const item = themes[selectedItem];
    setIsLoading(true);
    try {
      axiosIns.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${user.token}`;
      const result = await axiosIns.put('/design/' + item._id, {
        isActive: !item.isActive
      });
      console.log(result.data);
      let allThemes = themes;
      allThemes[selectedItem] = result.data;
      setThemes([...allThemes]);
      setIsStandardActivated(false);
    } catch (err) {
      setError(err.message || 'Unexpected Error!');
    } finally {
      setIsLoading(false);
    }
  };
  const disableAllThemes = async () => {
    setIsLoading(true);
    try {
      axiosIns.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${user.token}`;
      const result = await axiosIns.put(
        '/design?isStandard=' + !isStandardActivated
      );
      setIsStandardActivated(!isStandardActivated);

      if (result.status !== '201') {
        let allThemes = result.data;
        setThemes([...allThemes]);
      }
    } catch (err) {
      setError(err.message || 'Unexpected Error!');
    } finally {
      setIsLoading(false);
    }
  };
  const handleStyleInput = (e) => {
    const updatedThemes = themes.map((theme, index) => {
      if (index === selectedItem) {
        return { ...theme, style: e.target.value };
      }
      return theme;
    });

    setThemes([...updatedThemes]);
  };
  const handleNameInput = (e) => {
    const updatedThemes = themes.map((theme, index) => {
      if (index === selectedItem) {
        return { ...theme, name: e.target.value };
      }
      return theme;
    });

    setThemes([...updatedThemes]);
  };
  const handleSubmit = async (e) => {
    const item = themes[selectedItem];
    setIsLoading(true);
    try {
      axiosIns.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${user.token}`;
      const result = await axiosIns.post('/design', item);
      setisSuccess(true);
      console.log(result.data);
      let allThemes = themes;
      allThemes[selectedItem] = result.data;
      setThemes([...allThemes]);
    } catch (err) {
      setError(err.message || 'Unexpected Error!');
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdate = async (e) => {
    const item = themes[selectedItem];
    setIsLoading(true);
    try {
      axiosIns.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${user.token}`;
      const result = await axiosIns.put('/design/' + item?._id, item);
      setisSuccess(true);
      console.log(result.data);
      let allThemes = themes;
      allThemes[selectedItem] = result.data;
      setThemes([...allThemes]);
    } catch (err) {
      setError(err.message || 'Unexpected Error!');
    } finally {
      setIsLoading(false);
    }
  };
  const handleClick = () => {
    history.goBack();
  };
  return (
    <>
      <Snackbar
        style={{ position: 'fixed' }}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => {
          setisSuccess(false);
        }}>
        <Alert severity="success">Saved successfully</Alert>
      </Snackbar>
      {error && (
        <Snackbar
          style={{ position: 'fixed' }}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          open={isSuccess}
          autoHideDuration={3000}
          onClose={() => {
            setisSuccess(false);
          }}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      )}
      <Grid container direction="row" justifyItems="space-between">
        <Grid item>
          <Button className="btn btn-secondary mb-5" onClick={handleClick}>
            {' '}
            <FontAwesomeIcon icon={faChevronLeft} />
            <Typography
              variant="button"
              className="pl-2"
              style={{ fontWeight: 'bold' }}>
              Back to Design
            </Typography>
          </Button>
        </Grid>
        <Grid item display="flex">
          <Button className="btn btn-primary mr-2" onClick={handleAdd}>
            + Add New Theme
          </Button>
          <Button
            className="btn btn-danger"
            disabled={themes.length === 0}
            onClick={() => handleDelete(selectedItem)}>
            <Delete /> Delete
          </Button>
          {isLoading && <CircularProgress />}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="my-3">
        <Grid item sm={12} xs={12} md={3}>
          <Card className="h-100">
            <CardContent>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 'bold' }}
                className="text-primary">
                Themes:
              </Typography>
              <MenuList>
                {themes &&
                  themes?.map((theme, index) => (
                    <MenuItem
                      key={theme.id}
                      selected={index === selectedItem}
                      className={`font-weight-bold ${
                        index === selectedItem && 'bg-light'
                      } `}
                      onClick={() => menuClicked(index)}>
                      {theme.name}
                    </MenuItem>
                  ))}
                <MenuItem
                  selected={isStandardSelected}
                  className={`font-weight-bold ${
                    isStandardSelected && 'bg-light'
                  } `}
                  onClick={() => {
                    setSelectedItem(false);
                    setIsStandardSelected(true);
                  }}>
                  Standard Theme
                </MenuItem>
              </MenuList>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12} md={7}>
          <Card className="p-2">
            <form>
              {isStandardSelected && (
                <Alert severity="info">
                  <Typography variant="caption">
                    Standard Theme is not editable. It will load the default
                    styles that are defined in your store
                  </Typography>
                </Alert>
              )}
              <Typography
                variant="body1"
                style={{ fontWeight: 'bold' }}
                className="text-primary mt-2">
                Theme name
              </Typography>

              <TextField
                id="filled-full-width"
                size="small"
                variant="outlined"
                placeholder="Theme name"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleNameInput}
                disabled={isStandardSelected}
                defaultValue={themes[selectedItem]?.name || ''}
                value={themes[selectedItem]?.name || ''}
              />
              <Typography
                variant="caption"
                className="text-black-50"
                gutterBottom>
                Give a name to your css theme
              </Typography>
              <Typography
                variant="body1"
                style={{ fontWeight: 'bold' }}
                className="text-primary mt-2">
                Custom CSS
              </Typography>
              <TextField
                id="outlined-multiline-static"
                placeholder="Add your custom CSS (eg: .class{ color: #000; };)"
                multiline
                rows={25}
                fullWidth
                disabled={isStandardSelected}
                onChange={handleStyleInput}
                defaultValue={themes[selectedItem]?.style || ''}
                value={themes[selectedItem]?.style || ''}
                variant="outlined"
              />
              {isStandardSelected || themes[selectedItem]?._id ? (
                <Button
                  disabled={isStandardSelected || isLoading}
                  onClick={handleUpdate}
                  variant="contained"
                  color="primary"
                  className="mt-2">
                  Update{' '}
                  {isLoading ? (
                    <CircularProgress className="ml-2" size={20} />
                  ) : null}
                </Button>
              ) : (
                <Button
                  disabled={isStandardSelected || isLoading}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  className="mt-2">
                  Submit{' '}
                  {isLoading ? (
                    <CircularProgress className="ml-2" size={20} />
                  ) : null}
                </Button>
              )}
            </form>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12} md={2}>
          <Card className="p-2 h-100 bg-light">
            {isStandardSelected ? (
              <>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'bold' }}
                  className="text-primary">
                  Status:{' '}
                  {isStandardActivated ? (
                    <span className="badge badge-pill badge-success">
                      Active
                    </span>
                  ) : (
                    <span className="badge badge-pill badge-dark">
                      Inactive
                    </span>
                  )}
                </Typography>

                <Button
                  className={`${
                    isStandardSelected && isStandardActivated
                      ? 'btn btn-dark mt-2'
                      : 'btn btn-success mt-2'
                  }`}
                  onClick={disableAllThemes}
                  variant="text">
                  {isStandardSelected && isStandardActivated ? (
                    <span>Deactivate</span>
                  ) : (
                    <span>Activate</span>
                  )}
                </Button>
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'bold' }}
                  className="text-primary">
                  Status:{' '}
                  {themes[selectedItem]?.isActive ? (
                    <span className="badge badge-pill badge-success">
                      Active
                    </span>
                  ) : (
                    <span className="badge badge-pill badge-dark">
                      Inactive
                    </span>
                  )}
                </Typography>

                <Button
                  size="sm"
                  className={`${
                    themes[selectedItem]?.isActive
                      ? 'btn btn-dark mt-2'
                      : 'btn btn-success mt-2'
                  }`}
                  disabled={!themes[selectedItem]?._id}
                  onClick={() => activateDesign(themes[selectedItem]?._id)}
                  variant="text">
                  {themes[selectedItem]?.isActive ? (
                    <span>Deactivate</span>
                  ) : (
                    <span>Activate</span>
                  )}
                </Button>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CustomDesign);
