import React, { lazy, Suspense } from 'react';
import { Switch, Route, Link, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

import { SuspenseLoading } from './layout-components';

import { LeftSidebar } from './layout-blueprints';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ProtectedRoute from './ProtectedRoute';
import { CustomCssTheme, NotificationPage } from 'pages';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from '@material-ui/icons/Search';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreUsers from 'pages/StoreUsers';
import TruckloadRequests from './pages/TruckloadRequests';
const Apps = lazy(() => import('./pages/AppsPage'));
const AppDescription = lazy(() => import('./pages/AppDescriptionPage'));
const MyApps = lazy(() => import('./pages/MyAppsPage'));
const AppDetails = lazy(() => import('./pages/AppDetailsPage'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const BarcodesLookup = lazy(() => import('./pages/BarcodesLookup'));
const Products = lazy(() => import('./pages/Products'));
const AbandonedCarts = lazy(() => import('./pages/AbandonedCarts'));
const Categories = lazy(() => import('./pages/Categories'));
const Orders = lazy(() => import('./pages/Orders'));
const OrderDetails = lazy(() => import('./pages/OrderDetails'));
const AbandonedOrders = lazy(() => import('./pages/AbandonedOrders'));
const Homepage = lazy(() => import('./pages/Homepage'));
const SalesTeam = lazy(() => import('./pages/SalesTeam'));
const PageContact = lazy(() => import('./pages/Contact'));
const PageLogin = lazy(() => import('./pages/PageLogin'));
const PageRegister = lazy(() => import('./pages/PageRegister'));
const OauthRedirect = lazy(() => import('./pages/OauthRedirect'));
const CreatePages = lazy(() => import('./pages/CreatePages'));
const CreateBlogPosts = lazy(() => import('./pages/CreateBlogPostsPage'));
const AddPages = lazy(() => import('./pages/AddpagesPage'));
const About = lazy(() => import('./pages/About'));
const AddBlogPost = lazy(() => import('./pages/AddBlogPostPage'));
const AccountVerification = lazy(() => import('./pages/AccountVerification'));
const PageError404 = lazy(() => import('./pages/PageError404'));
const PageProfile = lazy(() => import('./pages/Profile'));
// const PageSettings = lazy(() => import('./pages/Settings'));
const GenerateTicketPage = lazy(() => import('./pages/GenerateTicket'));
const StoreDesignPage = lazy(() => import('./pages/StoreDesign'));
const ExportProductsChatGpt = lazy(() =>
  import('./pages/ExportProductsChatGpt')
);
const EbayOauthRedirect = lazy(() =>
  import('./pages/Settings/EbayOauthRedirect')
);
const EbayListingConfig = lazy(() =>
  import('./pages/Settings/EbayListingConfig')
);
const StripeOnboardingRedirect = lazy(() =>
  import('./pages/Settings/StripeOnboardingRedirect')
);
const CloverOauthRedirect = lazy(() =>
  import('./pages/Settings/CloverOauthRedirect')
);
const TruckloadsMarketPlace = lazy(() =>
  import('./pages/TruckloadsMarketPlace')
);
const TruckloadDetails = lazy(() => import('./pages/TruckloadDetails'));
const Store = lazy(() => import('./pages/Store'));
const CaseStudies = lazy(() => import('./pages/customers'));
const CreateStore = lazy(() => import('./components/Store/Add'));
const EditStore = lazy(() => import('./components/Store/Edit'));
const EditProductPage = lazy(() => import('./pages/Products/EditProductPage'));
const AddProductPage = lazy(() => import('./pages/Products/AddProductPage'));
const Discounts = lazy(() => import('./pages/Store/Discounts'));
const Blogs = lazy(() => import('./pages/Blogs'));
const BlogDetails = lazy(() => import('./pages/Blogs/BlogsDetailsPage'));
const StoreLocationsPage = lazy(() => import('./pages/Store/StoreLocations'));
const StoreShippingPage = lazy(() => import('./pages/Store/Shipping'));
const PaymentProcessors = lazy(() => import('./pages/Payment'));
const Notifications = lazy(() => import('./pages/NotificationsPage'));
const StoreShippingSettingsPage = lazy(() =>
  import('./pages/Store/Shipping/shippingSettings')
);
const StoreDeliverySettingsPage = lazy(() =>
  import('./pages/Store/Shipping/deliverySettings')
);
const StorePickupSettingsPage = lazy(() =>
  import('./pages/Store/Shipping/pickupSettings')
);
const casestudydetails = lazy(() => import('./pages/CaseStudyPage'));
const PaymentPage = lazy(() => import('./pages/Payments'));
const PlanPage = lazy(() => import('./pages/Plan'));
const TermsPage = lazy(() => import('./pages/Clover/Terms'));
const PolicyPage = lazy(() => import('./pages/Clover/Policy'));
const ForgetPassword = lazy(() => import('./pages/ForgetPassword'));
const PricingPage = lazy(() => import('./pages/Pricing'));
const FormsPage = lazy(() => import('./pages/Forms'));
const PartnerPage = lazy(() => import('./pages/PartnersPage'));

const Builder = lazy(() =>
  import('./components/PageBuilder/DesignBuilder/Builder/Builder')
);

const CollageApp = lazy(() => import('./pages/CollageApp'));

const DiscountApp = lazy(() => import('./pages/DiscountApp'));

const BulkEditApp = lazy(() => import('./pages/BulkEditor'));
const useStyles = makeStyles({
  root: {
    position: 'sticky',
    bottom: 0,
    zIndex: 1000
  }
});

const Routes = () => {
  const location = useLocation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  // const pageVariants = {
  //   initial: {
  //     opacity: 0
  //   },
  //   in: {
  //     opacity: 1
  //   },
  //   out: {
  //     opacity: 0
  //   }
  // };

  // const pageTransition = {
  //   type: 'tween',
  //   ease: 'linear',
  //   duration: 0.3
  // };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {/* Clients Area */}
            <Redirect
              exact
              from={process.env.REACT_APP_D_PREFIX}
              to={process.env.REACT_APP_D_PREFIX + '/home'}
            />

            <Route path={process.env.REACT_APP_D_PREFIX}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX + '/products/editProduct'
                    }
                    component={EditProductPage}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX + '/products/addProduct'
                    }
                    component={AddProductPage}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/export'}
                    component={ExportProductsChatGpt}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/home'}
                    component={Dashboard}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/lookup'}
                    component={BarcodesLookup}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/apps'}
                    component={Apps}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/apps/collageApp'}
                    component={CollageApp}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/apps/discountApp'}
                    component={DiscountApp}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/apps/bulkEditor'}
                    component={BulkEditApp}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/apps/installed'}
                    component={MyApps}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX + '/apps/:appId/details'
                    }
                    component={AppDetails}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX +
                      '/apps/:appId/description'
                    }
                    component={AppDescription}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX + '/abandonedCarts/:id'
                    }
                    component={AbandonedCarts}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/products'}
                    component={Products}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/design'}
                    component={StoreDesignPage}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX +
                      '/settings/payment-processors'
                    }
                    component={PaymentProcessors}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX + '/settings/notifications'
                    }
                    component={Notifications}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/categories'}
                    component={Categories}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/forms'}
                    component={FormsPage}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/truckloads'}
                    component={TruckloadsMarketPlace}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX + '/truckloads/requests'
                    }
                    component={TruckloadRequests}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX + '/truckloads/details/:id'
                    }
                    component={TruckloadDetails}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/profile'}
                    component={PageProfile}
                  />
                  {/* <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/settings'}
                    component={PageSettings}
                  /> */}
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX +
                      '/settings/platforms/ebay/redirect'
                    }
                    component={EbayOauthRedirect}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX +
                      '/settings/platforms/stripe/redirect'
                    }
                    component={StripeOnboardingRedirect}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/settings/ebay'}
                    component={EbayListingConfig}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX +
                      '/settings/platforms/clover/redirect'
                    }
                    component={CloverOauthRedirect}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/stores'}
                    component={Store}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/stores/design'}
                    component={CustomCssTheme}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/stores/users'}
                    component={StoreUsers}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/createstore'}
                    component={CreateStore}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/edit'}
                    component={EditStore}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/stores/pages'}
                    component={CreatePages}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/stores/blogposts'}
                    component={CreateBlogPosts}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX + '/stores/:id/pages/add'
                    }
                    component={AddPages}
                  />
                  <ProtectedRoute
                    exact
                    path={
                      process.env.REACT_APP_D_PREFIX +
                      '/stores/:id/blogposts/add'
                    }
                    component={AddBlogPost}
                  />
                  <ProtectedRoute
                    exact
                    path={`${process.env.REACT_APP_D_PREFIX}/stores/:id/discounts`}
                    component={Discounts}
                  />
                  <ProtectedRoute
                    exact
                    path={`${process.env.REACT_APP_D_PREFIX}/stores/:id/settings/locations`}
                    component={StoreLocationsPage}
                  />
                  <ProtectedRoute
                    exact
                    path={`${process.env.REACT_APP_D_PREFIX}/stores/:id/shipping`}
                    component={StoreShippingPage}
                  />
                  <ProtectedRoute
                    exact
                    path={`${process.env.REACT_APP_D_PREFIX}/stores/:id/shipping/shippingSettings`}
                    component={StoreShippingSettingsPage}
                  />
                  <ProtectedRoute
                    exact
                    path={`${process.env.REACT_APP_D_PREFIX}/stores/:id/shipping/deliverySettings`}
                    component={StoreDeliverySettingsPage}
                  />
                  <ProtectedRoute
                    exact
                    path={`${process.env.REACT_APP_D_PREFIX}/stores/:id/shipping/pickupSettings`}
                    component={StorePickupSettingsPage}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/orders'}
                    component={Orders}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/order/details'}
                    component={OrderDetails}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/payment'}
                    component={PaymentPage}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/plans'}
                    component={PlanPage}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/notifications'}
                    component={NotificationPage}
                  />
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/ticket'}
                    component={GenerateTicketPage}
                  />{' '}
                  <ProtectedRoute
                    exact
                    path={process.env.REACT_APP_D_PREFIX + '/abandonedorders'}
                    component={AbandonedOrders}
                  />
                  <Route component={PageError404} />
                </Switch>
              </LeftSidebar>
              {!matches && (
                <BottomNavigation
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  showLabels
                  className={classes.root}>
                  <BottomNavigationAction
                    component={Link}
                    to="/app/home"
                    label="Home"
                    icon={<HomeIcon />}
                  />
                  <BottomNavigationAction
                    label="Products"
                    component={Link}
                    to="/app/products"
                    icon={<DescriptionIcon />}
                  />
                  <BottomNavigationAction
                    label="Scan"
                    component={Link}
                    to="/app/lookup"
                    icon={<SearchIcon />}
                  />
                  <BottomNavigationAction
                    label="Store"
                    component={Link}
                    to="/app/stores"
                    icon={<LocalMallIcon />}
                  />

                  <BottomNavigationAction
                    label="Orders"
                    component={Link}
                    to="/app/orders"
                    icon={<ShoppingCartIcon />}
                  />
                </BottomNavigation>
              )}
            </Route>

            {/* Public Area */}
            {/* <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}> */}
            <Route
              exact
              path={'/builder/:id'}
              render={() => <Builder theme="store_front_1"></Builder>}
            />

            <Route exact path={'/blogs'} component={Blogs} />

            <Route exact path="/" component={Homepage} />
            <Route exact path="/customers" component={CaseStudies} />
            <Route exact path="/blog/details" component={BlogDetails} />
            <Route exact path="/customer/ccb" component={casestudydetails} />
            <Route exact path="/help/sales" component={SalesTeam} />
            <Route exact path="/help/contact" component={PageContact} />
            <Route exact path="/partner" component={PartnerPage} />
            <Route exact path="/terms" component={TermsPage} />
            <Route exact path="/policy" component={PolicyPage} />
            <Route exact path="/redirect" component={OauthRedirect} />
            <Route exact path="/about" component={About} />
            <Route
              exact
              path="/verifyaccount/:id"
              component={AccountVerification}
            />
            <Route exact path="/login" component={PageLogin} />
            <Route exact path="/register" component={PageRegister} />
            <Route exact path="/forgetpassword" component={ForgetPassword} />

            <Route exact path="/pricing" component={PricingPage} />
            <Route path="**" component={PageError404} />
            {/* </motion.div> */}
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
