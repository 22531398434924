import { axiosIns } from '../config/configAxios';
import { addProduct, deleteProductById } from './Products';

import beep from '../assets/sounds/beep.wav';

/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_BARCODE = 'ADD_BARCODE';
export const addBarcode = (barcode, playBeep = false) => ({
  type: ADD_BARCODE,
  payload: { barcode, playBeep }
});

export const REMOVE_BARCODE = 'REMOVE_BARCODE';
export const removeBarcode = (barcode) => ({
  type: REMOVE_BARCODE,
  payload: { barcode }
});

export const LOOKUP_STARTED = 'LOOKUP_STARTED';
export const lookupStart = () => ({
  type: LOOKUP_STARTED
});

export const LOOKUP_ENDED = 'LOOKUP_ENDED';
export const lookupEnd = (lookupResults) => ({
  type: LOOKUP_ENDED,
  payload: { lookupResults }
});

export const LOOKUP_PROGRESS_VALUE = 'LOOKUP_PROGRESS_VALUE';
export const lookupProgress = (progress) => ({
  type: LOOKUP_PROGRESS_VALUE,
  payload: { progress }
});

const LOOKUP_HISTORY_LOADED = 'LOOKUP_HISTORY_LOADED';
const lookupsLoad = (lookupHistory) => ({
  type: LOOKUP_HISTORY_LOADED,
  payload: { lookupHistory }
});

export const SHOW_UPGRADE_MODAL = 'SHOW_UPGRADE_MODAL';
export const upgradeModal = (error) => ({
  type: SHOW_UPGRADE_MODAL,
  payload: { error }
});
export const HIDE_UPGRADE_MODAL = 'HIDE_UPGRADE_MODAL';
export const hideUpgradeModal = () => ({
  type: HIDE_UPGRADE_MODAL
});
export const STOP_SCAN_PRODUCTS = 'STOP_SCAN_PRODUCTS';
export const stopScanProducts = () => ({
  type: STOP_SCAN_PRODUCTS
});
export const START_PAUSE_SCAN_PRODUCTS = 'START_PAUSE_SCAN_PRODUCTS';
export const startPauseScanProducts = () => ({
  type: START_PAUSE_SCAN_PRODUCTS
});
export const SHOW_RESULTS = 'SHOW_RESULTS';
export const showResults = () => ({
  type: SHOW_RESULTS
});

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const deleteProduct = (barcode) => ({
  type: DELETE_PRODUCT,
  payload: { barcode }
});
/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */
const initialState = {
  isFetching: false,
  progress: 0,
  scannedBarcodes: [],
  lookupResults: [],
  lookupHistory: [],
  subscriptionStatus: {},
  controls: {
    isPaused: false,
    isStopped: false
  }
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_BARCODE: {
      const { barcode, playBeep } = payload;
      const isDuplicate = state.scannedBarcodes.some(
        (b) => b.value === barcode.value
      );
      if (isDuplicate) return state;
      else {
        if (playBeep) {
          const audio = new Audio(beep);
          audio.play();
        }
        return {
          ...state,
          scannedBarcodes: state.scannedBarcodes.concat(barcode)
        };
      }
    }
    case REMOVE_BARCODE: {
      const { barcode } = payload;
      const newList = state.scannedBarcodes.filter(
        (code) => code.value !== barcode.value
      );
      return {
        ...state,
        scannedBarcodes: newList
      };
    }

    case LOOKUP_STARTED:
      return {
        ...state,
        isFetching: true,
        progress: 0,
        lookupResults: [],
        controls: { isPaused: false, isStopped: false }
      };

    case LOOKUP_ENDED: {
      const { lookupResults } = payload;
      return {
        ...state,
        lookupResults: state.lookupResults.concat(lookupResults)
      };
    }
    case SHOW_RESULTS: {
      return {
        ...state,
        isFetching: false,
        scannedBarcodes: [],
        controls: { isPaused: false, isStopped: false }
      };
    }
    case LOOKUP_PROGRESS_VALUE: {
      const { progress } = payload;
      return { ...state, progress };
    }

    case LOOKUP_HISTORY_LOADED: {
      const { lookupHistory } = payload;
      return { ...state, lookupHistory };
    }
    case SHOW_UPGRADE_MODAL: {
      const { error } = payload;
      return { ...state, subscriptionStatus: error };
    }
    case HIDE_UPGRADE_MODAL: {
      return { ...state, subscriptionStatus: {} };
    }
    case STOP_SCAN_PRODUCTS: {
      return {
        ...state,
        isFetching: false,

        scannedBarcodes: [],
        controls: { isPaused: false, isStopped: true }
      };
    }
    case START_PAUSE_SCAN_PRODUCTS: {
      return {
        ...state,
        controls: {
          isPaused: !state.controls.isPaused,
          isStopped: false
        }
      };
    }

    case DELETE_PRODUCT: {
      const { barcode } = payload;
      const newList = state.lookupResults.filter(
        (result) => result.barcode !== barcode
      );
      return {
        ...state,
        lookupResults: newList
      };
    }
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const fetchProducts = () => async (dispatch, getState) => {
  let isPaused = getState().Barcodes.controls.isPaused;
  if (!getState().Barcodes.controls.isPaused) {
    dispatch(lookupStart());
    console.log('start');
  } else {
    dispatch(startPauseScanProducts());
    console.log('resume');
  }

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const barcodes = state.Barcodes.scannedBarcodes;
    let lookupResults = [];
    let startIndex =
      isPaused && getState().Barcodes.isFetching
        ? getState().Barcodes.lookupResults.length
        : 0;
    for (var i = startIndex; i < barcodes.length; i++) {
      
      const { value, format, source, quantity, price, condition } = barcodes[i];
      const data = {
        identifier: value,
        type: format,
        source,
        quantity,
        condition,
        price
      };
      try {
        const res = await axiosIns.post('/lookup', data);

        // Check if product is created or activated
        if (res.status === 201 || res.status === 200) {
          dispatch(addProduct(res.data));

          let result = { barcode: value, result: res.data };
          lookupResults.push(result);
          if (getState().Barcodes.controls.isPaused) {
            console.log('Just Paused');
            dispatch(lookupEnd(lookupResults));
            break;
          }
          if (getState().Barcodes.controls.isStopped) {
            console.log('Stop');
            break;
          }
        }
      } catch (err) {
        if (err.response) {
          const { status } = err.response;
          let result = { barcode: value, result: '' };
          if (status === 302) {
            result.message = 'Already_Exist';
          } else if (status === 404) result.message = 'Not Found';
          else if (status === 400) result.message = 'Invalid barcode/format';
          else if (status === 500) {
            if (err.response.data) {
              if (
                err.response.data.error.name === 'ERR_EXCEED' ||
                err.response.data.error.name === 'ERR_SUBSCRIBE'
              ) {
                result.message = err.response.data.message;
                const error = {
                  errorCode: err.response.data.error.name,
                  errorMsg: err.response.data.message
                };
                dispatch(upgradeModal(error));
              } else {
                result.message = 'Server Error';
              }
            } else {
              result.message = 'Server Error';
            }
          } else result.message = 'Unknown Error';

          lookupResults.push(result);
          if (getState().Barcodes.controls.isPaused) {
            console.log('Just Paused');
            dispatch(lookupEnd(lookupResults));
            break;
          }
          if (getState().Barcodes.controls.isStopped) {
            console.log('Stop');
            break;
          }
        }
      } finally {
        // console.log(i);
        // if (!getState().Barcodes.controls.isPaused) {
        dispatch(lookupProgress(i + 1));
        // } else {
        // console.log('dont update progress');
        // }
      }
    }
    console.log(
      'End',
      getState().Barcodes.controls.isPaused,
      getState().Barcodes.controls.isStopped
    );
    if (
      !getState().Barcodes.controls.isPaused ||
      getState().Barcodes.controls.isStopped
    ) {
      console.log('show');
      // if stopped or
      dispatch(lookupEnd(lookupResults));
      dispatch(showResults());
    }
  } catch (err) {
    console.log(err);
    // dispatch(lookupFailure());
  }
};

export const loadLookupHistory = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axiosIns.get('/lookup');
    dispatch(lookupsLoad(res.data));
  } catch (err) {
    console.log(err.response);
  }
};

export const deleteScannedProduct = (result) => (dispatch, getState) => {
  dispatch(deleteProductById(result.result._id));
  dispatch(deleteProduct(result.barcode));
  
};
/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getScannedBarcodes = (state) => state.Barcodes.scannedBarcodes;
export const getLookupResults = (state) => state.Barcodes.lookupResults;
export const getLookupProgress = (state) => state.Barcodes.progress;
export const getLookupInProgress = (state) => state.Barcodes.isFetching;
export const getLookupHistory = (state) => state.Barcodes.lookupHistory;
export const getSubscriptionStatus = (state) =>
  state.Barcodes.subscriptionStatus;

export const getControls = (state) => state.Barcodes.controls;
