import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  Grid,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  ListItemText,
  Divider
} from '@material-ui/core';

import {
  updateMultipleProducts,
  bulkAddtoClover,
  bulkRemoveFromClover,
  selectedProducts
} from '../../../reducers/Products';
import ExportFacebookProducts from '../ExportFacebook';
import BarcodePdfExport from '../BarcodePdfExport';

import BulkEbayPublish from '../BulkEbayPublish';
import { getAllCategories } from '../../../reducers/AllCategories';
import { addLogo, getStoreLocation, store } from '../../../reducers/Store';
import { getUser } from 'reducers/UserAccount';
import QrCode from '../QRCodeApp';
import { useHistory } from 'react-router';
const Dividers = ({ children, ...props }) => (
  <Grid disabled container alignItems="center" spacing={3} {...props}>
    <Grid item xs>
      <Divider disabled />
    </Grid>
    <Grid item disabled>
      {children}
    </Grid>
    <Grid item xs>
      <Divider disabled />
    </Grid>
  </Grid>
);
const BulkActions = (props) => {
  const {
    products,
    categories,
    updateProducts,
    selected,
    setSelected,
    storeLocation,
    addToClover,
    removeFromClover,
    setresponseError,
    setresponseSuccess
  } = props;

  const actions = [
    {
      id: 'status',
      name: 'Update Status',
      type: 'dropdown',
      values: ['Ready', 'Unpublished']
    },
    {
      id: 'category',
      name: 'Add to Category',
      type: 'dropdown',
      values: categories
    },
    {
      id: 'storeLocation',
      name: 'Update Store Location',
      type: 'dropdown',
      values: storeLocation
    },
    {
      id: 'price',
      name: 'Update price',
      type: 'textfield',
      values: []
    },
    {
      id: 'quantity',
      name: 'Update inventory quantity',
      type: 'textfield',
      values: []
    },
    {
      id: 'featured',
      name: 'Mark as featured',
      type: 'noinput',
      values: []
    },
    {
      id: 'unfeatured',
      name: 'Remove from featured',
      type: 'noinput',
      values: []
    },
    {
      id: 'isActive',
      name: 'Delete products',
      type: 'noinput',
      values: []
    },
    {
      id: 'exportJson',
      name: 'Export as JSON',
      type: 'noinput',
      values: []
    },
    {
      id: 'exportCsv',
      name: 'Export as CSV',
      type: 'noinput',
      values: []
    },
    {
      id: 'barCodeGenerator',
      name: 'Generate Barcodes',
      type: 'noinput',
      values: []
    },
    {
      id: 'cloverPush',
      name: 'Add to Clover',
      type: 'noinput',
      values: []
    },
    {
      id: 'cloverEnd',
      name: 'Remove from Clover',
      type: 'noinput',
      values: []
    },
    {
      id: 'percentageDiscount',
      name: 'Percentage Discount',
      type: 'textfield',
      values: []
    },
    {
      id: 'facebookExport',
      name: 'Export Facebook Marketplace',
      type: 'noinput',
      values: []
    },
    {
      id: 'ebaybulkpublish',
      name: 'Ebay Bulk Publish',
      type: 'noinput',
      values: []
    },
    {
      id: 'ebaybulkdelete',
      name: 'Ebay Bulk Delete',
      type: 'noinput',
      values: []
    },
    {
      id: 'isShippable',
      name: 'Mark as Shippable',
      type: 'dropdown',
      values: ['true', 'false']
    },
    {
      id: 'isDeliverable',
      name: 'Mark as Deliverable',
      type: 'dropdown',
      values: ['true', 'false']
    },
    {
      id: 'qrcode_app',
      name: 'Generate QR Code',
      type: 'noinput',
      values: []
    },
    {
      id: 'facebookExport',
      name: 'Export Facebook Marketplace',
      type: 'noinput',
      values: []
    },
    {
      id: 'imageCollageApp',
      name: 'Make Image Collage',
      type: 'noinput',
      values: []
      // handler: collageAppHandler
    },
    {
      id: 'bulkeditor',
      name: 'BulkEditor',
      type: 'noinput',
      values: []
      // handler: collageAppHandler
    }
  ];
  const nonAppActions = [
    {
      id: 'isShippable',
      name: 'Mark as Shippable',
      type: 'dropdown',
      values: ['true', 'false']
    },
    {
      id: 'isDeliverable',
      name: 'Mark as Deliverable',
      type: 'dropdown',
      values: ['true', 'false']
    },
    {
      id: 'percentageDiscount',
      name: 'Percentage Discount',
      type: 'textfield',
      values: []
    },
    {
      id: 'status',
      name: 'Update Status',
      type: 'dropdown',
      values: ['Ready', 'Unpublished']
    },
    {
      id: 'category',
      name: 'Add to Category',
      type: 'dropdown',
      values: categories
    },
    {
      id: 'storeLocation',
      name: 'Update Store Location',
      type: 'dropdown',
      values: storeLocation
    },
    {
      id: 'price',
      name: 'Update price',
      type: 'textfield',
      values: []
    },
    {
      id: 'quantity',
      name: 'Update inventory quantity',
      type: 'textfield',
      values: []
    },
    {
      id: 'featured',
      name: 'Mark as featured',
      type: 'noinput',
      values: []
    },
    {
      id: 'unfeatured',
      name: 'Remove from featured',
      type: 'noinput',
      values: []
    },
    {
      id: 'isActive',
      name: 'Delete products',
      type: 'noinput',
      values: []
    },
    {
      id: 'exportJson',
      name: 'Export as JSON',
      type: 'noinput',
      values: []
    },
    {
      id: 'exportCsv',
      name: 'Export as CSV',
      type: 'noinput',
      values: []
    },
    {
      id: 'bulkeditor',
      name: 'BulkEditor',
      type: 'noinput',
      values: []
      // handler: collageAppHandler
    }
  ];
  const appActions = [
    {
      id: 'barCodeGenerator',
      name: 'Generate Barcodes',
      type: 'noinput',
      values: []
    },
    {
      id: 'cloverPush',
      name: 'Add to Clover',
      type: 'noinput',
      values: []
    },
    {
      id: 'cloverEnd',
      name: 'Remove from Clover',
      type: 'noinput',
      values: []
    },
    {
      id: 'qrcode_app',
      name: 'Generate QR Code',
      type: 'noinput',
      values: []
    },
    {
      id: 'ebaybulkpublish',
      name: 'Ebay Bulk Publish',
      type: 'noinput',
      values: []
    },
    {
      id: 'ebaybulkdelete',
      name: 'Ebay Bulk Delete',
      type: 'noinput',
      values: []
    },
    {
      id: 'facebookExport',
      name: 'Export Facebook Marketplace',
      type: 'noinput',
      values: []
    },
    {
      id: 'imageCollageApp',
      name: 'Make Image Collage',
      type: 'noinput',
      values: []
      // handler: collageAppHandler
    }
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const [action, setAction] = useState('');
  const handleAction = (event) => {
    const value = event.target.value;
    const action = actions.find((a) => a.id === value);
    console.log(action);
    if (action.id === 'imageCollageApp') {
      collageAppHandler();
    } else if (action.id === 'bulkeditor') {
      BulkEditorHandler();
    }
    if (action) {
      setAction(action);
    }
  };

  const [actionValue, setActionValue] = useState('');
  const [error, setError] = useState('');
  const handleActionValue = (event) => {
    if (
      (action.id === 'quantity' || action.id === 'price') &&
      event.target.value < 0
    )
      setError(`${action.id} cannot pe negative`);
    else setError('');
    const value = event.target.value;
    if (action.id === 'category') setSubCategories(getSubcategories(value));

    setActionValue(value);
  };

  const [actionValue2, setActionValue2] = useState('');
  const handleActionValue2 = (event) => setActionValue2(event.target.value);

  const [subCategories, setSubCategories] = useState([]);

  const onActionSubmit = () => {
    setresponseError('');
    setresponseSuccess('');
    let attributes = {};
    switch (action.id) {
      case 'isActive':
        attributes.isActive = false;
        break;
      case 'featured':
        attributes.isFeatured = true;
        break;
      case 'unfeatured':
        attributes.isFeatured = false;
        break;
      case 'exportJson':
        downloadJson();
        break;
      case 'exportCsv':
        downloadCsv();
        break;
      case 'cloverPush':
        addToClover({
          data: { ids: selected },
          setResponseError: setresponseError,
          setresponseSuccess: setresponseSuccess
        });
        break;
      case 'cloverEnd':
        removeFromClover({
          data: { ids: selected },
          setResponseError: setresponseError,
          setresponseSuccess: setresponseSuccess
        });
        break;
      case 'category': {
        attributes.category = actionValue;
        if (actionValue2) attributes.subCategory = actionValue2;
        break;
      }
      case 'isShippable':
        attributes[action.id] = actionValue === 'true';
        break;
      case 'isDeliverable':
        attributes[action.id] = actionValue === 'true';
        break;
      case 'facebookExport':
        break;
      case 'ebaybulkpublish':
        break;
      case 'ebaybulkdelete':
        break;
      case 'barcode_app':
        break;
      case 'qrcode_app':
        break;
      case 'bulkeditor':
        break;

      default:
        attributes[action.id] = actionValue;
        break;
    }

    if (attributes && action.id !== 'cloverPush')
      updateProducts({
        data: { ids: selected, attributes },
        setResponseError: setresponseError,
        setresponseSuccess: setresponseSuccess
      });

    setAction('');
    setActionValue('');
    setSelected([]);
  };

  const getSubcategories = (cId) => {
    const category = categories.find((category) => category._id === cId);
    if (category?.subCategories.length > 0) return category.subCategories;
    return [];
  };

  const downloadJson = () => {
    const items = products.filter((item) => selected.includes(item._id));
    const data = items.map((item) => {
      const pId = item._id;
      const title = item.title;
      const description = item.description;
      const price = item.price;
      const condition = item.condition;
      const brand = item.brand;
      const categories = item.categories;
      const isShippable = item.isShippable;
      const isDeliverable = item.isDeliverable;
      const thumbnail = `${process.env.REACT_APP_ASSETS_BASE_URL}/${item.thumbnail}`;
      const images = item.images.map(
        (image) => `${process.env.REACT_APP_ASSETS_BASE_URL}/${image.url}`
      );
      return {
        pId,
        title,
        description,
        price,
        condition,
        brand,
        categories,
        thumbnail,
        images,
        isShippable,
        isDeliverable
      };
    });

    const filename = 'export.json';
    const contentType = 'application/json;charset=utf-8;';
    const content = encodeURIComponent(JSON.stringify(data, null, 2));
    const a = document.createElement('a');

    a.download = filename;
    a.href = `data:${contentType},${content}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadCsv = () => {
    const data = transformToShopify();
    const csv = data.map((r) => r.join(',')).join('\n');

    const filename = 'export.csv';
    const contentType = 'text/csv;charset=utf-8';
    const content = encodeURIComponent(csv);
    const a = document.createElement('a');

    a.download = filename;
    a.href = `data:${contentType},${content}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const transformToShopify = () => {
    const rows = [
      [
        'Handle',
        'Title',
        'Body (HTML)',
        'Vendor',
        'Type',
        'Tags',
        'Published',
        'Option1 Name',
        'Option1 Value',
        'Option2 Name',
        'Option2 Value',
        'Option3 Name',
        'Option3 Value',
        'Variant SKU',
        'Variant Grams',
        'Variant Inventory Tracker',
        'Variant Inventory Qty',
        'Variant Inventory Policy',
        'Variant Fulfillment Service',
        'Variant Price',
        'Variant Compare At Price',
        'Variant Requires Shipping',
        'Variant Taxable',
        'Variant Barcode',
        'Image Src',
        'Image Position',
        'Image Alt Text',
        'Gift Card',
        'SEO Title',
        'SEO Description',
        'Google Shopping / Google Product Category',
        'Google Shopping / Gender',
        'Google Shopping / Age Group',
        'Google Shopping / MPN',
        'Google Shopping / AdWords Grouping',
        'Google Shopping / AdWords Labels',
        'Google Shopping / Condition',
        'Google Shopping / Custom Product',
        'Google Shopping / Custom Label 0',
        'Google Shopping / Custom Label 1',
        'Google Shopping / Custom Label 2',
        'Google Shopping / Custom Label 3',
        'Google Shopping / Custom Label 4',
        'Variant Image',
        'Variant Weight Unit',
        'Variant Tax Code',
        'Cost per item'
      ]
    ];

    products.forEach((el) => {
      if (el.category && el.subCategory) {
        const { category, subCategory } = getCategoryNames(
          el.category,
          el.subCategory
        );
        const row = [
          category?.replace(/\s+|,/g, '-').toLowerCase(),
          el.title,
          el.description && el.description?.replace(/,/g, ' '),
          el.manufacturer,
          subCategory?.replace(/,/g, ' '),
          '',
          true,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          el.weight,
          'shopify',
          el.quantity,
          'continue',
          'manual',
          el.price,
          '',
          '',
          '',
          el.barcode,
          `${process.env.REACT_APP_ASSETS_BASE_URL}/${el.images[0].url}`,
          1,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ];
        rows.push(row);
      }
    });
    return rows;
  };

  const getCategoryNames = (cId, scId) => {
    const names = {};
    for (const category of categories) {
      if (category._id === cId) {
        names.category = category.name;
        for (const subCategory of category.subCategories) {
          if (subCategory._id === scId) {
            names.subCategory = subCategory.name;
            break;
          }
        }
        break;
      }
    }
    return names;
  };
  const checkAppStatus = (actionId) => {
    const apps = props.user?.installed_apps;
    let selectedApp = null;
    switch (actionId) {
      case 'barCodeGenerator':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === 'barcode_app'
        );
        break;
      case 'ebaybulkpublish':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === 'ebay_app'
        );
        break;
      case 'ebaybulkdelete':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === 'ebay_app'
        );
        break;
      case 'cloverPush':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === 'clover_app'
        );
        break;
      case 'cloverEnd':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === 'clover_app'
        );
        break;
      case 'qrcode_app':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === 'qrcode_app'
        );
        break;
      case 'facebookExport':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === 'facebookmarketplace_app'
        );
        break;
      case 'imageCollageApp':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === 'image_collage_app'
        );
        break;
      default:
        return;
    }
    return selectedApp;
  };

  const collageAppHandler = () => {
    const payload = { selected: selected };
    dispatch(selectedProducts(payload));
    addLogoHandler();
    history.push('/app/apps/collageApp');
  };

  const BulkEditorHandler = () => {
    const payload = { selected: selected };
    dispatch(selectedProducts(payload));
    history.push('/app/apps/bulkEditor');
  };

  const storeData = useSelector(store);

  const addLogoHandler = () => {
    const logo = storeData[0].logo;
    const payload = { logo: logo };
    dispatch(addLogo(payload));
  };

  // const checkIfApp = (actionId) => {
  //   let selectedApp = null;
  //   console.log('ALL', allApps);
  //   switch (actionId) {
  //     case 'barCodeGenerator':
  //       selectedApp = allApps?.find((app) => app?.identifier === 'barcode_app');
  //       break;
  //     case 'barcodeGenerator':
  //       selectedApp = allApps?.find((app) => app?.identifier === 'ebay_app');
  //       break;
  //   }
  //   return selectedApp;
  // };
  return (
    <div className="p-4 bg-secondary">
      <Grid container spacing={4}>
        <Grid item md={3}>
          <FormControl variant="outlined" size="small" className="w-100">
            <InputLabel id="action-type-select-label">Actions</InputLabel>
            <Select
              fullWidth
              labelId="action-type-select-label"
              label="Actions"
              value={action.id ?? ''}
              onChange={handleAction}
              sx={{
                '& .MuiListItemIcon-root': {
                  display: 'none !important'
                }
              }}>
              <Divider />
              {nonAppActions.map((a) => (
                <MenuItem className="mx-2" value={a.id} key={a.id}>
                  {a.name}
                </MenuItem>
              ))}
              <Dividers disabled>APPS</Dividers>
              {appActions.map(
                (a) =>
                  checkAppStatus(a.id) && (
                    <MenuItem
                      // sx={{
                      //   '& .MuiListItemIcon-root': {
                      //     display: 'none !important'
                      //   }
                      // }}

                      className="mx-2"
                      value={a.id}
                      key={a.id}>
                      {/* <ListItemIcon
                        sx={{
                          '& .MuiListItemIcon-root': {
                            display: 'none !important'
                          }
                        }}>
                        <img
                          width={'40px'}
                          src={`${process.env.REACT_APP_ASSETS_BASE_URL}${a.icon}`}
                          alt={a.name}
                        />
                      </ListItemIcon> */}
                      <ListItemText>{a.name}</ListItemText>
                    </MenuItem>
                  )
                //  : (
                //   <MenuItem
                //     disabled={true}
                //     className="mx-2"
                //     value={a.id}
                //     key={a.id}>
                //     {a.name}
                //   </MenuItem>
                // )
              )}
              {/* <MenuItem className="mx-2">
                <ListItemText
                  onClick={() => {
                    collageAppHandler();
                  }}>
                  Collage App
                </ListItemText>
              </MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        {action !== '' &&
          action.type !== 'noinput' &&
          action.id !== 'category' && (
            <Grid item md={3}>
              {action.type === 'dropdown' && (
                <FormControl variant="outlined" size="small" className="w-100">
                  <InputLabel id="action-value-select-label">
                    Action Value
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="action-value-select-label"
                    label="Action Value"
                    value={actionValue}
                    onChange={handleActionValue}>
                    {action.id === 'storeLocation'
                      ? action.values.map((a) => (
                          <MenuItem className="mx-2" value={a._id} key={a._id}>
                            {a.name}
                          </MenuItem>
                        ))
                      : action.values.map((a) => (
                          <MenuItem className="mx-2" value={a} key={a}>
                            {a}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              )}
              {action.type === 'textfield' && (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Action Value"
                  type="number"
                  error={error !== '' ? `${error}` : false}
                  helperText={error}
                  onChange={handleActionValue}
                  value={actionValue}
                />
              )}
            </Grid>
          )}
        {action !== '' &&
          action.type === 'dropdown' &&
          action.id === 'category' && (
            <>
              <Grid item md={3}>
                <FormControl variant="outlined" size="small" className="w-100">
                  <InputLabel id="action-value-select-label">
                    Main Category
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="action-value-select-label"
                    label="Main Category"
                    value={actionValue}
                    onChange={handleActionValue}>
                    {action.values.map((c) => (
                      <MenuItem className="mx-2" value={c._id} key={c._id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3}>
                <FormControl variant="outlined" size="small" className="w-100">
                  <InputLabel id="action-value-select-label">
                    Sub-Category
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="action-value-select-label"
                    label="Sub-Category"
                    value={actionValue2}
                    onChange={handleActionValue2}>
                    {subCategories.map((sc) => (
                      <MenuItem className="mx-2" value={sc._id} key={sc._id}>
                        {sc.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        {action.id === 'facebookExport' ? (
          <ExportFacebookProducts
            selected={selected}
            products={products}
            isUnpublish={false}
          />
        ) : action.id === 'ebaybulkpublish' ? (
          <BulkEbayPublish selected={selected} products={products} />
        ) : action.id === 'ebaybulkdelete' ? (
          <BulkEbayPublish
            selected={selected}
            products={products}
            isUnpublish={true}
          />
        ) : action.id === 'barCodeGenerator' &&
          checkAppStatus('barCodeGenerator') ? (
          <BarcodePdfExport selected={selected} products={products} />
        ) : action.id === 'qrcode_app' && checkAppStatus('qrcode_app') ? (
          <QrCode selected={selected} />
        ) : (
          action !== '' && (
            <Grid item md={1}>
              <Button
                className="btn-primary font-weight-bold"
                disabled={error !== ''}
                onClick={onActionSubmit}>
                Go
              </Button>
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: getAllCategories(state),
  storeLocation: getStoreLocation(state),
  user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
  updateProducts: (data) => dispatch(updateMultipleProducts(data)),
  addToClover: (data) => dispatch(bulkAddtoClover(data)),
  removeFromClover: (data) => dispatch(bulkRemoveFromClover(data)),
  selectedProducts: (data) => dispatch(selectedProducts(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkActions);
