import {
  Button,
  Grid,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import googleSignIn from '../../assets/images/btn_google_signin_2x.png';
import illustration1 from '../../assets/images/illustrations/pack1/authentication.svg';
import { AuthPageWrapper } from '../../layout-components';
import {
  getIsAuthenticated,
  getUser,
  getVerificationStatus,
  login
} from '../../reducers/UserAccount';
import { loadPlans } from '../../reducers/Plans';
import { loadSubscription } from '../../reducers/UserSubscriptions';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
});

const Login1 = ({
  loggedInUser,
  loginUser,
  isAuthenticated,
  isVerified,
  loadPlans,
  loadSubscription
}) => {
  const [loginSuccess, setLoginSuccess] = useState(isAuthenticated);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [RecaptchError, setRecaptchError] = useState(false);

  const recaptchaRef = React.useRef();

  const location = useLocation();
  const { from } = location.state || {
    from: { pathname: `${process.env.REACT_APP_D_PREFIX}` }
  };
  useEffect(() => {
    if (isAuthenticated) {
      loadPlans();
      loadSubscription();
    }
  }, [isAuthenticated, loadPlans, loadSubscription]);
  if (loginSuccess && isAuthenticated && isVerified === 'active') {
    return <Redirect to={from} />;
  } else if (
    loginSuccess &&
    (isVerified === 'pending' ||
      isVerified === 'waiting' ||
      isVerified === 'inactive')
  ) {
    return <Redirect to={'/verifyaccount/' + loggedInUser._id} />;
  }
  const pushDataLayer = (userId) => {
    if (window && typeof window != 'undefined') {
      console.log('Pushed to Analytics');
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'login',
        authenticationMethod: 'email', //
        userId: userId //this should be replaced with an actual ID
      });
    }
  };
  return (
    <AuthPageWrapper illustration={illustration1}>
      <div className="text-black mt-3">
        <span className="text-center">
          <h1 className="display-4 mb-1 font-weight-bold">
            Login to your account
          </h1>
          <p className="font-size-lg mb-0 text-black-50">
            We're glad you're working on your app. Login below to continue.
          </p>
        </span>

        <div className="bg-secondary rounded p-4 my-4">
          <div className="text-black-50 text-center mb-3">Sign in with</div>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <Tooltip title="Facebook" arrow>
                <Button
                  href={`${process.env.REACT_APP_API_BASE_URL}/api/v1/facebook/`}
                  fullWidth
                  className="btn-facebook btn-animated-icon">
                  <span className="btn-wrapper--icon d-flex align-items-center justify-content-center">
                    <FacebookIcon />
                  </span>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item md={6}>
              <Tooltip title="Google" arrow>
                <Button
                  href={`${process.env.REACT_APP_API_BASE_URL}/api/v1/google/`}
                  fullWidth
                  className="btn-twitter btn-animated-icon">
                  <span className="btn-wrapper--icon d-flex align-items-center justify-content-center">
                    <img
                      alt="..."
                      src={googleSignIn}
                      style={{ width: 25, height: 25 }}
                    />
                  </span>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </div>

        <Formik
          initialValues={{
            email: loggedInUser?.email ?? '',
            password: '',
            both: null
          }}
          validationSchema={ValidationSchema}
          onSubmit={(
            values,
            { setSubmitting, setErrors, setStatus, resetForm }
          ) => {
            if (values.token) {
              loginUser(values)
                .then((data) => {
                  loadPlans();
                  loadSubscription();
                  setStatus({ success: true });
                  resetForm({});
                  setLoginSuccess(true);
                  pushDataLayer(data.payload.data._id);
                })
                .catch((err) => {
                  setSubmitting(false);
                  recaptchaRef.current.reset();
                  if (err.response.status === 400)
                    setErrors({ both: 'Incorrect e-mail or password !' });
                  else if (err.response.status === 500) setOpenSnackbar(true);
                });
            } else {
              recaptchaRef.current.reset();
              setRecaptchError(true);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="font-weight-bold mb-2">Email address</label>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="yourname@yourmail.com"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={errors.email && touched.email}
                />
              </div>
              <div className="mb-3">
                <div className="d-flex justify-content-between">
                  <label className="font-weight-bold mb-2">Password</label>
                  <Link to="/forgetpassword">Forgot password?</Link>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Enter your password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={errors.password && touched.password}
                />
              </div>

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                onChange={(value) => {
                  setFieldValue('token', value);
                  setRecaptchError(false);
                }}
                onErrored={(value) => recaptchaRef.current.reset()}
              />
              {RecaptchError && (
                <Typography color="error" variant="caption" component="p">
                  Recaptcha Failed
                </Typography>
              )}

              <div className="mb-3">
                {errors.both && <Alert severity="error">{errors.both}</Alert>}
              </div>

              <Button
                size="large"
                fullWidth
                disabled={isSubmitting}
                type="submit"
                className="text-uppercase font-weight-bold font-size-sm btn-primary">
                Sign in
              </Button>
            </form>
          )}
        </Formik>

        <div className="text-center pt-4 text-black-50">
          Don't have an account? <Link to="/register">Create an Account</Link>
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          Oops! Something went wrong.
        </Alert>
      </Snackbar>
    </AuthPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  loggedInUser: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
  isVerified: getVerificationStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (data) => dispatch(login(data)),
  loadSubscription: () => dispatch(loadSubscription()),
  loadPlans: () => dispatch(loadPlans())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login1);
