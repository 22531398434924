import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveTwoTone';

const UpdateForm = ({
  productToUpdate,
  setProductToUpdate,
  showProgress,
  disabled,
  error,
  cancelError
}) => {
  const [disableReduce, setDisableReduce] = useState(false);

  const ErrorMessage = error
    ? 'Sale price should be always smaller than the price'
    : '';

  const changeInputHanlder = (e) => {
    if (e.target.value < 0) {
      setProductToUpdate({ ...productToUpdate, [e.target.name]: 0 });
    } else {
      setProductToUpdate({
        ...productToUpdate,
        [e.target.name]: e.target.value
      });
    }
  };

  const addQuantity = () => {
    const qty = parseInt(productToUpdate.quantity);
    if (qty === 0) {
      setDisableReduce(false);
    }
    setProductToUpdate({ ...productToUpdate, quantity: qty + 1 });
  };

  const reduceQuantity = () => {
    const qty = parseInt(productToUpdate.quantity);
    if (qty > 0) {
      if (qty === 1) {
        setDisableReduce(true);
      }
      setProductToUpdate({ ...productToUpdate, quantity: qty - 1 });
    }
  };

  const Progress = showProgress ? (
    <Grid item container xs={4} alignItems="center" justifyContent="center">
      <Grid item xs={2}>
        <CircularProgress color="success" size="1rem" className="mr-2" />
      </Grid>
      <Grid item xs={2}>
        <Typography className="text-success" fontSize={12}>
          Processing...
        </Typography>
      </Grid>
    </Grid>
  ) : null;

  return (
    <>
      {/* Image && Title section*/}
      <Grid
        item
        container
        spacing={2}
        justifyContent="center"
        alignItems="center">
        <Grid item xs={3}>
          <img
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              '/' +
              productToUpdate.thumbnail
            }
            alt="thumbnail"
          />
        </Grid>

        <Grid item xs={7}>
          <Typography
            sx={{ fontWeight: 'bold' }}
            fontFamily="revert"
            textAlign="center"
            fontSize={12}>
            {productToUpdate.title}
          </Typography>
        </Grid>
      </Grid>

      {/* Price && Sale Price section*/}

      <Grid item xs={4}>
        <TextField
          autoFocus
          label="Price"
          name="price"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            style: { paddingLeft: 20 },
            inputProps: {
              step: '0.01',
              style: { paddingLeft: '1rem ', fontSize: 12 }
            }
          }}
          value={productToUpdate.price}
          fullWidth
          autoComplete="off"
          onChange={changeInputHanlder}
          onFocus={cancelError}
          variant="outlined"
          size="small"
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          error={error}
          label="Sale price"
          name="sale_price"
          value={productToUpdate.sale_price}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            style: { paddingLeft: 20 },
            inputProps: {
              step: '0.01',
              style: { paddingLeft: '1rem ', fontSize: 12 }
            }
          }}
          fullWidth
          variant="outlined"
          autoComplete="off"
          onChange={changeInputHanlder}
          onFocus={cancelError}
          size="small"
          disabled={disabled}
        />
        <Typography
          fontSize={6}
          fontWeight="bold"
          color={error ? 'red' : ''}
          sx={{ px: 2 }}>
          {ErrorMessage}
        </Typography>
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        xs={9}>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ boxShadow: 1 }}
            onClick={reduceQuantity}
            disabled={disableReduce || disabled}
            fullWidth>
            <RemoveIcon fontSize="small" />
          </Button>
        </Grid>
        <Grid item xs={7}>
          <TextField
            type="number"
            label="Quantity"
            name="quantity"
            value={productToUpdate.quantity}
            fullWidth
            variant="outlined"
            autoComplete="off"
            onChange={changeInputHanlder}
            InputProps={{
              inputProps: { style: { textAlign: 'center', fontSize: 12 } }
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ boxShadow: 1 }}
            onClick={addQuantity}
            fullWidth
            disabled={disabled}>
            <AddIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>

      {Progress}
    </>
  );
};

export default UpdateForm;
