import React from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import { PageTitle } from '../../layout-components';
import Dashboard1 from '../../components/Dashboard/Dashboard1';

export default function Dashboard() {
  return (
    <>
      <PageTitle
        titleHeading="Dashboard"
        titleDescription=""
        titleIcon={<DashboardIcon />}>
        {/* <PageTitleActions /> */}
      </PageTitle>

      <Dashboard1 />
    </>
  );
}
