import { axiosIns, axiosIns2 } from '../config/configAxios';
import { transformErrors } from 'utils';
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const ADD_APPLICATION = 'ADD_APPLICATION';
export const addApplicationSuccess = (category) => ({
  type: ADD_APPLICATION,
  payload: { category }
});
export const LOADING_IN_PROGRESS = 'APPS_LOADING_IN_PROGRESS';
export const loadingInProgress = () => ({
  type: LOADING_IN_PROGRESS
});

export const LOADING_SUCCESS = 'APPS_LOADING_SUCCESS';
export const loadingSuccess = (apps) => ({
  type: LOADING_SUCCESS,
  payload: { apps }
});

export const LOADING_FAILURE = 'APPS_LOADING_FAILURE';
export const loadingFailure = () => ({
  type: LOADING_FAILURE
});

/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initialState = { isLoading: false, data: [] };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_APPLICATION: {
      const { category } = payload;
      category.productsCount = 0;
      category.subCategories = [];
      return { ...state, data: state.data.concat(category) };
    }
    case LOADING_SUCCESS: {
      const { apps } = payload;
      console.log(payload);
      return {
        ...state,
        isLoading: false,
        data: apps
      };
    }
    case LOADING_IN_PROGRESS:
      return { ...state, isLoading: true };
    case LOADING_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadApps = () => async (dispatch, getState) => {
  dispatch(loadingInProgress());

  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axiosIns.get('/apps');
    const apps = response.data;
    console.log(apps, 'redux');
    dispatch(loadingSuccess(apps));
  } catch (err) {
    dispatch(loadingFailure());
  }
};
export const addCategory = (data, props) => async (dispatch, getState) => {
  const { setSubmitting, setStatus, resetForm, toggleModal, setErrors } = props;
  setSubmitting(true);

  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === 'seoMetaData') {
      formData.append(`${key}`, JSON.stringify(value));
    } else {
      formData.append(`${key}`, value);
    }
  });

  const state = getState();
  const token = state.UserAccount.data.token;
  axiosIns2.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    const res = await axiosIns2.post('/apps', formData);
    if (res.status === 201) {
      dispatch(addApplicationSuccess(res.data));
      setStatus({ success: true });
      resetForm({});
      toggleModal();
    }
  } catch (err) {
    console.log(err.response.data.errors);
    // console.log()
    setErrors(transformErrors(err.response.data.errors));
    setSubmitting(false);
    // if (err.response.status === 400) {
    //   seterror({ status: true, msg: err.response.data.errors[0].msg });
    // }
  } finally {
    setSubmitting(false);
  }
};
/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getAllApps = (state) => state.Apps.data;
export const getIsLoadingApps = (state) => state.Apps.isLoading;
