import React from 'react';

import Sales from '../../components/SalesTeam/Sales';

export default function SalesTeam() {
  return (
    <>
      <Sales />
    </>
  );
}
