import React, { useEffect } from 'react';
import { Box, Button, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default function QuantityAlerts(props) {
  const [open, setOpen] = React.useState(true);
  const [quantity, setQuantity] = React.useState(0);
  useEffect(() => {
    setQuantity(props.quantity);
  },[props.quantity]);
  return (
    <>
      {quantity > 10 ? (
        <Box sx={{ width: '100%' }}>
          <Collapse in={open}>
            <Alert
              severity="error"
              action={
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                </Button>
              }>
              {' '}
              You have {quantity} Quantity Selected. 150 Products
              Quantity Allowed
            </Alert>
          </Collapse>
        </Box>
      ) : null}
    </>
  );
}
