import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  fetchProducts,
  loadLookupHistory,
  getLookupProgress,
  getLookupInProgress,
  getScannedBarcodes,
  removeBarcode,
  getLookupResults,
  getLookupHistory,
  getSubscriptionStatus,
  hideUpgradeModal
} from '../../reducers/Barcodes';

import { getUser, updateUserCustomSettings } from '../../reducers/UserAccount';

import {
  Table,
  Container,
  Card,
  Button,
  List,
  ListItem,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide
} from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert } from '@material-ui/lab';

import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

import ManualInput from './ManualInput';
import FileUpload from './FileUpload';
import BarcodeScanner from './BarcodeScanner';
import ProgressContainer from './ProgressContainer';
import ResultsContainer from './ResultsContainer';
import HistoryContainer from './HistoryContainer';
import SubscriptionsModal from '../../layout-components/SubscriptionsModal';
import { Field, Formik } from 'formik';

const circularProgressStyle = {
  color: '#1bc943',
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const BarcodesLookup1 = (props) => {
  const {
    barcodes,
    removeBarcode,
    results,
    lookups,
    isFetching,
    startFetching,
    startLoadingHistory,
    progressValue = 0,
    upgradeStatus,
    hideUpgradeModal,
    user,
    updateUser
  } = props;

  const tabs = ['Scan Barcodes', 'Manual Input', 'Batch Upload'];
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const inputRef = useRef('');
  const [openConfig, setOpenConfig] = useState(false);
  const initialValues = {
    autoAssignCategories: user?.customSettings?.autoAssignCategories || false,
    hdStoreID: user?.customSettings?.hdStoreID || ''
  };
  const handleClickOpen = () => {
    setOpenConfig(true);
  };
  const handleClose = () => {
    setOpenConfig(false);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    startLoadingHistory();
  }, [startLoadingHistory]);

  const fetchProducts = () => {
    startFetching();
  };

  const updateUserData = (values) => {
    updateUser(values);
    handleClose();
  };
  const handleSubmit = (values) => {
    setLoading(true);
    updateUserData(values);
    setLoading(false);
  };

  return (
    <>
      <Card>
        <SubscriptionsModal
          status={Object.keys(upgradeStatus).length > 0 ? true : false}
          closeHandler={hideUpgradeModal}
          upgradeStatus={upgradeStatus}
        />
        <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-0">
          <List
            component="div"
            className="w-100 nav-line justify-content-center d-flex nav-line-alt nav-tabs-primary">
            {tabs.map((tabName, i) => (
              <ListItem
                key={i}
                button
                className="p-4 font-size-md rounded-0"
                selected={activeTab === i}
                onClick={() => toggle(i)}>
                <span className="font-weight-bold font-size-sm text-uppercase text-center">
                  {tabName}
                </span>
                <div className="divider" />
              </ListItem>
            ))}
          </List>
          <div className="mr-2">
            <Button
              onClick={() => {
                handleClickOpen();
              }}
              className="btn-outline-primary d-flex align-items-center justify-content-center d-40 p-0 rounded-pill">
              <SettingsTwoToneIcon className="w-50" />
            </Button>
            <Dialog
              open={openConfig}
              onClose={handleClose}
              TransitionComponent={Transition}
              keepMounted
              fullWidth
              maxWidth="sm">
              <DialogTitle>{'Settings'}</DialogTitle>
              <Formik enableReinitialize={true} initialValues={initialValues}>
                {({ values, setFieldValue, handleChange, handleBlur }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <DialogContent>
                        <Grid container>
                          <Grid item md={6} className="mb-3 pl-2">
                            <label className="mb-2">
                              Get local store live price
                              <Tooltip
                                interactive
                                title={
                                  <>
                                    <p>
                                      To get pricing based on your local
                                      storelocation please go to{' '}
                                      <a
                                        className="text-warning"
                                        href="https://www.homedepot.com/l/search"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        here
                                      </a>{' '}
                                      to find your store ID
                                    </p>
                                  </>
                                }>
                                <HelpIcon fontSize="small" />
                              </Tooltip>
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              name="hdStoreID"
                              value={values.hdStoreID}
                              placeholder="Enter HD Store ID # (optional)"
                              inputRef={inputRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <label>
                              <Field
                                type="checkbox"
                                name="autoAssignCategories"
                                // onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.autoAssignCategories}
                                onChange={(e) => {
                                  setFieldValue(
                                    'autoAssignCategories',
                                    e.target.checked
                                  );
                                }}
                                component={Checkbox}
                              />
                              Auto assign products to existing store categories.
                            </label>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          className="btn-success"
                          disabled={loading}
                          onClick={() => handleSubmit(values)}>
                          save
                        </Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            thickness={5}
                            style={{
                              color: '#1bc943',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: -12,
                              marginLeft: -12
                            }}
                          />
                        )}
                        <Button onClick={handleClose}>cancel</Button>
                      </DialogActions>
                    </form>
                  </>
                )}
              </Formik>
            </Dialog>
          </div>
        </div>
        <div className="px-0 py-0">
          <div
            className={clsx('tab-item-wrapper', { active: activeTab === 0 })}
            index={1}>
            <Alert severity="info">
              {/* <AlertTitle>Stripe Account not Connected</AlertTitle> */}
              Phone Scanner only works with <b>Chrome Web Browser</b>
            </Alert>
            <BarcodeScanner />
          </div>

          <div
            className={clsx('tab-item-wrapper', { active: activeTab === 1 })}
            index={2}>
            <ManualInput />
          </div>
          <div
            className={clsx('tab-item-wrapper', { active: activeTab === 2 })}
            index={3}>
            <FileUpload />
          </div>
        </div>

        <div className="divider" />

        <div className="py-3">
          <Container>
            <div className="px-0 pb-4">
              <div>
                <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                  Entered Barcodes
                </h6>
                <p className="text-black-50 mb-0">
                  Total Count: {barcodes.length}
                </p>
              </div>
            </div>
            <div className="table-responsive-md">
              <Table className="table table-sm table-bordered text-nowrap mb-4">
                <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                  <tr>
                    <th className="text-left px-1">Sr</th>
                    <th className="text-left px-1">Source</th>
                    <th className="text-left px-2">Barcode/SKU</th>
                    <th className="text-left px-1">Format</th>
                    <th className="text-left px-1">Qty</th>
                    <th className="text-left px-1">Price</th>
                    <th className="text-left px-2">Condition</th>
                    <th className="text-center" />
                  </tr>
                </thead>
                <tbody>
                  {barcodes.length > 0 ? (
                    barcodes.map((bc, i) => (
                      <tr key={i}>
                        <td className="text-left px-2">{i + 1}.</td>
                        <td className="text-left px-2">{bc.source}</td>
                        <td className="text-left px-4">{bc.value}</td>
                        <td className="text-left px-2">{bc.format}</td>
                        <td className="text-left px-2">{bc.quantity}</td>
                        <td className="text-left px-2">{bc.price}</td>
                        <td className="text-left px-2">{bc.condition}</td>
                        <td className="text-center">
                          <Button
                            className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                            onClick={() => removeBarcode(bc)}>
                            <ClearIcon className="font-size-md font-weight-bold" />
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>Please enter some input</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Container>
          <Container className="d-flex align-items-center justify-content-end">
            <div className="py-2" style={{ position: 'relative' }}>
              <Button
                size="large"
                className="btn-success font-weight-bold text-uppercase"
                onClick={() => fetchProducts()}
                disabled={barcodes.length === 0 || isFetching}>
                Get products
              </Button>
              {isFetching && (
                <CircularProgress
                  size={24}
                  thickness={5}
                  style={circularProgressStyle}
                />
              )}
            </div>
          </Container>
        </div>
      </Card>

      {isFetching && (
        <ProgressContainer
          progress={progressValue}
          totalCount={barcodes.length}
        />
      )}

      {results.length > 0 && !isFetching && (
        <ResultsContainer results={results} />
      )}

      <HistoryContainer lookups={lookups} />
    </>
  );
};

const mapStateToProps = (state) => ({
  barcodes: getScannedBarcodes(state),
  user: getUser(state),
  results: getLookupResults(state),
  progressValue: getLookupProgress(state),
  isFetching: getLookupInProgress(state),
  lookups: getLookupHistory(state),
  upgradeStatus: getSubscriptionStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
  startFetching: () => dispatch(fetchProducts()),
  updateUser: (data) => dispatch(updateUserCustomSettings(data)),
  startLoadingHistory: () => dispatch(loadLookupHistory()),
  removeBarcode: (barcode) => dispatch(removeBarcode(barcode)),
  hideUpgradeModal: () => dispatch(hideUpgradeModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(BarcodesLookup1);
