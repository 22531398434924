import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Checkbox,
  Select,
  MenuItem,
  Dialog,
  Button,
  Popover
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';

import InfoIcon from '@material-ui/icons/InfoRounded';
import { updateOrderById, addComment } from 'reducers/Orders';
export const MouseOverPopover = ({ children, icon, title }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    console.log('close');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        {icon}
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        style={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <div className="p-2 bg-gray">{children}</div>
      </Popover>
    </div>
  );
};
const getPaymentStatus = (paymentObj, total) => {
  const { status, metadata } = paymentObj;
  if (status === 'succeeded') {
    return (
      <div className="font-size-sm opacity-7 text-success d-flex align-items-center">
        <span className="badge badge-success mr-1 border-0 badge-circle">
          {' '}
        </span>
        Paid {paymentObj?.type && `(${paymentObj.type})`}
      </div>
    );
  } else if (status === 'created' || status === 'failed') {
    return (
      <div className="font-size-sm opacity-7 text-danger d-flex align-items-center">
        <span className="badge badge-danger mr-1 border-0 badge-circle"> </span>
        Unpaid
      </div>
    );
  } else if (status === 'refunded') {
    return (
      <div className="font-size-sm opacity-7 text-dark d-flex align-items-center">
        <span className="badge badge-dark mr-1 border-0 badge-circle"> </span>
        {Number(metadata?.amountRefunded).toFixed(2) < Number(total).toFixed(2)
          ? `Partially Refunded`
          : 'Refunded'}
      </div>
    );
  } else if (status === 'processing') {
    return (
      <div className="font-size-sm opacity-7 text-warning d-flex align-items-center">
        <span className="badge badge-warning mr-1 border-0 badge-circle">
          {' '}
        </span>
        Processing {paymentObj?.type && `(${paymentObj.type})`}
      </div>
    );
  } else {
    return (
      <div className="font-size-sm opacity-7 text-warning d-flex align-items-center">
        <span className="badge badge-warning mr-1 border-0 badge-circle">
          {' '}
        </span>
        Incomplete
      </div>
    );
  }
};

const TableRow = ({
  order,
  isChecked,
  onCheck,
  updateOrder,
  token,
  isAbandoned,
  name,
  addComment
}) => {
  const {
    _id,
    serialNo,
    customer,
    status,
    subtotal,
    extraFee,
    tax,
    shipping,
    discount,
    payment,
    createdAt
  } = order;
  const total = subtotal + tax + extraFee + shipping - discount.amount;
  const [dateOnly, timeOnly] = new Date(createdAt)
    .toLocaleString('en-US')
    .split(', ');
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [selectedStatus, setSelectedStatus] = useState();

  // Order status
  const [statusVal, setStatusVal] = useState(status);

  useEffect(() => {
    setStatusVal(status);
  }, [status]);
  const statuses = [
    'New',
    'Cancelled',
    'Pending',
    'Ready',
    'Shipped',
    'Delivered',
    'Completed'
  ];
  const handleStatus = (event) => {
    const value = event.target.value;
    setSelectedStatus(value);
    toggleModal();
  };
  const handleConfirm = () => {
    updateOrder(_id, { status: selectedStatus });
    setStatusVal(selectedStatus);
    addComment(_id, {
      message: `order status changed to ${selectedStatus}`,
      by: `${name}`
    });
    toggleModal();
  };

  return (
    <>
      <tr>
        <td>
          <Checkbox checked={isChecked} onChange={() => onCheck(_id)} />
        </td>
        {isAbandoned !== true && (
          <td>
            <Link
              to={{
                pathname: process.env.REACT_APP_D_PREFIX + '/order/details',
                state: { isAbandoned: isAbandoned, token: token, order: order }
              }}>
              <span className="font-weight-bold text-black-90">
                #{serialNo}
              </span>
            </Link>
          </td>
        )}
        <td className="font-size-sm">
          <div>{dateOnly}</div>
          <div>{timeOnly.replace(' ', '')}</div>
        </td>
        <td>
          <span className="font-weight-bold text-black-90 text-capitalize">
            {customer.firstName + ' ' + customer.lastName}
          </span>
          <span className="text-black-50 font-size-sm d-block">
            {customer.email}
          </span>
        </td>
        {/* <td>
          <div className="font-size-sm">
            {type === 'pickup' ? (
              <>
                <div className="text-black-50">{customer.phone}</div>
                <div>Local Pickup</div>
              </>
            ) : (
              <>
                <div className="text-black-50">
                  {customer.city + ', ' + customer.state}
                </div>
                {type === 'pickup' ? (
                  <div>Local Pickup</div>
                ) : type === 'shipping' ? (
                  <div>Shipping</div>
                ) : (
                  <div>Home Delivery</div>
                )}
              </>
            )}
          </div>
        </td> */}
        {isAbandoned !== true && (
          <td>
            <Select
              variant="outlined"
              margin="dense"
              value={statusVal}
              onChange={handleStatus}
              labelWidth={0}>
              {statuses.map((s) => (
                <MenuItem className="mx-2" value={s} key={s}>
                  {s === 'Ready' ? 'Ready for Pickup' : s}
                </MenuItem>
              ))}
            </Select>
          </td>
        )}
        <td>
          <span className="font-size-lg font-weight-bold">
            ${Number.parseFloat(total).toFixed(2)}
          </span>
          <div className="d-flex gap-2">
            {getPaymentStatus(payment, total)}
            <MouseOverPopover
              title="Cart Abandoned Email Status"
              icon={<InfoIcon fontSize="small" />}>
              <p style={{ width: '100%' }}>
                {order.recovered &&
                (order.payment.status === 'succeeded' ||
                  order.payment.status === 'refunded')
                  ? 'Order Recoverd'
                  : order.emailSent
                  ? 'Email Notification Sent'
                  : 'Email Scheduled'}
              </p>
            </MouseOverPopover>
          </div>
        </td>
        {isAbandoned && (
          <>
            <td>
              {order?.emailSent ? (
                <p className="text-success">Email Sent</p>
              ) : order.firstEmailTaskId ? (
                <p className="text-warning">Scheduled</p>
              ) : (
                <p className="text-danger">Email not Sent</p>
              )}
            </td>
            <td>
              {order.recovered &&
              (order.payment.status === 'succeeded' ||
                order.payment.status === 'refunded') ? (
                <p className="text-success">Recovered</p>
              ) : (
                <p className="text-danger">Not Recovered</p>
              )}
              {/* <MouseOverPopover
            title="Cart Abandoned Email Status"
            icon={<InfoIcon fontSize="small" />}>
            <p className="ml-2" style={{ width: '100%' }}>
              {order.recovered &&
              (order.payment.status === 'succeeded' ||
                order.payment.status === 'refunded')
                ? 'Order Recoverd'
                : order.emailSent
                ? 'Email Notification Sent'
                : 'Email Scheduled'}
            </p>
          </MouseOverPopover> */}
            </td>
          </>
        )}
        <td className="text-right">
          <Link
            to={{
              pathname: process.env.REACT_APP_D_PREFIX + '/order/details',
              state: { isAbandoned: isAbandoned, token: token, order: order }
            }}>
            <Button
              // onClick={toggleModal}
              className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
              <SearchIcon className="font-size-md font-weight-bold" />
            </Button>
          </Link>
        </td>
      </tr>
      <tr className="divider"></tr>
      <Dialog
        scroll="body"
        open={modal}
        maxWidth="sm"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>
        </div>
        <div className="p-4">
          Do you want to change status from {status} to {selectedStatus}?
        </div>
        <div className="divider bg-dark opacity-3" />
        <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral font-size-sm mr-3">
            Cancel
          </Button>
          <Button
            className="btn-danger font-weight-bold"
            onClick={handleConfirm}>
            Change
          </Button>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.UserAccount.data.token,
  name: state.UserAccount.data.firstName
});

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (id, data) => dispatch(updateOrderById(id, data)),
  addComment: (id, data) => dispatch(addComment(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TableRow);
