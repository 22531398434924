import React, { useState } from 'react';

import { Dialog, Button, Grid, Container } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const OrderDetails = ({ request }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const {
    requestNo,
    email,
    firstName,
    lastName,
    createdAt,
    contact,
    address,
    status,
    note,
    productRequest
  } = request;
  return (
    <>
      <Button
        onClick={toggleModal}
        className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
        <SearchIcon className="font-size-md font-weight-bold" />
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="md"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>
        </div>
        <div className="p-4 text-center">
          <h4 className="font-size-xxl font-weight-bold mb-2">
            {`Request #${requestNo}`}
          </h4>
          <p className="text-black-50 mb-0">
            Placed at: {new Date(createdAt).toLocaleString()}
          </p>
          <p className="text-black-50 mb-0">Status: {status}</p>
        </div>
        <div className="divider bg-dark opacity-2" />

        <Container className="pb-4">
          <Grid container spacing={2}>
            <Grid item md={12}>
              <div className="py-2 text-uppercase font-weight-bold text-primary font-size-sm">
                Details
              </div>
              <div className="py-2 font-size-sm p-2 bg-secondary rounded-sm">
                <div className="d-flex ">
                  <span className="font-weight-bold">Name :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {firstName + ' ' + lastName}
                  </span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold">E-mail :</span>
                  <span className="text-black-50  ml-3">{email ?? 'N/A'}</span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold">Phone :</span>
                  <span className="text-black-50  ml-3">
                    {contact ?? 'N/A'}
                  </span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold"> Address :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {address ?? 'N/A'}
                  </span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold"> Keywords :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {productRequest?.keywords ?? 'N/A'}
                  </span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold"> Model # :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {productRequest?.modelNumber ?? 'N/A'}
                  </span>
                </div>{' '}
                <div className="d-flex ">
                  <span className="font-weight-bold"> Matched CLSKU # :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {productRequest?.matchedCLSKU?.join(' ,') ?? 'N/A'}
                  </span>
                </div>{' '}
                <div className="d-flex ">
                  <span className="font-weight-bold"> Category # :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {productRequest?.category?.name ?? 'N/A'}
                  </span>
                </div>
                <div className="d-flex ">
                  <span className="font-weight-bold"> Note :</span>
                  <span className="text-capitalize text-black-50  ml-3">
                    {note ?? 'N/A'}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};

export default OrderDetails;
