import {
  Button,
  Dialog,
  Grid,
  Typography,
  makeStyles,
  DialogContent,
  IconButton,
  DialogTitle
} from '@material-ui/core';
import React, { useState } from 'react';
import BarcodeForm from './BarcodeForm';
import Content from './Content';
import AutoActions from './AutoActions';
import PopUpCancel from './PopUpCancel';
import PopUpAutoActions from './PopUpAutoActions';
import BarcodeImg from 'assets/images/barcodeIcon.jpg';
import { connect } from 'react-redux';
import {
  getIsUploadingProduct,
  getProducts,
  updateProductFromPOS
} from 'reducers/Products';
import { useEffect } from 'react';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px'
  }
}));

const PopUp = ({ updated, allProducts, updateProductById, isUploading }) => {
  const [products, setProducts] = useState([...allProducts]);
  useEffect(() => {
    setProducts([...allProducts]);
  }, [allProducts]);
  useEffect(() => {
    setShowProgress(isUploading);
  }, [isUploading]);
  const [productToUpdate, setProductToUpdate] = useState({
    barcode: '',
    _id: '',
    title: '',
    description: '',
    price: '',
    sale_price: '',
    quantity: '',
    condition: '',
    brand: '',
    categories: [''],
    thumbnail: '',
    images: [''],
    isShippable: false
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [error, setError] = useState(false);

  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const [increase, setIncrease] = useState(false);

  const [decrease, setDecrease] = useState(false);

  const [showProgress, setShowProgress] = useState(false);

  const [autoChecked, setAutoChecked] = useState(false);

  const [showWarningCancel, setshowWarningCancel] = useState(false);

  const [showWarningAutoActions, setshowWarningAutoActions] = useState(false);

  const clearProduct = () => {
    setProductToUpdate({
      barcode: '',
      _id: '',
      title: '',
      description: '',
      price: '',
      sale_price: '',
      quantity: '',
      condition: '',
      brand: '',
      categories: [''],
      thumbnail: '',
      images: [''],
      isShippable: false
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    //close update product popUP
    //if we didn't pull any product yet or the product pulled doesn't changed ==> close
    //else we will show warning popUP to confirm cancelling the previous update or not
    if (
      (isEmpty(productToUpdate.price) &&
        isEmpty(productToUpdate.sale_price) &&
        isEmpty(productToUpdate.quantity)) ||
      !productNotSaved(productToUpdate)
    ) {
      setOpen(false);
      setTimeout(() => {
        setShowUpdateForm(false);
      }, 500);
      setIncrease(false);
      setDecrease(false);
      clearProduct();
    } else {
      setshowWarningCancel(true);
    }
  };

  const isEmpty = (str) => {
    return !str || str.length === 0;
  };

  const productNotSaved = (product) => {
    if (
      !isEmpty(product.price) &&
      !isEmpty(product.sale_price) &&
      !isEmpty(product.quantity)
    ) {
      const comparedProduct = products.find(
        (product) => product.cl_upc === productToUpdate.cl_upc
      );
      if (
        !(comparedProduct.price === productToUpdate.price) ||
        !(comparedProduct.sale_price === productToUpdate.sale_price) ||
        !(comparedProduct.quantity === parseInt(productToUpdate.quantity))
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const cancelUpdateHandler = () => {
    if (showWarningCancel) {
      setshowWarningCancel(false);
      setOpen(false);
      setTimeout(() => {
        setShowUpdateForm(false);
      }, 500);
      setIncrease(false);
      setDecrease(false);
      setAutoChecked(false);
      clearProduct();
    }

    //If the cancel is made by clicking the cancel button in the cancel pop up
    // We need To pull the old product before the updates and use it
    //To initiate the product to update so the user can see the old infos

    if (showWarningAutoActions) {
      setshowWarningAutoActions(false);
      let beforeUpdateProduct = products.find(
        (product) => product.cl_upc === productToUpdate.cl_upc
      );

      const qty = beforeUpdateProduct.quantity;
      const copyProduct = { ...beforeUpdateProduct, quantity: qty.toString() };
      setProductToUpdate(copyProduct);
      setAutoChecked(true);
    }
  };

  const submitInputHanlder = (e) => {
    e.preventDefault();
    if (productToUpdate.price < productToUpdate.sale_price) {
      setError(true);
    } else {
      updateProductById({
        _id: productToUpdate._id,
        quantity: productToUpdate.quantity,
        price: productToUpdate.price,
        sale_price: productToUpdate?.sale_price
      });
      //Separate the logic of submitting the updated product in the warning popUp
      // with clicking the save button
      if (showWarningAutoActions) {
        setshowWarningAutoActions(false);
        setAutoChecked(true);
      } else {
        setOpen(false);
        setTimeout(() => {
          setShowUpdateForm(false);
        }, 500);
      }

      updated(true);
    }
  };

  const Buttons =
    showUpdateForm && !autoChecked ? (
      <Grid
        item
        container
        justifyContent="center"
        className="mb-2 pt-0"
        spacing={2}>
        <Grid item xs={2}>
          <Button variant="outlined" onClick={submitInputHanlder}>
            Save
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            className="border border-secondary text-secondary"
            variant="outlined"
            onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    ) : null;

  const autoActions = showUpdateForm ? (
    <AutoActions
      increase={increase}
      decrease={decrease}
      setIncrease={setIncrease}
      setDecrease={setDecrease}
      updateProductById={updateProductById}
      checked={autoChecked}
      setChecked={setAutoChecked}
      productNotSaved={() => productNotSaved(productToUpdate)}
      showWarning={() => setshowWarningAutoActions(true)}
    />
  ) : null;

  return (
    <>
      <IconButton
        className={classes.root}
        color="primary"
        aria-label="barcode img"
        component="span"
        onClick={handleClickOpen}>
        <img width={'70px'} src={BarcodeImg} alt="barcode " />
      </IconButton>

      <Dialog open={open} >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center">
            <Button
              className="position-absolute top-0 right-0"
              style={{ position: 'absolute', top: '0', right: '5px' }}
              onClick={handleClose}>
              X
            </Button>
            <Typography
              className={classes.form}
              sx={{
                fontWeight: 'bold'
              }}
              textAlign="center"
              fontSize={12}
              // className="my-3"
            >
              Inventory
            </Typography>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <BarcodeForm
              products={products}
              setProductToUpdate={setProductToUpdate}
              showUpdateForm={setShowUpdateForm}
              productToUpdate={productToUpdate}
              increase={increase}
              decrease={decrease}
              updateProductById={updateProductById}
              setProducts={setProducts}
              productNotSaved={productNotSaved}
              isEmpty={isEmpty}
            />

            <Content
              showForm={showUpdateForm}
              productToUpdate={productToUpdate}
              setProductToUpdate={setProductToUpdate}
              showProgress={showProgress}
              disableManualUpdate={autoChecked}
              error={error}
              cancelError={() => setError(false)}
            />

            {autoActions}

            {Buttons}

            <PopUpCancel
              showWarning={showWarningCancel}
              cancelUpdateHandler={cancelUpdateHandler}
              closeWarning={() => setshowWarningCancel(false)}
            />

            <PopUpAutoActions
              showWarning={showWarningAutoActions}
              saveUpdate={submitInputHanlder}
              undoUpdate={cancelUpdateHandler}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  allProducts: getProducts(state),
  isUploading: getIsUploadingProduct(state)
});

const mapDispatchToProps = (dispatch) => ({
  updateProductById: (data) => dispatch(updateProductFromPOS(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
