import {
  Button,
  CircularProgress,
  Collapse,
  Snackbar,
  Tooltip
} from '@material-ui/core';

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import InfoIcon from '@material-ui/icons/Info';
import SyncIcon from '@material-ui/icons/Sync';
import { Alert } from '@material-ui/lab';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  faXmarkCircle,
  faCircleCheck,
  faRocket,
  faLink
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';

import {
  getProducts,
  getZeroProducts,
  loadProducts,
  getUnCategorizedProducts,
  getIsLoadingProducts
} from 'reducers/Products';
import {
  getStoreList,
  healthcheckStore,
  publishStore,
  rebuildStore,
  removeStore,
  getSubscriptionStatus,
  hideUpgradeModal,
  getStoreLocation
} from 'reducers/Store';

import { getUser } from 'reducers/UserAccount';
import SubscriptionsModal from '../../layout-components/SubscriptionsModal';
import clsx from 'clsx';
import DeployProgress from '../../components/Store/Components/DeployProgress';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasZeroPriceProducts: props.zeroPriceCheck,
      UnCategorizedProducts: props.UnCategorizedProducts,
      storeList: [],
      storeListLoading: true,
      publishing: false,
      healthchecking: false,
      republishing: false,
      hasEnoughProducts: false,
      showSuccessSnackbar: false,
      isModified: false,
      removeStoreId: null,
      confirmationText: null,
      isMatched: false,
      deploymentInfos: false
    };
  }

  componentDidMount() {
    this.props.loadProducts();
    this.props.getStoreList(true);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.zeroPriceCheck !== nextProps.zeroPriceCheck) {
      this.setState({ hasZeroPriceProducts: nextProps.zeroPriceCheck });
    }
    if (this.props.UnCategorizedProducts !== nextProps.UnCategorizedProducts) {
      this.setState({ UnCategorizedProducts: nextProps.UnCategorizedProducts });
    }
    if (
      this.props.storeListSucess !== nextProps.storeListSucess &&
      nextProps.storeListSucess === true
    ) {
      this.setState({
        storeList: nextProps.storeList,
        storeListLoading: false
      });
      nextProps.storeList.forEach((store) => {
        if (store.site.status === 'publishing') {
          this.storeHealthCheck(store._id);
        }
      });
    } else if (
      this.props.storeListErrors !== nextProps.storeListErrors &&
      nextProps.storeListErrors !== null
    ) {
      this.setState({ storeListLoading: false });
    }

    if (
      this.props.storeDeleted !== nextProps.storeDeleted &&
      nextProps.storeDeleted === true
    ) {
      this.setState({
        storeList: nextProps.storeList,
        storeListLoading: false
      });
    } else if (
      this.props.storeListErrors !== nextProps.storeListErrors &&
      nextProps.storeListErrors !== null
    ) {
      this.setState({ storeListLoading: false });
    }

    if (
      this.props.storePublished !== nextProps.storePublished &&
      nextProps.storePublished === true
    ) {
      this.setState({
        storeList: nextProps.storeList,
        publishing: false,
        showSuccessSnackbar: true
      });
      this.storeHealthCheck(nextProps.storeId);
    } else if (
      this.props.storeListErrors !== nextProps.storeListErrors &&
      nextProps.storeListErrors !== null
    ) {
      this.setState({ publishing: false });
    }

    if (
      this.props.storeHealthChecked !== nextProps.storeHealthChecked &&
      nextProps.storeHealthChecked === true
    ) {
      this.setState({ storeList: nextProps.storeList, healthchecking: false });
    } else if (
      this.props.storeListErrors !== nextProps.storeListErrors &&
      nextProps.storeListErrors !== null
    ) {
      this.setState({ healthchecking: false });
    }

    if (
      this.props.storeSyncSuccess !== nextProps.storeSyncSuccess &&
      nextProps.storeSyncSuccess === true
    ) {
      this.setState({ storeList: nextProps.storeList, republishing: false });
    } else if (
      this.props.storeListErrors !== nextProps.storeListErrors &&
      nextProps.storeListErrors !== null
    ) {
      this.setState({ republishing: false });
    }
    if (nextProps.isLoading === false) {
      const status = nextProps.products.some(
        (p) =>
          p.isChanged === true &&
          (p.status === 'Published' || p.status === 'Ready')
      );
      this.setState({ isModified: status });
    }
    // TODO: Need to fix
    this.setState({
      storeList: nextProps.storeList,
      hasEnoughProducts: nextProps.products.length > 0
    });
  }

  removeStore = (id) => {
    this.props.removeStore(id);
  };

  handlePublish = (id) => {
    console.log('PUBLISH');
    this.setState({ publishing: true });
    this.props.publishStore(id);

    this.props.loadProducts();
    if (window !== 'undefined')
      window.localStorage.setItem('showStoreDeploymentProgress', true);
  };

  storeHealthCheck = (id) => {
    console.log('storeHealthCheck', id);
    this.setState({ healthchecking: true });
    this.props.healthcheckStore(id);
  };

  syncStore = (id) => {
    console.log('Sync');
    this.setState({ republishing: true });
    this.props.rebuildStore(id);
    this.props.loadProducts();
    if (window !== 'undefined')
      window.localStorage.setItem('showStoreDeploymentProgress', true);
  };
  handleChange = (event) => {
    if (event.target.value === this.state.confirmationText) {
      this.setState({ isMatched: true });
    } else {
      this.setState({ isMatched: false });
    }
  };

  render() {
    return this.state.storeList.length > 0 ? (
      <>
        <SubscriptionsModal
          status={
            Object.keys(this.props.upgradeStatus).length > 0 ? true : false
          }
          closeHandler={this.props.hideUpgradeModal}
          upgradeStatus={this.props.upgradeStatus}
        />
        {this.state.storeList
          ?.filter((store) => store.isActive)
          ?.map((store, key) => (
            <div className="sidebar-menu-box" key={key}>
              {store.site.status === 'init' ? (
                <ul>
                  <li>
                    <Tooltip arrow interactive title="Publish">
                      <Button
                        className="btn btn-primary btn-small"
                        disabled={
                          this.state.publishing || !this.state.hasEnoughProducts
                        }
                        onClick={() => this.handlePublish(store['_id'])}>
                        <span className="sidebar-icon">
                          <FontAwesomeIcon icon={faRocket} />
                        </span>
                        {this.state.publishing && (
                          <CircularProgress
                            size={16}
                            thickness={5}
                            style={{
                              color: '#1bc943',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: -12,
                              marginLeft: -12
                            }}
                          />
                        )}
                      </Button>
                    </Tooltip>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Tooltip arrow interactive title="Sync">
                      <Button
                        className="btn btn-secondary btn-small px-0 w-20"
                        disabled={
                          this.state.republishing ||
                          store.site.status === 'deploying'
                        }
                        onClick={() => this.syncStore(store['_id'])}>
                        <span className="sidebar-icon">
                          <SyncIcon />
                        </span>
                        {(this.state.republishing ||
                          store.site.status === 'deploying') && (
                          <CircularProgress
                            size={16}
                            thickness={5}
                            style={{
                              color: '#1bc943',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: -12,
                              marginLeft: -12
                            }}
                          />
                        )}
                      </Button>
                    </Tooltip>
                  </li>
                </ul>
              )}
              <ul>
                <li>
                  <a
                    href="#/"
                    onClick={(event) => {
                      this.setState({
                        deploymentInfos: !this.state.deploymentInfos
                      });
                      event.preventDefault();
                    }}
                    className={clsx('font-weight-normal', {
                      active: this.state.deploymentInfos
                    })}>
                    <VerifiedUserTwoToneIcon />
                    <span className="sidebar-item-label text-capitalize ml-2">
                      Deployment status :{' '}
                      {store.site.status === 'init'
                        ? 'Unpublished'
                        : store.site.status}
                      {store.site.status === 'deploying' ? (
                        <Tooltip
                          arrow
                          interactive
                          title={`It takes (approx. 5minutes) time to deploy store please be patient refresh page to see updated status`}>
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      ) : store.site.status === 'error' ||
                        store.site.status === 'stopped' ? (
                        <Tooltip
                          arrow
                          interactive
                          title={`Seems like there was an issue with last sync. Try refreshing page to update status before spending a sync or contact our customer support`}>
                          <span>
                            <FontAwesomeIcon
                              icon={faXmarkCircle}
                              className="font-size-sm text-danger ml-1"
                            />
                          </span>
                        </Tooltip>
                      ) : store.site.status === 'init' ? (
                        <Tooltip
                          arrow
                          interactive
                          title={`Push your store live by clicking publish button`}>
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          arrow
                          interactive
                          title={`Hoooray! Store is live`}>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="font-size-sm text-success ml-1"
                          />
                        </Tooltip>
                      )}
                    </span>
                    <span className="sidebar-icon-indicator">
                      <ChevronRightTwoToneIcon />
                    </span>
                  </a>
                  <Collapse in={this.state.deploymentInfos}>
                    <a
                      href={store.site.url}
                      className="text-white pl-0"
                      target="_blank"
                      rel="noopener noreferrer">
                      {store.site.url !== undefined &&
                        store.site.status !== 'init' &&
                        store.site.status !== 'publishing' && (
                          <>
                            <FontAwesomeIcon icon={faLink} className="mr-2" />
                            <span
                              className="sidebar-item-label"
                              style={{ fontSize: '0.8rem' }}>
                              Visit live site
                            </span>
                          </>
                        )}
                    </a>
                    <br />
                    <a
                      href="#/"
                      className=" pl-0 px-0 mb-2 text-white"
                      onClick={(event) => event.preventDefault()}>
                      {store.site.updated_at && (
                        <span
                          className="sidebar-item-label"
                          style={{ fontSize: '0.8rem' }}>
                          Last deployment date:{' '}
                          {new Date(store.site.updated_at).toDateString()}
                        </span>
                      )}
                    </a>
                    <div
                      style={{
                        display: !this.props.sidebarToggle ? 'block' : 'none'
                      }}>
                      {(store.site.status === 'deploying' ||
                        store.site.status === 'publishing') && (
                        <DeployProgress
                          store={store}
                          totalProducts={this.props.products?.length || 0}
                        />
                      )}
                    </div>
                  </Collapse>
                </li>
              </ul>
            </div>
          ))}

        <Snackbar
          open={this.state.showSuccessSnackbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ showSuccessSnackbar: false })}>
          <Alert
            onClose={() => this.setState({ showSuccessSnackbar: false })}
            severity="success">
            Your store has been published successfully.
          </Alert>
        </Snackbar>
      </>
    ) : (
      <div className="sidebar-menu-box">
        <ul>
          <span
            className="sidebar-item-label text-white"
            style={{ fontSize: '0.8rem' }}>
            <li> No store published yet.</li>
          </span>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  storeList: state.Store.data,
  storeListSucess: state.Store.success,
  storeListErrors: state.Store.errors,
  storeDeleted: state.Store.deleteSuccess,
  storePublished: state.Store.publishSuccess,
  storeHealthChecked: state.Store.healthcheckSuccess,
  storeSyncSuccess: state.Store.rebuildSuccess,
  storeId: state.Store.storeId,
  storelocations: getStoreLocation(state),
  products: getProducts(state),
  zeroPriceCheck: getZeroProducts(state),
  UnCategorizedProducts: getUnCategorizedProducts(state),
  isLoading: getIsLoadingProducts(state),
  upgradeStatus: getSubscriptionStatus(state),
  user: getUser(state),
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

const mapDispatchToProps = (dispatch) => ({
  getStoreList: (isActive) => dispatch(getStoreList(isActive)),
  removeStore: (id) => dispatch(removeStore(id)),
  publishStore: (id) => dispatch(publishStore(id)),
  healthcheckStore: (id) => dispatch(healthcheckStore(id)),
  loadProducts: () => dispatch(loadProducts()),
  rebuildStore: (id) => dispatch(rebuildStore(id)),
  hideUpgradeModal: () => dispatch(hideUpgradeModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
