import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';

import { addBarcode } from '../../../reducers/Barcodes';

import TabItemWrapper from '../TabItemWrapper';

const ManualInput = ({ addBarcode }) => {
  const sources = [
    { label: 'Amazon', value: 'amazon' },
    { label: 'Home Depot', value: 'homedepot' }
  ];
  const [source, setSource] = useState('');
  const handleSource = (e) => setSource(e.target.value);

  const [continuous, setContinuous] = useState(false);
  const handleContinuous = (e) => setContinuous(e.target.checked);

  const formats = ['upc', 'asin', 'sku'];

  const inputRef = useRef('');
  const [format, setFormat] = useState('');
  const [quantity, setQuantity] = useState(1);

  const onFormatChange = (event) => {
    setFormat(event.target.value);
  };

  const guessFormat = () => {
    const value = inputRef.current.value.trim();
    const asinRegex = /^(B0|BT)[0-9A-Z]{8}$/;
    const upcRegex = /^\d{12}|\d{13}$/;

    if (asinRegex.test(value)) setFormat('asin');
    else if (upcRegex.test(value)) setFormat('upc');
    else setFormat('sku');
    if (continuous && value.length === 13) onAdd();
  };

  const onAdd = () => {
    const value = inputRef.current.value.trim();
    if (value) {
      addBarcode({ value, format, source, quantity });
      inputRef.current.value = '';
      setFormat('');
      setQuantity(1);
    }
  };

  return (
    <TabItemWrapper title="Manual Input" subtitle="Enter barcodes manually.">
      <Grid container spacing={1}>
        <Grid item md={1}>
          <label className="font-weight-bold py-2">
            Source <span className="text-danger">*</span>
          </label>
        </Grid>
        <Grid item md={11}>
          <RadioGroup
            aria-label="source"
            name="source"
            value={source}
            onChange={handleSource}
            row={true}>
            {sources.map(({ label, value }, i) => (
              <FormControlLabel
                key={i}
                value={value}
                control={<Radio color="primary" />}
                label={label}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
      {!source && (
        <div className="text-info  font-size-sm text-danger">
          Please select a source
        </div>
      )}
      <div className="mb-2">
        <FormControlLabel
          control={
            <Checkbox
              name="mode"
              checked={continuous}
              onChange={handleContinuous}
            />
          }
          label="Enable continuous scanning mode (for barcode readers only)"
        />
      </div>
      <Grid container spacing={4}>
        <Grid item md={4}>
          <label className="font-weight-bold mb-2">Barcode/SKU</label>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Enter barcode/sku..."
            inputRef={inputRef}
            onChange={guessFormat}
          />
        </Grid>
        <Grid item md={3}>
          <label className="font-weight-bold mb-2">Quantity</label>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            placeholder="Qty"
            name="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </Grid>
        <Grid item md={3}>
          <label className="font-weight-bold mb-2">Format</label>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            value={format}
            onChange={onFormatChange}
            labelWidth={0}>
            {formats.map((f) => (
              <MenuItem className="mx-2" value={f} key={f}>
                {f}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={2}>
          <label className="font-weight-bold mb-2">{'\u00A0'}</label>
          <Button
            size="large"
            fullWidth
            disabled={!source}
            className="btn-primary font-weight-bold"
            onClick={onAdd}>
            Add
          </Button>
        </Grid>
      </Grid>
    </TabItemWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addBarcode: (barcode) => dispatch(addBarcode(barcode))
});

export default connect(null, mapDispatchToProps)(ManualInput);
