import React from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { PageTitle } from 'layout-components';
import PagesContainer from 'components/Store/CustomCssEditor';
import { getUser } from 'reducers/UserAccount';
import { Brush } from '@material-ui/icons';

const ShippingHomePage = () => {
  const { id } = useParams();

  return (
    <>
      <PageTitle
        titleHeading="Customize theme CSS"
        titleDescription="Use custom CSS to change the look and feel of your store."
        titleIcon={<Brush fontSize='24px'/>}></PageTitle>

      <PagesContainer storeId={id}  />
    </>
  );
};

const mapStateToProps = (state) => ({ user: getUser(state) });

export default connect(mapStateToProps)(ShippingHomePage);
