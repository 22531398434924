import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Dialog,
  Grid,
  CircularProgress,
  TextareaAutosize
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';

import { Formik } from 'formik';

import { emailUserNotify, getErrors, getIsLoadingForms } from 'reducers/Forms';
import { Alert } from '@material-ui/lab';

const SendEmail = (props) => {
  const { emailUserNotify, form, error } = props;

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [errors, seterrors] = useState({ status: false, msg: '' });
  const initValues = {
    note: form?.note || ''
  };

  const handleSubmit = (values, { ...props }) => {
    seterrors({ status: false, msg: '' });
    props.setSubmitting(true);
    if (form?._id) emailUserNotify(form._id, values, { ...props, toggleModal });
    else
      emailUserNotify(form._id, values, { ...props, toggleModal, seterrors });
    props.setSubmitting(false);
  };

  return (
    <>
      {form?._id ? (
        <Button
          size="small"
          className="btn-first hover-scale-sm d-30 p-0 mx-1 btn-icon"
          onClick={toggleModal}>
          <MailIcon className="font-size-lg" />
        </Button>
      ) : (
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={toggleModal}>
          <MailIcon className="font-size-lg" />
        </Button>
      )}

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="sm"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="p-4">
          <h4 className="font-size-xl font-weight-bold mb-0">
            {form?._id ? 'Send Email' : 'Send Email'}
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />
        {error && <Alert severity="error">{error}</Alert>}
        <Formik initialValues={initValues} onSubmit={handleSubmit}>
          {({
            values,
            // errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-4">
              
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <div>
                      <label className="font-weight-bold mb-1">
                        Add a special note:{' '}
                        <span className="text-danger">*</span>
                      </label>

                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        placeholder="Enter note..."
                        style={{ width: 300, height: 200 }}
                        name="note"
                        value={values.note}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.note && errors.note}
                        error={errors.note && touched.note}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className="divider bg-dark opacity-3" />

              <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
                <Button
                  onClick={toggleModal}
                  className="btn-neutral font-size-sm mr-2">
                  Cancel
                </Button>
                <div style={{ position: 'relative' }}>
                  <Button
                    className="btn-success font-weight-bold"
                    type="submit"
                    disabled={isSubmitting}>
                    {isSubmitting ? 'Sending...' : 'Send'}
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      thickness={5}
                      style={{
                        color: '#1bc943',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12
                      }}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  error: getErrors(state),
  isLoading: getIsLoadingForms(state)
});
const mapDispatchToProps = (dispatch) => ({
  emailUserNotify: (id, data, props) =>
    dispatch(emailUserNotify(id, data, props))
});

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);
