import React from 'react';

import { PageTitle } from '../../layout-components';
import { TruckloadRequests } from '../../components';

export default function FormsPage() {

  return (
    <>
      <PageTitle
        titleHeading="FormsPage"
        titleDescription="Manage all your form submissions for interacting with your customers."></PageTitle>

      <TruckloadRequests  />
    </>
  );
}
