import { CircularProgress, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { axiosIns } from 'config/configAxios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getIsLoadingPlans } from 'reducers/Plans';
import { fetchUser, getIsUploadingUser, getUser } from 'reducers/UserAccount';
import {
  getIsLoadingSubscription,
  getIsUploadingSubscription,
  getSubscription
} from 'reducers/UserSubscriptions';

function OwedBalance({ user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    console.log('users effect');
    setIsLoading(true);
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    axiosIns.get('/users/platforms/stripe/user/unpaid').then((response) => {
      setIsLoading(false);
      setData(response.data);
    });
  }, [user]);
  return !isLoading ? (
    data?.balance > 0 && (
      <Alert className="mb-4" severity="warning">
        <span>
          <Typography
            variant="subtitle1"
            className="text-primary pl-2 font-size-sm text-uppercase"
            style={{ fontWeight: 'bold' }}>
            You have a balance due of
            {!data ? '$ 0.00' : '$ ' + Number(data.balance / 100).toFixed(2)} as
            pending dues. Please update your payment method or your account will
            be suspended soon.
          </Typography>
        </span>
      </Alert>
    )
  ) : (
    <CircularProgress size={24} thickness={5} />
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  isUploading: getIsUploadingUser(state),
  isLoading: getIsLoadingPlans(state),
  isLoadingSubscription: getIsLoadingSubscription(state),
  isUploadingSubscription: getIsUploadingSubscription(state),
  subscription: getSubscription(state)
});
const mapDispatchToProps = (dispatch) => ({
  loadUser: (data) => dispatch(fetchUser(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(OwedBalance);
