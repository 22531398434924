import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Card,
  Badge,
  Container,
  Table,
  Button,
  Typography,
  Dialog,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CardContent
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import ClearIcon from '@material-ui/icons/Clear';

import placeholder from 'assets/images/placeholders/no-product-image.png';

import { deleteScannedProduct } from '../../../reducers/Barcodes';
// import EditProduct from '../../Products/EditProduct';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ResultsContainer = ({ results, deleteProduct }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [selectedId, setselectedId] = useState(null);

  const foundProds = results.filter((prod) => prod.result !== '');
  const categories = foundProds
    .map((prod) => prod.result.categories[0])
    .filter((item, pos, self) => self.indexOf(item) === pos);
  categories.forEach((elemnt) => {
    let numOfProducts = 0;
    foundProds.forEach((product) => {
      if (product.result.categories[0] === elemnt) {
        numOfProducts = numOfProducts + 1;
      }
    });
    console.log(
      `the number of products for the category ${elemnt} is ${numOfProducts}`
    );
  });
  console.log(foundProds);
  // console.log(categories, 'here');
  const nonFoundProds = results.length - foundProds.length;
  console.log(
    `total number of found products: ${foundProds.length}`,
    `total number of non-found products: ${nonFoundProds}`
  );
  let prod5stars =
    (foundProds.filter((res) => Math.trunc(Number(res.result.rating)) === 5)
      .length /
      foundProds.length) *
    100;
  let prod4stars =
    (foundProds.filter((res) => Math.trunc(Number(res.result.rating)) === 4)
      .length /
      foundProds.length) *
    100;
  let prod3stars =
    (foundProds.filter((res) => Math.trunc(Number(res.result.rating)) === 3)
      .length /
      foundProds.length) *
    100;
  let prod2stars =
    (foundProds.filter((res) => Math.trunc(Number(res.result.rating)) === 2)
      .length /
      foundProds.length) *
    100;
  let prod1stars =
    (foundProds.filter((res) => Math.trunc(Number(res.result.rating)) === 1)
      .length /
      foundProds.length) *
    100;

  console.log(
    `% of prod with 5 stars: ${prod5stars.toFixed(2)}% |`,
    `% of prod with 4 stars: ${prod4stars.toFixed(2)}% |`,
    `% of prod with 3 stars: ${prod3stars.toFixed(2)}% |`,
    `% of prod with 2 stars: ${prod2stars.toFixed(2)}% |`,
    `% of prod with 1 stars: ${prod1stars.toFixed(2)}% `
  );

  let retailsPrice = foundProds.reduce(
    (previous, current) =>
      Number(current.result.price) * Number(current.result.quantity) + previous,
    0
  );
  console.log(`total retail price: ${retailsPrice.toFixed(2)}`);

  return (
    <>
      <div className="divider my-4" />
      <Card className="mb-4">
        <CardContent>
        <div className="px-0 pb-4">
          <h6 className="font-weight-bold font-size-xl mb-1 text-black">
            Statistics
          </h6>
          <p className="text-black-50 mb-0">
           Statistics about manifest lookup
          </p>
        </div>
          <Table className="table  text-nowrap mb-4">
            <TableHead className="thead-light text-capitalize font-size-sm font-weight-bold">
              <TableRow>
                <TableCell className="text-left px-2">
                  {' '}
                  found products
                </TableCell>
                <TableCell className="text-left px-2">
                  {' '}
                  non-found products
                </TableCell>
                <TableCell className="text-left px-2">
                  Total retail price
                </TableCell>
                <TableCell className="text-left px-2">
                  {' '}
                  5 <FontAwesomeIcon icon={faStar} />{' '}
                </TableCell>
                <TableCell className="text-left px-2">
                  {' '}
                  4 <FontAwesomeIcon icon={faStar} />{' '}
                </TableCell>
                <TableCell className="text-left px-2">
                  3 <FontAwesomeIcon icon={faStar} />{' '}
                </TableCell>
                <TableCell className="text-left px-2">
                  {' '}
                  2 <FontAwesomeIcon icon={faStar} />{' '}
                </TableCell>
                <TableCell className="text-left px-2">
                  {' '}
                  1 <FontAwesomeIcon icon={faStar} />{' '}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <td>{`${((foundProds.length / results.length) * 100).toFixed(
                  2
                )}%`}</td>
                <td>{`${((nonFoundProds / results.length) * 100).toFixed(
                  2
                )}%`}</td>
                <TableCell>{`$${retailsPrice.toFixed(2)}`}</TableCell>
                <TableCell>{`${prod5stars.toFixed(2)}%`}</TableCell>
                <TableCell>{`${prod4stars.toFixed(2)}%`}</TableCell>
                <TableCell>{`${prod3stars.toFixed(2)}%`}</TableCell>
                <TableCell>{`${prod2stars.toFixed(2)}%`}</TableCell>
                <TableCell>{`${prod1stars.toFixed(2)}%`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {categories.map((elemnt, index) => {
            let numOfProducts = 0;
            foundProds.forEach((product) => {
              if (product.result.categories[0] === elemnt) {
                numOfProducts = numOfProducts + 1;
              }
            });
            return (
              <React.Fragment key={`category-${index}`}>
                <Typography
                  variant="title1"
                  style={{fontWeight: 'bold'}}>{`The number of products for the category ${elemnt} is : ${numOfProducts}`}</Typography>
                <br />
              </React.Fragment>
            );
          })}
        </CardContent>
      </Card>
      <Card style={{ overflow: 'auto' }}>
        <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-0">
          <Container className="py-4">
            <div className="px-0 pb-4">
              <div>
                <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                  Results
                </h6>
                <p className="text-black-50 mb-0">
                  We can not find any product info against these barcodes.
                  Please try to add them manually.
                </p>
              </div>
            </div>
            {/* <div className="table-responsive-md"> */}
            <Table className="table  text-nowrap mb-4">
              <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                <tr>
                  <th className="text-left px-2">Sr</th>
                  <th className="text-left px-2">Image</th>
                  <th className="text-left px-2">Barcode</th>
                  <th className="text-left px-2">Title</th>
                  <th className="text-left px-2">Price</th>
                  <th className="text-left px-2">Quantity</th>
                  <th className="text-left px-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {results.map((res, i) => (
                  <tr key={i}>
                    {res.message === undefined ? (
                      <>
                        <td className="text-left">{i + 1}</td>
                        <td>
                          {' '}
                          {res.result.thumbnail ? (
                            <Badge
                              // color={
                              //   res.condition.match(/New|Like-new/i)
                              //     ? 'secondary'
                              //     : 'error'
                              // }
                              badgeContent={res.condition}
                              className="mr-1 mt-1">
                              <img
                                alt="..."
                                className="hover-scale-lg rounded-sm"
                                src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${res.result.thumbnail}`}
                                loading="lazy"
                                style={{ width: 70 }}
                              />
                            </Badge>
                          ) : (
                            <img
                              alt={res.result.thumbnail}
                              className="hover-scale-lg rounded-sm"
                              src={placeholder}
                              style={{ width: 50 }}
                            />
                          )}
                        </td>
                        <td className="text-left ">{res.barcode}</td>
                        <td>
                          <p
                            style={{
                              width: '15ch',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}>
                            {res.result.title}
                          </p>
                        </td>
                        <td>$ {Number(res.result.price).toFixed(2)}</td>
                        <td>{res.result.quantity}</td>
                        <td>
                          {' '}
                          <Button
                            onClick={() => {
                              setselectedId(res);
                              toggleModal();
                            }}
                            className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                            <ClearIcon className="font-size-md font-weight-bold" />
                          </Button>
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to={{
                              pathname: `${process.env.REACT_APP_D_PREFIX}/products/editProduct`,
                              state: { productDetails: res.result }
                            }}>
                            <Button className="btn-first mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                              <EditIcon className="font-size-md font-weight-bold" />
                            </Button>
                          </Link>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="text-left ">{i + 1}.</td>
                        <td className="text-left ">{res.barcode}</td>
                        <td colSpan="4" className="text-left px-4 text-danger">
                          {res.message}
                          {''}
                        </td>
                        <td>
                          {' '}
                          {res.message !== 'Already_Exist' && (
                            <Button
                              className="btn btn-primary"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${process.env.REACT_APP_D_PREFIX}/products/addProduct`}>
                              <span className="btn-wrapper--icon">
                                <AddIcon />
                              </span>
                              <Typography variant="subtitle1">
                                {' '}
                                Add product
                              </Typography>
                            </Button>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* </div> */}
          </Container>
        </div>
      </Card>
      <Dialog
        scroll="body"
        open={modal}
        maxWidth="xs"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="p-4">
          <h4 className="font-size-xxl font-weight-bold mb-2">
            Delete Product
          </h4>
          <p className="text-black-50 mb-0">
            You'll lose all related data to the product named{' '}
            <span className="font-weight-bold">
              {selectedId?.result?.title}
            </span>
            . Are sure you want to permanently delete this?
          </p>
        </div>
        <div className="divider bg-dark opacity-3" />
        <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral font-size-sm mr-3">
            Cancel
          </Button>
          <Button
            className="btn-danger font-weight-bold"
            onClick={() => {
              deleteProduct(selectedId);
              toggleModal();
            }}>
            Yes, delete it
          </Button>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  // user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
  deleteProduct: (id) => dispatch(deleteScannedProduct(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsContainer);
