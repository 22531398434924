import { Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const SuccessPopUp = ({ success, handleClose }) => {
  return (
    <Snackbar
      open={success}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        <Typography fontSize={12} fontWeight="bold">
          Product updated with success !
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default SuccessPopUp;
