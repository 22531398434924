import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Button,
  TableRow,
  Paper,
  Grid,
  Tooltip,
  Typography,
  Dialog,
  CircularProgress
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import TablePagination from '@material-ui/core/TablePagination';

import DeleteForever from '@material-ui/icons/DeleteForever';
// import AddRequestForm from './AddRequestForm';
// import RequestDetails from './RequestDetails';

const useStyles = makeStyles((theme) => ({
  table: {
    borderRadius: '10px',
    margin: '0 auto',
    width: '100%'
  },
  tableContainer: {
    borderRadius: '10px',
    margin: '0 auto'
  },
  tableHeaderCell: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.light)
  },
  name: {
    fontWeight: 'bold',
    color: theme.palette.secondary.dark,
    marginLeft: '2rem'
  },
  status: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: 'white',
    backgroundColor: 'grey',
    borderRadius: 8,
    padding: '3px 10px',
    display: 'inline-block'
  }
}));

function Contacts({ crequest, handleStatus, deleteForm, newsletterProgress }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [open, setOpen] = useState(false);

  const [isDeleteDialogueOpen, setOpenDeleteDialogue] = useState(false);
  const [selectedID, setSelectedId] = useState(null);
  //AddrequestForm handlers
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  //pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleRemove = (id) => {
    deleteForm(id);
    setOpenDeleteDialogue(false);
    setSelectedId(null);
  };
  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell} align="left">
                Name
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="left">
                Email
              </TableCell>

              <TableCell className={classes.tableHeaderCell} align="left">
                Date
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="left">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newsletterProgress ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <div className="mt-3">
                    <CircularProgress />
                  </div>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              crequest
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>
                      <Grid container>
                        {/* <Grid item lg={2}>
                          <Avatar
                            alt={row.name}
                            src="."
                            className={classes.avatar}
                          />
                        </Grid> */}
                        <Grid item lg={10}>
                          <Typography className={classes.name}>
                            {row.firstName} {row.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography color="primary" variant="subtitle2">
                        {row.email}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography color="primary" variant="subtitle2">
                        {new Date(row.createdAt).toLocaleString()}
                      </Typography>
                    </TableCell>

                    {/* <TableCell>
                      <FormControl
                        variant="outlined"
                        size="small"
                        className="w-100">
  
                        <Select
                          fullWidth
                          labelId="select-label"
                          label="Select Store"
                          value={row.status}
                          onChange={(e) =>
                            handleStatus(row._id, { status: e.target.value })
                          }>
                          {['pending', 'completed', 'rejected'].map((s) => (
                            <MenuItem className="mx-2" value={s} key={s}>
                              {s}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell> */}

                    <TableCell>
                      {/* <Tooltip title="Show details"> */}
                      {/* <RequestDetails request={row} /> */}
                      {/* </Tooltip> */}
                      <Tooltip title="Delete request">
                        <Button
                          className="btn-neutral-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                          onClick={() => {
                            setSelectedId(row._id);

                            setOpenDeleteDialogue(true);
                          }}>
                          <DeleteForever />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <div className="divider" />
        <div className="p-3 d-flex justify-content-center">
          <TablePagination
            count={crequest.length}
            rowsPerPageOptions={[3, 6, 9]}
            component="div"
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>
      <Dialog
        scroll="body"
        open={isDeleteDialogueOpen}
        maxWidth="xs"
        onClose={() => {
          setOpenDeleteDialogue(false);
          setSelectedId(null);
        }}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="p-4">
          <h4 className="font-size-xxl font-weight-bold mb-2">
            Delete Request
          </h4>
          <p className="text-black-50 mb-0">
            {' '}
            Are you sure you want to permanently delete this?
          </p>
        </div>
        <div className="divider bg-dark opacity-3" />

        <div className="divider bg-dark mt-3 opacity-3" />
        <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={() => {
              setOpenDeleteDialogue(false);
              setSelectedId(null);
            }}
            className="btn-success font-size-sm mr-3">
            Cancel
          </Button>
          <Button
            className="btn-danger font-weight-bold"
            onClick={() => {
              handleRemove(selectedID);
            }}>
            Delete
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default Contacts;
