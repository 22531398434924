import React from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Button, Tooltip } from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

import illustration1 from '../../assets/images/illustrations/pack4/personal_settings.svg';

export default function LivePreviewExample({ status }) {
  const history = useHistory();
  const handlePaymentNavigation = () => {
    history.push('/app/payment');
  };
  return (
    <>
      <div className="app-wrapper bg-white">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className="px-4 px-lg-0 mx-auto text-center text-black">
                        <img
                          src={illustration1}
                          className="w-50 mx-auto d-block my-5 img-fluid"
                          alt="..."
                        />

                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                          {status === 'past_due'
                            ? 'Your Subscription is on hold due to a unpaid invoice'
                            : 'Your Plan has expired'}
                        </h3>
                        <p>
                          {status === 'past_due'
                            ? 'This maybe caused because of payment failure multiple times Please check you payments page or email for more information'
                            : 'You are seeing this message because your subscription has expired and cancelled'}
                        </p>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            sm={12}
                            md={4}
                            className="mt-4 mt-lg-3 px-2">
                            <Button
                              onClick={handlePaymentNavigation}
                              className="d-block w-100 btn-first"
                              size="large">
                              <span className="btn-wrapper--label">
                                Payment Details
                              </span>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="hero-footer py-4">
                      <Tooltip title="Facebook" arrow>
                        <Button
                          className="btn-link font-size-lg rounded-sm d-40 btn-icon text-facebook btn-animated-icon"
                          href="#/"
                          onClick={(e) => e.preventDefault()}>
                          <span className="btn-wrapper--icon d-flex">
                            <FacebookIcon />
                          </span>
                        </Button>
                      </Tooltip>
                      <Tooltip title="Twitter" arrow>
                        <Button
                          className="btn-link font-size-lg rounded-sm d-40 btn-icon text-twitter btn-animated-icon"
                          href="#/"
                          onClick={(e) => e.preventDefault()}>
                          <span className="btn-wrapper--icon d-flex">
                            <TwitterIcon />
                          </span>
                        </Button>
                      </Tooltip>
                      <Tooltip title="Instagram" arrow>
                        <Button
                          className="btn-link font-size-lg rounded-sm d-40 btn-icon text-instagram btn-animated-icon"
                          href="#/"
                          onClick={(e) => e.preventDefault()}>
                          <span className="btn-wrapper--icon d-flex">
                            <InstagramIcon />
                          </span>
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
