import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  MenuItem,
  ListItem,
  List,
  Typography,
  ListItemText,
  ListItemIcon,
  FormControl,
  Box,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  Switch,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Grid,
  Button,
  TextField,
  DialogActions
} from '@material-ui/core';
import QuantityAlerts from './QuantityAlert';
import TransitionAlerts from './Alert';
import MyDocument from './GeneratePdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faQrcode } from '@fortawesome/free-solid-svg-icons';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 !important',
    padding: '0 !important'
  }
}));
function QrCodeGenerator(props) {
  const { setOpen, open, products } = props;
  const [quantity, setQuantity] = useState(0);
  const [count, setCount] = useState(0);
  const classes = useStyles();
  const [showProductName, setShowProductName] = useState(true);
  const [image, setImage] = useState(true);
  const [showProductPrice, setShowProductPrice] = useState(true);
  const [showDiscountPrice, setShowDiscountPrice] = useState(true);
  const [showSavePrice, setShowSavePrice] = useState(true);
  const [Layout, setLayout] = useState('A4');
  const [Notes, SetNotes] = useState('');
  const [showNotes, setShowNotes] = useState(true);
  const [stock, setStockQty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [url, setUrl] = useState('product');

  const [openSpec, SetSpec] = useState(false);

  const [qrLayout, SetQrLayout] = useState(false);

  useEffect(() => {
    console.log(products?.length, 'Length');
    products.forEach((item) => {
      setQuantity((q) => Number(q) + Number(item.quantity));
      setCount((c) => Number(c) + Number(1));
    });
  }, [products]);
  const handleSelect = (event) => {
    setUrl(event.target.value);
  };

  const handleChangeNotes = (event) => {
    SetNotes(event.target.value);
  };
  const SetShowNotes = (event) => {
    if (event.target.checked) {
      setShowNotes(true);
    } else {
      setShowNotes(false);
    }
  };
  const NameHandler = (event) => {
    if (event.target.checked) {
      setShowProductName(true);
    } else {
      setShowProductName(false);
    }
  };

  const priceHandler = (event) => {
    if (event.target.checked) {
      setShowProductPrice(true);
    } else {
      setShowProductPrice(false);
    }
  };

  const discountHandler = (event) => {
    if (event.target.checked) {
      setShowDiscountPrice(true);
    } else {
      setShowDiscountPrice(false);
    }
  };

  const savePriceHandler = (event) => {
    if (event.target.checked) {
      setShowSavePrice(true);
    } else {
      setShowSavePrice(false);
    }
  };

  const imageHandler = (event) => {
    if (event.target.checked) {
      setImage(true);
    } else {
      setImage(false);
    }
  };

  const stockHandler = (event) => {
    if (event.target.checked) {
      setStockQty(true);
    } else {
      setStockQty(false);
    }
  };

  const ResetFilter = () => {
    setShowProductName(false);
    setShowProductPrice(false);
    setShowDiscountPrice(false);
    setShowSavePrice(false);
    setShowNotes(false);
    setImage(false);
    setStockQty(false);

    setLayout('A4');
    setUrl('');

    SetQrLayout(false);
    SetSpec(false);
  };

  const handleSpecs = (event) => {
    setIsLoading(false);
    if (event.target.checked) {
      SetSpec(true);
      SetQrLayout(false);
      setLayout('A4');
      setShowProductName(true);
      setShowProductPrice(true);
      setShowDiscountPrice(true);
      setShowSavePrice(true);
      setShowNotes(true);
      setImage(true);
      setStockQty(true);
    } else {
      SetSpec(false);
      SetQrLayout(false);
      setLayout('A4');
    }
  };
  const handleqr = (event) => {
    setIsLoading(false);
    if (event.target.checked) {
      SetQrLayout(true);
      SetSpec(false);
      setLayout('A4');
      setShowProductName(true);
      setShowProductPrice(true);
      setShowDiscountPrice(true);
      setShowSavePrice(true);
      setShowNotes(true);
      setImage(true);
      setStockQty(true);
    } else {
      SetQrLayout(false);
      SetSpec(false);
      setLayout('A4');
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>QR-Code options</DialogTitle>
      <DialogContent>
        <TransitionAlerts count={count} />
        <QuantityAlerts quantity={quantity} />
        <Grid container spacing={0} className="my-3">
          <Grid item md={6}>
            <div className="p-2">
              <Button
                variant="outlined"
                className="d-block border-0 w-100 shadow-none py-3 btn-transition-none btn-animated-icon-sm">
                <div className="mx-auto">
                  <span className="btn-wrapper--icon btn-icon mx-auto font-size-lg d-40 bg-light rounded-pill d-block text-primary mb-2">
                    <FontAwesomeIcon icon={faFilePdf} />
                  </span>
                </div>
                <span className="font-weight-bold font-size-md">
                  <Checkbox
                    checked={openSpec}
                    onClick={handleSpecs}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  Download QR-Codes As PDF
                </span>
              </Button>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="p-2">
              <Button
                variant="outlined"
                className="d-block border-0 w-100 shadow-none py-3 btn-transition-none btn-animated-icon-sm">
                <div className="mx-auto">
                  <span className="btn-wrapper--icon btn-icon mx-auto font-size-lg d-40 bg-light rounded-pill d-block text-primary mb-2">
                    <FontAwesomeIcon icon={faQrcode} />
                  </span>
                </div>
                <span className="font-weight-bold font-size-md">
                  <Checkbox
                    onClick={handleqr}
                    checked={qrLayout}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  Download Qr-Codes As Images
                </span>
              </Button>
            </div>
          </Grid>
        </Grid>

        {openSpec && (
          <>
            <Grid container>
              <Grid item md={6}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  Details to show
                </Typography>{' '}
                <List>
                  <ListItem className={classes.root}>
                    <ListItemIcon>
                      <Checkbox
                        checked={showProductName}
                        onChange={NameHandler}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Product Title`} />
                  </ListItem>
                  <ListItem className={classes.root}>
                    <ListItemIcon>
                      <Checkbox
                        checked={showProductPrice}
                        onChange={priceHandler}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Product Price`} />
                  </ListItem>
                  <ListItem className={classes.root}>
                    <ListItemIcon>
                      <Checkbox
                        checked={showDiscountPrice}
                        onChange={discountHandler}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Product Compare At Price`} />
                  </ListItem>

                  <ListItem className={classes.root}>
                    <ListItemIcon>
                      <Checkbox
                        checked={image}
                        onChange={imageHandler}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Show Image`} />
                  </ListItem>
                  <ListItem className={classes.root}>
                    <ListItemIcon>
                      <Checkbox
                        checked={showSavePrice}
                        onChange={savePriceHandler}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Save At Price`} />
                  </ListItem>
                  <ListItem className={classes.root}>
                    <ListItemIcon>
                      <Checkbox
                        checked={showNotes}
                        onChange={SetShowNotes}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Notes`} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  Qr Code Links To
                </Typography>

                <FormControl
                  variant="outlined"
                  size="small"
                  className="w-100 mb-3">
                  {/* <InputLabel id="select-label">QR-code link</InputLabel> */}
                  <Select value={url} onChange={handleSelect} fullWidth>
                    <MenuItem value={'store'}>Store Url</MenuItem>
                    <MenuItem value={'product'}>Product Page Url</MenuItem>
                    <MenuItem value={'homedepot'}>
                      Listing Url (Vendor Url)
                    </MenuItem>
                  </Select>
                </FormControl>

                {showNotes && (
                  <>
                    <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                      Type Notes
                    </Typography>
                    <TextField
                      className="mb-3"
                      value={Notes}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Add a note..."
                      onChange={handleChangeNotes}
                    />
                  </>
                )}
                <ListItem>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={stock}
                          onChange={stockHandler}
                          inputProps={{ 'aria-label': 'controlled' }}
                          className="switch-small"
                        />
                      }
                    />
                  </FormGroup>
                  <Typography variant="subtitle2" className="font-weight-bold">
                    Generate QR-codes based on stock quantity
                  </Typography>
                </ListItem>
              </Grid>
            </Grid>
          </>
        )}
        {qrLayout && (
          <Grid container>
            <Grid item lg={6} md={6} className="mb-3">
              <Typography
                variant="subtitle1"
                className="mb-2"
                style={{ fontWeight: 800 }}>
                Download QrCodes As Images Into a Zip File
              </Typography>
              <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                Qr Code Links To
              </Typography>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    id="demo-simple-select"
                    value={url}
                    onChange={handleSelect}>
                    <MenuItem value={'store'}>Store Url</MenuItem>
                    <MenuItem value={'product'}>Product Page Url</MenuItem>
                    <MenuItem value={'homedepot'}>
                      Listing Url (Vendor Url)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <MyDocument
          activeStore={props.activeStore}
          ResetFilter={ResetFilter}
          products={products}
          showProductName={showProductName}
          showProductPrice={showProductPrice}
          showDiscountPrice={showDiscountPrice}
          showSavePrice={showSavePrice}
          showNotes={showNotes}
          Layout={Layout}
          Notes={Notes}
          url={url}
          stock={stock}
          image={image}
          quantity={quantity}
          qrLayout={qrLayout}
          openSpec={openSpec}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        <Button
          className="btn btn-dark"
          onClick={() => {
            setOpen(false);
            ResetFilter();
          }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QrCodeGenerator;
