import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container, Button } from '@material-ui/core';

// import hero6 from '../../../assets/images/hero-bg/hero-6.jpg';
import { NavLink } from 'react-router-dom';
import CommerceHeader from '../../../components/Homepage/Homepage1/CommerceHeader';

import hand from '../../../assets/images/homebanner.png';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function LivePreviewExample() {
  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper" style={{ backgroundColor: "#0c145b"}} >
        <Container className="header-top-section py-2">
          <CommerceHeader />
        </Container>
        <div className="divider bg-white-5" />
        <div className="hero-wrapper--content">
          {/* <div
            className="bg-composed-wrapper--image"
            style={{ backgroundImage: 'url(' + hero6 + ')' }}
          /> */}
          {/* <div className="bg-composed-wrapper--bg opacity-5" /> */}
          <div className="bg-composed-wrapper--content">
            <div className="">
              <div className="pb-0">
                <Container className="z-over text-white text-center MuiContainer-maxWidthXl ">
                  <Grid container spacing={2} className="py-5">
                    <Grid item lg={6} className="d-flex align-items-center">
                      <div className="text-center text-lg-left">
                        <h1 className="display-3 font-weight-bold">
                          Convert your Barcodes into a powerful eCom store Fast!
                        </h1>
                        <p className="font-size-xl py-3 text-white-50">
                          Cartlauncher is the only solution that allows
                          Liquidation businesses to launch a powerful online
                          store from barcodes.
                        </p>
                        <div className="pt-3">
                          <Button
                            component={NavLink}
                            to="/Register"
                            size="large"
                            className="btn-pill"
                            style={{
                              border: '5px dotted #1e2e50',
                              background: '#fff'
                            }}>
                            <span className="btn-wrapper--label">
                              Launch my store now
                            </span>
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      justify="center"
                      className="d-flex align-items-center mt-5 mt-lg-0 justify-content-center 	d-none d-lg-block">
                      <div className="object-skew d-flex flex-wrap align-items-center justify-content-center">
                        <img
                          src={hand}
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                          className="m-auto img-fluid d-none d-lg-block"
                          alt="..."
                          fetchpriority="high"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Container>
                <div className="shape-container-top-1 z-below d-none d-lg-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 900 181"
                    fill="none">
                    <path
                      d="M0 4.00006L18.8 7.50006C37.7 11.0001 75.3 18 112.8 22.3C150.3 26.7 187.7 28.3001 225.2 36.7001C262.7 45.0001 300.3 60.0001 337.8 72.7001C375.3 85.3001 412.7 95.7001 450.2 93.3C487.7 91 525.3 76 562.8 59.8C600.3 43.7001 637.7 26.3001 675.2 15.5001C712.7 4.70006 750.3 0.300049 787.8 0.300049C825.3 0.300049 862.7 4.70005 881.3 6.80005L900 9.00006V182H881.3C862.7 182 825.3 182 787.8 182C750.3 182 712.7 182 675.2 182C637.7 182 600.3 182 562.8 182C525.3 182 487.7 182 450.2 182C412.7 182 375.3 182 337.8 182C300.3 182 262.7 182 225.2 182C187.7 182 150.3 182 112.8 182C75.3 182 37.7 182 18.8 182H0V4.00006Z"
                      fill="#0C145B"
                    />
                    <path
                      d="M0 137L18.8 137.5C37.7 138 75.3 139 112.8 129C150.3 119 187.7 98 225.2 84.3C262.7 70.7 300.3 64.3 337.8 64.7C375.3 65 412.7 72 450.2 85.3C487.7 98.7 525.3 118.3 562.8 124.2C600.3 130 637.7 122 675.2 110C712.7 98 750.3 82 787.8 86.5C825.3 91 862.7 116 881.3 128.5L900 141V182H881.3C862.7 182 825.3 182 787.8 182C750.3 182 712.7 182 675.2 182C637.7 182 600.3 182 562.8 182C525.3 182 487.7 182 450.2 182C412.7 182 375.3 182 337.8 182C300.3 182 262.7 182 225.2 182C187.7 182 150.3 182 112.8 182C75.3 182 37.7 182 18.8 182H0V137Z"
                      fill="#FEA403"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
