import React, { useState, useEffect } from 'react';
import { ListItem, List, Button, CircularProgress } from '@material-ui/core';

import clsx from 'clsx';
import { WantedProducts, Contacts, Newsletters } from './Tables';
import { connect } from 'react-redux';
import {
  getIsLoadingForms,
  getOrders,
  updateOrderById,
  loadOrders,
  deleteRequest
} from 'reducers/Forms';
import FileSaver from 'file-saver';
import { transformToCsv } from './outils';

const RequestTable = ({
  orders,
  updateOrder,
  loadById,
  stores,
  deleteForm,
  authUserId
}) => {
  const [crequest, setCRequest] = useState(orders);

  const [wantedProductsProgress, setwantedProductsProgress] = useState(false);
  const [contactsProgress, setContactsProgress] = useState(true);
  const [newsletterProgress, setnewsletterProgress] = useState(true);

  const [activeTab, setActiveTab] = useState('0');
  useEffect(() => {
    if (stores && stores.length > 0) {
      if (activeTab === '0') {
        loadById(stores[0]._id, 'product');
      } else if (activeTab === '2') {
        loadById(stores[0]._id, 'contact');
      } else if (activeTab === '3') {
        loadById(stores[0]._id, 'newsletter');
      }
    }
  }, [activeTab, loadById, stores]);
  useEffect(() => {
    setCRequest(orders);
  }, [orders]);

  //tabs handlers
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // const handleRemove = (req, name) => {
  //   setCRequest(crequest.filter((request) => request.name !== name));
  // };
  const handleStatus = (id, value) => {
    updateOrder(id, value);
  };

  const loadWantedProductsHandler = () => {
    toggle('0');
    setTimeout(() => {
      setwantedProductsProgress(false);
    }, 600);
    setContactsProgress(true);
    setnewsletterProgress(true);
  };
  const loadContactsHandler = () => {
    toggle('2');
    setTimeout(() => {
      setContactsProgress(false);
    }, 600);
    setnewsletterProgress(true);
    setwantedProductsProgress(true);
  };
  const loadNewsletterHandler = () => {
    toggle('3');
    setTimeout(() => {
      setnewsletterProgress(false);
    }, 600);
    setContactsProgress(true);
    setwantedProductsProgress(true);
  };


  const downloadNewslettersHandler = () => {
    const data = transformToCsv(orders);
    const csv = data.map((r) => r.join(',')).join('\n');

    let name = `Newsletters.csv`;
    let file = new Blob([csv], { type: 'text/csv' });
    FileSaver.saveAs(file, name);
  };
  return (
    <>
      <List className="nav-tabs nav-tabs-primary d-flex">
        <ListItem
          button
          disableRipple
          selected={activeTab === '0'}
          onClick={loadWantedProductsHandler}>
          Wanted items
        </ListItem>

        <ListItem
          button
          disableRipple
          selected={activeTab === '2'}
          onClick={loadContactsHandler}>
          contact
        </ListItem>
        <ListItem
          button
          disableRipple
          selected={activeTab === '3'}
          onClick={loadNewsletterHandler}>
          Newsletter
        </ListItem>
      </List>

      <div
        className={clsx('tab-item-wrapper', {
          active: activeTab === '0'
        })}
        index={0}>
        {wantedProductsProgress ? (
          <div className="mt-3 d-flex justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <WantedProducts
            crequest={crequest}
            handleStatus={handleStatus}
            updateOrder={updateOrder}
            deleteForm={deleteForm}
            wantedProductsProgress={wantedProductsProgress}
          />
        )}
      </div>

      <div
        className={clsx('tab-item-wrapper', {
          active: activeTab === '2'
        })}
        index={2}>
        <Contacts
          crequest={crequest}
          handleStatus={handleStatus}
          updateOrder={updateOrder}
          deleteForm={deleteForm}
          contactsProgress={contactsProgress}
        />
      </div>
      <div
        className={clsx('tab-item-wrapper', {
          active: activeTab === '3'
        })}
        index={3}>
        <Newsletters
          crequest={crequest}
          handleStatus={handleStatus}
          updateOrder={updateOrder}
          deleteForm={deleteForm}
          newsletterProgress={newsletterProgress}
        />

        {orders.length > 0 && (
          <Button
            variant="contained"
            className="btn-first mt-5 mr-3 float-right"
            onClick={downloadNewslettersHandler}>
            Download Newsletters.
          </Button>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  orders: getOrders(state),
  isLoading: getIsLoadingForms(state),
  stores: state.Store.data,
  authUserId: state.UserAccount.data._id
});

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (id, data) => dispatch(updateOrderById(id, data)),
  loadById: (id, data) => dispatch(loadOrders(id, data)),
  deleteForm: (id) => dispatch(deleteRequest(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestTable);
