import React, { useState } from 'react';

import clsx from 'clsx';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import PublishTwoToneIcon from '@material-ui/icons/PublishTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import CheckIcon from '@material-ui/icons/Check';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  getUser,
  updateUser,
  getIsUploadingUser,
  getUploadStatus,
  uploadInit
} from '../../../reducers/UserAccount';
import {
  makeStyles,
  Grid,
  Container,
  Card,
  MenuItem,
  Button,
  Snackbar,
  TextField,
  Select,
  CircularProgress,
  CardContent
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ChangePassword from 'components/Settings/ChangePassword';
const useStyles = makeStyles(() => ({
  circularProgress: {
    color: '#1bc943',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));
const Profile = function Profile({
  user,
  updateUser,
  isUploading,
  status,
  uploadInit
}) {
  const country = [
    'Switzerland',
    'Portugal',
    'United States',
    'United Kingdom',
    'Spain',
    'China',
    'Germany'
  ];
  const classes = useStyles();

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => cb(reader.result);
  };

  const [files, setFiles] = useState(
    `${process.env.REACT_APP_ASSETS_BASE_URL}/${user.image}`
  );
  const [fileChanged, setFileChanged] = useState(false);

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps
  } = useDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      getBase64(acceptedFiles[0], (img) => {
        setFiles(img);
        setFileChanged(true);
        return img;
      });
    }
  });
  const thumbs = (
    <div>
      <div
        key={files}
        className="rounded-circle avatar-image overflow-hidden d-140 bg-neutral-success text-center font-weight-bold text-success d-flex justify-content-center align-items-center">
        <img
          className="img-fluid img-fit-container rounded-sm"
          src={files}
          alt="..."
        />
      </div>
    </div>
  );
  const ValidationSchema = Yup.object().shape({
    areaCode: Yup.string()
      .min(3, 'Make sure area code is 3 digits number')
      .max(3, 'Make sure area code is 3 digits number'),
    phone: Yup.number().positive('Area code cannot be negative'),
    postalCode: Yup.string()
      .min(5, 'Please enter a valid postal code')
      .max(5, 'Please enter valid postal code')
  });
  const initValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    areaCode: user.areaCode,
    phone: user.phone,
    postalCode: user.postalCode,
    address: user.address,
    city: user.city,
    country: user.country
  };

  return (
    <>
      <Card>
        <CardContent>
          <div className="card-header">Personal Details</div>
          <Container>
            <Grid container>
              <Grid item md={12}>
                <div className="d-block d-lg-flex justify-content-between align-items-center py-3">
                  <div className="d-flex align-items-center mr-0 mr-md-4">
                    <div>
                      <div className="font-weight-bold">Change Password</div>
                      <span className="opacity-6 d-block">
                        You can change the password for your account using this
                        dialog window.
                      </span>
                    </div>
                  </div>
                  <ChangePassword />
                </div>
              </Grid>
            </Grid>
          </Container>
          <Formik
            initialValues={initValues}
            validationSchema={ValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (fileChanged) {
                values = { ...values, image: files };
              }
              updateUser(values);
              setSubmitting(false);
              setFileChanged(false);
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="px-0 py-0 py-lg-4">
                  <div
                    className={clsx('tab-item-wrapper', { active: true })}
                    index={1}>
                    <Container>
                      <div className="text-uppercase font-weight-bold text-primary pt-4 font-size-sm">
                        General
                      </div>
                      <div className="py-4">
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center">
                          <Grid item md={2}>
                            <div className="d-block d-md-flex align-items-start">
                              <div className="dropzone rounded-circle shadow-sm-dark mr-md-3">
                                <div
                                  {...getRootProps({
                                    className: 'dropzone-upload-wrapper'
                                  })}>
                                  <input {...getInputProps()} />
                                  <div className="dropzone-inner-wrapper d-140 rounded-circle dropzone-avatar">
                                    <div className="avatar-icon-wrapper d-140 rounded-circle m-2">
                                      <Button
                                        onClick={open}
                                        className="btn-first avatar-button badge shadow-sm-dark btn-icon badge-position badge-position--bottom-right border-0 text-indent-0 d-40 badge-circle badge-first text-white">
                                        <PublishTwoToneIcon className="d-20" />
                                      </Button>

                                      <div>
                                        {isDragAccept && (
                                          <div className="rounded-circle overflow-hidden d-140 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                            <CheckIcon className="d-40" />
                                          </div>
                                        )}
                                        {isDragReject && (
                                          <div className="rounded-circle overflow-hidden d-140 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                            <CloseTwoToneIcon className="d-60" />
                                          </div>
                                        )}
                                        {!isDragActive && (
                                          <div className="rounded-circle overflow-hidden d-140 bg-second text-center font-weight-bold text-white-50 d-flex justify-content-center align-items-center">
                                            <AccountCircleTwoToneIcon className="d-50" />
                                          </div>
                                        )}
                                      </div>

                                      {thumbs !== '' && thumbs}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="py-4">
                        <Grid container direction="row" spacing={1}>
                          <Grid item md={3}>
                            <div className="mb-4">
                              <label className="font-weight-bold mb-2">
                                First Name
                              </label>
                              <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                name="firstName"
                                placeholder="First name..."
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="mb-4">
                              <label className="font-weight-bold mb-2">
                                Last Name
                              </label>
                              <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Last name..."
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid>

                          <Grid item md={2}>
                            <div className="mb-4">
                              <label className="font-weight-bold mb-2">
                                Area Code
                              </label>
                              <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Area..."
                                name="areaCode"
                                value={values.areaCode}
                                helperText={touched.areaCode && errors.areaCode}
                                error={errors.areaCode && touched.areaCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="mb-4">
                              <label className="font-weight-bold mb-2">
                                Number
                              </label>

                              <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Number..."
                                name="phone"
                                value={values.phone}
                                helperText={touched.phone && errors.phone}
                                error={errors.phone && touched.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Container>
                    <div className="divider mb-4" />
                    <Container>
                      <div className="text-uppercase font-weight-bold text-primary font-size-sm pb-4">
                        Location
                      </div>
                      <Grid container spacing={6}>
                        <Grid item md={4}>
                          <div className="mb-4">
                            <label className="font-weight-bold mb-2">
                              Postal Code
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              placeholder="Postal code..."
                              name="postalCode"
                              value={values.postalCode}
                              helperText={
                                touched.postalCode && errors.postalCode
                              }
                              error={errors.postalCode && touched.postalCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="mb-4">
                            <label className="font-weight-bold mb-2">
                              Country
                            </label>
                            <Select
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              name="country"
                              value={values.country ?? ''}
                              onChange={(value) =>
                                setFieldValue(
                                  value.target.name,
                                  value.target.value
                                )
                              }
                              onBlur={(value) =>
                                setFieldTouched(value.target.name, true)
                              }
                              labelWidth={0}>
                              {country.map((c) => (
                                <MenuItem className="mx-2" value={c} key={c}>
                                  {c}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="mb-4">
                            <label className="font-weight-bold mb-2">
                              City
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              placeholder="City..."
                              value={values.city}
                              name="city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <div className="mb-4">
                        <label className="font-weight-bold mb-2">Address</label>
                        <TextField
                          multiline
                          variant="outlined"
                          fullWidth
                          size="small"
                          placeholder="Address..."
                          value={values.address}
                          name="address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </Container>
                    <div className="divider mt-5 mb-4" />
                    <Container className="d-flex align-items-center justify-content-end">
                      <div className="py-4">
                        <Button
                          size="large"
                          disabled={isUploading}
                          type="submit"
                          className="btn-success font-weight-bold">
                          Save details
                          {isUploading && (
                            <CircularProgress
                              size={24}
                              thickness={5}
                              className={classes.circularProgress}
                            />
                          )}
                        </Button>
                      </div>
                    </Container>
                    <Snackbar
                      open={status === 'success' && !isSubmitting}
                      autoHideDuration={3000}
                      onClose={() => uploadInit()}>
                      <Alert severity="success">
                        Your Profile was Updated Successfully
                      </Alert>
                    </Snackbar>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  isUploading: getIsUploadingUser(state),
  status: getUploadStatus(state)
});
const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  uploadInit: () => dispatch(uploadInit())
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
