import React from 'react';
import { useHistory } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faIn,faFa,faTw,faGo } from '@fortawesome/free-solid-svg-icons';

import { Grid, Container, List, ListItem } from '@material-ui/core';

import projectLogo from '../../../assets/images/logo193.png';

export default function LivePreviewExample() {
  const history = useHistory();
  return (
    <>
      <div className="bg-second py-5 mb-0">
        <Container className="py-0 text-center text-xl-left py-xl-5">
          <Grid container spacing={4}>
            <Grid
              item
              xl={6}
              md={4}
              lg={6}
              className="d-flex align-items-center">
              <div className="mb-5 mb-xl-0 w-100">
                <div className="app-nav-logo justify-content-center text-left justify-content-xl-left flex-column flex-xl-row">
                  <a
                    href="/"
                    onClick={(e) => e.preventDefault()}
                    title="CartLauncher logo"
                    className="app-nav-logo">
                    <div className="app-nav-logo--icon rounded-circle">
                      <img alt="CartLauncher logo" src={projectLogo} />
                    </div>
                  </a>
                </div>
                <p className="my-4 text-white-50">
                  We tend to help companies to launch powerful online stores and
                  scale their business
                </p>
              </div>
            </Grid>
            <Grid item xl={6} md={8} lg={6} className="d-none d-md-flex">
              <Grid container spacing={6} className="w-100">
                <Grid item md={4}>
                  <div className="divider-v divider-v-lg opacity-1 d-none d-xl-block" />
                  <div className="pl-0">
                    <h6 className="text-white text-left font-weight-bold mb-3">
                      Product
                    </h6>
                    <List
                      component="div"
                      className="nav-transparent-alt flex-column align-items-center">
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Overview
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Features
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Tutorials
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Pricing
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Releases
                      </ListItem>
                    </List>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="divider-v divider-v-lg opacity-1 d-none d-xl-block" />
                  <div className="pl-0 pl-lg-3">
                    <h6 className="text-white text-left font-weight-bold mb-3">
                      Company
                    </h6>
                    <List
                      component="div"
                      className="nav-transparent-alt flex-column">
                      <ListItem
                        component="a"
                        button
                        href="/about"
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        About
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Press
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Careers
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Contact
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        // href="#/"
                        onClick={(e) => history.push('/partner')}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Partners
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        // href="#/"
                        onClick={(e) => history.push('/blogs')}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Blogs
                      </ListItem>
                    </List>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="pl-0 pl-lg-3">
                    <h6 className="text-white text-left font-weight-bold mb-3">
                      Support
                    </h6>
                    <List
                      component="div"
                      className="nav-transparent-alt flex-column">
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => history.push('/terms')}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Help center
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => history.push('/terms')}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Terms of services
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => history.push('/terms')}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Legal
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => history.push('/terms')}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Privacy policy
                      </ListItem>
                      <ListItem
                        component="a"
                        button
                        href="#/"
                        onClick={(e) => history.push('/terms')}
                        className="d-block d-xl-flex px-0 py-1 text-white-50">
                        Status
                      </ListItem>
                    </List>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="divider border-2 d-none d-md-block rounded-circle border-white bg-white opacity-2 mx-auto my-5 w-50" />
          <small className="text-center d-block text-white-50">
            &copy; 2022 CartLauncher, all rights reserved
          </small>
          {/* <List
                    component="div"
                    className="nav-transparent text-nowrap d-flex justify-content-center">
                    <ListItem
                      button
                      className="text-white-50"
                      href="#/"
                      onClick={(e) => e.preventDefault()}>
                      <FontAwesomeIcon
                        icon={['fa-solid fa-facebook']}
                        className="font-size-lg"
                      />
                    </ListItem>
                    <ListItem
                      button
                      className="text-white-50"
                      href="#/"
                      onClick={(e) => e.preventDefault()}>
                      <FontAwesomeIcon
                        icon='fab fa-twitter'
                        className="font-size-lg"
                      />
                    </ListItem>
                    <ListItem
                      button
                      className="text-white-50"
                      href="#/"
                      onClick={(e) => e.preventDefault()}>
                     <FontAwesomeIcon icon="fa-brands fa-google" />
                    </ListItem>
                    <ListItem
                      button
                      className="text-white-50"
                      href="#/"
                      onClick={(e) => e.preventDefault()}>
                      <FontAwesomeIcon
                        icon='fab fa-instagram'
                        className="font-size-lg"
                      />
                    </ListItem>
                  </List> */}
        </Container>
      </div>
    </>
  );
}
