export const transformErrors = (res) => {
  let errors = {};
  try {
    for (let i = 0; i < res.length; i++) {
      const { param, msg } = res[i];
      if (param.includes('.')) {
        let newerr = {};
        const [t1, t2] = param.split('.');
        newerr[t2] = msg;
        errors[t1] = { ...newerr };
      } else errors[param] = msg;
    }
  } catch (err) {
    console.log(err);
  }
  return errors;
};

export const resizeImg = (file, options) =>
  new Promise((resolve) => {
    const objectUrl = URL.createObjectURL(file);
    const img = new Image();
    img.onload = function () {
      const MAX_WIDTH = options?.maxWidth || 1000;
      const MAX_HEIGHT = options?.maxHeight || 1000;
      let width = this.width;
      let height = this.height;

      if (width <= MAX_WIDTH && height <= MAX_HEIGHT) resolve(file);

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 100);
      URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  });

  // --------- Constants
   const  STORE = ['TGT', 'DG', 'AMZ', 'LWS', 'WM', 'KL', 'JCP','HD', 'other'];
   export default STORE