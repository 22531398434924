import { axiosIns } from '../config/configAxios';
const LOADING_FEATURES_IN_PROGRESS = 'LOADING_FEATURES_IN_PROGRESS';
const LOADING_FEATURES_SUCCESS = 'LOADING_FEATURES_SUCCESS';
export const loadingFeaturesInProgress = () => ({
  type: LOADING_FEATURES_IN_PROGRESS
});
export const loadingFeaturesSuccessful = (data) => ({
  type: LOADING_FEATURES_SUCCESS,
  payload: { features: data }
});

const LOADING_PLANS_IN_PROGRESS = 'LOADING_PLANS_IN_PROGRESS';
const LOADING_PLANS_SUCCESS = 'LOADING_PLANS_SUCCESS';
const ADD_PLANS = 'ADD_PLANS';
export const loadingPlansInProgress = () => ({
  type: LOADING_PLANS_IN_PROGRESS
});
export const loadingPlansSuccessful = (data) => ({
  type: LOADING_PLANS_SUCCESS,
  payload: { plans: data }
});
export const addPlansSuccess = (data) => ({
  type: ADD_PLANS,
  payload: { data }
});

export const UPLOADING_IN_PROGRESS = 'PRODUCTS_UPLOADING_IN_PROGRESS';
export const uploadingInProgress = () => ({
  type: UPLOADING_IN_PROGRESS
});

export const UPLOADING_SUCCESS = 'PRODUCTS_UPLOADING_SUCCESS';
export const uploadingSuccess = () => ({
  type: UPLOADING_SUCCESS
});

export const UPLOADING_FAILURE = 'PRODUCTS_UPLOADING_FAILURE';
export const uploadingFailure = () => ({
  type: UPLOADING_FAILURE
});
const initialState = {
  data: {
    features: [],
    plans: []
  },
  error: {},
  isLoadingPlans: false,
  isLoadingFeatures: false,
  isUploading:false
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_FEATURES_IN_PROGRESS: {
      return { ...state, isLoadingFeatures: true };
    }

    case LOADING_FEATURES_SUCCESS: {
      // eslint-disable-next-line no-case-declarations
      const { features } = payload;
      if (state.data.plans.length > 0) {
        return {
          ...state,
          data: { ...state.data, features },
          isLoadingFeatures: false
        };
      } else {
        return {
          ...state,
          data: { plans: [], features },
          isLoadingFeatures: false
        };
      }
    }
    case LOADING_PLANS_IN_PROGRESS: {
      return { ...state, isLoadingPlans: true };
    }
    case LOADING_PLANS_SUCCESS: {
      const { plans } = payload;
      return {
        ...state,
        data: { ...state.data, plans },
        isLoadingPlans: false
      };
    }
    default:
      return state;
  }
}
/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */
export const loadFeatures = () => async (dispatch, getState) => {
  dispatch(loadingFeaturesInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.get('/features');
    const features = response.data;
    dispatch(loadingFeaturesSuccessful(features));
  } catch (e) {}
};
export const loadPlans = () => async (dispatch, getState) => {
  dispatch(loadingPlansInProgress());
  try {
    const state = getState();
    const token = state.UserAccount.data.token;
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axiosIns.get('/plan');
    const plan = response.data;
    dispatch(loadingPlansSuccessful(plan));
  } catch (e) {}
};
export const getFeatures = (state) => state.Plans.data.features;
export const getIsLoadingFeatures = (state) => state.Plans.isLoadingFeatures;
export const getPlans = (state) => state.Plans.data.plans;
export const getIsLoadingPlans = (state) => state.Plans.isLoadingPlans;
export const getIsUploadingPlans = (state) => state.Plans.isUploading;
