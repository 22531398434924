import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  makeStyles,
  Dialog,
  Button,
  TextField,
  Container,
  CircularProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { axiosIns } from '../../../config/configAxios';

import { getUser } from '../../../reducers/UserAccount';

const useStyles = makeStyles(() => ({
  circularProgress: {
    color: '#1bc943',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const ValidationSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  newPassword: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  confirmpassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must match'
  )
});

const ChangePassword = ({ user }) => {
  const [isUploading, setisUploading] = useState(false);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const classes = useStyles();

  const initValues = {
    password: '',
    newPassword: '',
    confirmpassword: '',
    error: null
  };

  return (
    <>
      <div className="d-block d-md-flex mt-3 mt-lg-0 align-items-center">
        <Button
          onClick={toggleModal}
          size="small"
          className="btn-warning text-nowrap">
          Change Password
        </Button>
      </div>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="md"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="p-4 text-center">
          <h4 className="font-size-xxl font-weight-bold mb-2">
            Change Password
          </h4>
          <p className="text-black-50 mb-0">
            Fill in the form fields below to change your password.
          </p>
        </div>
        <div className="divider bg-dark opacity-2" />

        <Formik
          initialValues={initValues}
          validationSchema={ValidationSchema}
          onSubmit={(
            values,
            { setSubmitting, setErrors, setStatus, resetForm, setFieldError }
          ) => {
            setisUploading(true);
            axiosIns.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${user.token}`;
            axiosIns
              .put('/users/password', values)
              .then((res) => {
                if (res.data.success) {
                  setStatus({ success: true });
                  resetForm({});
                  setisUploading(false);
                  toggleModal();
                } else {
                  setStatus({ success: false });
                  setisUploading(false);
                }
              })
              .catch((err) => {
                console.log(err);
                setStatus({ success: false });
                setisUploading(false);
                setFieldError('error', 'You entered incorrect password');
              });
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <Container>
                <div className="text-uppercase font-weight-bold text-primary pt-4 font-size-sm">
                  Update Password
                </div>

                <div className="py-4">
                  <div className="mb-3">
                    <label className="font-weight-bold mb-1">
                      Current Password
                    </label>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Current Password..."
                      variant="outlined"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={errors.password && touched.password}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="font-weight-bold mb-1">
                      New Password
                    </label>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="New Password..."
                      variant="outlined"
                      type="password"
                      name="newPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                      helperText={touched.newPassword && errors.newPassword}
                      error={errors.newPassword && touched.newPassword}
                    />
                  </div>

                  <label className="font-weight-bold mb-2">
                    Confirm New Password
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Confirm new password..."
                    size="small"
                    type="password"
                    name="confirmpassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmpassword}
                    helperText={
                      touched.confirmpassword && errors.confirmpassword
                    }
                    error={errors.confirmpassword && touched.confirmpassword}
                  />
                </div>
              </Container>

              <div className="mb-3">
                {errors.error && <Alert severity="error">{errors.error}</Alert>}
              </div>
              <div className="divider bg-dark opacity-3" />

              <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
                <Button
                  onClick={toggleModal}
                  className="btn-neutral-danger font-size-sm mr-4">
                  Cancel
                </Button>

                <div style={{ position: 'relative' }}>
                  <Button
                    className="btn-success font-weight-bold"
                    type="submit"
                    disabled={isUploading}>
                    Save now
                  </Button>
                  {isUploading && (
                    <CircularProgress
                      size={24}
                      thickness={5}
                      className={classes.circularProgress}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state)
});

export default connect(mapStateToProps)(ChangePassword);
