import React from "react";

import UpdateForm from "./UpdateForm";
import Icons from "./Icons";

const Content = ({
  showForm,
  productToUpdate,
  setProductToUpdate,
  showProgress,
  disableManualUpdate,
  error,
  cancelError,
}) => {
  const content = showForm ? (
    <UpdateForm
      productToUpdate={productToUpdate}
      setProductToUpdate={setProductToUpdate}
      showProgress={showProgress}
      disabled={disableManualUpdate}
      error={error}
      cancelError={cancelError}
    />
  ) : (
    <Icons />
  );
  return <>{content}</>;
};

export default Content;
