import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { Button, CircularProgress, Snackbar } from '@material-ui/core';

import ConfirmationNumberSharpIcon from '@material-ui/icons/ConfirmationNumberSharp';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
// import HeaderSearch from '../../layout-components/HeaderSearch';
import HeaderDots from '../../layout-components/HeaderDots';
import { disableDemoMode } from 'reducers/Store';
import { loadProducts } from 'reducers/Products';
import { loadCategories } from 'reducers/AllCategories';
// import HeaderWidget from '../../layout-components/HeaderWidget';

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    storeList,
    disableDemoMode
  } = props;
  const [isDemo, setIsDemo] = useState(storeList[0]?.isDemoMode);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (storeList?.length > 0 && storeList[0]?.isDemoMode) {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, [storeList, isDemo]);
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const handleDemoMode = () => {
    setIsLoading(true);
    disableDemoMode(storeList[0]?._id);
    setIsDemo(false);
    setIsLoading(false);
    setOpen(true);
    if (typeof window != undefined) {
      if (window.location.pathname.includes('categories')) {
        console.log('Requires Refresh');
        props.loadCategories();
      }
      if (window.location.pathname.includes('products')) {
        console.log('Requires Refresh');
        props.loadProducts();
      }
    }
  };

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          {isDemo && (
            <div className="d-flex flex-row ">
              <Button
                name="isDemo"
                checked={isDemo}
                onClick={() => handleDemoMode()}
                className=" btn btn-danger btn-sm  ">
                {isLoading && <CircularProgress />} Delete Sample Data
              </Button>
            </div>
          )}{' '}
          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Account updated!"
          />
          {/* <HeaderSearch /> */}
          {/* <HeaderWidget /> */}
        </div>
        <div className="app-header--pane ">
          <a  target="__blank" href='https://cartlauncher.freshdesk.com'>
            <Button className="btn-dribbble m-2 ">
              {/* pulse-animation */}
              <span className="btn-wrapper--icon  ">
                <ConfirmationNumberSharpIcon className="font-size-lg" />
              </span>
              <span className="btn-wrapper--label">Need Help!</span>
            </Button>
          </a>
          <HeaderDots />
          <HeaderUserbox />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  storeList: state.Store.data
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  disableDemoMode: (id) => dispatch(disableDemoMode(id)),
  loadProducts: () => dispatch(loadProducts()),
  loadCategories: () => dispatch(loadCategories())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
