import React, { useState } from 'react';
import { Button } from '@material-ui/core';
//styles

import QrCodeGenerator from './QrCodeGenerator';
import { connect } from 'react-redux';
import { activeStore } from 'reducers/Store';

const QrCode = ({ selected, allProducts, activeStore }) => {
  const [products, setProducts] = useState([]);

  // const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    const filteredProducts = allProducts.filter((product) => {
      return selected.includes(product._id);
    });
    setProducts(filteredProducts);
    setOpen(true);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <>
      <Button className="btn btn-primary" onClick={handleOpenModal}>
        Generate
      </Button>
      {/* </Link> */}

      <QrCodeGenerator
        open={open}
        setOpen={setOpen}
        activeStore={activeStore}
        products={products}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  allProducts: state.Products.data,
  activeStore: activeStore(state)
});
export default connect(mapStateToProps)(QrCode);
