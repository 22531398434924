import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { aspects } from 'utils/US_States';

import {
  makeStyles,
  Dialog,
  Button,
  TextField,
  MenuItem,
  Grid,
  Select,
  Container,
  InputAdornment,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Tooltip,
  Box,
  Typography
} from '@material-ui/core';
import { Alert, Rating } from '@material-ui/lab';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import HelpIcon from '@material-ui/icons/Help';
import CkEditor from '../../CkEditor';

import AddOrEditCategory from '../../Categories/AddOrEditCategory';
import AddOrEditSubCategory from '../../Categories/AddOrEditSubCategory';

import {
  addNewProduct,
  getIsUploadingProduct,
  getSubscriptionStatus,
  hideUpgradeModal,
  getFormError
} from 'reducers/Products';
import {
  getAllCategories,
  addCategory,
  addSubCategory
} from 'reducers/AllCategories';

import SubscriptionsModal from 'layout-components/SubscriptionsModal';
import ImagesContainer from '../ImagesContainer';
import slugify from 'slugify';

const useStyles = makeStyles(() => ({
  circularProgress: {
    color: '#1bc943',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  heading: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#3f51b5'
  },
  textFieldFullWidth: {
    border: '1px solid #ced4da'
  },
  button: {
    alignItems: 'center',
    alignContent: 'center'
  }
}));

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  barcode: Yup.string().matches(/^\d{8,13}$/, 'Must be 8-13 characters long'),
  asin: Yup.string().matches(/^(B0|BT)[0-9A-Z]{8}$/, 'Invalid e.g. B0XXXXXXXX'),
  // cl_sku: Yup.string()
  //   .lowercase()
  //   .matches(
  //     /^(cl)[0-9]{2,12}$/,
  //     'Invalid:must starts with CL/cl and be 2-12 characters long'
  //   ),
  customField: Yup.string(),
  price: Yup.number()
    .required('Required')
    .positive('Price should be positive')
    .test(function (value) {
      const { oldPrice } = this.parent;
      const error = this.createError({
        path: 'price',
        message: 'Enter a lower value than compare at price'
      });
      if (oldPrice > 0 && oldPrice <= value) return error;
      return true;
    }),
  oldPrice: Yup.number()
    .required('Required')
    .test(function (value) {
      const { price } = this.parent;
      const error = this.createError({
        path: 'oldPrice',
        message: 'Enter a higher value than price'
      });
      if (value > 0 && value < price) return error;
      return true;
    }),
  quantity: Yup.number()
    .min(0, 'Quantity should be positive')
    .required('Required'),
  category: Yup.string().required('Category is required'),
  subCategory: Yup.string(),
  seoMetaData: Yup.object().shape({
    description: Yup.string().min(1, 'Too Short'),
    title: Yup.string().min(1, 'Too Short'),
    slug: Yup.string().min(1, 'Too Short')
  })
  // images: Yup.array().required()
});

const AddProduct = ({
  addProduct,
  categories,
  addCategory,
  addSubCategory,
  isUploading,
  fromHistory = null,
  upgradeStatus,
  hideUpgradeModal,
  storeList,
  formError
}) => {
  const [modal, setModal] = useState(false);

  const [openAccordian, setOpenAccordian] = useState(false);
  const toggleModal = () => setModal(!modal);
  const classes = useStyles();

  const initValues = {
    title: '',
    description: '',
    barcode: fromHistory?.type === 'upc' ? fromHistory.identifier : '',
    asin: fromHistory?.type === 'asin' ? fromHistory.identifier : '',
    cl_sku: '',
    rating: 0,
    manufacturer: '',
    brand: '',
    aspects: [],
    condition: 'New',
    category: '',
    subCategory: '',
    price: '',
    oldPrice: 0,
    quantity: 1,
    status: 'Ready',
    isFeatured: false,
    images: [],
    customField: '',
    storeLocation: '',
    isShippable: false,
    isDeliverable: false,
    seoMetaData: {
      title: '',
      description: '',
      slug: ''
    }
  };

  const conditions = ['New', 'Like-new', 'Open-box', 'Used', 'Refurbished'];

  const [showCategoryInput, setShowCategoryInput] = useState(false);
  const categoryInputRef = useRef('');
  const onAddCategory = () => {
    const name = categoryInputRef.current.value.trim();
    if (name) addCategory(name);
    setShowCategoryInput(false);
  };

  const [showSubCategoryInput, setShowSubCategoryInput] = useState(false);
  const subCategoryInputRef = useRef('');
  const onAddSubCategory = (id) => {
    const name = subCategoryInputRef.current.value.trim();
    if (name) addSubCategory(id, name);
    setShowSubCategoryInput(false);
  };

  const getSubcategories = (id) => {
    const category = categories.find((category) => category._id === id);
    if (category?.subCategories.length > 0) return category.subCategories;
    return [];
  };

  const statuses = ['Ready', 'Unpublished'];

  // const getBase64 = (file, cb) => {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => cb(reader.result);
  // };
  const slug_options = {
    replacement: '-',
    remove: /[*+~.()'"!:@]/g,
    lower: true,
    strict: true
  };
  return (
    <>
      <Button
        onClick={toggleModal}
        size={fromHistory ? 'small' : 'medium'}
        className="btn-primary">
        <span className="btn-wrapper--icon">
          <AddIcon />
        </span>
        <span className="btn-wrapper--label">Add Product</span>
      </Button>

      <SubscriptionsModal
        status={Object.keys(upgradeStatus).length > 0 ? true : false}
        closeHandler={hideUpgradeModal}
        upgradeStatus={upgradeStatus}
      />
      <Dialog
        scroll="body"
        onBackdropClick="false"
        open={modal}
        maxWidth="md"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="p-4 text-center">
          <h4 className="font-size-xxl font-weight-bold mb-2">
            Create New Product
          </h4>
          <p className="text-black-50 mb-0">
            Fill in the form fields below to create your product listing.
          </p>
        </div>
        <div className="divider bg-dark opacity-2" />

        <Formik
          enableReinitialize={true}
          initialValues={initValues}
          validationSchema={ValidationSchema}
          onSubmit={(
            values,
            { setSubmitting, setErrors, setStatus, resetForm }
          ) => {
            let aspects = {};
            if (
              typeof values.aspects == 'object' &&
              Object.keys(values.aspects).length > 0
            ) {
              try {
                values.aspects &&
                  values.aspects.forEach(([key, value]) => {
                    if (key && value) aspects[key] = value;
                  });
              } catch (e) {
                Object.keys(values.aspects).forEach((key) => {
                  if (key && values.aspects[key])
                    aspects[key] = values.aspects[key];
                });
              }

              values.aspects = aspects;
            }
            if (values.cl_sku) {
              values.cl_sku = values.cl_sku.toLowerCase();
            }

            addProduct(values)
              .then(() => {
                setStatus({ success: true });
                resetForm({});
                toggleModal();
              })
              .catch((err) => {
                setOpenAccordian(true);
                setErrors(err.response.data.errors);
                console.log('err', err.response.data.errors);
              });
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <Container>
                <div className="text-uppercase font-weight-bold text-primary pt-4 font-size-sm">
                  General
                </div>

                <div className="py-4">
                  <div className="mb-3">
                    <label className="font-weight-bold mb-1">Title</label>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Product title..."
                      variant="outlined"
                      type="text"
                      name="title"
                      onChange={(e) => {
                        handleChange(e);
                        if (
                          values.seoMetaData.title === '' ||
                          e.target.value
                            .toLocaleLowerCase()
                            .includes(
                              values.seoMetaData?.title.toLocaleLowerCase()
                            )
                        ) {
                          setFieldValue('seoMetaData.title', e.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.title}
                      helperText={touched.title && errors.title}
                      error={errors.title && touched.title}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="font-weight-bold mb-1">Description</label>
                    {/* <TextField
                      fullWidth
                      size="small"
                      placeholder="Product description..."
                      variant="outlined"
                      multiline
                      type="text"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                  
                 
                    /> */}
                    <Field name={'description'}>
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta
                      }) => (
                        <div>
                          <CkEditor
                            value={values.description}
                            // handleChange={handleChange}}
                            form={setFieldValue}
                            name={'description'}
                          />
                        </div>
                      )}
                    </Field>
                  </div>

                  <Grid container spacing={4}>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">Barcode</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter barcode..."
                        size="small"
                        type="text"
                        name="barcode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.barcode}
                        helperText={touched.barcode && errors.barcode}
                        error={errors.barcode && touched.barcode}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">ASIN</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter ASIN..."
                        size="small"
                        type="text"
                        name="asin"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.asin}
                        helperText={touched.asin && errors.asin}
                        error={errors.asin && touched.asin}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">CL_SKU</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter CL_SKU..."
                        size="small"
                        type="text"
                        disabled={true}
                        name="cl_sku"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cl_sku}
                        helperText={touched.cl_sku && errors.cl_sku}
                        error={errors.cl_sku && touched.cl_sku}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">
                        Custom Field (optional)
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Location..."
                        size="small"
                        type="text"
                        name="customField"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.customField}
                        helperText={touched.customField && errors.customField}
                        error={errors.customField && touched.customField}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">Rating</label>
                      <div>
                        <Rating
                          name="rating"
                          size="large"
                          defaultValue={0}
                          precision={0.5}
                          value={values.rating}
                          onChange={(e, value) =>
                            setFieldValue('rating', value)
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4}>
                    <Grid item md={6}>
                      <label className="font-weight-bold mb-2">
                        Manufacturer
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Manufacturer name..."
                        size="small"
                        type="text"
                        name="manufacturer"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.manufacturer}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <label className="font-weight-bold mb-2">Brand</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Brand name..."
                        size="small"
                        type="text"
                        name="brand"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brand}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Container>

              <div className="divider bg-dark opacity-3" />

              <Container>
                <div className="text-uppercase font-weight-bold text-primary pt-4 font-size-sm">
                  Specifications
                </div>

                <div className="py-4">
                  {values.aspects.length > 0 ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item md={5}>
                          <label className="font-weight-bold pl-1">Name</label>
                        </Grid>
                        <Grid item md={5}>
                          <label className="font-weight-bold pl-1">Value</label>
                        </Grid>
                        <Grid item md={2}>
                          <label className="font-weight-bold"> </label>
                        </Grid>
                      </Grid>
                      {values.aspects.map(([key, value], i) => (
                        <Grid container spacing={2} key={i}>
                          <Grid item md={5}>
                            {/* <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              type="text"
                              name="aspectName"
                              value={key}
                              onChange={(e) =>
                                setFieldValue(
                                  `aspects[${i}][0]`,
                                  e.target.value
                                )
                              }
                            /> */}
                            <Autocomplete
                              single
                              id="tags-outlined"
                              options={aspects}
                              onChange={(event, value) => {
                                setFieldValue(`aspects[${i}][0]`, value);
                              }}
                              autoSelect
                              // freeSolo
                              getOptionLabel={(option) => option}
                              value={key}
                              filterSelectedOptions
                              renderInput={(params) => (
                                <TextField
                                  // disabled={isSuccess && !store.length > 0}
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select tags..."
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={5}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              type="text"
                              name="aspectValue"
                              value={value}
                              onChange={(e) =>
                                setFieldValue(
                                  `aspects[${i}][1]`,
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid item md={2}>
                            <Button
                              onClick={() =>
                                setFieldValue(
                                  'aspects',
                                  values.aspects.concat([['', '']])
                                )
                              }
                              className="btn-first mx-1 d-40 p-0 d-inline-flex align-items-center justify-content-center">
                              <AddIcon />
                            </Button>
                            <Button
                              onClick={() => {
                                values.aspects.splice(i, 1);
                                setFieldValue('aspects', values.aspects);
                              }}
                              className="btn-danger mx-1 d-40 p-0 d-inline-flex align-items-center justify-content-center">
                              <ClearIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Button
                      onClick={() =>
                        setFieldValue(
                          'aspects',
                          values.aspects.concat([['', '']])
                        )
                      }
                      size="small"
                      className="btn-primary">
                      <span className="btn-wrapper--icon">
                        <AddIcon />
                      </span>
                      <span className="btn-wrapper--label">New Aspect</span>
                    </Button>
                  )}
                </div>
              </Container>

              <div className="divider bg-dark opacity-3" />

              <Container>
                <div className="text-uppercase font-weight-bold text-primary pt-4 font-size-sm">
                  Publishing Details
                </div>
                <div className="py-4">
                  <Grid container spacing={4}>
                    <Grid item md={5}>
                      <label className="font-weight-bold mb-2">
                        Main Category
                      </label>
                      <Select
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="category"
                        value={values.category}
                        onChange={(value) =>
                          setFieldValue(value.target.name, value.target.value)
                        }
                        labelWidth={0}
                        error={errors.category && touched.category}>
                        {categories.map((c) => (
                          <MenuItem className="mx-2" value={c._id} key={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.category && (
                        <FormHelperText error={errors.category}>
                          {errors.category}
                        </FormHelperText>
                      )}
                    </Grid>
                    {showCategoryInput ? (
                      <>
                        <Grid item md={5}>
                          <label className="font-weight-bold mb-2">
                            New Category Name
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Enter category name..."
                            size="small"
                            inputRef={categoryInputRef}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <label>{'\u00A0'}</label>
                          <Button
                            fullWidth
                            className="btn-primary"
                            onClick={onAddCategory}>
                            Add
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid item md={5}>
                        <label>{'\u00A0'}</label>
                        <div className="py-2">
                          <AddOrEditCategory />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item md={5}>
                      <label className="font-weight-bold mb-2">
                        Sub Category
                      </label>
                      <Select
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="subCategory"
                        value={values.subCategory}
                        error={errors.subCategory && touched.subCategory}
                        onChange={(value) =>
                          setFieldValue(value.target.name, value.target.value)
                        }
                        labelWidth={0}>
                        {getSubcategories(values.category).map((c) => (
                          <MenuItem className="mx-2" value={c._id} key={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.subCategory && (
                        <FormHelperText error={errors.subCategory}>
                          {errors.subCategory}
                        </FormHelperText>
                      )}
                    </Grid>
                    {showSubCategoryInput ? (
                      <>
                        <Grid item md={5}>
                          <label className="font-weight-bold mb-2">
                            New Sub Category Name
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Enter subcategory name..."
                            size="small"
                            inputRef={subCategoryInputRef}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <label>{'\u00A0'}</label>
                          <Button
                            fullWidth
                            className="btn-primary font-weight-bold text-uppercase"
                            onClick={() => onAddSubCategory(values.category)}>
                            Add
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid item md={5}>
                        <AddOrEditSubCategory
                          subcategory={null}
                          cId={values.category}
                        />
                      </Grid>
                    )}
                    <Grid item md={5}>
                      <label className="font-weight-bold mb-2">Location</label>
                      <Select
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="storeLocation"
                        value={values.storeLocation}
                        onChange={(value) =>
                          setFieldValue(value.target.name, value.target.value)
                        }
                        labelWidth={0}
                        error={errors.storeLocation && touched.storeLocation}>
                        {storeList &&
                          storeList.map((c) =>
                            c.storeLocation.map((location) => (
                              <MenuItem
                                className="mx-2"
                                value={location._id}
                                key={location._id}>
                                {location.name}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                      {errors.storeLocation && (
                        <FormHelperText error={errors.storeLocation}>
                          {errors.storeLocation}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label className="font-weight-bold"> </label>
                      <div className="py-2">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isShippable"
                              checked={values.isShippable}
                              onChange={(e) => {
                                setFieldValue('isShippable', e.target.checked);
                              }}
                            />
                          }
                          label="Shippable Product"
                        />
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <label className="font-weight-bold"> </label>
                      <div className="py-2">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isDeliverable"
                              checked={values.isDeliverable}
                              onChange={(e) => {
                                setFieldValue(
                                  'isDeliverable',
                                  e.target.checked
                                );
                              }}
                            />
                          }
                          label="Deliverable Product"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">Price</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter price..."
                        size="small"
                        type="number"
                        name="price"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.price}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              US$
                            </InputAdornment>
                          )
                        }}
                        helperText={touched.price && errors.price}
                        error={errors.price && touched.price}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">
                        Compare at price{' '}
                        <Tooltip title="To show a reduced price, move the product's original price into Compare at price. Enter a lower value into Price.">
                          <HelpIcon fontSize="small" />
                        </Tooltip>
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter price..."
                        size="small"
                        type="number"
                        name="oldPrice"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.oldPrice}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              US$
                            </InputAdornment>
                          )
                        }}
                        helperText={touched.oldPrice && errors.oldPrice}
                        error={errors.oldPrice && touched.oldPrice}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">Quantity</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter stock size..."
                        size="small"
                        type="number"
                        name="quantity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.quantity}
                        helperText={touched.quantity && errors.quantity}
                        error={errors.quantity && touched.quantity}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">Condition</label>
                      <Select
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="condition"
                        value={values.condition ?? ''}
                        onChange={(value) =>
                          setFieldValue(value.target.name, value.target.value)
                        }
                        onBlur={(value) =>
                          setFieldTouched(value.target.name, true)
                        }
                        labelWidth={0}>
                        {conditions.map((c) => (
                          <MenuItem className="mx-2" value={c} key={c}>
                            {c}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item md={4}>
                      <label className="font-weight-bold mb-2">
                        Publishing Status
                      </label>
                      <Select
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="status"
                        value={values.status}
                        onChange={(value) =>
                          setFieldValue(value.target.name, value.target.value)
                        }
                        onBlur={(value) =>
                          setFieldTouched(value.target.name, true)
                        }
                        labelWidth={0}>
                        {statuses.map((s) => (
                          <MenuItem className="mx-2" value={s} key={s}>
                            {s}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item md={4}>
                      <label className="font-weight-bold"> </label>
                      <div className="py-2">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isFeatured"
                              checked={values.isFeatured}
                              onChange={(e) => {
                                setFieldValue('isFeatured', e.target.checked);
                              }}
                            />
                          }
                          label="List as featured item"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Container>

              <div className="divider bg-dark opacity-3" />

              <Container>
                <div className="text-uppercase font-weight-bold text-primary pt-4 font-size-sm">
                  Images
                </div>

                <div className="py-4">
                  <label htmlFor="upload-images">
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/jpeg, image/png, 'image/webp'"
                      id="upload-images"
                      name="images"
                      multiple
                      onChange={(e) => {
                        const fileArray = Array.from(e.currentTarget.files);
                        fileArray.forEach((file) => {
                          if (
                            file.type === 'image/jpeg' ||
                            file.type === 'image/png' ||
                            file.type === 'image/jpg'||
                            file.type === 'image/webp'
                          ) {
                            values.images.unshift(file);
                            setFieldValue('images', values.images);
                          }
                        });
                        if (
                          fileArray.some((file) => {
                            return (
                              file.type !== 'image/jpeg' ||
                              file.type !== 'image/png' ||
                              file.type !== 'image/jpg'
                            );
                          })
                        )
                          setFieldError('images', 'Unsupported File Format');
                      }}
                    />
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      component="span">
                      Browse images
                    </Button>
                  </label>

                  <div>
                    <div className="text-black-50 text-center font-size-sm mb-2">
                      {errors.images && (
                        <p className="text-danger">
                          {errors.images}
                          <br />
                          This file format is not supported we only allow PNG
                          and JPG files.
                        </p>
                      )}
                    </div>
                    <div className="font-weight-bold my-2 text-uppercase text-dark font-size-sm text-center">
                      Uploaded Files
                    </div>
                    <div className="text-black-50 text-center font-size-sm mb-2">
                      {values.images.length === 0
                        ? 'Uploaded files will appear here!'
                        : 'Drag image to the left most side to make it main image.'}
                    </div>
                    {values.images.length > 0 && (
                      <div>
                        <Alert className="btn-success text-center mb-3">
                          You have uploaded <b>{values.images.length}</b> files!
                        </Alert>
                        <DndProvider backend={HTML5Backend}>
                          <ImagesContainer
                            images={values.images}
                            setFieldValue={setFieldValue}
                          />
                        </DndProvider>
                      </div>
                    )}
                  </div>
                </div>
                <div className="divider bg-dark opacity-3" />
                <Accordion
                  expanded={openAccordian}
                  onChange={() => setOpenAccordian(!openAccordian)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading}>SEO</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item>
                        <Box m={2}>
                          <Typography variant="subtitle1" m={2}>
                            You can modify the title, description and the url to
                            get better ranking in a search engine listing
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={12} lg={12} sm={12}>
                        <TextField
                          id="outlined-multiline-flexible"
                          label="title"
                          fullWidth
                          name="seoMetaData.title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.seoMetaData.title}
                          helperText={
                            touched.seoMetaData?.title &&
                            errors.seoMetaData?.title
                          }
                          error={
                            errors.seoMetaData?.title &&
                            touched.seoMetaData?.title
                          }
                          margin="dense"
                          variant="outlined"
                          //   {...formik.getFieldProps('title')} when I desabled formik the count is working
                        />
                        <Typography variant="subtitle2" gutterBottom>
                          {values.seoMetaData.title?.length} of 70 characters
                          used
                        </Typography>
                      </Grid>
                      <Grid item md={12} lg={12} sm={12}>
                        <TextField
                          fullWidth
                          margin="dense"
                          id="outlined-multiline-flexible"
                          className={classes.textFieldFullWidth}
                          label="Description"
                          multiline
                          placeholder="Enter a description to get a better ranking on search engines"
                          rows="4"
                          name="seoMetaData.description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            values.seoMetaData?.description ||
                            values?.description?.replace(/<[^>]+>/g, '')
                          }
                          helperText={
                            touched.seoMetaData?.description &&
                            errors.seoMetaData?.description
                          }
                          error={
                            errors.seoMetaData?.description &&
                            touched.seoMetaData?.description
                          }
                        />
                        <Typography variant="subtitle2" gutterBottom>
                          {values.seoMetaData.description?.length} of 300
                          characters used
                        </Typography>
                      </Grid>
                      <Grid item md={12} lg={12} sm={12}>
                        <TextField
                          label="URL"
                          margin="dense"
                          id="outlined-start-adornment"
                          fullWidth
                          name="seoMetaData.slug"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.seoMetaData?.slug}
                          helperText={
                            touched.seoMetaData?.slug &&
                            formError['seoMetaData.slug']
                          }
                          error={
                            formError['seoMetaData.slug'] &&
                            touched.seoMetaData?.slug
                          }
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                https://storename.mycartlauncher.com/products/
                              </InputAdornment>
                            )
                          }}
                        />
                        <Typography variant="subtitle2" gutterBottom>
                          <b>Preview:</b>{' '}
                          https://storename.mycartlauncher.com/products/
                          {slugify(
                            values.seoMetaData.slug.toLocaleLowerCase() ||
                              values.title.toLocaleLowerCase(),
                            slug_options
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Container>

              <div className="divider bg-dark opacity-3" />

              <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
                <Button
                  onClick={toggleModal}
                  className="btn-neutral-danger font-size-sm mr-4">
                  Cancel
                </Button>
                <div style={{ position: 'relative' }}>
                  <Button
                    className="btn-success font-weight-bold"
                    type="submit"
                    disabled={isUploading}>
                    Save now
                  </Button>
                  {isUploading && (
                    <CircularProgress
                      size={24}
                      thickness={5}
                      className={classes.circularProgress}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  categories: getAllCategories(state),
  isUploading: getIsUploadingProduct(state),
  upgradeStatus: getSubscriptionStatus(state),
  storeList: state.Store.data,
  formError: getFormError(state)
});

const mapDispatchToProps = (dispatch) => ({
  addProduct: (data) => dispatch(addNewProduct(data)),
  addCategory: (name) => dispatch(addCategory(name)),
  addSubCategory: (id, name) => dispatch(addSubCategory(id, name)),
  hideUpgradeModal: () => dispatch(hideUpgradeModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
