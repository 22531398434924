import React from 'react';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AddIcon from '@material-ui/icons/Add';
import { PageTitle } from '../../layout-components';
import { Products1 } from '../../components';
import { Button, Typography } from '@material-ui/core';

export default function Products() {
  return (
    <>
      <PageTitle
        titleHeading="Products"
        titleDescription="Products listing for your Store."
        titleIcon={<BusinessCenterIcon />}>
        <Button
        className='btn btn-primary'
        href={`${process.env.REACT_APP_D_PREFIX}/products/addProduct`}>
        <span className="btn-wrapper--icon">
          <AddIcon />
        </span>
        <Typography variant='subtitle1'> Add product</Typography>

        </Button>
      </PageTitle>

      <Products1 />
    </>
  );
}
