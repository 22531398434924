import React from 'react';

export default function Categories() {
  return (
    <iframe
      seamless="seamless"
      width="100%"
      height="110%"
      style={{ border: 0 }}
      title="Generate Ticket"
      src="https://app.smartsheet.com/b/form/86dd1e748df14309bfb8d8b12e77c9c5"></iframe>
  );
}
