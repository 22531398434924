import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  makeStyles,
  Button,
  Dialog,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Box,
  InputAdornment
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Formik } from 'formik';
import * as Yup from 'yup';
import slugify from 'slugify';
import { addSubCategory, updateSubCategory } from 'reducers/AllCategories';

const useStyles = makeStyles(() => ({
  circularProgress: {
    color: '#1bc943',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  heading: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: '#3f51b5'
  }
}));

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  seoMetaData: Yup.object().shape({
    description: Yup.string().min(1, 'Too Short'),
    title: Yup.string().min(1, 'Too Short'),
    slug: Yup.string().min(1, 'Too Short')
  })
});

const AddOrEditSubCategory = (props) => {
  const { add, update, isEdit, subcategory, cId } = props;
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const initValues = {
    name: subcategory?.name || '',
    seoMetaData: {
      title:
        (subcategory?.seoMetaData.title ?? null) || subcategory?.name || '',
      description: subcategory?.seoMetaData.description ?? '',
      slug: (subcategory?.seoMetaData.slug ?? null) || subcategory?.name || ''
    }
  };
  const onSave = (values, { ...props }) => {
    if (isEdit) update(subcategory._id, values, { ...props, toggleModal });
    else {
      add(cId, values, { ...props, toggleModal });
    }
  };
  const options = {
    replacement: '-',
    remove: /[*+~.()'"!:@]/g,
    lower: true,
    strict: true
  };
  return (
    <>
      {isEdit ? (
        <Button
          size="small"
          className="btn-first hover-scale-sm d-30 p-0 mx-1 btn-icon"
          onClick={toggleModal}>
          <EditIcon className="font-size-lg" />
        </Button>
      ) : (
        <Button
          onClick={toggleModal}
          className="btn-first mx-1 d-40 p-0 d-inline-flex align-items-center justify-content-center">
          <AddIcon />
        </Button>
      )}

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="md"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="p-4">
          <h4 className="font-size-xl font-weight-bold">
            {isEdit ? 'Update Sub-Category' : 'Add New Sub-Category'}
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />

        <Formik
          initialValues={initValues}
          validationSchema={ValidationSchema}
          onSubmit={onSave}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-4">
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <div>
                      <label className="font-weight-bold mb-1">
                        Name <span className="text-danger">*</span>
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter sub category name..."
                        size="small"
                        name="name"
                        value={values.name}
                        onChange={(e) => {
                          handleChange(e);
                          if (
                            values.seoMetaData.title === '' ||
                            e.target.value
                              .toLocaleLowerCase()
                              .includes(
                                values.seoMetaData?.title.toLocaleLowerCase()
                              )
                          ) {
                            setFieldValue('seoMetaData.title', e.target.value);
                          }
                        }}
                        onBlur={handleBlur}
                        helperText={touched.name && errors.name}
                        error={errors.name && touched.name}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className="divider bg-dark opacity-3" />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography className={classes.heading}>SEO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item>
                      <Box m={2}>
                        <Typography variant="subtitle1" m={2}>
                          You can modify the title, description and the url to
                          get better ranking in a search engine listing
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={12} lg={12} sm={12}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="title"
                        fullWidth
                        name="seoMetaData.title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.name}
                        value={values.seoMetaData?.title}
                        helperText={
                          touched.seoMetaData?.title &&
                          errors.seoMetaData?.title
                        }
                        error={
                          errors.seoMetaData?.title &&
                          touched.seoMetaData?.title
                        }
                        margin="dense"
                        variant="outlined"
                      />
                      <Typography variant="subtitle2" gutterBottom>
                        {values.seoMetaData.title?.length} of 70 characters used
                      </Typography>
                    </Grid>
                    <Grid item md={12} lg={12} sm={12}>
                      <TextField
                        fullWidth
                        margin="dense"
                        id="outlined-multiline-flexible"
                        label="Description"
                        multiline
                        placeholder="Enter a description to get a better ranking on search engines"
                        rows="4"
                        name="seoMetaData.description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.seoMetaData.description}
                        helperText={
                          touched.seoMetaData?.description &&
                          errors.seoMetaData?.description
                        }
                        error={
                          errors.seoMetaData?.description &&
                          touched.seoMetaData?.description
                        }
                      />
                      <Typography variant="subtitle2" gutterBottom>
                        {values.seoMetaData.description?.length} of 300
                        characters used
                      </Typography>
                    </Grid>
                    <Grid item md={12} lg={12} sm={12}>
                      <TextField
                        label="URL"
                        margin="dense"
                        id="outlined-start-adornment"
                        fullWidth
                        name="seoMetaData.slug"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.seoMetaData?.slug}
                        helperText={
                          touched.seoMetaData?.slug && errors.seoMetaData?.slug
                        }
                        error={
                          errors.seoMetaData?.slug && touched.seoMetaData?.slug
                        }
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              https://storename.mycartlauncher.com/collections/
                            </InputAdornment>
                          )
                        }}
                      />
                      <Typography variant="subtitle2" gutterBottom>
                        <b>Preview:</b>{' '}
                        https://storename.mycartlauncher.com/collections/
                        {slugify(
                          values.seoMetaData?.slug?.toLocaleLowerCase() ||
                            values.name?.toLocaleLowerCase() ||
                            ''
                        ,options)}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
                <Button
                  onClick={toggleModal}
                  className="btn-neutral font-size-sm mr-2">
                  Cancel
                </Button>
                <div style={{ position: 'relative' }}>
                  <Button
                    className="btn-success font-weight-bold"
                    type="submit"
                    disabled={isSubmitting}>
                    {isSubmitting ? 'Saving...' : 'Save now'}
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      thickness={5}
                      style={{
                        color: '#1bc943',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12
                      }}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  add: (cId, values, props) => dispatch(addSubCategory(cId, values, props)),
  update: (scId, values, props) =>
    dispatch(updateSubCategory(scId, values, props))
});

export default connect(null, mapDispatchToProps)(AddOrEditSubCategory);
