import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  Table,
  Card,
  CardContent,
  Button,
  Collapse,
  CircularProgress,
  Dialog,
  DialogTitle,
  Slide,
  DialogContent,
  DialogActions,
  Checkbox,
  TextField,
  Typography,
  Grid
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import {
  loadCategories,
  getAllCategories,
  getIsLoadingCategories,
  deleteCategory,
  getNoCategoryCount
} from 'reducers/AllCategories';

import AddOrEditCategory from './AddOrEditCategory';
import SubCategories from './SubCategories';
import AddOrEditSubCategory from './AddOrEditSubCategory';

import placeholder from 'assets/images/placeholders/no-repo-preview-image.jpg';
import CleanCategories from './CleanCategories';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Formik } from 'formik';
import { getUser, updateUserCustomSettings } from 'reducers/UserAccount';
// import DeleteDemoCategories from './DeleteDemoCategories';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Category = ({ item, deleteCategory }) => {
  const {
    _id,
    name,
    thumbnail,
    isFeatured,
    productsCount,
    subCategories
  } = item;

  const [expand, setExpand] = useState(false);
  const toggleExpand = () => setExpand(!expand);

  return (
    <>
      <tr className={clsx({ 'panel-open': expand })}>
        <td>
          <Button
            size="small"
            className="btn-neutral hover-scale-sm rounded d-30 p-0 btn-icon"
            onClick={toggleExpand}>
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </td>
        <td>
          <img
            src={
              thumbnail
                ? `${process.env.REACT_APP_ASSETS_BASE_URL}/${thumbnail}`
                : placeholder
            }
            alt="..."
            style={{ maxWidth: 75, maxHeight: 60 }}
          />
        </td>
        <td>{name}</td>
        <td className="text-center">{productsCount}</td>
        <td className="text-center text-success">
          <span style={{ fontSize: 24 }}>
            {isFeatured ? (
              <CheckCircleIcon fontSize="inherit" />
            ) : (
              <RadioButtonUncheckedIcon fontSize="inherit" />
            )}
          </span>
        </td>
        <td className="text-center">
          <AddOrEditSubCategory isEdit={false} subcategory={null} cId={_id} />
          <AddOrEditCategory category={item} />

          <Button
            size="small"
            className="btn-danger hover-scale-sm d-30 p-0 mx-1 btn-icon"
            disabled={
              subCategories.length > 0 || isFeatured || productsCount > 0
            }
            onClick={() => deleteCategory(_id)}>
            <DeleteIcon className="font-size-lg" />
          </Button>
        </td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={4} hidden={!expand}>
          <Collapse in={expand}>
            {subCategories.length > 0 ? (
              <SubCategories subCategories={subCategories} />
            ) : (
              <div>No sub-category exist yet.</div>
            )}
          </Collapse>
        </td>
      </tr>
    </>
  );
};

const Categories1 = (props) => {
  const [openConfig, setOpenConfig] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpenConfig(true);
  };
  const handleClose = () => {
    setOpenConfig(false);
  };
  const {
    startLoading,
    categories,
    isLoading,
    deleteCategory,
    noCategoryCount,
    updateUser,
    user
  } = props;
  const initialValues = {
    unpublishCategories:
      user?.customSettings?.hideCategoryByLimit?.unpublishCategories || false,
      productsLimit:
      user?.customSettings?.hideCategoryByLimit?.productsLimit || 0
  };
  useEffect(() => {
    startLoading();
  }, [startLoading]);
  const handleSubmit = (values) => {
    setLoading(true);
    updateUser({ hideCategoryByLimit: values });
    setLoading(false);
    handleClose();
  };
  return (
    <>
      <Dialog
        open={openConfig}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm">
        <DialogTitle>{'Settings'}</DialogTitle>
        <Formik enableReinitialize={true} initialValues={initialValues}>
          {({ values, setFieldValue }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid container>
                    <Grid item md={12}>
                      <label>
                        <Field
                          type="checkbox"
                          name="unpublish"
                          checked={values.unpublishCategories}
                          onChange={(e) => {
                            setFieldValue(
                              'unpublishCategories',
                              e.target.checked
                            );
                          }}
                          component={Checkbox}
                        />
                        Auto hide categories with less than a min number of
                        products
                      </label>
                    </Grid>
                    {values.unpublishCategories && (
                      <Grid item md={6}>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: '700' }}>
                          Specify the number of products for categories
                        </Typography>
                        <TextField
                          type={'number'}
                          fullWidth
                          maxWidth="md"
                          variant="outlined"
                          size="small"
                          value={values.productsLimit}
                          onChange={(e) => {
                            setFieldValue('productsLimit', e.target.value);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="btn-success"
                    disabled={loading}
                    onClick={() => handleSubmit(values)}>
                    save
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      thickness={5}
                      style={{
                        color: '#1bc943',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12
                      }}
                    />
                  )}
                  <Button onClick={handleClose}>cancel</Button>
                </DialogActions>
              </form>
            </>
          )}
        </Formik>
      </Dialog>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header pr-3">
          <div className="card-header--title"></div>
          <div className="card-header--actions">
            {/* <DeleteDemoCategories /> */}
            <Button
             size="small"

             variant="contained"
              className="btn btn-primary"
              onClick={() => {
                handleClickOpen();
              }}>
              <FontAwesomeIcon icon={faCog} />
            </Button>
            <AddOrEditCategory />
            <CleanCategories />
          </div>
        </div>
        <CardContent>
          {isLoading ? (
            <CircularProgress size={24} thickness={5} />
          ) : (
            <div className="table-responsive-md">
              <Table className="table table-borderless mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-left">Thumbnail</th>
                    <th className="text-left">Name</th>
                    <th className="text-center">Products</th>
                    <th className="text-center">Featured</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.length === 0 ? (
                    <tr>
                      <td>Not category exists yet!</td>
                    </tr>
                  ) : (
                    categories.map((item) => (
                      <Category
                        key={item._id}
                        item={item}
                        deleteCategory={deleteCategory}
                      />
                    ))
                  )}
                  {noCategoryCount > 0 && (
                    <tr className={clsx({ 'panel-open': 'as' })}>
                      <td>
                        <Button
                          size="small"
                          className="btn-neutral hover-scale-sm rounded d-30 p-0 btn-icon"
                          onClick={null}></Button>
                      </td>
                      <td>
                        <img
                          src={placeholder}
                          alt="..."
                          style={{ maxWidth: 75, maxHeight: 60 }}
                        />
                      </td>
                      <td>Uncategorized</td>
                      <td className="text-center">{noCategoryCount}</td>
                      <td className="text-center text-success">
                        <span style={{ fontSize: 24 }}></span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  categories: getAllCategories(state),
  isLoading: getIsLoadingCategories(state),
  noCategoryCount: getNoCategoryCount(state),
  user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(loadCategories()),
  deleteCategory: (id) => dispatch(deleteCategory(id)),
  updateUser: (data) => dispatch(updateUserCustomSettings(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories1);
