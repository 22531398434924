import React from 'react';

import { Grid, Card, List, ListItem, Button } from '@material-ui/core';

import { getUser } from '../../reducers/UserAccount';
import { connect } from 'react-redux';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { updateUser } from '../../reducers/UserAccount';
const LivePreviewExample = ({ user, stats, updateUser }) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xl={12}>
          <Card className="card-box">
            <div className="card-header">
              <div className="card-header--title text-center">
                <small className="d-block text-uppercase mt-1">
                  To-do list
                </small>
                <b>
                  Get ready for your first sale. Follow our tips to get started.
                </b>
              </div>
              <Button
                onClick={()=>updateUser({guideStatus:true})}
                className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                <ClearIcon className="font-size-md font-weight-bold" />
              </Button>
            </div>

            <List component="div" className="list-group-flush">
              <ListItem
                component="a"
                button
                href="#/"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                <div className="d-flex align-items-center">
                  <div className="avatar-icon  text-success mr-3 d-50">
                    <CheckCircleOutlineIcon className="text-size-5" />
                  </div>
                  <div>
                    <div className="font-weight-bold text-black">
                      <s>
                        {' '}
                        <span className="text-success">Done</span> Create an
                        account{' '}
                        <span role="img" aria-label="Account">
                          🐱‍🏍
                        </span>
                      </s>
                    </div>
                    {/* <p className="text-black-100 mb-0">
                      View any of the 5+ live previews we&#39;ve set up to
                    </p> */}
                  </div>
                </div>
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="/app/payment"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                {user.stripeCustomerDetails.paymentId ? (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon  text-success mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-size-5" />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        <s>
                          {' '}
                          <span className="text-success">Done</span> Add your
                          payment information{' '}
                          <span role="img" aria-label="Account">
                            💳
                          </span>
                        </s>
                      </div>
                      <p className="text-black-100 mb-0">
                        Go to Payment > Add payment method{' '}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon   mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-size-5" />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Add your payment information{' '}
                        <span role="img" aria-label="Account">
                          💳
                        </span>
                      </div>
                      <p className="text-black-100 mb-0">
                        Go to Payment > Add payment method{' '}
                      </p>
                    </div>
                  </div>
                )}
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="/app/settings"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                {user.platforms.paypal.isConnected ? (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-success" />
                    </div>
                    <div>
                      <s>
                        {' '}
                        <div className="font-weight-bold text-black">
                          <span className="text-success">Done</span> Connect
                          your paypal account{' '}
                          <span role="img" aria-label="paypal">
                            💸
                          </span>
                        </div>
                      </s>

                      <p className="text-black-100 mb-0">
                        Go to Settings >Ecommerce platforms > Connect Paypal{' '}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Connect your paypal account{' '}
                        <span role="img" aria-label="paypal">
                          💸
                        </span>
                      </div>
                      <p className="text-black-100 mb-0">
                        Go to Settings >Ecommerce platforms > Connect Paypal{' '}
                      </p>
                    </div>
                  </div>
                )}
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="/app/settings"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                {user.platforms.stripe.chargesEnabled ? (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-success" />
                    </div>
                    <div>
                      <s>
                        {' '}
                        <div className="font-weight-bold text-black">
                          <span className="text-success">Done</span> Connect
                          your Stripe account{' '}
                          <span role="img" aria-label="paypal">
                            💸
                          </span>
                        </div>
                      </s>

                      <p className="text-black-100 mb-0">
                        Go to Settings >Ecommerce platforms > Connect Stripe{' '}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Connect your Stripe account{' '}
                        <span role="img" aria-label="paypal">
                          💸
                        </span>
                      </div>
                      <p className="text-black-100 mb-0">
                        Go to Settings >Ecommerce platforms > Connect Stripe{' '}
                      </p>
                    </div>
                  </div>
                )}
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="/app/settings"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                {user.platforms.ebay.isConnected ? (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-success" />
                    </div>
                    <div>
                      <s>
                        {' '}
                        <div className="font-weight-bold text-black">
                          <span className="text-success">Done</span> Connect
                          your ebay account{' '}
                          <span role="img" aria-label="paypal">
                            💸
                          </span>
                        </div>
                      </s>

                      <p className="text-black-100 mb-0">
                        Go to Settings >Ecommerce platforms > Connect Ebay{' '}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Connect your ebay account{' '}
                        <span role="img" aria-label="paypal">
                          💸
                        </span>
                      </div>
                      <p className="text-black-100 mb-0">
                        Go to Settings >Ecommerce platforms > Connect Ebay{' '}
                      </p>
                    </div>
                  </div>
                )}
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="/app/settings"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                {user.platforms.ebay.listingConfig?.listingPolicies
                  ?.returnPolicyId !== null &&
                user.platforms.ebay.listingConfig?.listingPolicies
                  ?.listingPolicies?.paymentPolicyId !== null &&
                user.platforms.ebay.listingConfig?.listingPolicies
                  ?.fulfillmentPolicyId !== null ? (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-success" />
                    </div>
                    <div>
                      <s>
                        <div className="font-weight-bold text-black">
                          <span className="text-success">Done</span> Select your
                          ebay policies{' '}
                          <span role="img" aria-label="paypal">
                            🧾
                          </span>
                        </div>
                      </s>

                      <p className="text-black-100 mb-0">
                        Go to Settings >Ecommerce platforms > Settings (Scroll
                        down to see the ebay policies){' '}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    {' '}
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Select your ebay policies{' '}
                        <span role="img" aria-label="paypal">
                          🧾
                        </span>
                      </div>
                      <p className="text-black-100 mb-0">
                        Go to Settings >Ecommerce platforms > Settings (Scroll
                        down to see the ebay policies){' '}
                      </p>
                    </div>
                  </div>
                )}
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="/app/lookup"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                {stats.totalProducts > 0 ? (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-success" />
                    </div>
                    <div>
                      <s>
                        {' '}
                        <div className="font-weight-bold text-black">
                          <span className="text-success">Done</span> Scan a
                          product (use that UPC as a sample){' '}
                          <span role="img" aria-label="paypal">
                            🔦
                          </span>
                        </div>
                      </s>

                      <p className="text-black-100 mb-0">
                        Go to Lookup <b>></b> Manual input <b>></b> Type
                        1243240328 on barcode/Sku field
                        <br /> click on ADD buttons <b>></b> click get products
                        button <b>></b> go to products to see your product{' '}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Scan a product (use that UPC as a sample){' '}
                        <span role="img" aria-label="paypal">
                          🔦
                        </span>
                      </div>
                      <p className="text-black-100 mb-0">
                        Go to Lookup <b>></b> Manual input <b>></b> Type
                        1243240328 on barcode/Sku field
                        <br /> click on ADD buttons <b>></b> click get products
                        button <b>></b> go to products to see your product{' '}
                      </p>
                    </div>
                  </div>
                )}
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="/app/products"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                {stats.featuredProducts > 0 ? (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-success" />
                    </div>
                    <div>
                      <s>
                        <div className="font-weight-bold text-black">
                          <span className="text-success">Done</span> Add
                          products to featured collection{' '}
                          <span role="img" aria-label="paypal">
                            🌟
                          </span>
                        </div>
                      </s>

                      <p className="text-black-100 mb-0">
                        Select a product > Click on Actions > Mark as featured{' '}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        Add products to featured collection{' '}
                        <span role="img" aria-label="paypal">
                          🌟
                        </span>
                      </div>
                      <p className="text-black-100 mb-0">
                        Select a product > Click on Actions > Mark as featured{' '}
                      </p>
                    </div>
                  </div>
                )}
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="/app/categories"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                {stats.featuredCategory > 0 ? (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-success" />
                    </div>
                    <div>
                      <s>
                        {' '}
                        <div className="font-weight-bold text-black">
                          <span className="text-success">Done</span> add
                          categories to featured collection{' '}
                          <span role="img" aria-label="paypal">
                            🛒
                          </span>
                        </div>
                      </s>

                      <p className="text-black-100 mb-0">
                        Go to Categories >Click on edit button (pen) > Check
                        "list as featured category" > Save now{' '}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        add categories to featured collection{' '}
                        <span role="img" aria-label="paypal">
                          🛒
                        </span>
                      </div>
                      <p className="text-black-100 mb-0">
                        Go to Categories >Click on edit button (pen) > Check
                        "list as featured category" > Save now{' '}
                      </p>
                    </div>
                  </div>
                )}
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href="/app/stores"
                disableRipple
                className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                {!stats.store ? (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon className="text-success" />
                    </div>
                    <div>
                      <s>
                        {' '}
                        <div className="font-weight-bold text-black">
                          <span className="text-success">Done</span> create &
                          publish your store{' '}
                          <span role="img" aria-label="paypal">
                            📢
                          </span>
                        </div>
                      </s>

                      <p className="text-black-100 mb-0">
                        Go to store > Create a store > add your information >
                        Save now > and click on publish Button
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon mr-3 d-50">
                      <CheckCircleOutlineIcon />
                    </div>
                    <div>
                      <div className="font-weight-bold text-black">
                        create & publish your store{' '}
                        <span role="img" aria-label="paypal">
                          📢
                        </span>
                      </div>
                      <p className="text-black-100 mb-0">
                        Go to store > Create a store > add your information >
                        Save now > and click on publish Button{' '}
                      </p>
                    </div>
                  </div>
                )}
                <div className="ml-auto">
                  <div className="badge badge-neutral-success text-success font-size-xs font-weight-normal py-1 h-auto px-3 badge-pill">
                    <NavigateNextOutlinedIcon />
                  </div>
                </div>
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data))
});

const mapStateToProps = (state) => ({
  user: getUser(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(LivePreviewExample);
