import React from 'react';

import { Login1 } from '../../components';

export default function PageLogin() {
  return (
    <>
      <Login1 />
    </>
  );
}
