import React, { useState } from 'react';
import UpdateProductPopUp from './PopUp';
import SuccessPopup from './SuccessPopUp';
import { Container } from '@material-ui/core';

function Index() {
  const [successUpdate, setSuccessUpdate] = useState(false);
  return (
    <>
      <Container>
        <UpdateProductPopUp updated={setSuccessUpdate} />
        <SuccessPopup
          success={successUpdate}
          handleClose={() => setSuccessUpdate(false)}
        />
      </Container>
    </>
  );
}

export default Index;
