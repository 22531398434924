import React from 'react';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import { PageTitle } from '../../layout-components';
import Plans from '../../components/Payments/Plans';

export default function Products() {
  return (
    <>
      <PageTitle
        titleHeading="Plans"
        titleDescription="Plans You Need"
        titleIcon={<BusinessCenterIcon />}></PageTitle>

      <Plans />
    </>
  );
}
