const newsletterToArray = (newsletters) => {
  return [
    newsletters?.firstName.replace(/<[^>]*>?/gm, ''),
    newsletters.email.replace(/<[^>]*>?/gm, ''),
    new Date(newsletters.createdAt).toLocaleString()
  ];
};

export const transformToCsv = (data) => {
  const rows = [['NAME', 'EMAIL', 'DATE']];
  data.forEach((newsletter) => {
    const row = newsletterToArray(newsletter);
    rows.push(row);
  });
  return rows;
};
