import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { fromUnixTime } from 'date-fns';

import { axiosIns } from '../../../config/configAxios';

import { Table } from '@material-ui/core';

import { getUser } from '../../../reducers/UserAccount';

const ResultsContainer = ({ user }) => {
  // const [expand, setExpand] = useState(false);
  const [invoices, setInvoices] = useState(null);
  // const [numofItems, setNumofItems] = useState(5);

  useEffect(() => {
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    axiosIns.get('/subscriptions/invoice/upcoming').then((res) => {
      setInvoices(res.data);
    });
  }, [user]);
  return (
    <>
      {invoices && (
        <Table className="table table-sm table-bordered text-nowrap">
          <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
            <tr>
              <th className="text-center px-4">Description</th>
              <th className="text-center px-4">Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoices?.lines?.data.map((line) => (
              <tr>
                <td>
                  {line.description}
                  <br />({fromUnixTime(
                    line?.period.start
                  ).toLocaleDateString()}{' '}
                  - {fromUnixTime(line?.period?.end).toLocaleDateString()})
                </td>
                <td className="text-center px-4">
                  {line?.amount && '$' + Number(line?.amount) / 100}
                </td>
              </tr>
            ))}
            <tr>
              <td className="text-right px-4">Subtotal</td>
              <td className="text-center px-4">
                {invoices?.subtotal && '$' + Number(invoices.subtotal) / 100}
              </td>
            </tr>
            <tr>
              <td className="text-right px-4">Total</td>
              <td className="text-center px-4">
                {invoices?.total && '$' + Number(invoices?.total) / 100}
              </td>
            </tr>

            <tr>
              <td className="text-right px-4">Balance</td>
              <td className="text-center px-4">
                {invoices?.starting_balance &&
                  '$' + Number(invoices?.starting_balance) / 100}
              </td>
            </tr>
            <tr>
              <td className="text-right px-4">Amount Due</td>
              <td className="text-center px-4">
                {invoices?.amount_due &&
                  '$' + Number(invoices?.amount_due) / 100}
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: getUser(state)
});
export default connect(mapStateToProps, null)(ResultsContainer);
