import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';

import {
  makeStyles,
  IconButton,
  GridList,
  GridListTile,
  GridListTileBar
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles(() => ({
  gridListWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'
  },

  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },

  gridListTile: {
    maxHeight: 175,
    maxWidth: 175,
    marginRight: '4px',
    cursor: 'move'
  },

  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  }
}));

const PreviewImage = ({ file, index, moveImage, onRemove }) => {
  const classes = useStyles();

  const [preview, setPreview] = useState();

  useEffect(() => {
    if (typeof file === 'object') {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else setPreview(`${process.env.REACT_APP_ASSETS_BASE_URL}/${file}`);
  }, [setPreview, file]);

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;

      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  });
  const opacity = isDragging ? 0.25 : 1;
  drag(drop(ref));

  return (
    <GridListTile ref={ref} className={classes.gridListTile}>
      <img src={preview} alt="..." style={{ opacity }} />
      <GridListTileBar
        titlePosition="top"
        className={classes.titleBar}
        actionIcon={
          <IconButton
            aria-label={`remove image`}
            onClick={() =>
              onRemove(typeof file === 'object' ? file.name : file)
            }>
            <HighlightOffIcon className="text-danger" />
          </IconButton>
        }
      />
    </GridListTile>
  );
};

const ImagesContainer = ({ images, setFieldValue, removeImages }) => {
  const classes = useStyles();

  const moveImage = useCallback(
    (dragIndex, hoverIndex) => {
      const dragImage = images[dragIndex];
      setFieldValue(
        'images',
        update(images, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragImage]
          ]
        })
      );
    },
    [images, setFieldValue]
  );

  return (
    <div className={classes.gridListWrapper}>
      <GridList className={classes.gridList}>
        {images&&images?.map((file, i) => (
          <PreviewImage
            key={i}
            file={file}
            index={i}
            moveImage={moveImage}
            onRemove={(value) => {
              if (typeof file === 'object') {
                setFieldValue(
                  'images',
                  images.filter((img) => img.name !== value)
                );
              } else {
                removeImages.push(value);
                setFieldValue(
                  'images',
                  images.filter((url) => url !== value)
                );
              }
            }}
          />
        ))}
      </GridList>
    </div>
  );
};

export default ImagesContainer;
