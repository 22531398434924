import React from 'react';

import { Dialog, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const SubscriptionModal = (props) => {
  return (
    <>
      <Dialog
        open={props.status}
        //   onClose={toggle3}
        classes={{ paper: 'shadow-lg rounded' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-danger text-white m-0 d-130">
              {/* <FontAwesomeIcon
                icon={['far', 'dot-circle']}
                className="d-flex align-self-center display-3"
              /> */}
            </div>
          </div>
          <div className="font-weight-bold font-size-lg mt-4">
            {props.upgradeStatus.errorMsg}
          </div>
          <p className="mb-0 mt-2 text-muted">
            Please upgrade to continue using our services!
          </p>
          <div className="pt-4">
            <Button
              onClick={props.closeHandler}
              className="btn-neutral-secondary btn-pill mx-1">
              <span className="btn-wrapper--label">No, Thanks</span>
            </Button>
            <Link to={'/app/payment'}>
              <Button
                variant="text"
                className="btn-outline-danger btn-pill mx-1">
                <span className="btn-wrapper--label">Upgrade</span>
              </Button>
            </Link>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SubscriptionModal;
