import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Checkbox,
  Button,
  Dialog,
  Badge,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import StarRateIcon from '@material-ui/icons/StarRate';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons//Warning';

import placeholder from 'assets/images/placeholders/no-product-image.png';
import ebayLogo from 'assets/images/ebay-logo.png';

import { deleteProductById } from 'reducers/Products';
import { getUser } from 'reducers/UserAccount';

import ManageListing from '../ManageListing';
import PublishClover from '../PublishClover';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
const styles = makeStyles((theme) => ({
  valuePrice_text: {
    fontSize: '0.7rem',
    color: 'red'
  },
  category_text: {
    fontSize: '0.7rem'
  }
}));
const TableRow = (props) => {
  const {
    product,
    user,
    isChecked,
    onCheck,
    deleteProduct,
    productCategory
  } = props;
  const {
    _id,
    title,
    rating,
    price,
    status,
    quantity,
    thumbnail,
    condition,
    cloverId,
    valuePrice
  } = product;
  const classes = styles();

  // Delete Modal
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const renderStatus = (value) => {
    let colorVariant = '';
    switch (value) {
      case 'Added':
        colorVariant = 'info';
        break;
      case 'Ready':
        colorVariant = 'warning';
        break;
      case 'Published':
        colorVariant = 'success';
        break;
      case 'Unpublished':
        colorVariant = 'danger';
        break;
      default:
        colorVariant = 'primary';
        break;
    }

    return (
      <div
        className={`px-4 py-1 h-auto text-${colorVariant} text-capitalize font-size-sm border-0 badge badge-neutral-${colorVariant}`}>
        {value}
      </div>
    );
  };

  const subCategory = productCategory?.subCategories.find(
    (subcat) => subcat._id === product.subCategory
  );
  const checkAppStatus = (identifier) => {
    const apps = props.user?.installed_apps;
    // console.log(apps);
    let selectedApp = null;
    // eslint-disable-next-line default-case
    switch (identifier) {
      case 'clover_app':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === identifier
        );
        break;
      case 'ebay_app':
        selectedApp = apps?.find(
          (app) => app.app_id?.identifier === identifier
        );
        break;
    }
    return selectedApp;
  };
  const clover_app = checkAppStatus('clover_app');
  const ebay_app = checkAppStatus('ebay_app');
  return (
    <>
      <tr>
        <td className="text-center text-black-50">
          <Checkbox checked={isChecked} onChange={() => onCheck(product._id)} />
        </td>
        <td>
          <div className="d-flex align-items-center">
            {thumbnail ? (
              <Badge
                color={condition.match(/New|Like-new/i) ? 'secondary' : 'error'}
                badgeContent={condition}
                className="mr-2 mt-1">
                <img
                  alt="..."
                  className="hover-scale-lg rounded-sm"
                  src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${thumbnail}`}
                  loading="lazy"
                  style={{ width: 70 }}
                />
              </Badge>
            ) : (
              <img
                alt="..."
                className="hover-scale-lg rounded-sm"
                src={placeholder}
                style={{ width: 70 }}
              />
            )}

            <div className="pl-3">
              <Link
                to={{
                  pathname: `${process.env.REACT_APP_D_PREFIX}/products/editProduct`,
                  state: { productDetails: product }
                }}>
                <strong>{product.title}</strong>
              </Link>
              <span className="text-black-50 d-block">
                In stock: <b>{quantity ?? 'N/A'}</b>
              </span>
              <span className={clsx(classes.category_text, 'text-black-50')}>
                {`${productCategory?.name}${
                  (subCategory && '>' + subCategory?.name) || ''
                }`}
              </span>
            </div>
          </div>
        </td>
        <td style={{ width: '100px' }}>
          <span className="font-size-lg font-weight-bold">
            $
            {Number(price).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) ?? 'N/A'}
          </span>
          {product.valuePrice && (
            <p className={classes.valuePrice_text}>
              Live price: $
              {Number(valuePrice).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) ?? 'N/A'}
            </p>
          )}
        </td>
        <td>
          <span>
            <StarRateIcon className="text-warning" />
            {rating ? Number.parseFloat(rating).toFixed(1) : 'N/A'}
          </span>
        </td>
        <td>{renderStatus(status)}</td>
        <td style={{ width: '80px' }}>
          {clover_app && clover_app.isInstalled && (
            <PublishClover
              user={user}
              productId={product._id}
              cloverId={cloverId}
              clover_app={clover_app}
            />
          )}
          {ebay_app &&
          ebay_app?.inInstalled &&
          product.ebayListing?.listingId ? (
            <Badge
              badgeContent={<CheckCircleIcon />}
              className="py-1"
              style={{ color: '#1bc943' }}>
              <img src={ebayLogo} alt="eBay logo" width={40} />
            </Badge>
          ) : !product.ebayListing?.listingId &&
            product.ebayListing?.errors?.isError ? (
            <Tooltip
              title={
                <>
                  <ul>
                    {' '}
                    {product.ebayListing?.errors?.messages.map(
                      (m) => `<li>${m}</li>`
                    )}
                  </ul>
                </>
              }>
              <Badge
                badgeContent={
                  <WarningIcon
                    className="pulse-animation"
                    style={{ width: '18px' }}
                  />
                }
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                style={{ color: 'orange' }}>
                <img src={ebayLogo} alt="eBay logo" width={40} />
              </Badge>
            </Tooltip>
          ) : (
            ebay_app &&
            ebay_app?.isInstalled && (
              <img
                src={ebayLogo}
                alt="eBay logo"
                width={40}
                style={{ opacity: 0.75 }}
              />
            )
          )}
        </td>
        <td className="text-right">
          {/* <EditProduct product={product} /> */}
          <Link
            to={{
              pathname: `${process.env.REACT_APP_D_PREFIX}/products/editProduct`,
              state: { productDetails: product }
            }}>
            <Button className="btn-first mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
              <EditIcon className="font-size-md font-weight-bold" />
            </Button>
          </Link>

          {ebay_app && ebay_app?.isInstalled && (
            <ManageListing
              user={user}
              productId={product._id}
              ebayListing={product.ebayListing}
            />
          )}

          <Button
            onClick={toggleModal}
            className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>

          <Dialog
            scroll="body"
            open={modal}
            maxWidth="xs"
            onClose={toggleModal}
            classes={{ paper: 'w-100 border-0 bg-white' }}>
            <div className="p-4">
              <h4 className="font-size-xxl font-weight-bold mb-2">
                Delete Product
              </h4>
              <p className="text-black-50 mb-0">
                You'll lose all related data to the product named{' '}
                <span className="font-weight-bold">{title}</span>. Are sure you
                want to permanently delete this?
              </p>
            </div>
            <div className="divider bg-dark opacity-3" />
            <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
              <Button
                onClick={toggleModal}
                className="btn-neutral font-size-sm mr-3">
                Cancel
              </Button>
              <Button
                className="btn-danger font-weight-bold"
                onClick={() => {
                  deleteProduct(_id);
                  toggleModal();
                }}>
                Yes, delete it
              </Button>
            </div>
          </Dialog>
        </td>
      </tr>
      <tr className="divider"></tr>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
  deleteProduct: (id) => dispatch(deleteProductById(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(TableRow);
