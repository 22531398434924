import React, { useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  RadioGroup,
  Radio,
  FormLabel,
  Table,
  FormControlLabel,
  CircularProgress,
  IconButton,
  Card,
  Badge,
  Grid,
  Tooltip
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
// import StarRateIcon from '@material-ui/icons/StarRate';
import CheckCircleIcon from '@material-ui/icons//CheckCircle';
import WarningIcon from '@material-ui/icons//Warning';
import ClearIcon from '@material-ui/icons/Clear';

import { getUser } from '../../../reducers/UserAccount';
import { axiosIns } from 'config/configAxios';

import ebayLogo from 'assets/images/ebay-logo.png';
import { Cancel, ErrorOutlineRounded } from '@material-ui/icons';
import { loadProducts } from 'reducers/Products';

const Row = ({
  p,
  customErrors,
  publishedProducts,
  requestResponse,
  isEmpty
}) => {
  console.log(requestResponse);
  const product = publishedProducts
    ? publishedProducts.filter((prod) => prod._id === p._id)
    : [];
  // console.log(product);
  const response =
    product.length > 0
      ? requestResponse &&
        requestResponse.filter(
          (res) => res.listingId === product[0].ebayListing.listingId
        )
      : [];
  const error = customErrors
    ? customErrors.length > 0 && customErrors.filter((err) => err.id === p._id)
    : [];
  console.log('error', error, product, response);
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          {p.thumbnail ? (
            <Badge
              color={p.condition.match(/New|Like-new/i) ? 'secondary' : 'error'}
              badgeContent={p.condition}
              className="mr-2 mt-1">
              <img
                alt="..."
                className="hover-scale-lg rounded-sm"
                src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${p.thumbnail}`}
                loading="lazy"
                style={{ width: 70 }}
              />
            </Badge>
          ) : (
            <img
              alt="..."
              className="hover-scale-lg rounded-sm"
              src={p.placeholder}
              style={{ width: 70 }}
            />
          )}
          <div className="pl-3">
            <strong>{p.title.slice(0, 12) + '...'}</strong>
            <span className="text-black-50 d-block">
              Ebay Sku:{' '}
              <b>{product.length > 0 && product[0].ebayListing.sku}</b>
            </span>
          </div>
        </div>
      </td>
      <td style={{ width: '100px' }}>
        <span className="font-size-lg font-weight-bold">
          {product.length > 0 && product[0].ebayListing.listingId ? (
            <CheckCircleIcon className="text-success" />
          ) : (
            <Cancel className="text-danger" />
          )}
        </span>
      </td>

      {error.length > 0 ? (
        <>
          <td style={{ width: '80px' }}>
            {error.map((e) => {
              return e.errors.map((erro, i) => (
                <Tooltip key={i} title={erro.message}>
                  <IconButton>
                    <ErrorOutlineRounded />
                  </IconButton>
                </Tooltip>
              ));
            })}
          </td>
          <td style={{ width: '80px' }}>
            {error.map((e) => {
              return e.warnings.map((erro, i) => (
                <Tooltip key={i} title={erro.message}>
                  <IconButton>
                    <WarningIcon className="text-warning" />
                  </IconButton>
                </Tooltip>
              ));
            })}
          </td>
        </>
      ) : (
        <>
          <td style={{ width: '80px' }}></td>
          <td style={{ width: '80px' }}></td>
        </>
      )}

      {response.length > 0 ? (
        <>
          <td style={{ width: '80px' }}>
            {response.map((e) => {
              return e.errors.map((erro, i) => (
                <Tooltip key={i} title={erro.message}>
                  <IconButton>
                    <ErrorOutlineRounded />
                  </IconButton>
                </Tooltip>
              ));
            })}
          </td>
          <td style={{ width: '80px' }}>
            {response.map((e) => {
              return e.warnings.map((erro, i) => (
                <Tooltip key={i} title={erro.message}>
                  <IconButton>
                    <WarningIcon className="text-warning" />
                  </IconButton>
                </Tooltip>
              ));
            })}
          </td>
        </>
      ) : (
        <>
          <td style={{ width: '80px' }}></td>
          <td style={{ width: '80px' }}></td>
        </>
      )}
    </tr>
  );
};
const TableRows = ({ selectedProducts, response }) => {
  const {
    publishedProducts = [],
    customErrors = [],
    requestResponse = []
  } = response;
  return (
    selectedProducts.length > 0 &&
    selectedProducts.map((p, i) => (
      <Row
        key={i}
        p={p}
        publishedProducts={publishedProducts}
        customErrors={customErrors}
        requestResponse={requestResponse}
        isEmpty={!response}
      />
    ))
  );
};
const BulkEbayPublish = ({ selected, products, user }) => {
  const [saving, setsaving] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  const dispatch = useDispatch();
  const [response, setresponse] = useState({
    publishedProducts: [],
    customErrors: [],
    requestResponse: []
  });
  const [errors, setErrors] = useState({ errors: [], warnings: [] });
  const [modal, setModal] = useState(false);
  const toggleModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setModal(!modal);
    }
    setresponse({
      publishedProducts: [],
      customErrors: [],
      requestResponse: []
    });
  };
  const [exportType, setExportType] = useState('selected');
  const handleExportType = (e) => {
    setErrors({ errors: [], warnings: [] });
    setExportType(e.target.value);
  };

  const downloadCsv = () => {
    setresponse({
      publishedProducts: [],
      customErrors: [],
      requestResponse: []
    });
    setsaving(true);
    setisLoaded(false);
    let ids = [];
    if (exportType === 'all') {
      ids = products.map((p) => p._id);
    } else {
      ids = selected.slice(0, 15);
    }
    setErrors({ errors: [], warnings: [] });

    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

    axiosIns
      .post(`/ebay/bulkpublishitem`, {
        ids
      })
      .then((resp) => {
        setresponse(resp.data);

        if (response) {
          console.log(response);
        }
        setisLoaded(false);
        // link.click();
        setisLoaded(true);
        setsaving(false);
      })
      .catch((err) => {
        if (err.response.data.responses) {
          let errors = [];
          let warnings = [];
          err.response.data.responses.forEach((element) => {
            errors = element.errors.map((e) => e.message);
            warnings = element.warnings.map((e) => e.message);
          });
          console.log(errors, warnings);
          console.log(errors, warnings);
          setErrors({ errors, warnings });
        } else if (err.response.data.errors) {
          console.log(err.response.data.errors);
          let errors = err.response.data.errors.map((e) => e.message);
          setErrors({ errors, warnings: [] });
        } else {
          setErrors({
            errors: [
              'Something went wrong. Please refresh the page and try again.'
            ],
            warnings: []
          });
        }
        setsaving(false);
        setisLoaded(true);
      })
      .finally((e) => {
        dispatch(loadProducts());
      });
  };
  const selectedProducts =
    exportType === 'all'
      ? products
      : products.filter((p) => selected.includes(p._id)).slice(0, 7);
  return (
    <>
      <Button
        size="small"
        className="btn-neutral-secondary"
        onClick={toggleModal}>
        <span className="btn-wrapper--icon">
          <img src={ebayLogo} alt="eBay logo" width={40} />
        </span>
        <span className="btn-wrapper--label">Bulk Ebay Publish</span>
      </Button>

      <Dialog
        open={modal}
        scroll="paper"
        maxWidth="xl"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>
        </div>

        <div className="p-3">
          <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
            Ebay Bulk Publish
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="p-3 text-black-70">
          <div className="mb-2">
            <br />
            Due to limit on products on ebay there is a limit to have 25
            products in each request.
          </div>
          {!saving ? (
            <>
              <FormLabel style={{ marginBottom: '8px' }}>Publish</FormLabel>
              <RadioGroup
                aria-label="export"
                name="export"
                value={exportType}
                onChange={handleExportType}>
                {/* <FormControlLabel
                  value="all"
                  control={<Radio style={{ padding: '2px 8px' }} />}
                  label="All products"
                /> */}
                <FormControlLabel
                  value="selected"
                  control={<Radio style={{ padding: '2px 8px' }} />}
                  disabled={selectedProducts.length === 0}
                  label={`Selected: ${selectedProducts.length} products`}
                />
              </RadioGroup>
              <div className="divider bg-dark opacity-3" />
              <div>
                <div
                  className="table-responsive-sm"
                  style={{ overflowX: 'scroll' }}>
                  <div className="divider bg-dark opacity-3" />

                  <Grid container className="mt-2 mb-2" justify="center">
                    <Table className="table table-alternate-spaced mb-0">
                      <thead>
                        <tr>
                          <th
                            className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                            scope="col">
                            Product
                          </th>
                          <th
                            className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                            scope="col">
                            Status
                          </th>
                          <th
                            className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                            scope="col">
                            Error
                          </th>
                          <th
                            className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                            scope="col">
                            Warning
                          </th>
                          {/* <th
                            className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                            scope="col">
                            ListingId-offerId-sku
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <TableRows
                          selectedProducts={selectedProducts}
                          response={response}
                        />
                      </tbody>
                    </Table>
                  </Grid>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <Skeleton
                  animation="wave"
                  height={20}
                  style={{ marginBottom: 6 }}
                />
                <Card className="my-3 p-4 text-center">
                  <CircularProgress size={24} thickness={5} />
                  {isLoaded}
                </Card>
                <Skeleton animation="wave" height={50} width="100%" />
              </div>
            </>
          )}
        </div>
        {errors.errors.length > 0 && (
          <div className="p-3">
            <Alert severity="error">
              <AlertTitle>Error/s occured while publishing</AlertTitle>
              {errors.errors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </Alert>
          </div>
        )}
        {errors.warnings.length > 0 && (
          <div className="p-3">
            <Alert severity="warning">
              <AlertTitle>Some listings ended up with warnings</AlertTitle>
              {errors.warnings.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </Alert>
          </div>
        )}
        <div className="p-3 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral font-size-sm mr-3">
            Cancel
          </Button>
          <Button
            className="btn-primary font-weight-bold"
            onClick={downloadCsv}>
            Publish
          </Button>
        </div>
      </Dialog>
    </>
  );
};
const BulkEbayDelete = ({ selected, products, user }) => {
  const [saving, setsaving] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  const dispatch = useDispatch();
  const [response, setresponse] = useState({
    unPublishedProductIds: [],
    customErrors: []
  });
  const [errors, setErrors] = useState({ errors: [] });
  const [modal, setModal] = useState(false);
  const toggleModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setModal(!modal);
    }
    setresponse({
      unPublishedProductIds: [],
      customErrors: []
    });
  };
  const [exportType, setExportType] = useState('all');
  const handleExportType = (e) => {
    setErrors({ errors: [] });
    setExportType(e.target.value);
  };

  const downloadCsv = () => {
    setresponse({
      unPublishedProducts: [],
      customErrors: []
    });
    setsaving(true);
    setisLoaded(false);

    let ids = [];
    if (exportType === 'all') {
      ids = products.map((p) => p._id);
    } else {
      ids = selected.slice(0, 15);
    }
    setErrors({ errors: [] });

    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

    axiosIns
      .post(`/ebay/bulkunpublish`, {
        ids
      })
      .then((resp) => {
        setresponse(resp.data);

        if (response) {
          console.log(response);
        }
        setisLoaded(false);
        // link.click();
        setisLoaded(true);
        setsaving(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.responses) {
          let errors = [];
          err.response.data.responses.forEach((element) => {
            errors = element.errors.map((e) => e.message);
          });
          console.log(errors);
          console.log(errors);
          setErrors({ errors });
        } else if (err.response.data.errors) {
          console.log(err.response.data.errors);
          let errors = err.response.data.errors.map((e) => e.message);
          setErrors({ errors });
        } else {
          setErrors({
            errors: [
              'Something went wrong. Please refresh the page and try again.'
            ],
            warnings: []
          });
        }
        setsaving(false);
        setisLoaded(true);
      })
      .finally(() => {
        dispatch(loadProducts());
      });
  };
  const selectedProducts =
    exportType === 'all'
      ? products
      : products.filter((p) => selected.includes(p._id));
  return (
    <>
      <Button
        size="small"
        className="btn-neutral-secondary"
        onClick={toggleModal}>
        <span className="btn-wrapper--icon">
          <img src={ebayLogo} alt="eBay logo" width={40} />
        </span>
        <span className="btn-wrapper--label">Bulk Ebay UnPublish</span>
      </Button>

      <Dialog
        open={modal}
        scroll="paper"
        maxWidth="xl"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>
        </div>

        <div className="p-3">
          <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
            Ebay Bulk UnPublish
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="p-3 text-black-70">
          <div className="mb-2">
            <br />
            Unpublish products from ebay Listings.
          </div>
          {!saving ? (
            <>
              <FormLabel style={{ marginBottom: '8px' }}>Unpublish</FormLabel>
              <RadioGroup
                aria-label="export"
                name="export"
                value={exportType}
                onChange={handleExportType}>
                <FormControlLabel
                  value="all"
                  control={<Radio style={{ padding: '2px 8px' }} />}
                  label="All products"
                />
                <FormControlLabel
                  value="selected"
                  control={<Radio style={{ padding: '2px 8px' }} />}
                  disabled={selectedProducts.length === 0}
                  label={`Selected: ${selectedProducts.length} products`}
                />
              </RadioGroup>
              <div className="divider bg-dark opacity-3" />
              <div>
                <div
                  className="table-responsive-sm"
                  style={{ overflowX: 'scroll' }}>
                  <div className="divider bg-dark opacity-3" />

                  <Grid container className="mt-2 mb-2" justify="center">
                    <Table className="table table-alternate-spaced mb-0">
                      <thead>
                        <tr>
                          <th
                            className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                            scope="col">
                            Product
                          </th>
                          <th
                            className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                            scope="col">
                            Status
                          </th>
                          <th
                            className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                            scope="col">
                            Error
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProducts.map((p, i) => (
                          <>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  {p.thumbnail ? (
                                    <Badge
                                      color={
                                        p.condition.match(/New|Like-new/i)
                                          ? 'secondary'
                                          : 'error'
                                      }
                                      badgeContent={p.condition}
                                      className="mr-2 mt-1">
                                      <img
                                        alt="..."
                                        className="hover-scale-lg rounded-sm"
                                        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${p.thumbnail}`}
                                        loading="lazy"
                                        style={{ width: 70 }}
                                      />
                                    </Badge>
                                  ) : (
                                    <img
                                      alt="..."
                                      className="hover-scale-lg rounded-sm"
                                      src={p.placeholder}
                                      style={{ width: 70 }}
                                    />
                                  )}
                                  <div className="pl-3">
                                    <strong>
                                      {p.title.slice(0, 12) + '...'}
                                    </strong>
                                    {/* <span className="text-black-50 d-block">
              Ebay Sku:{' '}
              <b>{product.length > 0 && product[0].ebayListing.sku}</b>
            </span> */}
                                  </div>
                                </div>
                              </td>
                              <td style={{ width: '100px' }}>
                                <span className="font-size-lg font-weight-bold">
                                  {response.unPublishedProductIds &&
                                  response.unPublishedProductIds.length > 0 &&
                                  response.unPublishedProductIds.includes(
                                    p._id
                                  ) ? (
                                    <CheckCircleIcon className="text-success" />
                                  ) : (
                                    <Cancel className="text-danger" />
                                  )}
                                </span>
                              </td>

                              {response.customErrors.length > 0 ? (
                                <>
                                  <td style={{ width: '80px' }}>
                                    {response.customErrors &&
                                      response.customErrors
                                        .filter((err) => err.id === p._id)
                                        .map((e) => {
                                          return e.errors.map((erro, i) => (
                                            <Tooltip
                                              key={i}
                                              title={erro.message}>
                                              <IconButton>
                                                <ErrorOutlineRounded className="text-danger" />
                                              </IconButton>
                                            </Tooltip>
                                          ));
                                        })}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td style={{ width: '80px' }}></td>
                                </>
                              )}

                              {/* {response.length > 0 ? (
        <>
          <td style={{ width: '80px' }}>
            {response.map((e) => {
              return e.errors.map((erro, i) => (
                <Tooltip key={i} title={erro.message}>
                  <IconButton>
                    <ErrorOutlineRounded />
                  </IconButton>
                </Tooltip>
              ));
            })}
          </td>
          <td style={{ width: '80px' }}>
            {response.map((e) => {
              return e.warnings.map((erro, i) => (
                <Tooltip key={i} title={erro.message}>
                  <IconButton>
                    <WarningIcon />
                  </IconButton>
                </Tooltip>
              ));
            })}
          </td>
        </>
      ) : (
        <>
          <td style={{ width: '80px' }}></td>
          <td style={{ width: '80px' }}></td>
        </>
      )} */}
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </Grid>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <Skeleton
                  animation="wave"
                  height={20}
                  style={{ marginBottom: 6 }}
                />
                <Card className="my-3 p-4 text-center">
                  <CircularProgress size={24} thickness={5} />
                  {isLoaded}
                </Card>
                <Skeleton animation="wave" height={50} width="100%" />
              </div>
            </>
          )}
        </div>
        {errors.errors.length > 0 && (
          <div className="p-3">
            <Alert severity="error">
              <AlertTitle>Error/s occured while publishing</AlertTitle>
              {errors.errors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </Alert>
          </div>
        )}

        <div className="p-3 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral font-size-sm mr-3">
            Cancel
          </Button>
          <Button
            className="btn-primary font-weight-bold"
            onClick={downloadCsv}>
            Publish
          </Button>
        </div>
      </Dialog>
    </>
  );
};
const ExportFacebookProducts = ({ selected, products, user, isUnpublish }) => {
  return !isUnpublish ? (
    <BulkEbayPublish selected={selected} products={products} user={user} />
  ) : (
    <BulkEbayDelete selected={selected} products={products} user={user} />
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state)
});
export default connect(mapStateToProps, null)(ExportFacebookProducts);
