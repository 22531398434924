import React, { useState } from 'react';

import clsx from 'clsx';

import { Card, Container, Table, Button, Collapse } from '@material-ui/core';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import AddProduct from '../../Products/AddProduct';
import { Pagination } from '@material-ui/lab';

const ResultsContainer = ({ lookups }) => {
  const [expand, setExpand] = useState(false);
  const toggleExpand = () => setExpand(!expand);
  const [pageNumber, setPageNumber] = useState(1);
  const handlePageChange = (event, val) => {
    setPageNumber(val);
  };

  return (
    <>
      <div className="divider my-4" />

      <Card className="card-box mb-5 accordion">
        <Card
          className={clsx(
            'border-0 bg-transparent shadow-none rounded-0 card-box',
            { 'panel-open': expand }
          )}>
          <Card className="border-0 bg-transparent shadow-none rounded-top">
            <div className="card-header rounded-top">
              <div className="panel-title">
                <div className="accordion-toggle rounded-top overflow-hidden">
                  <Button
                    size="large"
                    variant="text"
                    className="btn-link border-bottom-0 d-flex align-items-center justify-content-between btn-transition-none"
                    onClick={toggleExpand}>
                    <span className="font-weight-bold">Lookup History</span>
                    <ExpandLessIcon className="accordion-icon" />
                  </Button>
                </div>
              </div>
            </div>
            <Collapse in={expand}>
              <Container className="py-4">
                <div className="table-responsive-md">
                  <Table className="table table-sm table-bordered text-nowrap mb-4">
                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                      <tr>
                        <th className="text-left px-2">Sr</th>
                        <th className="text-left px-4">{'Date & Time'}</th>
                        <th className="text-left px-4">Barcode / ASIN</th>
                        <th className="text-left px-4">Status</th>
                        <th className="text-left px-4"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {lookups.slice(
                      (pageNumber - 1) * 20,
                      pageNumber * 20
                    ).map((lookup, i) => (
                        <tr key={i}>
                          <td className="text-left px-2">{i + 1}.</td>
                          <td className="text-left px-4">
                            {new Date(lookup.createdAt).toLocaleString()}
                          </td>
                          <td className="text-left px-4">
                            {lookup.requestBody?.identifier}
                          </td>
                          <td className="text-left px-4">
                            {lookup.foundAt === 'not_found' ? (
                              <span className="text-danger">Not Found</span>
                            ) : (
                              <span className="text-success">Found</span>
                            )}
                          </td>
                          <td className="text-left px-4">
                            {lookup.foundAt === 'not_found' ? (
                              <AddProduct fromHistory={lookup.requestBody} />
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
            <Pagination
              className="pagination-success"
              count={Math.ceil(lookups.length / 20)}
              page={pageNumber}
              onChange={handlePageChange}
            />
          </div>
                </div>
              </Container>
            </Collapse>
          </Card>
        </Card>
      </Card>
    </>
  );
};

export default ResultsContainer;
