import React, { useState, useEffect } from 'react';

import {
  Button,
  Dialog,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  CircularProgress,
  Badge
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { axiosIns } from 'config/configAxios';

import cloverLogo from 'assets/images/clover.svg';
import cloverBraning from 'assets/images/cloverbraning.png';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  circularProgress: {
    color: '#1bc943',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const PublishClover = ({ user, productId, cloverId, clover_app }) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    setIsSuccess(false);
    setErrors([]);
  };
  useEffect(() => {
    setIsListed(cloverId);
  }, [cloverId]);
  const { token, platforms } = user;
  axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const isEbayConnected = platforms?.clover.isConnected || false;
  const [isListed, setIsListed] = useState(cloverId !== null);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isEnding, setIsEnding] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const publish = () => {
    setIsUpdating(true);
    axiosIns
      .post('/clover/publishItem', { productId })
      .then((res) => {
        if (res.status === 201) {
          setIsSuccess(true);
          setIsListed(true);
        }
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors.map((err) => err.message));
        } else {
          setErrors([
            'Something went wrong. Please refresh the page and try again.'
          ]);
        }
      })
      .finally(() => setIsUpdating(false));
  };

  //   const update = () => {
  //     setIsUpdating(true);
  //     axiosIns
  //       .post('/clover/updateItem', { productId })
  //       .then((res) => {
  //         if (res.status === 204) {
  //           setIsSuccess(true);
  //         }
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           setErrors(err.response.data.errors.map((err) => err.message));
  //         } else {
  //           setErrors([
  //             'Something went wrong. Please refresh the page and try again.'
  //           ]);
  //         }
  //       })
  //       .finally(() => setIsUpdating(false));
  //   };

  const unpublish = () => {
    setIsSuccess(false);
    setIsEnding(true);
    axiosIns
      .post('/clover/unpublishItem', { productId })
      .then((res) => {
        if (res.status === 201) setIsListed(false);
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors.map((err) => err.message));
        } else {
          setErrors([
            'Something went wrong. Please refresh the page and try again.'
          ]);
        }
      })
      .finally(() => setIsEnding(false));
  };

  return (
    <>
      <Button
        onClick={toggleModal}
        className="btn-primary mx-1 rounded-sm shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
        {/* <PublishIcon className="font-size-xl font-weight-bold" /> */}
        {isListed ? (
          <Badge
            badgeContent={
              <CheckCircleIcon className="font-size-xs font-weight-bold" />
            }
            className="py-1"
            style={{ color: '#1bc943' }}>
            <img src={cloverBraning} alt="eBay logo" width={30} />
          </Badge>
        ) : (
          <img
            src={cloverBraning}
            alt="eBay logo"
            width={30}
            style={{ opacity: 1 }}
          />
        )}
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="sm"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-xl font-weight-bold" />
          </Button>
        </div>

        <div className="p-3">
          <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
            Add Product To Clover
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="py-2">
          <List>
            <ListItem>
              <ListItemAvatar>
                <img src={cloverLogo} alt="Clover logo" width={40} />
              </ListItemAvatar>
              <ListItemText
                primary="Clover"
                secondary={
                  <>
                    {!isEbayConnected && (
                      <div className="text-danger">
                        Account is not connected. Please connect your account
                        from here{' '}
                        <Link
                          to={`${process.env.REACT_APP_D_PREFIX}/apps/${clover_app.app_id._id}/details`}>
                          connect
                        </Link>
                      </div>
                    )}
                    {isListed
                      ? 'This item is  on clover.'
                      : 'This item is not on clover.'}
                  </>
                }
              />

              <ListItemSecondaryAction>
                {isListed ? (
                  <div className="d-flex align-items-center justify-content-center">
                    {/* <div style={{ position: 'relative' }} className="ml-2">
                      <Button
                        size="small"
                        className="btn-neutral-primary"
                        onClick={update}
                        disabled={isUpdating}>
                        Update
                      </Button>
                      {isUpdating && (
                        <CircularProgress
                          size={24}
                          thickness={5}
                          className={classes.circularProgress}
                        />
                      )}
                    </div> */}

                    <div style={{ position: 'relative' }} className="ml-2">
                      <Button
                        size="small"
                        className="btn-neutral-danger"
                        onClick={unpublish}
                        disabled={isEnding || isUpdating}>
                        End
                      </Button>
                      {isEnding && (
                        <CircularProgress
                          size={24}
                          thickness={5}
                          className={classes.circularProgress}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ position: 'relative' }}>
                    <Button
                      edge="end"
                      size="small"
                      aria-label="publish"
                      className="btn-neutral-primary"
                      disabled={!isEbayConnected || isUpdating}
                      onClick={publish}>
                      Push
                    </Button>
                    {isUpdating && (
                      <CircularProgress
                        size={24}
                        thickness={5}
                        className={classes.circularProgress}
                      />
                    )}
                  </div>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        {errors.length > 0 && (
          <div className="p-3">
            <Alert severity="error">
              <AlertTitle>Error/s occured while publishing</AlertTitle>
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </Alert>
          </div>
        )}
        {isSuccess && (
          <div className="p-3">
            <Alert severity="success">
              Success! You product is now active on clover. Please verify the
              quantity and pricing of product from clover dashboard.
            </Alert>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default PublishClover;
