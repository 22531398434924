import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
// import avatar1 from 'assets/images/avatars/avatar1.jpg';
import theresa from 'assets/images/ccb.png';
// import mockup from '../../../assets/images/stock-photos/phone-mockup.png';
import particles3 from 'assets/images/hero-bg/particles-3.svg';

export default function LivePreviewExample() {
  return (
    <>
      <div
        className="hero-wrapper bg-composed-wrapper"
        style={{ backgroundColor: '#0c145b' }}>
        <div className="hero-wrapper--content flex-row">
          <div
            className="bg-composed-wrapper--image bg-composed-filter-rm opacity-6"
            style={{ backgroundImage: 'url(' + particles3 + ')' }}
          />
          <div className="bg-composed-wrapper--content py-5">
            {/* <Container className="py-5 text-center"> */}
            <Grid container justify="center" alignItems="center">
              <Grid item md={8} lg={8} sm={12} className=" px-3">
                <Grid container justify="flex-end">
                  <Grid container md={9}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <FontAwesomeIcon
                          icon={faQuoteRight}
                          className="text-white font-size-xxl"
                        />
                      </div>
                    </div>
                    <span
                      className="text-white  font-weight-bold"
                      style={{ fontWeight: 700, fontSize: '1.5rem' }}>
                      Cartlauncher exceeded our expectations by providing a
                      tailored solution for our daily changing inventory needs.
                      Since implementation, our business has seen significant
                      growth. I highly recommend Cartlauncher for any business
                      looking for a anyone looking to grow and sell online.
                    </span>
                    <div className="align-box-row mt-lg-1 mt-md-1">
                      <div className="avatar-icon-wrapper avatar-icon-lg">
                        <div className="avatar-icon rounded-circle">
                          <img src={theresa} alt="..." />
                        </div>
                      </div>
                      <div className="pl-2 pt-3">
                        <span className="d-block text-white font-size-lg">
                        Theresa Mitchell
                          <small className="d-block text-white-50">
                          Manager, Circle City Bargains
                          </small>
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} lg={4} sm={12}>
                <div className="d-flex align-items-center justify-content-start pl-5 ">
                  <img
                    src={theresa}
                    alt="cartlancher phone mockup"
                    className="rounded-circle border border-danger d-none d-md-block"
                    style={{ maxWidth: '60' }}
                  />
                </div>
              </Grid>
            </Grid>
            {/* </Container> */}
          </div>
        </div>
      </div>

      {/* <Container>
        <section>
          <Grid container justify="center">
            <Grid item md={7} lg={7} sm={12} className="pt-5 px-3">
              <Grid container justify="flex-end">
                <Grid container md={9}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        className="text-dark font-size-xxl"
                      />
                    </div>
                  </div>
                  <span
                    className="text-black-70  font-weight-bold"
                    style={{ fontWeight: 700, fontSize: '1.5rem' }}>
                    We were looking for a solution to our daily changing
                    inventory, other solutions in the market were not taillored
                    to our business and requires more time and skills to manage.
                    Cartlauncher did just what we wanted and more. Our business
                    have been growing since we launcher.
                  </span>
                  <div className="align-box-row mt-lg-1 mt-md-1">
                    <div className="avatar-icon-wrapper avatar-icon-lg">
                      <div className="avatar-icon rounded-circle">
                        <img src={avatar1} alt="..." />
                      </div>
                    </div>
                    <div className="pl-2">
                      <span className="d-block font-size-lg">
                        Mandy Erle
                        <small className="d-block text-black-50">
                          CCB Company
                        </small>
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5} lg={5} sm={12}>
              <div className="d-flex align-items-center justify-content-start">
                <img
                  src={mockup}
                  alt="cartlancher phone mockup"
                  style={{ width: '95' }}
                />
              </div>
            </Grid>
          </Grid>
        </section>
      </Container> */}
    </>
  );
}
