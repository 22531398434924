import React from 'react';

import Homepage1 from '../../components/Homepage/Homepage1';

export default function Homepage() {
  return (
    <>
      <Homepage1 />
    </>
  );
}
