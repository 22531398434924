import React from 'react';
import { PageTitle } from '../../layout-components';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import Payment from '../../components/Payments/Payment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Payments() {
  return (
    <>
      <PageTitle
        titleHeading="Plan"
        titleDescription="Usage and Invoices."
        titleIcon={<FontAwesomeIcon icon={faMoneyBill} size='xs'/>}></PageTitle>
      <Payment />
    </>
  );
}
