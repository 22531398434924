import { Container, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const Video = () => {
  return (
    <div
      style={{ height: 'fit-content', paddingBlock: '5rem' , background: '#eee' }}
      className="d-flex flex-wrap align-items-center justify-content-center">
      <Container>
        <Grid container md={12} spacing={2}>
          <Grid item md={6} alignItems="center" alignContent="center">
            <Grid item md={12} className='d-flex justify-content-center justify-content-lg-start'>
              <div className="d-70 border-0 my-2 card-icon-wrapper bg-danger text-white btn-icon mb-4">
                <FontAwesomeIcon icon={faPlay} className="font-size-xxl" />
              </div>
            </Grid>
            <Grid container md={12} className="text-center text-lg-left">
              <Grid item md={12}>
                <h2 className="display-3 mb-2 font-weight-bold">
                  Seeing is believing!
                </h2>
              </Grid>
              <Grid item md={12}>
                <h5 className="mb-2">
                See how Cartlauncher can help you  grow your liquidation business 
                </h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            {' '}
            <iframe
              width="100%"
              height="270px"
              src="https://www.youtube.com/embed/WF9gwKuAwbM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Video;
