import React, { useState } from 'react';

import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
  makeStyles,
  CircularProgress,
  Grid
} from '@material-ui/core';

import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import CloudDownload from '@material-ui/icons/CloudDownload';

import { getUser } from '../../../reducers/UserAccount';
import { axiosIns } from 'config/configAxios';
const useStyles = makeStyles(() => ({
  circularProgress: {
    color: '#1bc943',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));
const ExportFacebookProducts = ({ selected, products, user }) => {
  const classes = useStyles();
  const [saving, setsaving] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [exportType, setExportType] = useState('all');
  const handleExportType = (e) => setExportType(e.target.value);

  const downloadCsv = () => {
    setsaving(true);
    setisLoaded(true);
    let ids = [];
    if (exportType === 'all') {
      ids = products.map((p) => p._id);
    } else {
      ids = selected;
    }

    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

    axiosIns
      .post(
        `/products/facebookexport`,
        {
          ids
        },
        {
          responseType: 'arraybuffer'
        }
      )
      .then((resp) => {
        const url = new Blob([resp.data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.classList.add('btn');
        link.classList.add('btn-primary');
        link.href = URL.createObjectURL(url);
        link.text = 'Images + csv (*zip)';
        link.setAttribute('download', 'file.zip');
        if (document.getElementById('zip-btn').childElementCount > 1) {
          document.getElementById('zip-btn').removeChild(link);
          setisLoaded(false);
        }
        document.getElementById('zip-btn').appendChild(link);
        // link.click();
        //   setIsLoading(false);
        setsaving(false);
      })
      .catch((e) => {
        console.log(e);
        setsaving(false);
      });
  };

  return (
    <>
      <Button
        size="small"
        className="btn-neutral-secondary"
        onClick={toggleModal}>
        <span className="btn-wrapper--icon">
          <PublishIcon />
        </span>
        <span className="btn-wrapper--label">Facebook Export</span>
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="xs"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="card-tr-actions z-over">
          <Button
            onClick={toggleModal}
            className="btn-neutral-dark mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <ClearIcon className="font-size-md font-weight-bold" />
          </Button>
        </div>

        <div className="p-3">
          <h4 className="font-size-xl" style={{ marginBottom: 0 }}>
            Facebook Marketplace Export
          </h4>
        </div>

        <div className="divider bg-dark opacity-3" />

        <div className="p-3 text-black-70">
          <div className="mb-2">
            This CSV file can be used to list products on facebook marketplace
            with all product info.
            <br />
            Due to limit on products on facebook we will export products in
            batches of csv files so you can upload easily.
          </div>
          <FormLabel style={{ marginBottom: '8px' }}>Export</FormLabel>
          <RadioGroup
            aria-label="export"
            name="export"
            value={exportType}
            onChange={handleExportType}>
            <FormControlLabel
              value="all"
              control={<Radio style={{ padding: '2px 8px' }} />}
              label="All products"
            />
            <FormControlLabel
              value="selected"
              control={<Radio style={{ padding: '2px 8px' }} />}
              disabled={selected.length === 0}
              label={`Selected: ${selected.length} products`}
            />
          </RadioGroup>
        </div>

        <div className="divider bg-dark opacity-3" />
        <div>
          <div className="table-responsive-sm" style={{ overflowX: 'scroll' }}>
            <div className="divider bg-dark opacity-3" />

            <Grid container className="mt-2 mb-2" justify="center">
              <Button
                className="btn-success font-weight-bold m-auto p-auto"
                style={{ justifyContent: 'center' }}
                id="zip-btn"
                disabled={(saving && isLoaded) || !isLoaded}>
                <CloudDownload className="font-size-xl font-weight-bold" />
                {saving && 'Processing...'}{' '}
                {saving && isLoaded && (
                  <CircularProgress
                    size={24}
                    thickness={5}
                    className={classes.circularProgress}
                  />
                )}
              </Button>
            </Grid>
          </div>
        </div>
        <div className="p-3 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral font-size-sm mr-3">
            Cancel
          </Button>
          <Button
            className="btn-primary font-weight-bold"
            onClick={downloadCsv}>
            Export products
          </Button>
        </div>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: getUser(state)
});
export default connect(mapStateToProps, null)(ExportFacebookProducts);
