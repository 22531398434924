import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Button, List, ListItem } from '@material-ui/core';

import projectLogo from '../../../assets/images/logo193.png';

import { getIsAuthenticated } from '../../../reducers/UserAccount';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const LivePreviewExample = ({ isAuthenticated }) => {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);
  return (
    <>
      <div className="header-nav-wrapper header-nav-wrapper-lg navbar-dark">
        <div className="app-nav-logo">
          <a href="/" title="CartLauncher logo" className="app-nav-logo">
            <div className="app-nav-logo--icon rounded-circle">
              <img alt="CartLauncher logo" src={projectLogo} />
            </div>
          </a>
        </div>
        <div className="header-nav-menu d-none d-lg-block">
          <ul className="d-flex justify-content-center">
            <li>
              <a className="rounded-pill" href="/about">
                About
                <span className="opacity-5 dropdown-arrow">
                  <FontAwesomeIcon icon={['fas', 'angle-down']} />
                </span>
              </a>
            </li>
            <li>
              <Link to="/pricing" className="rounded-pill">
                Pricing
                <span className="opacity-5 dropdown-arrow">
                  <FontAwesomeIcon icon={['fas', 'angle-down']} />
                </span>
              </Link>
            </li>
            <li>
              <a
                className="rounded-pill"
                href="#/"
                onClick={(e) => e.preventDefault()}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="header-nav-actions flex-grow-0 flex-lg-grow-1">
          {isAuthenticated ? (
            <span className="d-none d-lg-block">
              <Button
                component={NavLink}
                to={`${process.env.REACT_APP_D_PREFIX}`}
                className="btn-pill text-uppercase font-weight-bold text-nowrap font-size-xs shadow-sm-dark btn-first">
                Dashboard
              </Button>
            </span>
          ) : (
            <>
              <span className="d-none d-lg-block">
                <Button
                  component={NavLink}
                  to="/register"
                  className="btn-pill text-uppercase font-weight-bold text-nowrap font-size-xs shadow-sm-dark btn-secondary mr-2">
                  Get started
                </Button>
              </span>
              <span className="d-none d-lg-block">
                <Button
                  component={NavLink}
                  to="/login"
                  className="btn-pill text-uppercase font-weight-bold text-nowrap font-size-xs shadow-sm-dark btn-first">
                  Login
                </Button>
              </span>
            </>
          )}

          <span className="d-block d-lg-none">
            <button
              onClick={toggle}
              className={clsx('navbar-toggler hamburger hamburger--elastic', {
                'is-active': collapse
              })}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </span>
        </div>
        <div className="d-flex d-lg-none">
          <Collapse
            in={collapse}
            className="nav-collapsed-wrapper navbar-collapse">
            <div className="nav-inner-wrapper">
              <Button
                onClick={toggle}
                className="btn-danger btn-icon d-40 shadow-sm hover-scale-lg btn-animated-icon-sm nav-toggle-inner-btn p-0">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </Button>

              <List
                component="div"
                className="nav-pills nav-neutral-primary nav-pills-rounded nav-lg flex-column p-3">
                <ListItem
                  component="a"
                  button
                  href="/about"
                  className="px-4 d-flex align-items-center">
                  <span>About</span>
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="/pricing"
                  className="px-4 d-flex align-items-center">
                  <span>Pricing</span>
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="px-4 d-flex align-items-center">
                  <span>Contact</span>
                </ListItem>
              </List>
            </div>
          </Collapse>
        </div>
      </div>
      <div
        className={clsx('collapse-page-trigger', { 'is-active': collapse })}
        onClick={toggle}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state)
});

export default connect(mapStateToProps)(LivePreviewExample);
