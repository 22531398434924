import React from 'react';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';


const ResultsContainer = ({
  user,
  loadUser,
  invoices,
  isLoading,
  setIsLoading,
  setInvoices
}) => {
  // const refreshPaymentsMethod = () => {
  //   axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
  //   axiosIns.get('/users/payments/card-source').then((respinse) => {
  //     setIsLoading(false);
  //     setInvoices(respinse.data);
  //   });
  // };
  // const handleDelete = (id) => {
  //   axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
  //   axiosIns
  //     .post('/users/payments/card-source', { cardId: id })
  //     .then((respinse) => {
  //       refreshPaymentsMethod();
  //     });
  // };
  // const handleMarkedDefault = (id) => {
  //   axiosIns.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
  //   axiosIns
  //     .post('/users/payments/update-card-source', { id: id })
  //     .then((response) => {
  //       loadUser(user.token);
  //     });
  // };
  return (
    <>
      {!isLoading ? (
        <>
          {invoices && invoices.length > 0 ? (
            // invoices.map((invoice) => (
            <div
              key={invoices[0]?.id}
              className="d-flex flex-row justify-content-start align-items-center">
              <div classNmae="h-100">
                {invoices[0].card?.brand === 'visa' ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 32 32">
                    <path
                      fill="currentColor"
                      d="M26.117 14.628s.422 2.067.517 2.5h-1.855l.889-2.417c-.011.017.183-.506.294-.828zM32 6.222v19.556a2.668 2.668 0 0 1-2.667 2.667H2.666a2.668 2.668 0 0 1-2.667-2.667V6.222a2.668 2.668 0 0 1 2.667-2.667h26.667A2.668 2.668 0 0 1 32 6.222zM8.472 20.178l3.511-8.622H9.622l-2.183 5.889l-.239-1.194l-.778-3.967c-.128-.55-.522-.706-1.011-.728H1.817l-.039.172a8.783 8.783 0 0 1 2.344.95l1.989 7.5zm5.245.011l1.4-8.633h-2.233l-1.394 8.633zm7.772-2.822c.011-.983-.589-1.733-1.872-2.35c-.783-.395-1.261-.661-1.261-1.067c.011-.367.406-.745 1.283-.745a3.825 3.825 0 0 1 1.661.328l.2.094l.306-1.867a5.553 5.553 0 0 0-2-.367c-2.206 0-3.756 1.178-3.767 2.855c-.017 1.239 1.111 1.928 1.956 2.344c.861.422 1.156.7 1.156 1.072c-.011.578-.7.844-1.339.844c-.889 0-1.367-.139-2.095-.461l-.294-.139l-.311 1.939c.522.239 1.489.45 2.489.461c2.344.005 3.872-1.156 3.889-2.944zm7.844 2.822l-1.8-8.633h-1.728c-.533 0-.939.156-1.167.717l-3.317 7.917h2.344s.383-1.067.467-1.294h2.867c.067.306.267 1.294.267 1.294z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="currentColor"
                    class="bi bi-credit-card"
                    viewBox="0 0 16 16">
                    {' '}
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />{' '}
                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />{' '}
                  </svg>
                )}
              </div>
              <div className="h-100 d-flex flex-column justify-content-center align-items-start pl-3">
                <div className="d-flex flex-row justify-content-center align-items-center h-100">
                  <Typography
                    variant="body2"
                    className="text-uppercase"
                    style={{ fontWeight: 600 }}>
                    {invoices[0].card?.brand} card
                  </Typography>{' '}
                  <span className="btn-pill ml-2 bg-success text-white badge">
                    Primary
                  </span>
                </div>
                <div>
                  {' '}
                  <p className="text-left mb-0 text-black-50">
                    {' '}
                    Expires in :{' '}
                    {`${invoices[0].card?.exp_month}/${invoices[0].card?.exp_year}`}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            // ))
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              You don't have any payment methods
            </Typography>
          )}
          {/* <div className='d-flex flex-row justify-content-center align-items-center'>
          <div>
          {invoice.card?.brand === 'visa' ? (
                        <div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--32 Box-root Flex-flex">
                          <svg
                            class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--32-svg"
                            height="32"
                            width="32"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd">
                              <path d="M0 0h32v32H0z" fill="#00579f"></path>
                              <g fill="#fff" fill-rule="nonzero">
                                <path d="M13.823 19.876H11.8l1.265-7.736h2.023zm7.334-7.546a5.036 5.036 0 0 0-1.814-.33c-1.998 0-3.405 1.053-3.414 2.56-.016 1.11 1.007 1.728 1.773 2.098.783.379 1.05.626 1.05.963-.009.518-.633.757-1.216.757-.808 0-1.24-.123-1.898-.411l-.267-.124-.283 1.737c.475.213 1.349.403 2.257.411 2.123 0 3.505-1.037 3.521-2.641.008-.881-.532-1.556-1.698-2.107-.708-.354-1.141-.593-1.141-.955.008-.33.366-.667 1.165-.667a3.471 3.471 0 0 1 1.507.297l.183.082zm2.69 4.806l.807-2.165c-.008.017.167-.452.266-.74l.142.666s.383 1.852.466 2.239h-1.682zm2.497-4.996h-1.565c-.483 0-.85.14-1.058.642l-3.005 7.094h2.123l.425-1.16h2.597c.059.271.242 1.16.242 1.16h1.873zm-16.234 0l-1.982 5.275-.216-1.07c-.366-1.234-1.515-2.575-2.797-3.242l1.815 6.765h2.14l3.18-7.728z"></path>
                                <path d="M6.289 12.14H3.033L3 12.297c2.54.641 4.221 2.189 4.912 4.049l-.708-3.556c-.116-.494-.474-.633-.915-.65z"></path>
                              </g>
                            </g>
                          </svg>
                        </div>
                      ) : (
                        invoice.card.brand
                      )}
          </div>
          <div>
            <h3>{invoice.card.name}</h3>
            <p>{invoice.card.number}</p>
          </div>
        </div> */}
        </>
      ) : (
        <>
          {/* <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} width="100%" /> */}
          <Skeleton animation="wave" height={50} width="50%" />
        </>
      )}
    </>
  );
};

export default ResultsContainer;
