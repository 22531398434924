import React from 'react';

import SearchIcon from '@material-ui/icons/Search';

import { PageTitle } from '../../layout-components';
import { BarcodesLookup1 } from '../../components';

export default function BarcodesLookup() {
  return (
    <>
      <PageTitle
        titleHeading="Barcodes Lookup"
        titleDescription="Search barcodes by scanning, input or batch uploading."
        titleIcon={<SearchIcon />}></PageTitle>

      <BarcodesLookup1 />
    </>
  );
}
