import React from 'react';
import jsBarcode from 'jsbarcode';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFViewer
} from '@react-pdf/renderer';
import { Button, Dialog, Toolbar, AppBar, Slide } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// in order to use a font in react pdf rendrer you should register it first,uncommenting this will create an issue fontkit__WEBPACK_IMPORTED_MODULE_6__.create is not a function
// Font.register({
//   family: 'Open Sans',
//   fonts: [
//     {
//       src:
//         'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-bold.ttf'
//     },
//     {
//       src:
//         'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
//       fontWeight: 600
//     }
//   ]
// });

//pdf component
const BcPdf = ({
  open,
  setOpen,
  setIsOver,
  showProductName,
  showProductPrice,
  showProductModel,
  barecodeSize,
  setBareCodeSize,
  printStockQty,
  infos,
  handleCloseOptions,
  setIsGenerating
}) => {
  // close pdf viewer
  const handleClose = () => {
    // setShowProductName(false);
    // setShowProductPrice(false);
    // setShowProductModel(false);
    // setBareCodeSize({
    //   id: null,
    //   labelName: '',
    //   labelSize: {
    //     textSize: null,
    //     dimensions: []
    //   }
    // });
    setOpen(false);
    setIsGenerating(false);
  };
  //component thet renders barcode
  const BarcodeRender = (props) => {
    try {
      let canvas = document.createElement('canvas');
      jsBarcode(canvas, props.data, { format: 'CODE128' });
      const url = canvas.toDataURL();
      // console.log(url); // Debugging code
      return <Image style={{ width: 'auto' }} src={url} />;
    } catch (error) {
      console.error('Error rendering barcode:', error);
      return <div>Error rendering barcode</div>;
    }
  };
  //labels styles
  const styles = StyleSheet.create({
    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
      width: '336px',
      height: '105.6px'
    },
    section: {
      height: '70%'
    },
    PdfViewer: {
      marginTop: '5rem',
      width: '90%',
      height: '95vh'
    },
    title: {
      fontFamily: 'Times-Roman',
      fontSize: barecodeSize.labelSize.textSize,
      paddingHorizontal: '2',
      fontWeight: 'bold'
    },
    model: {
      fontFamily: 'Times-Roman',
      fontSize: 8,
      fontWeight: 'bold',
      paddingHorizontal: '2'
    },
    price: {
      fontFamily: 'Times-Roman',
      fontSize: 14,
      paddingHorizontal: '2',
      fontWeight: 'bold'
    },
    bottomSection: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '50%'
    },
    bottomSectionPrice: {
      width: '40%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstLabelBottomSectionPrice: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    bottomSectionBc: {
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstLabelBottomSection: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '50%'
    },
    firstLabelPrice: {
      fontFamily: 'Times-Roman',
      textAlign: 'center',
      fontSize: 6.5,
      fontWeight: 'bold',
      marginBottom: 1,
      width: '100%'
    },
    firstLabelModel: {
      fontFamily: 'Times-Roman',
      fontSize: 4,
      paddingHorizontal: 1,
      fontWeight: 'bold'
    },
    fifthLabelModel: {
      fontFamily: 'Times-Roman',
      fontSize: 12,
      paddingHorizontal: 1,
      fontWeight: 'bold'
    },
    fifthLabelPrice: {
      fontFamily: 'Times-Roman',
      fontSize: 20,
      fontWeight: 'bold'
    }
  });
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }} color="primary">
        <Toolbar>
          <Button
            onClick={() => {
              setIsOver(false);
              handleCloseOptions();
              handleClose();
            }}
            className="m-2 btn-primary">
            Close
          </Button>
        </Toolbar>
      </AppBar>

      <div className="d-flex align-items-center justify-content-center">
        <PDFViewer style={styles.PdfViewer}>
          <Document>
            {infos?.map((elem) => {
              return printStockQty ? (
                [...Array(elem.quantity)].map((_elementInArray, index) => (
                  <Page
                    size={{
                      width: Number(barecodeSize.labelSize.dimensions[0]) * 72,
                      height: Number(barecodeSize.labelSize.dimensions[1]) * 72
                    }}
                    style={styles.page}
                    key={`page-${index}`}>
                    <View>
                      <Text
                        style={
                          barecodeSize.id === 0
                            ? [styles.title, { fontSize: 4.5 }]
                            : styles.title
                        }>
                        {showProductName && elem.title.substring(0, 38)}
                      </Text>
                      <Text
                        style={
                          barecodeSize.id === 0
                            ? styles.firstLabelModel
                            : barecodeSize.id === 5
                            ? styles.fifthLabelModel
                            : styles.model
                        }>
                        {showProductModel &&
                          elem.aspects?.Model &&
                          `Model: ${elem.aspects?.Model}`}
                      </Text>
                    </View>

                    <View
                      style={
                        barecodeSize?.id === 0
                          ? styles.firstLabelBottomSection
                          : styles.bottomSection
                      }>
                      <View
                        style={
                          barecodeSize.id === 0
                            ? styles.firstLabelBottomSectionPrice
                            : styles.bottomSectionPrice
                        }>
                        <Text
                          style={
                            barecodeSize?.id === 0
                              ? styles.firstLabelPrice
                              : barecodeSize?.id === 5
                              ? styles.fifthLabelPrice
                              : styles.price
                          }>
                          {showProductPrice && `$${elem.price}`}
                        </Text>
                      </View>
                      <View style={styles.bottomSectionElemBc}>
                        <BarcodeRender data={elem.cl_upc} />
                      </View>
                    </View>
                  </Page>
                ))
              ) : (
                <Page
                  size={{
                    width: Number(barecodeSize.labelSize.dimensions[0]) * 72,
                    height: Number(barecodeSize.labelSize.dimensions[1]) * 72
                  }}
                  style={styles.page}>
                  <View>
                    <Text
                      style={
                        barecodeSize.id === 0
                          ? [styles.title, { fontSize: 4.5 }]
                          : styles.title
                      }>
                      {showProductName && elem.title.substring(0, 38)}
                    </Text>
                    <Text
                      style={
                        barecodeSize.id === 0
                          ? styles.firstLabelModel
                          : barecodeSize.id === 5
                          ? styles.fifthLabelModel
                          : styles.model
                      }>
                      {showProductModel &&
                        elem.aspects?.Model &&
                        `Model: ${elem.aspects?.Model}`}
                    </Text>
                  </View>

                  <View
                    style={
                      barecodeSize?.id === 0
                        ? styles.firstLabelBottomSection
                        : styles.bottomSection
                    }>
                    <View
                      style={
                        barecodeSize.id === 0
                          ? styles.firstLabelBottomSectionPrice
                          : styles.bottomSectionPrice
                      }>
                      <Text
                        style={
                          barecodeSize?.id === 0
                            ? styles.firstLabelPrice
                            : barecodeSize?.id === 5
                            ? styles.fifthLabelPrice
                            : styles.price
                        }>
                        {showProductPrice && `$${elem.price}`}
                      </Text>
                    </View>
                    <View style={styles.bottomSectionElemBc}>
                      <BarcodeRender data={elem.cl_upc} />
                    </View>
                  </View>
                </Page>
              );
            })}
          </Document>
        </PDFViewer>
      </div>
    </Dialog>
  );
};

export default BcPdf;