import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { Button, Dialog, Typography } from '@material-ui/core';

const PopUpWarningCode = ({ showWarning, undoUpdate, saveUpdate }) => {
  return (
    <Dialog open={showWarning}>
      <div className="text-center p-5">
        <WarningIcon fontSize="large" className="text-danger" />

        <div className="mt-4">
          <Typography fontSize={14} fontWeight="bold">
            Save the updates before entering new barcode
          </Typography>
        </div>
        <p className="mb-0 mt-2 text-muted">
          Once you chose you can't undo it after
        </p>
        <div className="pt-4">
          <Button
            variant="contained"
            className="btn-pill m-2 btn-success"
            onClick={saveUpdate}>
            Save Update
          </Button>
          <Button
            onClick={undoUpdate}
            variant="contained"
            className="btn-pill m-2 btn-danger">
            Undo Update
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default PopUpWarningCode;
